
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  id: null,
  status: 'active',
  type: 'Stock Item',
  factoringPercentage: 0,
  description: '',
  inventoryStatus: 0,
  weight: 0,
  wholesalePrice: 0,
  salesPrice: 0,
  itemCost: 0,
  brandId: '',
  warehouseId: '',
  itemUnit: 'E',
  item: '',
  tplWarehouseItem: {
    sku: ''
  }
};

let defaultFilters = {
  showDetailedView: false,
  status: 'active',
  warehouseId: '',
  brandId: '',
  type: '',
  inventoryStatus: -1,
  projectId: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ItemModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

export default ItemModule;
