
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  sku: '',
  orderNumber: '',
  brandId: '',
  status: '',
  warehouseId: ''
};

let defaultFilters = {
  sku: '',
  orderNumber: '',
  brandId: '',
  status: '',
  warehouseId: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};
