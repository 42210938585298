import { render, staticRenderFns } from "./BsSelect.vue?vue&type=template&id=c56c1a58&scoped=true"
import script from "./BsSelect.vue?vue&type=script&lang=js"
export * from "./BsSelect.vue?vue&type=script&lang=js"
import style0 from "./BsSelect.vue?vue&type=style&index=0&id=c56c1a58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../LacoreClientApp/wwwroot/dashboard/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c56c1a58",
  null
  
)

export default component.exports