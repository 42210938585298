<template>
  <b-row>
    <div class="col-12 col-sm-9">
      <section class="content">
      <form @submit.prevent data-vv-scope="itemForm">
        <b-card class="mb-3" :header="heading" no-body>
          <div class="card-body">
            <b-row>
                <b-form-group class="col-12 col-sm-6" label="Type:">
                  <b-v-select
                    v-model="itemForm.type"
                    :searchable="true"
                    :disabled="isEditing"
                    :options="productTypeList"
                  />
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" label="Item Group:"
                  v-if="itemForm.id && !has('pm.is_end_partner')">
                  <b-v-select
                    v-model="itemForm.warehouseProductGroupId"
                    :searchable="true"
                    :options="productGroupsList"
                  />
                </b-form-group>
                <b-form-group
                  class="col-12 col-sm-6"
                  label="Warehouse:"
                >
                  <b-v-select
                    v-model="itemForm.warehouseId"
                    :searchable="true"
                    :disabled="isEditing"
                    :options="warehousesListAll"
                  />
                </b-form-group>
                <b-form-group class="col-12 col-sm-6" label="Client:">
                  <b-v-select
                    v-model="itemForm.brandId"
                    :searchable="true"
                    :disabled="isEditing"
                    :options="brandsFilter"
                  />
                </b-form-group>

                <b-form-group class="col-6" label="Item SKU:">
                  <b-form-input
                  v-validate="{ required: true, min: 2 }"
                  name="form-input-item"
                  :state="
                    validateStateScoped('itemForm', 'form-input-item')
                  "
                  :disabled="!canUpdateFields"
                   v-model="itemForm.item" />
                </b-form-group>
                <b-form-group class="col-12 col-md-6" label="Name:">
                  <b-form-input
                  v-validate="{ required: true, min: 3 }"
                  name="form-input-description"
                  :state="
                    validateStateScoped('itemForm', 'form-input-description')
                  "
                  :disabled="!canUpdateFields"
                  v-model="itemForm.description" />
                </b-form-group>
                <b-form-group
                  class="col-12 col-sm-6"
                  label="Status:"
                  v-if="itemForm.id && !has('pm.is_end_partner') && itemForm.status != 'pending'"
                >
                  <b-v-select
                  v-validate="{ required: true }"
                  name="form-input-status"
                  :state="
                    validateStateScoped('itemForm', 'form-input-status')
                  "
                    :disabled="!canUpdateFields"
                    v-model="itemForm.status"
                    :searchable="true"
                    :options="warehouseItemsStatusListOpts"
                  />
                </b-form-group>
            </b-row>
          </div>
        </b-card>
        <b-card class="mb-3" header="Item Config" no-body v-if="itemForm.brandProduct && !has('pm.is_end_partner')">
          <div class="card-body">
            <b-row>
          <b-form-group class="col-12 col-md-6" label="Primary Uom">
            <b-v-select
              v-model="itemForm.brandProduct.primaryUom"
              :searchable="true"
              :disabled="itemForm.brandProduct.id"
              :options="uomList"
            />
          </b-form-group>
          <div class="col-12 border-top m-2"></div>
          <b-form-group class="col-12 col-md-6" label="Movable Unit Weight (lbs):" description="A movable unit is generally considered a pallet.">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
            name="form-input-packagingUom"
              v-validate="{ required: true, min: 0.001 }"
              :state="validateState('form-input-packagingUom')" type="number" step="0.001" v-model="itemForm.brandProduct.movableUnitWeight" />
          </b-form-group>
          <b-form-group
               class="col-12 col-md-6" label="Units in Movable Unit:" description="A movable unit is generally considered a pallet.">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
            name="form-input-movableUnitNumOfUnits"
              v-validate="{ required: true, min: 1 }"
              :state="validateState('form-input-movableUnitNumOfUnits')"
               type="number" step="1" v-model="itemForm.brandProduct.movableUnitNumOfUnits" />
          </b-form-group>
          <div class="col-12 border-top m-2"></div>
          <b-form-group class="col-12 col-md-4" label="Track Serial Number">
            <b-v-select
            name="form-input-trackSerialNumber"
              v-validate="{ required: true }"
              :state="validateState('form-input-trackSerialNumber')"
              v-model="itemForm.brandProduct.trackSerialNumber"
              :disabled="itemForm.brandProduct.id"
              :options="trackListOpts"
            />
          </b-form-group>
          <b-form-group class="col-12 col-md-4" label="Track Expiration date">
            <b-v-select
            name="form-input-trackExpirationDate"
              v-validate="{ required: true }"
              :state="validateState('form-input-trackExpirationDate')"
              v-model="itemForm.brandProduct.trackExpirationDate"
              :disabled="itemForm.brandProduct.id"
              :options="trackListOpts"
            />
          </b-form-group>
          <b-form-group class="col-12 col-md-4" label="Track Lot Number">
            <b-v-select
            name="form-input-trackLotNumber"
              v-validate="{ required: true }"
              :state="validateState('form-input-trackLotNumber')"
              v-model="itemForm.brandProduct.trackLotNumber"
              :disabled="itemForm.brandProduct.id"
              :options="trackListOpts"
            />
          </b-form-group>
          <div class="col-12 border-top m-2"></div>
          <b-form-group class="col-12 col-md-6" label="Packaging Uom">
            <b-v-select
            name="form-input-packagingUom"
              v-validate="{ required: true, min: 1 }"
              :state="validateState('form-input-packagingUom')"
              v-model="itemForm.brandProduct.packagingUom"
              :searchable="true"
              :disabled="itemForm.brandProduct.id"
              :options="uomList"
            />
          </b-form-group>
          <b-form-group class="col-12 col-md-6" label="Units in Packaging:">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
            name="form-input-packagingUnitNumOfUnits"
              v-validate="{ required: true, min: 1 }"
              :state="validateState('form-input-packagingUnitNumOfUnits')"
               type="number" step="1" v-model="itemForm.brandProduct.packagingUnitNumOfUnits" />
          </b-form-group>
          <b-form-group class="col-12 col-md-4" label="Packaging Unit Length (in):">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
              name="form-input-packagingUnitLength"
              v-validate="{ required: true, min: 0 }"
              :state="validateState('form-input-packagingUnitLength')"
               type="number" step="1" v-model="itemForm.brandProduct.packagingUnitLength" />
          </b-form-group>
          <b-form-group class="col-12 col-md-4" label="Packaging Unit Width (in):">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
              name="form-input-packagingUnitLength"
              v-validate="{ required: true, min: 0 }"
              :state="validateState('form-input-packagingUnitWidth')"
               type="number" step="1" v-model="itemForm.brandProduct.packagingUnitWidth" />
          </b-form-group>
          <b-form-group class="col-12 col-md-4" label="Packaging Unit Height (in):">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
              name="form-input-packagingUnitLength"
              v-validate="{ required: true, min: 0 }"
              :state="validateState('form-input-packagingUnitHeight')"
               type="number" step="1" v-model="itemForm.brandProduct.packagingUnitHeight" />
          </b-form-group>
          <b-form-group class="col-12 col-md-6" label="Packaging Unit Net Weight (in):">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
              name="form-input-netWeight"
              v-validate="{ required: true, min: 0.0001 }"
              :state="validateState('form-input-netWeight')"
               type="number" step="1" v-model="itemForm.brandProduct.netWeight" />
          </b-form-group>
          <b-form-group class="col-12 col-md-6" label="Packaging Unit Gross Weight (in):">
            <b-form-input
              :disabled="itemForm.brandProduct.id"
              name="form-input-weight"
              v-validate="{ required: true, min: 0.0001 }"
              :state="validateState('form-input-weight')"
              type="number" step="1" v-model="itemForm.brandProduct.weight" />
          </b-form-group>
            </b-row>
          </div>
        </b-card>
        <b-card v-else class="mb-3" header="Item Config" no-body>
          <div class="card-body">
            <b-row>
              <b-form-group
                v-if="!has('pm.is_end_partner')"
                class="col-12 col-md-6"
                label="Wholesale Price:"
                description="Used in factoring calculations."
              >
                <b-form-input
                  v-validate="{ required: false }"
                  name="form-input-wholesalePrice"
                  :state="
                    validateStateScoped('itemForm', 'form-input-wholesalePrice')
                  "
                  type="number"
                  step="0.01"
                  :disabled="!canUpdateFields"
                  v-model="itemForm.wholesalePrice" />
              </b-form-group>
              <b-form-group
                v-if="!has('pm.is_end_partner')"
                class="col-12 col-md-6"
                label="Item Cost:"
                description="Default cost of item used in Purchase Orders."
              >
                <b-form-input
                v-validate="{ required: false }"
                name="form-input-itemCost"
                :state="
                  validateStateScoped('itemForm', 'form-input-itemCost')
                "
                type="number"
                step="0.01"
                v-model="itemForm.itemCost" />
              </b-form-group>

              <b-form-group class="col-12"
                v-if="is('developer')"
                label="Sku in 4PL Warehouse:" description="If 4PL tracks the item under a different SKU, you can enter that here, so 4PL inventory tracking works for the item.">
                <b-form-input
                v-validate="{ required: false }"
                name="form-input-controlItem"
                :state="
                  validateStateScoped('itemForm', 'form-input-controlItem')
                "
                v-model="itemForm.controlItem" />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Default Sales Price:"
                v-if="!has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.salesPrice" />
              </b-form-group>
              <b-form-group
                class="col-12 col-md-6"
                label="Harmonized Tariff Code:"
                v-if="!has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.hsCode" />
              </b-form-group>

              <b-form-group
                class="col-12 col-md-6"
                label="Default Sales Price:"
                v-if="has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.salesPrice" disabled />
              </b-form-group>
              <b-form-group
                class="col-12 col-md-6"
                label="Harmonized Tariff Code:"
                v-if="has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.hsCode" disabled />
              </b-form-group>
              <b-form-group
                class="col-12 col-md-6"
                label="Gross Weight (lbs):"
                v-if="has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.weight" disabled />
              </b-form-group>
              <b-form-group
                class="col-12 col-md-6"
                label="Net Weight (lbs):"
                v-if="has('pm.is_end_partner')"
              >
                <b-form-input v-model="itemForm.netWeight" disabled />
              </b-form-group>
              <b-form-group class="col-12 col-md-6" label="Measurement Unit:"
                  v-if="itemForm.tplWarehouseItem == null && !has('pm.is_end_partner')"
                  >
                <b-v-select
                  v-model="itemForm.itemUnit"
                  :searchable="true"
                  :options="uomList"
                />
              </b-form-group>
            </b-row>
          </div>
        </b-card>
        </form>
      </section>
    </div>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <button
          class="btn btn-block btn-success"
          type="submit"
          v-if="!has('pm.is_end_partner') && itemForm.status == 'pending'"
          @click="SaveFormAsApproved"
        >
          Approve
        </button>
        <button
          class="btn btn-block btn-primary"
          type="submit"
          v-if="!has('pm.is_end_partner')"
          @click="SaveForm"
        >
          Save Changes
        </button>
        <router-link
          class="mt-2 btn btn-block btn-danger"
          :to="{ name: 'Warehouses.Items' }"
        >
          Back To List
        </router-link>
      </b-card>
    </aside>
  </b-row>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'Warehouses.WarehouseItems.Form',
  mixins: [crudMixinGen('warehouseProducts'), myCommonDataMixin],
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    heading: 'Warehouse Item',
    productGroupsAll: []
  }),

  computed: {
    productGroupsList: {
      get() {
        let itmsList = [];
        let myItems = this.productGroupsAll;
        itmsList.push({ value: null, text: ' - None -' });
        if(myItems) {
          myItems.forEach(itm => {
            itmsList.push({ value: itm.id, text: itm.name });
          });
        }
        return itmsList;
      },
      set() {
      }
    },
    isEditing: {
      get() {
        let canUpd = false;
        if(this.itemForm.id)
        {
          canUpd = true;
        }
        return canUpd;
      },
      set(val) {
        //his.$store.dispatch('setFacilitiesList', val);
      }
    },
    canUpdateFields: {
      get() {
        let canUpd = true;
        if(this.has('pm.is_end_partner'))
        {
          canUpd = false;
        }
        else if(this.itemForm && this.itemForm.id) {
          //canUpd = false;
        }
        return canUpd;
      },
      set(val) {
        //his.$store.dispatch('setFacilitiesList', val);
      }
    },
    warehouseItemsStatusListOpts: {
      get() {
        let itmsList = [];
        let myItems = this.warehouseItemsStatusList;
        if(myItems) {
          itmsList = myItems.filter(a => a.value != 'pending');
        }
        return itmsList;
      },
      set(val) {
        //his.$store.dispatch('setFacilitiesList', val);
      }
    },
    ...mapGetters({
      uomList: 'getUOMList',
      productTypeList: 'getProductTypeList',
      warehouseItemsStatusList: 'getWarehouseItemsStatusList',
      getUOMName: 'getUOMName'
    }),
  },

  watch: {
  },

  mounted() {
    this.ResetForm();
    if(!this.has('pm.is_end_partner'))
      this.$store.dispatch('fetchMyTPLData');
    if (this.id) {
      this.LoadItem(this.id).then((resp) => {
        this.itemForm = resp.data;
        this.LoadProductGroups(this.itemForm.brandId);
        if(this.itemForm.tplWarehouseItem != null && this.itemForm.tplWarehouseItem.options != null && this.itemForm.tplWarehouseItem.options.trackBys != null) {
          if(this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber == 1) {
            this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber = true;
          } else {
            this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber = false;
          }
          if(this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate == 1) {
            this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate = true;
          } else {
            this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate = false;
          }
          if(this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber == 1) {
            this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber = true;
          } else {
            this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber = false;
          }
          if(this.itemForm.tplWarehouseItem.options.trackBys.trackCost == 1) {
            this.itemForm.tplWarehouseItem.options.trackBys.trackCost = true;
          } else {
            this.itemForm.tplWarehouseItem.options.trackBys.trackCost = false;
          }

        }
      })
    } else {
      this.readyToLoad = true;
    }
  },

  methods: {

    LoadProductGroups(brandId) {
      this.$http.get('/api/common/warehouseProductGroups/forBrand/' + brandId)
        .then((resp) => {
          this.productGroupsAll = resp.data;
        })
        .catch((err) => {
          this.productGroupsAll = [];
        })
    },
    SaveFormAsApproved() {
      this.itemForm.status = 'active';
      this.SaveForm();
    },
    SaveForm() {
      if(this.itemForm.tplWarehouseItem != null && this.itemForm.tplWarehouseItem.options != null && this.itemForm.tplWarehouseItem.options.trackBys != null) {
        if(this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber) {
          this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber = 1;
        } else {
          this.itemForm.tplWarehouseItem.options.trackBys.trackLotNumber = 0;
        }
        if(this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate) {
          this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate = 1;
        } else {
          this.itemForm.tplWarehouseItem.options.trackBys.trackExpirationDate = 0;
        }
        if(this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber) {
          this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber = 1;
        } else {
          this.itemForm.tplWarehouseItem.options.trackBys.trackSerialNumber = 0;
        }
        if(this.itemForm.tplWarehouseItem.options.trackBys.trackCost) {
          this.itemForm.tplWarehouseItem.options.trackBys.trackCost = 1;
        } else {
          this.itemForm.tplWarehouseItem.options.trackBys.trackCost = 0;
        }
      }
      this.SaveItem()
      .then(resp => {
        if (resp.status) {
          if (resp.id) {
            this.$swal({ title: 'Warehouse Item Was Saved.', icon: 'info' });
          } else {
            this.$swal({ title: 'Warehouse Item was added.', icon: 'success' });
          }
          this.$router.push({
            name: 'Warehouses.Items'
          });
        } else {
          this.$swal({ title: resp.errorMessage, icon: 'error' });
        }
      })
      .catch(err => {
        console.error('saved po err', err);
      });
    }
  }
};
</script>
