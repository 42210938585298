export const itemsBaseApiPath = '/api/common/tickets';

import {
  fetchItemsGen,
  deleteItemGen,
  getItemByIdGen,
  addItemGen,
  editItemGen
} from '../../../utils/apiHelperGenerators.js';

export const fetchItems = fetchItemsGen(itemsBaseApiPath, 'listItems');
export const deleteItem = deleteItemGen(itemsBaseApiPath);
export const getItemById = getItemByIdGen(itemsBaseApiPath);
export const addItem = addItemGen(itemsBaseApiPath);
export const editItem = editItemGen(itemsBaseApiPath);

export default {
  fetchItems,
  deleteItem,
  getItemById,
  addItem,
  editItem
};
