export default [
  /*  Communications */
  {
    path: '/communications/:openChannelId?',
    name: 'Communications.Index',
    props: true,
    meta: {
      label: 'Communications Center',
      authorized: true
    },
    component: () => import('@/views/apps/communications/Index.vue')
  },
  {
    path: '/communications/view/:openChannelId?',
    name: 'Communications.Index2',
    props: true,
    meta: {
      label: 'Communications Center',
      authorized: true
    },
    component: () => import('@/views/apps/communications/Index.vue')
  },

  {
    path: '/user/my-account',
    name: 'user-my-account',
    component: () => import('@/views/apps/user/MyAccount.vue')
  },
  // Webhooks Log
  {
    path: '/log/webhooks',
    name: 'Manage.WebhooksLog.Index',
    component: () => import('@/views/apps/webhooks-log/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.api.automation.webhooks.view'
    }
  },
  // R&D Manufacturing
  {
    path: '/rnd/projects/list',
    name: 'Manufacturing.RnDProjects.Index',
    component: () => import('@/views/apps/rndProjects/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.manufacturing.rndProjects.view'
    }
  },
  {
    path: '/rnd/projects/view/:id',
    props: true,
    name: 'Manufacturing.RnDProjects.View',
    component: () => import('@/views/apps/rndProjects/view/Index.vue'),
    meta: {
      //permissionRequired: 'pm.customerBilling.invoices.view'
    }
  },
  // Invoices
  {
    path: '/customer-invoices/list',
    name: 'Manage.CustomerInvoices.Index',
    component: () => import('@/views/apps/customer-invoices/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.customerBilling.invoices.view'
    }
  },
  // Automations
  {
    path: '/manage/automations/list',
    name: 'Other.Automation.Index',
    component: () => import('@/views/apps/automations/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },
  {
    path: '/manage/automations/create',
    name: 'Other.Automation.Create',
    component: () => import('@/views/apps/automations/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },
  {
    path: '/manage/automations/view/:id',
    name: 'Other.Automation.View',
    props: true,
    component: () => import('@/views/apps/automations/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },
  // Automations: Templates
  {
    path: '/manage/automationTemplates/list',
    name: 'Other.ContentTemplates.Index',
    component: () => import('@/views/apps/automationsContent/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },
  {
    path: '/manage/automationTemplates/create',
    name: 'Other.ContentTemplates.Create',
    component: () => import('@/views/apps/automationsContent/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },
  {
    path: '/manage/automationTemplates/view/:id',
    name: 'Other.ContentTemplates.View',
    props: true,
    component: () => import('@/views/apps/automationsContent/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.automation.triggers'
    }
  },

  // SmallParcel Shipments
  {
    path: '/smallparcel-shipments/list',
    name: 'Warehouses.ManualShipments.Index',
    component: () => import('@/views/apps/smallparcel-shipments/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.view'
    }
  },
  {
    path: '/smallparcel-shipments/create',
    name: 'Warehouses.ManualShipments.Create',
    component: () => import('@/views/apps/smallparcel-shipments/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.request'
    }
  },
  {
    path: '/smallparcel-shipments/view/:id',
    name: 'Warehouses.ManualShipments.View',
    props: true,
    component: () => import('@/views/apps/smallparcel-shipments/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.view'
    }
  },
  // Bulk Shipments
  {
    path: '/bulk-shipments/list',
    name: 'Warehouses.BulkShipments.Index',
    component: () => import('@/views/apps/bulk-shipments/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.view'
    }
  },
  {
    path: '/bulk-shipments/create',
    name: 'Warehouses.BulkShipments.Create',
    component: () => import('@/views/apps/bulk-shipments/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.request'
    }
  },
  {
    path: '/bulk-shipments/view/:id',
    name: 'Warehouses.BulkShipments.View',
    props: true,
    component: () => import('@/views/apps/bulk-shipments/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.bulkShipments.view'
    }
  },
  // ASNs
  {
    path: '/asns/list',
    name: 'Warehouses.AdvancedShipNotice.Index',
    component: () => import('@/views/apps/asns/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.asn.view'
    }
  },
  {
    path: '/asns/view/:id',
    name: 'Warehouses.AdvancedShipNotice.View',
    props: { default: true, isWT: false },
    component: () => import('@/views/apps/asns/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.asn.view'
    }
  },
  {
    path: '/asns/create',
    name: 'Warehouses.AdvancedShipNotice.Create',
    component: () => import('@/views/apps/asns/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.asn.create'
    }
  },
  // warehouse Transfers
  {
    path: '/warehouse-transfers/list',
    name: 'Warehouses.WarehouseTransfers.Index',
    component: () => import('@/views/apps/warehouse-transfers/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.wt.view'
    }
  },
  {
    path: '/warehouse-transfers/view/:id',
    name: 'Warehouses.WarehouseTransfers.View',
    props: { default: true, isWT: true },
    component: () => import('@/views/apps/warehouse-transfers/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.wt.view'
    }
  },
  {
    path: '/warehouse-transfers/create',
    name: 'Warehouses.WarehouseTransfers.Create',
    component: () => import('@/views/apps/warehouse-transfers/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.wt.create'
    }
  },
  {
    path: '/purchase-orders/list',
    name: 'Warehouses.PurchaseOrders.Index',
    component: () => import('@/views/apps/purchase-orders/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.purchaseOrders.view'
    }
  },
  {
    path: '/purchase-orders/:purchaseOrderId/create',
    name: 'Warehouses.PurchaseOrders.CreateShipment',
    props: true,
    component: () => import('@/views/apps/purchase-orders/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.asn.create'
    }
  },
  {
    path: '/purchase-orders/view/:id',
    name: 'Warehouses.PurchaseOrders.View',
    props: true,
    component: () => import('@/views/apps/purchase-orders/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.purchaseOrders.view'
    }
  },
  {
    path: '/purchase-orders/edit/:id',
    name: 'Warehouses.PurchaseOrders.Edit',
    props: true,
    component: () => import('@/views/apps/purchase-orders/form/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.purchaseOrders.edit'
    }
  },
  {
    path: '/purchase-orders/Create',
    name: 'Warehouses.PurchaseOrders.Create',
    component: () => import('@/views/apps/purchase-orders/form/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.purchaseOrders.create'
    }
  },

  {
    path: '/reports/stats/warehouse-items',
    name: 'apps-reports-warehouse-stats',
    component: () => import('@/views/apps/reports/wms-stats/list/ListItems.vue')
  },

  {
    path: '/reports/stats/storefront-products',
    name: 'apps-reports-storefront-stats',
    component: () =>
      import('@/views/apps/reports/storefront-stats/list/ListItems.vue'),
      meta: {
        permissionRequired: 'pm.reports.storefrontProdStats'
      }
  },

  {
    path: '/warehouse-items/list',
    name: 'Warehouses.Items',//'apps-inventory-items-list',
    component: () => import('@/views/apps/warehouse-items/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.items.view'
    }
  },
  {
    path: '/warehouse-items/byLocation/list',
    name: 'Warehouses.Items.Locations',//'apps-inventory-items-list',
    component: () => import('@/views/apps/warehouse-items/list/ListItemsByLocation.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.items.viewInventoryByLocation'
    }
  },
  {
    path: '/warehouse-items/item/:id',
    props: true,
    name: 'Warehouses.Items.Form',
    component: () => import('@/views/apps/warehouse-items/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.warehouses.items.view'
    }
  },
  // Orders
  {
    path: '/customer-orders/import',
    name: 'Orders.Index.Import',
    component: () => import('@/views/apps/customer-orders/create/Import.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/list-all',
    name: 'Orders.Index.All',
    component: () => import('@/views/apps/customer-orders/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/list',
    name: 'Orders.Index',
    component: () => import('@/views/apps/customer-orders/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/list',
    name: 'Orders.Index.Filtered',
    component: () => import('@/views/apps/customer-orders/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/returns',
    name: 'Storefronts.OrderReturns.Index',
    props: { default: true, splitRecs: true },
    component: () => import('@/views/apps/customer-orders/returns/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.orderReturns.view'
    }
  },
  {
    path: '/customer-orders/returns/view/:orderReturnId?',
    name: 'Storefronts.OrderReturns.Edit',
    props: true,
    component: () => import('@/views/apps/customer-orders/returns/Form.vue'),
    meta: {
      permissionRequired: 'pm.orderReturns.view'
    }
  },
  {
    path: '/customer-orders/split-recs',
    name: 'Orders.Index.SplitRecs',
    props: { default: true, splitRecs: true },
    component: () => import('@/views/apps/customer-orders/list/ListItems.vue'),
    meta: {
      permissionRequired: 'pm.orders.splitOrder'
    }
  },

  {
    path: '/customer-orders/view',
    name: 'apps-customer-orders-view',
    component: () => import('@/views/apps/customer-orders/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/view',
    name: 'Common.OrderDetails',
    component: () => import('@/views/apps/customer-orders/view/Index.vue'),
    meta: {
      permissionRequired: 'pm.orders.view'
    }
  },
  {
    path: '/customer-orders/create',
    name: 'apps-customer-orders-create',
    component: () => import('@/views/apps/customer-orders/create/Index.vue'),
    meta: {
      permissionRequired: 'pm.orders.create'
    }
  },

  {
    path: '/docs',
    name: 'DocsHome',
    meta: {
      label: 'Docs',
      authorized: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "DocsHome" */ '@/views/apps/other/DocsHome'
      );
    }
  },
  {
    path: '/docs/article/:slug',
    name: 'ViewKnowledgeArticle',
    props: true,
    meta: {
      label: 'Docs Article',
      authorized: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "ViewKnowledgeArticle" */ '@/views/apps/other/ViewKnowledgeArticle'
      );
    }
  },
  {
    path: '/docs/group/:slug',
    name: 'ViewKnowledgeGroup',
    props: true,
    meta: {
      label: 'Article Group',
      authorized: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "ViewKnowledgeGroup" */ '@/views/apps/other/ViewKnowledgeGroup'
      );
    }
  },
];
