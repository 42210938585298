<template>
  <section class="row" v-if="itemForm.id">
    <div class="col-md-8 changelog-page">
      <b-card :key="itemForm.id">
      <template #header>
        <div class="d-flex">
          <router-link class="btn btn-warning" :to="{
              name: 'ViewKnowledgeGroup',
              params: { slug: itemForm.knowledgebaseGroup.slug }
            }">
            <i class="fa fa-angle-left"></i>
            Back
          </router-link>
          <h4 class="mb-0 pl-3 pt-1">{{ itemForm.name }}</h4>
        </div>
      </template>

      <div class="vue-prosemirror changelogentry" style="color: #495057;" >
        <vue-markdown :key="itemForm.content" :initialValue="itemForm.content">{{itemForm.content}}</vue-markdown>
      </div>
    </b-card>
    </div>
    <div class="col-md-4">
    </div>
  </section>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'ChangelogPublic',
  mixins: [crudMixinGen('docs/knowledgebasePages'), myCommonDataMixin],
  props: {
    slug: {
      type: String,
      default: '',
      required: false
    }
  },
  components: {
    'vue-markdown': Viewer,
  },

  data: () => ({
  }),
  computed: {
  },

  watch: {
    'slug': function(val) {
      this.slug = val;
      this.$http.get('/api/docs/knowledgebasePages/'+this.slug)
        .then((resp) => {
          this.itemForm = resp.data;
        })
    },
  },
  mounted() {
    if (this.slug) {
      this.$http.get('/api/docs/knowledgebasePages/'+this.slug)
        .then((resp) => {
          this.itemForm = resp.data;
        })
    }
  },

  methods: {
  }
};
</script>
