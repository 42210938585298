
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  weightOz: 0,
  price: 0,
  active: true,
  projectId: '',
  name: '',
  sku: '',
  id: null,
  type: 'Stock Item',
  onFulfillmentIgnore: false,
  onFulfillmentHold: false,
};

let defaultFilters = {
  null: false,
  projectId: '',
  name: '',
  mappedStatus: '',
  retiredStatus: '',
  sku: '',
  type: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
