import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  type: 0,
  userNote: '',
  shippings: [],
  projectId: '',
  brandId: null,
  vendorId: null,
  orderNumber: '',
  expectedDate: '',
  shippingFee: 0,
  otherFees: 0,
  orderStatus: 0,
  items: []
};

let defaultFilters = {
  brandId: '',
  isDue: '',
  customerRequested: false,
  orderStatus: 'Non-Archived',
  itemSKU: '',
  orderNumber: '',
  type: 0,
  startReceivedDate: '',
  endReceivedDate: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
