export const itemsBaseApiPath = '/api/manufacturing/client/rndProjects';
import { apiHelperGenerators } from '@/shared';
const {
  fetchItemsGen,
  deleteItemGen,
  getItemByIdGen,
  editItemGen,
  addItemGen
} = apiHelperGenerators;

export const fetchItems = fetchItemsGen(itemsBaseApiPath, 'list');
export const deleteItem = deleteItemGen(itemsBaseApiPath);
export const getItemById = getItemByIdGen(itemsBaseApiPath);
export const addItem = addItemGen(itemsBaseApiPath);
export const editItem = editItemGen(itemsBaseApiPath);

export default {
  fetchItems,
  deleteItem,
  getItemById,
  addItem,
  editItem
};
