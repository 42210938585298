//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  orderNumber: '',
};

let defaultFilters = {
  warehouseId: '',
  brandId: '',
  lotNumber: '',
  lpn: '',
  location: '',
  expirationDateStart: '',
  expirationDateEnd: '',
  item: '',
  description: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ItemModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

export default ItemModule;
