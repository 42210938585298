<template>
  <b-card no-body>
    <b-card-header>
      <div class="w-100">
        <b-row>
              <!-- title and subtitle -->
              <div class="col-12 col-sm">
                <b-card-title class="mb-1 pt-2" title-tag="h5">
                  Sold in Storefronts
                </b-card-title>
                <b-card-sub-title></b-card-sub-title>
              </div>
              <!--/ title and subtitle -->

              <!-- badge -->
              <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1 col-12 col-sm-auto">
                <!-- dropdown button -->
                <div>
                  <div class="pr-3">
                    <span class="badge badge-secondary mr-1">AVG SOLD / DAY:</span><span>{{ avgSoldForPeriod }}</span>
                  </div>
                </div>
                <div>
                  <b-form-group class="pt-1 mb-1 mr-2">
                    <b-input-group prepend="Storefront">
                      <b-v-select
                        style="width: auto; min-width: 210px;"
                        name="form-input-projectId"
                        v-model="projectId"
                        :searchable="true"
                        :options="projectsFilter2"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
                <b-button-group class="mt-1 mt-sm-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="isLoadingChartData"
                    :class="{ active: daysBack == 30 }"
                    @click="reloadChart({ daysBack: 30 })"
                  >
                    30 days
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="isLoadingChartData"
                    :class="{ active: daysBack == 90 }"
                    @click="reloadChart({ daysBack: 90 })"
                  >
                    90 days
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="isLoadingChartData"
                    :class="{ active: daysBack == 180 }"
                    @click="reloadChart({ daysBack: 180 })"
                  >
                    6 Months
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="isLoadingChartData"
                    :class="{ active: daysBack == 365 }"
                    @click="reloadChart({ daysBack: 365 })"
                  >
                    a Year
                  </b-button>
                </b-button-group>
                <!--/ dropdown button -->
              </div>
              <!--/ badge -->
        </b-row>
      </div>
    </b-card-header>

    <b-card-body>
      <div class="text-center" v-if="isLoadingChartData">
        <b-spinner
          variant="primary"
          class="m-5"
          style="height: 4rem; width: 4rem"
          label="Loading..."
        ></b-spinner>
      </div>
      <vue-apex-charts
        v-else
        type="line"
        ref="chart"
        height="400"
        :options="chartData.chartOptions"
        :series="chartData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
let $themeColors = {
  'danger': '#3c4b64',
  'primary': '#3c4b64',
  'warning': '#ffc107',
  'light': '#17a2b8'
};
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';

export default {
  mixins: [myCommonDataMixin],
  props: {
    warehouseId: {
      required: true,
      default: ''
    },
    item: {
      required: true,
      default: ''
    },
    brandId: {
      required: true,
      default: ''
    },
    storefrontId: {
      required: false,
      default: ''
    }
  },
  directives: {
    Ripple
  },
  components: {
    VueApexCharts
  },
  data() {
    return {
      soldForPeriod: 0,
      daysSoldForPeriod: 0,
      avgSoldForPeriod: 0,
      activeDays: 30,
      projectId: '',
      loadedChartInfo: null,
      isLoadingChartData: true,
      daysBack: 30,
      chartData: {
        series: [
          {
            name: 'Sold Items',
            color: $themeColors.warning,
            data: []
          }
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light]
            //colors: [$themeColors.primary]
          },
          colors: [$themeColors.primary, $themeColors.warning],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          tooltip: {
            custom(data) {
              let seriesNames = ['Sold'];
              return `${'<div class="px-1 py-50"><span>'}${
                seriesNames[data.seriesIndex]
              } ${
                data.series[data.seriesIndex][data.dataPointIndex]
              } items</span></div>`;
            }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm'
              }
            }
          },
          yaxis: {
            // opposite: isRtl,
          }
        }
      }
    };
  },
  created() {
    // subcomponent, do not call common data
  },
  computed: {
    projectsFilter2: {
      get() {
        let arrs = [{ value: '', text: 'All' }];
        arrs = arrs.concat(this.projectsList.filter(a => a.brandId == this.brandId && a.warehouseId == this.warehouseId));
        return arrs;
      }
    }
  },
  watch: {
    'storefrontId': function(val) {
      if(this.projectId != val)
        this.projectId = val;
    },
    'projectId': function(val) {
      this.loadChart();
    },
    chartData: {
      handler: function(val) {
        // DOM updated
        if (this.$refs.chart) {
          this.$refs.chart.updateSeries(
            [
              {
                data: val.series[0].data
              }
            ],
            false,
            true
          );
        }
      },
      deep: true
    }
  },
  methods: {
    reloadChart({ daysBack } = { daysBack: 30 }) {
      this.activeDays = daysBack;
      this.loadChart();
    },
    loadChart() {
      this.soldForPeriod = 0;
      this.daysSoldForPeriod = 0;
      let daysBack = this.activeDays;
      this.isLoadingChartData = true;
      this.daysBack = daysBack;
      this.$http
        .post('/api/warehouseProducts/ordersByDay', {
          daysBack: daysBack,
          item: this.item,
          warehouseId: this.warehouseId,
          brandId: this.brandId
        })
        .then(resp => {
          this.isLoadingChartData = false;
          //let dataPoints = [];
          let dataPoints2 = [];
          this.loadedChartInfo = JSON.parse(JSON.stringify(resp.data));
          //let items = [];
          // if(this.projectId) {
          //   items = resp.data.itemsOrdered.filter(a => a.group.storefrontId == this.projectId);
          // } else {
          //   resp.data.itemsOrdered.forEach((itm) => {
          //     let fndGroupdItm = items.find(a => a.group.date == itm.group.date);
          //     if(fndGroupdItm == null) {
          //       fndGroupdItm = {
          //         group: {
          //           date: itm.group.date
          //         },
          //         quantity: 0
          //       };
          //       items.push(fndGroupdItm);
          //     }
          //     fndGroupdItm.quantity += itm.quantity;
          //   });
          // }
          // items.forEach(itm => {
          //   dataPoints.push({ x: new Date(itm.group.date), y: itm.quantity });
          // });
          //this.chartData.series[0].data = dataPoints;
          let itemsShipped = [];
          if(this.projectId) {
            resp.data.itemsOrdered.filter(a => a.group.storefrontId == this.projectId).forEach((itm) => {
              let fndGroupdItm = itemsShipped.find(a => a.group.date == itm.group.date);
              if(fndGroupdItm == null) {
                fndGroupdItm = {
                  group: {
                    date: itm.group.date
                  },
                  quantity: 0
                };
                itemsShipped.push(fndGroupdItm);
                this.daysSoldForPeriod++;
              }
              this.soldForPeriod += itm.quantity;
              fndGroupdItm.quantity += itm.quantity;
            });
          } else {
            resp.data.itemsOrdered.forEach((itm) => {
              let fndGroupdItm = itemsShipped.find(a => a.group.date == itm.group.date);
              if(fndGroupdItm == null) {
                fndGroupdItm = {
                  group: {
                    date: itm.group.date
                  },
                  quantity: 0
                };
                itemsShipped.push(fndGroupdItm);
                this.daysSoldForPeriod++;
              }
              this.soldForPeriod += itm.quantity;
              fndGroupdItm.quantity += itm.quantity;
            });
          }
          itemsShipped.forEach(itm => {
            dataPoints2.push({ x: new Date(itm.group.date), y: itm.quantity });
          });
          this.chartData.series[0].data = dataPoints2;
          this.avgSoldForPeriod = Math.round((this.soldForPeriod / this.daysSoldForPeriod),2);
        })
        .catch(err => {
          this.isLoadingChartData = false;
          this.chartData.series[0].data = [];
          //this.chartData.series[1].data = [];
        });
    }
  },
  mounted() {
    this.projectId = this.storefrontId;
    this.loadChart();
  }
};
</script>
