export default [
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/Ecommerce.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard-main',
    component: () => import('@/views/dashboard/Main.vue')
  }
];
