//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  accessVendorIds: [],
  accessBrandsIds: [],
  accessWarehouseIds: [],
  permissions: [],
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  newPassword: '',
  projectId: '',
  roleType: '',
  isManager: false,
  moreRoles: []
};

let defaultFilters = {
  search: '',
  brandId: '',
  email: '',
  warehouseId: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ItemModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state,
    humanReadablePermissions: [
      {"id": "pm.api.apikeys.view", "label": "View API Keys"},

      {"id": "pm.api.storefronts.view", "label": "API Access - View Storefronts"},
      {"id": "pm.api.storefronts.create", "label": "API Access - Manage Storefronts"},
      {"id": "pm.api.storefronts.disable", "label": "API Access - Disable Storefronts"},
      {"id": "pm.api.orderGroups.view", "label": "API Access - View Order Groups"},
      {"id": "pm.api.orderGroups.manage", "label": "API Access - Manage Order Groups"},
      {"id": "pm.api.warehouses.view", "label": "API Access - View Warehouses"},
      {"id": "pm.api.warehouses.manage", "label": "API Access - Manage Warehouses"},
      {"id": "pm.api.automation.webhooks.view", "label": "API Access - View Webhook Automation"},
      {"id": "pm.api.automation.webhooks.manage", "label": "API Access - Config Webhook Automation"},

      {"id": "pm.teams.users.view", "label": "View Team Members"},
      {"id": "pm.teams.users.invite", "label": "Invite Team Members"},
      {"id": "pm.teams.users.remove", "label": "Remove Team Members"},

      {"id": "pm.users.manage.view", "label": "View User Management"},
      {"id": "pm.users.manage.addOrUpdate", "label": "Add or Update Users"},
      {"id": "pm.support.tickets.viewAll", "label": "View All Support Tickets"},
      {"id": "pm.warehouses.purchaseOrders.workOrders.create", "label": "Create Work Orders under POs"},
      {"id": "pm.warehouses.purchaseOrders.workOrders.view", "label": "View Work Orders under POs"},
      {"id": "pm.automation.log", "label": "View Automation Log"},
      {"id": "pm.automation.triggers", "label": "Manage Automation Triggers"},
      {"id": "pm.automation.contentTemplates", "label": "Manage Content Templates"},
      {"id": "pm.support.tickets.create", "label": "Create Support Tickets"},
      {"id": "pm.support.tickets.update", "label": "Update Support Tickets"},
      {"id": "pm.clients.view", "label": "View Clients"},
      {"id": "pm.clients.manage", "label": "Manage Clients"},
      {"id": "pm.clients.addOrUpdate", "label": "Add or Update Clients"},
      {"id": "pm.storefronts.create", "label": "Create Storefronts"},
      {"id": "pm.storefronts.edit", "label": "Edit Storefronts"},
      {"id": "pm.storefronts.enableOrDisable", "label": "Enable or Disable Storefronts"},
      {"id": "pm.storefronts.retryAllVirtual", "label": "Mass Retry Virtual Orders"},
      {"id": "pm.storefronts.view", "label": "View Storefronts"},
      {"id": "pm.storefront.orderGroups.view", "label": "View Storefront Order Groups"},
      {"id": "pm.storefront.orderGroups.addOrUpdate", "label": "Add or Update Storefront Order Groups"},
      {"id": "pm.storefront.orderTags.create", "label": "Create Storefront Order Tags"},
      {"id": "pm.storefront.orderTags.view", "label": "View Storefront Order Tags"},
      {"id": "pm.storefront.products.create", "label": "Create Storefront Products"},
      {"id": "pm.storefront.products.manage", "label": "Manage Storefront Products"},
      {"id": "pm.storefront.products.canMap", "label": "Map Storefront Products"},
      {"id": "pm.storefront.products.view", "label": "View Storefront Products"},
      {"id": "pm.reports.storefrontProdStats", "label": "View Storefront Product Stats"},
      {"id": "pm.reports.wmsProdStats", "label": "View Warehouse Product Stats"},
      {"id": "pm.reports.orderedItems", "label": "View Ordered Items Report"},
      {"id": "pm.reports.orderFulfillment", "label": "View Order Fulfillment Report"},
      {"id": "pm.reports.shippedCount", "label": "View Shipped Count Report"},
      {"id": "pm.reports.wareTransSummary", "label": "View Warehouse Transaction Summary"},
      {"id": "pm.dashboard.nonecom.view", "label": "Dashboard - Non-Ecom View"},
      {"id": "pm.orders.view", "label": "SO - View Orders"},
      {"id": "pm.orders.splitOrder", "label": "SO - Split Orders"},
      {"id": "pm.orders.reship", "label": "SO - Reship Orders"},
      {"id": "pm.orders.removeOnHold", "label": "SO - Remove Orders on Hold"},
      {"id": "pm.orders.putOnHold", "label": "SO - Put Orders on Hold"},
      {"id": "pm.orders.simulateSunbasket", "label": "SO - Simulate Sunbasket Orders"},
      {"id": "pm.orders.downloadInvoice", "label": "SO - Download Order Export Invoice"},
      {"id": "pm.orders.markShipped", "label": "SO - Mark Orders as Shipped"},
      {"id": "pm.orders.awaitingShip", "label": "SO - Move to Awaiting Shipment"},
      {"id": "pm.orders.undoPending", "label": "S O- Undo Pending Fulfillment"},
      {"id": "pm.orders.edit", "label": "SO - Edit Orders"},
      {"id": "pm.orders.create", "label": "SO - Create Orders"},
      {"id": "pm.orders.cancel", "label": "SO  - Cancel Orders"},
      {"id": "pm.orders.bulkUpdate.main", "label": "SO - Bulk Update Orders"},
      {"id": "pm.orders.view.financeMargin", "label": "SO - View Finance Margin"},
      {"id": "pm.orders.view.connectLog", "label": "SO - View Connect Log"},
      {"id": "pm.orders.checkout.view", "label": "SO - View Checkout"},
      {"id": "pm.orders.checkout.create", "label": "SO - Create Checkout"},
      {"id": "pm.orderReturns.confirmReceived", "label": "Confirm Order Returns as Received"},
      {"id": "pm.orderReturns.view", "label": "View Order Returns"},
      {"id": "pm.billing.factoringLedger", "label": "View Factoring Ledger"},
      {"id": "pm.customerBilling.billingEntries", "label": "Manage Client Billing Entries"},
      {"id": "pm.customerBilling.invoices.view", "label": "View Client Invoices"},
      {"id": "pm.customerBilling.ptcFiles", "label": "Manage PTC Files"},
      {"id": "pm.customerBilling.rateCards", "label": "Manage Rate Cards"},
      {"id": "pm.other.shipCodeMappings", "label": "Manage Ship Code Mappings"},
      {"id": "pm.other.skuMaintenance", "label": "Manage SKU Maintenance"},
      {"id": "pm.other.systemLogs", "label": "View System Logs"},
      {"id": "pm.other.userLogs", "label": "View User Logs"},
      {"id": "pm.integrations.shipstation.orderTags", "label": "Manage ShipStation Order Tags"},
      {"id": "pm.integrations.shipstation.storefrontProducts", "label": "Manage ShipStation Storefront Products"},
      {"id": "pm.integrations.shipstation.stores", "label": "Manage ShipStation Stores"},
      {"id": "pm.integrations.extensiv.uom", "label": "Manage Extensiv UOM"},
      {"id": "pm.integrations.extensiv.receipts", "label": "Manage Extensiv Receipts"},
      {"id": "pm.integrations.extensiv.facilities", "label": "Manage Extensiv Facilities"},
      {"id": "pm.integrations.extensiv.customers", "label": "Manage Extensiv Customers"},
      {"id": "pm.integrations.extensiv.customerOrders", "label": "Manage Extensiv Customer Orders"},
      {"id": "pm.integrations.extensiv.carrierServices", "label": "Manage Extensiv Carrier Services"},
      {"id": "pm.integrations.extensiv.carriers", "label": "Manage Extensiv Carriers"},
      {"id": "pm.integrations.extensiv.billingCodes", "label": "Manage Extensiv Billing Codes"},
      {"id": "pm.integrations.extensiv.adjustments", "label": "Manage Extensiv Adjustments"},
      {"id": "pm.communications.purchaseOrders.internalChannel", "label": "Manage Purchase Orders Internal Channel"},
      {"id": "pm.manufacturing.rndProjects.view", "label": "View Manufacturing RnD Projects"},
      {"id": "pm.manufacturing.rndProjects.addOrUpdate", "label": "Create/Update Manufacturing RnD Projects"},
      {"id": "pm.manufacturing.workOrders.view", "label": "View Manufacturing Work Orders"},
      {"id": "pm.manufacturing.workOrders.addOrUpdate", "label": "Add or Update Manufacturing Work Orders"},
      {"id": "pm.manufacturing.vendors.view", "label": "View Manufacturing Vendors"},
      {"id": "pm.manufacturing.vendors.addOrUpdate", "label": "Add or Update Manufacturing Vendors"},
      {"id": "pm.manufacturing.billOfMaterials.view", "label": "View Bill of Materials"},
      {"id": "pm.manufacturing.billOfMaterials.addOrUpdate", "label": "Add or Update Bill of Materials"},
      {"id": "pm.warehouses.create", "label": "Create Warehouses"},
      {"id": "pm.warehouses.edit", "label": "Edit Warehouses"},
      {"id": "pm.warehouses.view", "label": "View Warehouses"},
      {"id": "pm.warehouses.asn.cancel", "label": "Cancel ASN"},
      {"id": "pm.warehouses.asn.create", "label": "Create ASN"},
      {"id": "pm.warehouses.asn.receive", "label": "Receive ASN"},
      {"id": "pm.warehouses.asn.view", "label": "View ASN"},
      {"id": "pm.warehouses.asn.update", "label": "Update ASN"},
      {"id": "pm.warehouses.asn.confirm", "label": "Confirm ASN"},
      {"id": "pm.warehouses.asn.autoConfirm", "label": "Auto-confirm ASN"},
      {"id": "pm.warehouses.wt.cancel", "label": "Cancel WT"},
      {"id": "pm.warehouses.wt.create", "label": "Create WT"},
      {"id": "pm.warehouses.wt.receive", "label": "Receive WT"},
      {"id": "pm.warehouses.wt.update", "label": "Update WT"},
      {"id": "pm.warehouses.wt.view", "label": "View WT"},
      {"id": "pm.warehouses.wt.confirm", "label": "Confirm WT"},
      {"id": "pm.warehouses.wt.autoConfirm", "label": "Auto-confirm  WT"},
      {"id": "pm.warehouses.bulkShipments.confirm", "label": "Confirm Bulk Shipments"},
      {"id": "pm.warehouses.bulkShipments.create", "label": "Create Bulk Shipments"},
      {"id": "pm.warehouses.bulkShipments.view", "label": "View Bulk Shipments"},
      {"id": "pm.warehouses.bulkShipments.request", "label": "Request Bulk Shipment"},
      {"id": "pm.warehouses.smallparcels.view", "label": "View Small Parcels"},
      {"id": "pm.warehouses.smallparcels.create", "label": "Create Small Parcels"},
      {"id": "pm.warehouses.purchaseOrders.view", "label": "View Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.reopen", "label": "Reopen Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.edit", "label": "Edit Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.confirm", "label": "Confirm Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.create", "label": "Create Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.complete", "label": "Complete Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.cancel", "label": "Cancel Purchase Orders"},
      {"id": "pm.warehouses.purchaseOrders.autoConfirm", "label": "Auto-confirm Purchase Orders"},
      {"id": "pm.warehouses.items.view", "label": "View Warehouse Items"},
      {"id": "pm.warehouses.items.update", "label": "Update Warehouse Items"},
      {"id": "pm.warehouses.items.create", "label": "Create Warehouse Items"},
      {"id": "pm.warehouses.items.import", "label": "Import Warehouse Items"},
      {"id": "pm.warehouses.items.viewInventoryByLocation", "label": "View Warehouse Inventory by Location"},
      {"id": "pm.warehouses.items.viewItemDetails", "label": "View Details Report for Warehouse Item"},
      {"id": "pm.warehouses.diu.view", "label": "View Adjustments Updates"},
      {"id": "pm.warehouses.diu.create", "label": "Create Adjustments Updates"}
  ]
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters,
    // a getter that takes a parameter, permission id, and returns a label
    getPermissionLabel: (state) => (permissionId) => {
      let permission = state.humanReadablePermissions.find((p) => p.id === permissionId);
      return permission ? permission.label : permissionId;
    }
  },

  actions: {
    ...genedStore.actions
  }
};

export default ItemModule;
