//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  measurementUnit: 'E',
  billOfMaterialsId: null,
  orderNumber: '',
  warehouseId: '',
  brandId: '',
  assembleType: 0,
  type: 0,
  quantity: 1
};

let defaultFilters = {
  warehouseId: '',
  brandId: '',
  name: '',
  sku: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
