<template>
  <b-row class="pt-3 pb-3">
    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                <b-row>
                  <div class="col-auto pr-2">Item Detailed Stats</div>
                  <div class="col">
                    <div class="d-none d-md-block">
                      <b>Name: </b> {{item.description}} |
                      <b>Warehouse: </b> <span v-if="myWarehouses && myWarehouses.find(a => a.id == item.warehouseId)">{{ myWarehouses.find(a => a.id == item.warehouseId).name }}</span>
                      <span v-else>-</span> |
                      <b>Customer: </b> <span
                        v-if="myBrands && myBrands.find(a => a.id == item.brandId)"
                        >{{ myBrands.find(a => a.id == item.brandId).name }}</span
                      >
                      <span v-else>-</span>
                    </div>
                  </div>
                </b-row>
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :items="[itemColored]"
                :showPaginationRow="false"
                :fields.sync="itemDetailsFields"
                :bordered="false"
              >
                <template v-slot:cell(quantityDemandStorefronts)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantityDemandStorefronts">{{ data.item.quantityDemandStorefronts }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(quantityDemandOther)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantityDemandOther">{{ data.item.quantityDemandOther }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(outTransit)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.outTransit">{{ data.item.outTransit }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(quantityDemand)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantityDemand">{{ data.item.quantityDemand }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(quantityAvail)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantityAvail">{{ data.item.quantityAvail }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(quantityAvailable)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantityAvailable">{{ data.item.quantityAvailable }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(quantity)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.quantity">{{ data.item.quantity }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(weeksLeft)="data">
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <span :key="data.item.weeksLeft">{{ data.item.weeksLeft }}</span>
                  </Transition>
                </template>
                <template v-slot:cell(inventoryStatus)="data">
                  <InventoryStatusBadge :inventoryStatus="data.item.inventoryStatus"></InventoryStatusBadge>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                By Warehouse
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareItemsSimilar">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareItemsSimilar"
                :items.sync="wareItems"
                :showPaginationRow="false"
                :fields.sync="wareItemsFields"
                :bordered="false"
              >
                <template v-slot:cell(lastSoldDate)="data">
                  <div v-if="data.item.lastSoldDate">
                    <span v-if="'0001-01-01T00:00:00' == data.item.lastSoldDate"
                      >Never</span
                    >
                    <span v-else>{{
                      data.item.lastSoldDate | moment('MMMM Do YYYY')
                    }}</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>
                <template v-slot:cell(warehouseId)="data">
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.warehouseId)"
                    >{{ myWarehouses.find(a => a.id == data.item.warehouseId).name }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(quantityAvail)="data">
                  <div>{{ data.item.quantityAvail }}</div>
                </template>
                <template v-slot:cell(quantity)="data">
                  <span>{{ data.item.quantity }} </span>
                  <span v-for="alterItem in data.item.alters" :key="alterItem.id">
                    <span
                    v-b-tooltip.hover title="On Hand as Reported by 4PL Integration."
                      v-if="alterItem.quantity != data.item.quantity"
                    >
                        <span class="badge badge-warning">
                          ({{alterItem.quantity}})
                        </span>
                    </span>
                  </span>
                </template>
                <template v-slot:cell(inventoryStatus)="data">
                  <InventoryStatusBadge :inventoryStatus="data.item.inventoryStatus"></InventoryStatusBadge>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                Inventory By Location
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareItemsLocation">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareItemsLocation"
                :items.sync="locationItems"
                :showPaginationRow="false"
                :fields.sync="locationItemsFields"
                :bordered="false"
              >
              <template v-slot:empty-slot>
                  <div class="card mb-0">
                    <div class="card-body text-center">
                      <b-row class="justify-content-center">
                        <div class="col-12 col-xl-10">

                          <p class="text-muted mt-3 mb-0">
                            There are no items to list.
                          </p>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(createDateTimeUtc)="data">
                  <span
                    v-if="
                      data.item.createDateTimeUtc &&
                        !data.item.createDateTimeUtc.endsWith('Z')
                    "
                    >{{
                      (data.item.createDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')
                    }}</span
                  >
                  <span v-else-if="data.item.createDateTimeUtc">{{
                    data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
                  }}</span>
                </template>
                <template v-slot:cell(expirationDate)="data">
                  <span
                    v-if="
                      data.item.expirationDate &&
                        !data.item.expirationDate.endsWith('Z')
                    "
                    >{{
                      (data.item.expirationDate + 'Z') | moment('MMMM Do YYYY')
                    }}</span
                  >
                  <span v-else-if="data.item.expirationDate">{{
                    data.item.expirationDate | moment('MMMM Do YYYY')
                  }}</span>
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="data.item.isQuarantined" class="badge badge-danger">Quarantined</span>
                  <span v-else-if="data.item.isOnHold" class="badge badge-info">On Hold</span>
                  <span v-else class="badge badge-success">Available</span>
                </template>

                <template v-slot:cell(warehouseProduct.brandId)="data">
                  <span
                    v-if="myBrands && myBrands.find(a => a.id == data.item.warehouseProduct.brandId)"
                    >{{ myBrands.find(a => a.id == data.item.warehouseProduct.brandId).name }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(warehouseProduct.warehouseId)="data">
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.warehouseProduct.warehouseId)"
                    >{{ myWarehouses.find(a => a.id == data.item.warehouseProduct.warehouseId).name }}</span
                  >
                  <span v-else>-</span>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                Active ASNs
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareItemsASN">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareItemsASN"
                :items.sync="asnItems"
                :showPaginationRow="false"
                :fields.sync="asnItemsFields"
                :bordered="false"
              >
              <template v-slot:empty-slot>
                  <div class="card mb-0">
                    <div class="card-body text-center">
                      <b-row class="justify-content-center">
                        <div class="col-12 col-xl-10">

                          <p class="text-muted mt-3 mb-0">
                            There are no items to list.
                          </p>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(qtyOnShipment)="data">
                  <div v-if="data.item.items">
                    <div v-if="data.item.items.find(a => a.warehouseProductId == item.id)">
                      <span>{{ data.item.items.find(a => a.warehouseProductId == item.id).quantityShipped }}  (R: {{ data.item.items.find(a => a.warehouseProductId == item.id).quantityReceived }})</span>
                    </div>
                    <span v-else> N / A</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>
                <template v-slot:cell(user)="data">
                    <div>
                      <span  class="d-inline-block mr-1">
                        <label v-if="data.item.customerRequested" class="badge badge-danger m-0" v-b-tooltip.hover title="Customer Request"><i class="fa fa-user"></i></label>
                        <label v-else class="badge badge-warning m-0" v-b-tooltip.hover title="Entered By Logistics/Manufacturer"><i class="fa fa-users"></i></label>
                      </span>
                      <span
                      v-if="
                        data.item.byUserInfo && data.item.byUserInfo.fullName"
                      >{{ data.item.byUserInfo.fullName }} </span
                    >
                    <span v-else> Unknown </span>
                    </div>
                  </template>
                  <template v-slot:cell(createDateTimeUtc)="data">
                    <span
                      v-if="
                        data.item.createDateTimeUtc &&
                          !data.item.createDateTimeUtc.endsWith('Z')
                      "
                      >{{
                        (data.item.createDateTimeUtc + 'Z')
                          | moment('MMM Do YY, h:mm a')
                      }}</span
                    >
                    <span v-else-if="data.item.createDateTimeUtc">{{
                      data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
                    }}</span>
                  </template>

                  <template v-slot:cell(fromWarehouseId)="data">
                    <span
                      v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.fromWarehouseId)"
                      >{{ myWarehouses.find(a => a.id == data.item.fromWarehouseId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>

                  <template v-slot:cell(vendorId)="data">
                    <span
                      v-if="myVendors && myVendors.find(a => a.id == data.item.vendorId)"
                      >{{ myVendors.find(a => a.id == data.item.vendorId).name }}</span
                    >
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(purchaseOrderId)="data">
                    <router-link
                      target="_blank"
                      v-if="data.item.purchaseOrderId && data.item.purchaseOrder"
                      :to="{name: 'Warehouses.PurchaseOrders.View', params: {id: data.item.purchaseOrderId}}"
                      class="badge badge-secondary"
                      >{{ data.item.purchaseOrder.orderNumber }}</router-link>
                    <span v-else>N / A</span>
                  </template>
                  <template v-slot:cell(toWarehouseId)="data">
                    <span
                      v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.toWarehouseId)"
                      >{{ myWarehouses.find(a => a.id == data.item.toWarehouseId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>
                  <template v-slot:cell(customReferenceNumber)="data">
                    <router-link
                      target="_blank"
                      :to="{name: 'Warehouses.AdvancedShipNotice.View', params: {id: data.item.id}}"
                      >{{ data.item.customReferenceNumber }}</router-link>
                  </template>
                  <template v-slot:cell(brandId)="data">
                    <span
                      v-if="myBrands && myBrands.find(a => a.id == data.item.brandId)"
                      >{{ myBrands.find(a => a.id == data.item.brandId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>

                  <template v-slot:cell(expectedDate)="data">
                    <span>{{ data.item.expectedDate | moment('MMMM Do YYYY') }}</span>
                  </template>

                  <template v-slot:cell(realStatus)="data">
                    <div>
                      <template v-if="data.item.realStatus == 'Open'">
                        <label class="badge badge-info"
                          ><i class="fa fa-check" /> Open</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'Draft'">
                        <label class="badge badge-secondary"
                          ><i class="fa fa-check" /> Request (Pending)</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'cancelled'">
                        <label class="badge badge-danger"
                          ><i class="fa fa-check" /> Cancelled</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'closed'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'Received'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'In Transit'">
                        <label class="badge badge-warning"
                          ><i class="fa fa-check" /> In Transit</label
                        >
                      </template>
                      <template v-else>
                        <label class="badge badge-warning"
                          ><i class="fa fa-check" /> Active</label
                        >
                      </template>
                    </div>
                  </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                Warehouse Transfers
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareItemsWT">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareItemsWT"
                :items.sync="wtItems"
                :showPaginationRow="false"
                :fields.sync="wtItemsFields"
                :bordered="false"
              >
              <template v-slot:empty-slot>
                  <div class="card mb-0">
                    <div class="card-body text-center">
                      <b-row class="justify-content-center">
                        <div class="col-12 col-xl-10">

                          <p class="text-muted mt-3 mb-0">
                            There are no items to list.
                          </p>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(qtyOnShipment)="data">
                  <div v-if="data.item.items">
                    <div v-if="data.item.items.find(a => a.warehouseProductId == item.id)">
                      <span>{{ data.item.items.find(a => a.warehouseProductId == item.id).quantityShipped }}  (R: {{ data.item.items.find(a => a.warehouseProductId == item.id).quantityReceived }})</span>
                    </div>
                    <span v-else> N / A</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>
                <template v-slot:cell(customReferenceNumber)="data">
                    <router-link
                    target="_blank"
                      :to="{name: 'Warehouses.WarehouseTransfers.View', params: {id: data.item.id}}"
                      >{{ data.item.customReferenceNumber }}</router-link>
                  </template>

                <template v-slot:cell(user)="data">
                    <div>
                      <span  class="d-inline-block mr-1">
                        <label v-if="data.item.customerRequested" class="badge badge-danger m-0" v-b-tooltip.hover title="Entered By Customer/Third Party"><i class="fa fa-user"></i></label>
                        <label v-else class="badge badge-warning m-0" v-b-tooltip.hover title="Entered By Logistics/Manufacturer"><i class="fa fa-users"></i></label>
                      </span>
                      <span
                      v-if="
                      data.item.byUserInfo && data.item.byUserInfo.fullName"
                      >{{ data.item.byUserInfo.fullName }} </span
                    >
                    <span v-else> Unknown </span>
                    </div>
                  </template>
                  <template v-slot:cell(createDateTimeUtc)="data">
                    <span
                      v-if="
                        data.item.createDateTimeUtc &&
                          !data.item.createDateTimeUtc.endsWith('Z')
                      "
                      >{{
                        (data.item.createDateTimeUtc + 'Z')
                          | moment('MMM Do YY, h:mm a')
                      }}</span
                    >
                    <span v-else-if="data.item.createDateTimeUtc">{{
                      data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
                    }}</span>
                  </template>

                  <template v-slot:cell(fromWarehouseId)="data">
                    <span
                      v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.fromWarehouseId)"
                      >{{ myWarehouses.find(a => a.id == data.item.fromWarehouseId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>
                  <template v-slot:cell(vendorId)="data">
                    <span
                      v-if="myVendors && myVendors.find(a => a.id == data.item.vendorId)"
                      >{{ myVendors.find(a => a.id == data.item.vendorId).name }}</span
                    >
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell(toWarehouseId)="data">
                    <span
                      v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.toWarehouseId)"
                      >{{ myWarehouses.find(a => a.id == data.item.toWarehouseId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>

                  <template v-slot:cell(brandId)="data">
                    <span
                      v-if="myBrands && myBrands.find(a => a.id == data.item.brandId)"
                      >{{ myBrands.find(a => a.id == data.item.brandId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>

                  <template v-slot:cell(expectedDate)="data">
                    <span>{{ data.item.expectedDate | moment('MMMM Do YYYY') }}</span>
                  </template>

                  <template v-slot:cell(realStatus)="data">
                    <div>
                      <template v-if="data.item.realStatus == 'Open'">
                        <label class="badge badge-info"
                          ><i class="fa fa-check" /> Open</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'Draft'">
                        <label class="badge badge-secondary"
                          ><i class="fa fa-check" /> Request (Pending)</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'cancelled'">
                        <label class="badge badge-danger"
                          ><i class="fa fa-check" /> Cancelled</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'closed'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'Received'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else-if="data.item.realStatus == 'In Transit'">
                        <label class="badge badge-warning"
                          ><i class="fa fa-check" /> In Transit</label
                        >
                      </template>
                      <template v-else>
                        <label class="badge badge-warning"
                          ><i class="fa fa-check" /> Active</label
                        >
                      </template>
                    </div>
                  </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                Active Purchase Orders
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareItemsPO">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareItemsPO"
                :items.sync="poItems"
                :showPaginationRow="false"
                :fields.sync="poItemsFields"
                :bordered="false"
              >
                <template v-slot:empty-slot>
                  <div class="card mb-0">
                    <div class="card-body text-center">
                      <b-row class="justify-content-center">
                        <div class="col-12 col-xl-10">

                          <p class="text-muted mt-3 mb-0">
                            There are no items to list.
                          </p>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(qtyOnShipment)="data">
                  <div v-if="data.item.items">
                    <div v-if="data.item.items.find(a => a.warehouseProductItem == item.item)">
                      <span>Req: {{ data.item.items.find(a => a.warehouseProductItem == item.item).quantity }} (Received/Shipped - {{ data.item.items.find(a => a.warehouseProductItem == item.item).quantityTotalReceived }} / {{ data.item.items.find(a => a.warehouseProductItem == item.item).quantityTotalShipped }})</span>
                    </div>
                    <span v-else> N / A</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>

                <template v-slot:cell(orderNumber)="data">
                  <router-link
                    target="_blank"
                    :to="{name: 'Warehouses.PurchaseOrders.View', params: {id: data.item.id}}"
                    >{{ data.item.orderNumber }}</router-link>
                </template>

                <template v-slot:cell(user)="data">
                  <div>
                    <span  class="d-inline-block mr-1">
                      <label v-if="data.item.customerRequested" class="badge badge-danger m-0" v-b-tooltip.hover title="Entered By Customerr/Third Party"><i class="fa fa-user"></i></label>
                      <label v-else class="badge badge-warning m-0" v-b-tooltip.hover title="Entered By Logistics/Manufacturer"><i class="fa fa-users"></i></label>
                    </span>
                    <span
                    v-if="
                      data.item.byUserInfo && data.item.byUserInfo.fullName"
                    >{{ data.item.byUserInfo.fullName }} </span
                  >
                  <span v-else> Unknown </span>
                  </div>
                </template>
                <template v-slot:cell(createDateTimeUtc)="data">
                  <span
                    v-if="
                      data.item.createDateTimeUtc &&
                        !data.item.createDateTimeUtc.endsWith('Z')
                    "
                    >{{
                      (data.item.createDateTimeUtc + 'Z')
                        | moment('MMM Do YY, h:mm a')
                    }}</span
                  >
                  <span v-else-if="data.item.createDateTimeUtc">{{
                    data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
                  }}</span>
                </template>

                <template v-slot:cell(vendorId)="data">
                  <span
                    v-if="myVendors && myVendors.find(a => a.id == data.item.vendorId)"
                    >{{ myVendors.find(a => a.id == data.item.vendorId).name }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:cell(brandId)="data">
                  <span
                    v-if="myBrands && myBrands.find(a => a.id == data.item.brandId)"
                    >{{ myBrands.find(a => a.id == data.item.brandId).name }}</span
                  >
                  <span v-else>N / A</span>
                </template>

                <template v-slot:cell(expectedDate)="data">
                  <span>{{ data.item.expectedDate | moment('MMMM Do YYYY') }}</span>
                </template>

                <template v-slot:cell(orderStatus)="data">
                  <div :id="'ht' + data.item.id">
                    <template v-if="data.item.forceStatus">
                      <label class="badge badge-warning"
                        ><i class="fa fa-check" /> {{ data.item.forceStatus }}</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 0">
                      <label class="badge badge-info"
                        ><i class="fa fa-check" /> Open</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 1">
                      <label class="badge badge-secondary"
                        ><i class="fa fa-check" /> Request (Pending)</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 2">
                      <label class="badge badge-warning"
                        ><i class="fa fa-check" /> Active</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 4">
                      <label class="badge badge-danger"
                        ><i class="fa fa-check" /> Cancelled</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 5">
                      <label class="badge badge-warning"
                        ><i class="fa fa-check" /> Active</label
                      >
                    </template>
                    <template v-else-if="data.item.orderStatus == 6">
                      <label class="badge badge-warning"
                        ><i class="fa fa-check" /> Active</label
                      >
                    </template>
                    <template v-else>
                      <label class="badge badge-success badge-green"
                        ><i class="fa fa-check" /> Completed</label
                      >
                    </template>
                  </div>
                  <b-popover
                    v-if="data.item.shippings.length > 0"
                    class="popovermappings"
                    boundary="window"
                    :target="'ht' + data.item.id"
                    triggers="hover"
                    placement="right"
                  >
                    <template v-slot:title>
                      Shipments:
                    </template>
                    <b-list-group>
                      <b-list-group-item
                        v-for="shipPO in data.item.shippings"
                        :key="shipPO.id"
                        class="p-1"
                      >
                        <div>
                          <span class="badge badge-secondary">{{ shipPO.name }}</span>
                          <span>
                            - Status:
                            <template v-if="shipPO.realStatus == 'Open'">
                              <label class="badge badge-info"
                                ><i class="fa fa-check" /> Open</label
                              >
                            </template>
                            <template v-else-if="shipPO.realStatus == 'On Hold'">
                              <label class="badge badge-secondary"
                                ><i class="fa fa-check" /> On Hold</label
                              >
                            </template>
                            <template v-else-if="shipPO.realStatus == 'Complete'">
                              <label class="badge badge-success badge-green"
                                ><i class="fa fa-check" /> Complete</label
                              >
                            </template>
                            <template v-else>
                              <label class="badge badge-warning"
                                ><i class="fa fa-check" />
                                {{
                                  shipPO.realStatus ? shipPO.realStatus : 'Unknown'
                                }}</label
                              >
                            </template>
                          </span>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                <span>Latest Bulk Shipments &amp; Small Parcel</span>
                <!-- <span v-else>Latest Bulk Shipments</span> -->
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingManualShips">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingManualShips"
                :items.sync="manualShipItems"
                :showPaginationRow="false"
                :fields.sync="manualShipsFields"
                :bordered="false"
              >
                <template v-slot:cell(qtyOnShipment)="data">
                  <div v-if="data.item.items">
                    <div v-if="data.item.items.find(a => a.warehouseProductItem == item.item)">
                      <span>{{ data.item.items.find(a => a.warehouseProductId == item.id || a.warehouseProductItem == item.item).quantity }}</span>
                    </div>
                    <span v-else> N / A</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>
                <template v-slot:cell(createDateTimeUtc)="data">
                  <div v-if="data.item.createDateTimeUtc">
                    <span v-if="'0001-01-01T00:00:00' == data.item.createDateTimeUtc"
                      >Never</span
                    >
                    <span v-else>{{
                      data.item.createDateTimeUtc | moment('MMMM Do YYYY')
                    }}</span>
                  </div>
                  <span v-else>
                    N / A
                  </span>
                </template>
                <template v-slot:cell(warehouseId)="data">
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.warehouseId)"
                    >{{ myWarehouses.find(a => a.id == data.item.warehouseId).name }}</span
                  >
                  <span v-else>-</span>
                </template>

                  <template v-slot:cell(orderStatus)="data">
                    <span v-if="data.item.orderStatus == 'Shipped'" class="badge badge-success">{{ data.item.orderStatus }}</span>
                    <span v-else-if="data.item.orderStatus == 'Draft'" class="badge badge-warning">Request (Pending)</span>
                    <span v-else-if="data.item.orderStatus == 'Cancelled'" class="badge badge-danger">{{ data.item.orderStatus }}</span>
                    <span v-else-if="data.item.orderStatus == 'Open'" class="badge badge-primary">{{ data.item.orderStatus }}</span>
                    <span v-else-if="data.item.orderStatus == 'AwaitingShipment'" class="badge badge-info">{{ data.item.orderStatus }}</span>
                    <span v-else class="badge badge-secondary">{{ data.item.orderStatus }}</span>
                    <span v-if="data.item.syncFailed">
                      <i v-b-tooltip.hover
                          class="fa fa-info-circle"
                          title="Failed to sync with Warehouse."></i>
                    </span>
                  </template>

                  <template v-slot:cell(orderNumber)="data">
                    <div v-if="data.item.orderType=='BulkShipment'">
                      <router-link
                        target="_blank"
                        :to="{name: 'Warehouses.BulkShipments.View', params: {id: data.item.id}}"
                        >{{ data.item.orderNumber }}</router-link>
                    </div>
                    <div v-else>
                      <router-link
                        target="_blank"
                        :to="{name: 'Warehouses.ManualShipments.Edit', params: {id: data.item.id}}"
                        >{{ data.item.orderNumber }}</router-link>
                    </div>
                  </template>
                  <template v-slot:cell(brandId)="data">
                    <span
                      v-if="myBrands && myBrands.find(a => a.id == data.item.brandId)"
                      >{{ myBrands.find(a => a.id == data.item.brandId).name }}</span
                    >
                    <span v-else>N / A</span>
                  </template>

                <template v-slot:cell(inventoryStatus)="data">
                  <InventoryStatusBadge :inventoryStatus="data.item.inventoryStatus"></InventoryStatusBadge>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>

    <b-col cols="12" v-if="item" :key="item.id">
      <ChartOrdersByDayForWarehouseItem :warehouseId="item.warehouseId" :brandId="item.brandId" :item="item.item"></ChartOrdersByDayForWarehouseItem>
    </b-col>

    <b-col cols="12" v-if="!has('pm.is_end_partner')">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1" title-tag="h5">
                Item Transactions History
              </b-card-title>
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body class="p-0">
            <div class="text-center" v-if="isLoadingWareHistory">
              <b-spinner
                variant="primary"
                class="m-5"
                style="height: 4rem; width: 4rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else>
              <TableListing
                class="mt-0"
                moreCustom5Class="nopadinr"
                sticky-header
                :responsive="true"
                :fixed="false"
                head-variant="light"
                :hide-pagination-top-fields="true"
                :loading-page="isLoadingWareHistory"
                :items.sync="wareHistoryItems"
                :showPaginationRow="false"
                :fields.sync="wareHistoryFields"
                :bordered="false"
              >
                <template v-slot:cell(createDateTimeUtc)="data">
                  <span
                    v-if="
                      data.item.createDateTimeUtc &&
                        !data.item.createDateTimeUtc.endsWith('Z')
                    "
                    >{{
                      (data.item.createDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')
                    }}</span
                  >
                  <span v-else-if="data.item.createDateTimeUtc">{{
                    data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
                  }}</span>
                </template>
              </TableListing>
            </div>
          </b-card-body>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import  InventoryStatusBadge  from './InventoryStatusBadge.vue';
import { TableListing } from '@/views/components/custom';
import ChartOrdersByDayForWarehouseItem from './ChartOrdersByDayForWarehouseItem.vue';
export default {
  props: {
    item: {
      required: true
    }
  },
  mixins: [myCommonDataMixin],
  components: {
    ChartOrdersByDayForWarehouseItem: ChartOrdersByDayForWarehouseItem,
    InventoryStatusBadge,
    TableListing: TableListing
  },
  data() {
    return {
      manualShipItems: [],
      wareItems: [],
      asnItems: [],
      wtItems: [],
      poItems: [],
      locationItems: [],

      wareHistoryItems: [],
      isLoadingWareHistory: true,

      isLoadingManualShips: true,
      isLoadingWareItemsSimilar: true,
      isLoadingWareItemsASN: true,
      isLoadingWareItemsWT: true,
      isLoadingWareItemsPO: true,
      isLoadingWareItemsLocation: true,
      wareHistoryFields: [
        { key: 'createDateTimeUtc', label: 'When', sortable: true },
        { key: 'description', label: 'Description', sortable: false, _cellVariant: 'info' },
        { key: 'quantityOld', label: 'Quantity Before', sortable: false, _cellVariant: 'secondary' },
        { key: 'quantityNew', label: 'Quantity After', sortable: false, _cellVariant: 'danger' },
        { key: 'internalNote', label: 'Internal Note', sortable: false },
        //{ key: 'byUserId', label: 'user', sortable: false }
      ],

      itemDetailsFields: [
        { key: 'quantity', label: 'In Warehouse', sortable: false, _cellVariant: 'secondary' },
        { key: 'quantityUnavailable', label: 'Unavailable', sortable: false, _cellVariant: 'danger' },
        //{ key: 'quantityReserved', label: 'Allocated', sortable: false, _cellVariant: 'danger' },
        { key: 'quantityAvailable', label: 'On Hand', sortable: false, _cellVariant: 'info' },

        { key: 'quantityDemandStorefronts', label: 'Storefronts', sortable: false, _cellVariant: 'danger' },
        { key: 'quantityFulfilledStorefronts', label: 'By Storefronts - Fulfilled', sortable: false },

        { key: 'quantityDemandOther', label: 'Small Parcel & Bulk', sortable: false, _cellVariant: 'danger' },
        { key: 'outTransit', label: 'WTs', sortable: false, _cellVariant: 'danger' },

        { key: 'quantityAvailFulfilAgainst', label: 'Fulfill Against', sortable: false },
        { key: 'quantityDemand', label: 'Total Demand', sortable: false, _cellVariant: 'danger' },
        { key: 'quantityAvail', label: 'Available', sortable: false, _cellVariant: 'warning' },
        { key: 'inTransit', label: 'In Transit', sortable: false, _cellVariant: 'success' },
        { key: 'weeksLeft', label: 'Weeks Left', sortable: false },
        { key: 'shippedPerWeekAvg', label: 'Shipped per week Avg', sortable: false },
        { key: 'averageOnHand', label: 'Average OnHand', sortable: false },
        { key: 'inventoryVelocity', label: 'Inventory Velocity', sortable: false },
        { key: 'inventoryStatus', label: 'Status', sortable: false }
      ],
      wareItemsFields: [
        { key: 'warehouseId', label: 'Warehouse', sortable: true },
        { key: 'lastSoldDate', label: 'Last Sold', sortable: true },
        { key: 'quantity', label: 'In Warehouse', sortable: true },
        { key: 'quantityAvailable', label: 'On Hand', sortable: true },
        { key: 'quantityDemand', label: 'Demand', sortable: true },
        { key: 'quantityAvail', label: 'Available', sortable: true },
        { key: 'inTransit', label: 'In Transit', sortable: true },
        { key: 'shippedPerWeekAvg', label: 'Per Week Avg', sortable: true },
        { key: 'inventoryVelocity', label: 'Velocity', sortable: true },
        { key: 'weeksLeft', label: 'Weeks Left', sortable: true },
        { key: 'inventoryStatus', label: 'Status', sortable: true }
      ],
      manualShipsFields: [
        { key: 'orderNumber', label: 'Order Number', sortable: true },
        { key: 'qtyOnShipment', label: 'Qty', sortable: true },
        { key: 'orderStatus', label: 'Status', sortable: true },
        { key: 'warehouseId', label: 'Warehouse', sortable: true },
        { key: 'brandId', label: 'Client', sortable: true },
        { key: 'createDateTimeUtc', label: 'Created At', sortable: true },
        { key: 'orderType', label: 'Type', sortable: true }
      ],
      asnItemsFields: [
        { key: 'customReferenceNumber', label: 'Ref #', sortable: false },
        { key: 'qtyOnShipment', label: 'Qty', sortable: true },
        { key: 'vendorId', label: 'Vendor', sortable: false },
        { key: 'toWarehouseId', label: 'To', sortable: false },
        { key: 'realStatus', label: 'Status', sortable: false },
        { key: 'expectedDate', label: 'Expected', sortable: false },
        { key: 'purchaseOrderId', label: 'PO #', sortable: false },
        { key: 'user', label: 'By User', sortable: false },
        { key: 'createDateTimeUtc', label: 'Created At', sortable: false },
        //{ key: 'id', label: 'Action', sortable: false }
      ],
      wtItemsFields: [
        { key: 'customReferenceNumber', label: 'Ref #', sortable: false },
        { key: 'qtyOnShipment', label: 'Qty', sortable: true },
        //{ key: 'vendorId', label: 'Vendor', sortable: false },
        { key: 'fromWarehouseId', label: 'From', sortable: false },
        { key: 'toWarehouseId', label: 'To', sortable: false },
        { key: 'realStatus', label: 'Status', sortable: false },
        { key: 'expectedDate', label: 'Expected', sortable: false },
        { key: 'user', label: 'By User', sortable: false },
        { key: 'createDateTimeUtc', label: 'Created At', sortable: false },
        //{ key: 'id', label: 'Action', sortable: false }
      ],
      poItemsFields: [
          { key: 'orderNumber', label: 'Order #', sortable: false },
          { key: 'qtyOnShipment', label: 'Qty', sortable: true },
          //{ key: 'vendorId', label: 'Vendor', sortable: false },
          { key: 'orderStatus', label: 'Status', sortable: false },
          { key: 'expectedDate', label: 'Expected', sortable: false },
          { key: 'user', label: 'By User', sortable: false },
          { key: 'createDateTimeUtc', label: 'Created At', sortable: false },
          //{ key: 'id', label: 'Action', sortable: false }
      ],
      locationItemsFields: [
        //{ key: 'warehouseProduct.brandId', label: 'Client', sortable: true },
        { key: 'warehouseProduct.warehouseId', label: 'Warehouse', sortable: true },
        //{ key: 'warehouseProduct.item', label: 'Item', sortable: true },
        //{ key: 'warehouseProduct.description', label: 'Description', sortable: true },
        { key: 'lotNumber', label: 'Lot Number', sortable: true },
        { key: 'warehouseLocation.code', label: 'Pallet #', sortable: true },
        { key: 'warehouseLocation.parentLocation.code', label: 'Location', sortable: true },
        { key: 'quantity', label: 'On Hand', sortable: true },
        { key: 'quantityOnHold', label: 'On Hold', sortable: true },
        { key: 'quantityUnavailable', label: 'Unavailable', sortable: true },
        { key: 'quantityAvailable', label: 'Available', sortable: true },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'expirationDate', label: 'Expires At', sortable: true },
      ],
    };
  },
  computed: {
    itemColored: {
      get() {
        let itm = this.item;
        itm['_cellVariants'] = {
          quantity: 'primary' ,
          quantityUnavailable: (itm.quantityUnavailable>0 ? 'danger' : ''),
          //quantityReserved: (itm.quantityReserved>0 ? 'danger' : ''),
          quantityAvailable: 'info' ,
          quantityDemandStorefronts: (itm.quantityDemandStorefronts>0 ? 'secondary' : ''),
          quantityDemandOther: (itm.quantityDemandOther>0 ?'secondary' : ''),
          outTransit: (itm.outTransit>0  ?'secondary' : ''),
          quantityDemand: 'danger' ,
          quantityAvail: 'warning' ,
          inTransit: (itm.inTransit>0 ? 'success' : ''),
          weeksLeft: '',
          shippedPerWeekAvg: '',
          averageOnHand: '',
          inventoryVelocity: ((itm.inventoryVelocity>4 && itm.inventoryVelocity>12) ? 'success' : (itm.inventoryVelocity!=0 ? 'warning' : '')),
          inventoryStatus: ''
        };
        return itm;
      },
      set(old, val) {}
    },
    shouldFetchMyCommonDataHere: {
      get() {
        return false;
      },
      set(old, val) {}
    },
  },
  watch: {
    'item': {
      handler (val, oldVal) {
          this.loadAllRequiredComponents();
        },
        deep: true
    }
  },
  methods: {
    loadManualShipsSimilar() {
      this.isLoadingWareItemsSimilar = true;
      let ordType = '';
      // if(this.has('pm.is_end_partner')) {
      //   ordType = 'BulkShipment';
      // }
      this.$http
        .post('/api/common/manualShipments/listItems', {
          perPage: 20,
          page: 1,
          orderType: ordType,
          itemSKU: this.item.item,
          brandId: this.item.brandId,
          sortBy: 'createDateTimeUtc',
          sortDesc: true
        })
        .then(resp => {
          this.isLoadingManualShips = false;
          let itms = resp.data.items;
          let fndItm = itms.find(a => a.id == this.item.id);
          if(fndItm != null) {
            fndItm['_rowVariant'] = 'warning';
          }
          this.manualShipItems = itms
        })
        .catch(err => {
          console.error('err',err)
          this.isLoadingManualShips = false;
          this.manualShipItems = [];
        });
    },
    loadWareItemsSimilar() {
      this.isLoadingWareItemsSimilar = true;
      this.$http
        .post('/api/warehouseProducts/listItems', {
          perPage: 20,
          page: 1,
          includeAlter: true,
          sku: this.item.item,
          brandId: this.item.brandId,
          sortBy: 'item'
        })
        .then(resp => {
          this.isLoadingWareItemsSimilar = false;
          let itms = resp.data.items;
          let fndItm = itms.find(a => a.id == this.item.id);
          if(fndItm != null) {
            fndItm['_rowVariant'] = 'warning';
          }
          this.wareItems = itms
        })
        .catch(err => {
          console.error('err',err)
          this.isLoadingWareItemsSimilar = false;
          this.wareItems = [];
        });
    },
    loadASNFor() {
      this.isLoadingWareItemsASN = true;
      this.$http
        .post('/api/common/warehouseShipments/listItems', {
          perPage: 50,
          page: 1,
          orderStatus: 'Non-Archived',
          itemSKU: this.item.item,
          brandId: this.item.brandId,
          toWarehouseId: this.item.warehouseId,
          type: 0
        })
        .then(resp => {
          this.isLoadingWareItemsASN = false;
          this.asnItems = resp.data.items;
        })
        .catch(err => {
          this.isLoadingWareItemsASN = false;
          this.asnItems = [];
        });
    },
    loadWTsFor() {
      this.isLoadingWareItemsWT = true;
      this.$http
        .post('/api/common/warehouseShipments/listItems', {
          perPage: 50,
          page: 1,
          orderStatus: 'Non-Archived',
          itemSKU: this.item.item,
          brandId: this.item.brandId,
          //toWarehouseId: this.item.warehouseId,
          type: 1
        })
        .then(resp => {
          this.isLoadingWareItemsWT = false;
          this.wtItems = resp.data.items;
        })
        .catch(err => {
          this.isLoadingWareItemsWT = false;
          this.wtItems = [];
        });
    },
    loadWareHistory() {
      console.log('loadWareHistory')
      this.isLoadingWareHistory = true;
      this.$http
        .get('/api/warehouseProducts/history/'+this.item.id)
        .then(resp => {
          this.isLoadingWareHistory = false;
          this.wareHistoryItems = resp.data.items;
        })
        .catch(err => {
          console.error(err)
          this.isLoadingWareItemsPO = false;
          this.wareHistoryItems = [];
        });
    },
    loadPOsFor() {
      this.isLoadingWareItemsPO = true;
      this.$http
        .post('/api/common/purchaseOrders/listItems', {
          perPage: 50,
          page: 1,
          itemSKU: this.item.item,
          brandId: this.item.brandId,
          orderStatus: 'Non-Archived',
          type: 0
        })
        .then(resp => {
          this.isLoadingWareItemsPO = false;
          this.poItems = resp.data.items;
        })
        .catch(err => {
          this.isLoadingWareItemsPO = false;
          this.poItems = [];
        });
    },
    loadLocationsFor() {
      this.isLoadingWareItemsLocation = true;
      this.$http
        .post('/api/common/warehouseProductLocations/listItems', {
          perPage: 100,
          loadedAll: true,
          item: this.item.item,
          brandId: this.item.brandId,
          warehouseId: this.item.warehouseId
        })
        .then(resp => {
          this.isLoadingWareItemsLocation = false;
          this.locationItems = resp.data.items;
        })
        .catch(err => {
          this.isLoadingWareItemsLocation = false;
          this.locationItems = [];
        });
    },
    loadAllRequiredComponents() {
      this.loadWareItemsSimilar();
      this.loadASNFor();
      this.loadWTsFor();
      this.loadPOsFor();
      this.loadLocationsFor();
      this.loadManualShipsSimilar();
      if(!this.has('pm.is_end_partner')) {
        this.loadWareHistory();
      }
    },
    reloadItemInfo() {
      this.$http
        .get('/api/warehouseProducts/'+this.item.id)
        .then(resp => {
          this.item = resp.data;
        })
        .catch(err => {
        });
    },
  },
  mounted() {
    this.loadAllRequiredComponents();
    try {
      if(!this.shouldFetchMyCommonDataHere)
      this.$userHub.$on('warehouse-product-updated', (payload) => {
        console.log('warehouse-product-updated',payload)
        this.reloadItemInfo();
        this.loadASNFor();
        this.loadWTsFor();
      });
      this.$userHub.$emit('JoinRoom', 'WarehouseProduct-'+this.item.id)
    } catch {
      console.log('err')
    }
  },
  beforeDestroy: function() {
    try {
      this.$userHub.$emit('LeaveRoom', 'WarehouseProduct-'+this.item.id)
    } catch {
      console.log('err')
    }
  },
};
</script>
