
import { AccountModule } from '@/shared';
import * as Sentry from '@sentry/vue';
AccountModule.actions.refreshToken = function({ state, commit, rootState }, oldToken) {
    return new Promise((resolve, reject) => {
    AccountModule.actions.fetchRefreshedToken(oldToken)
        .then(response => {
            if (response.env == 'prod') {
                Sentry.setUser({ email: response.email, id: response.id });
                if(!response.userRoles.includes('brand-user')){
                    window.location.replace('https://admin.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substr(2, 5)));
                }
            } else {
                Sentry.setUser({ email: response.email, id: response.id });
                if(!response.userRoles.includes('brand-user')){
                    window.location.replace('https://stagingadmin.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substr(2, 5)));
                }
            }
            commit('setUserData', {
                jwtToken: response.jwtToken,
                user: response,
                refreshToken: response.refreshToken,
                env: response.env
            });
            $http.defaults.headers.common['Authorization'] = `Bearer ${response.jwtToken}`;
            if(window && window.$http)
                window.$http.defaults.headers.common['Authorization'] = `Bearer ${response.jwtToken}`;

            resolve(response);
        })
        .catch(err => {
            // should raise error and redirect to login
            reject(err);
        });
    });
};
export default AccountModule;
