import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

Vue.component(FeatherIcon.name, FeatherIcon);

import { money } from '@/shared';
import { bsSelect } from '@/shared';
Vue.component('b-v-select', bsSelect);

Vue.use(money, {
  decimal: '.',
  thousands: ',',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: true /* doesn't work with directive */
});
