
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  type: '',
  name: '',
  status: 'Requested',
  projectId: null,
  forMultipleStorefronts: false,
  orderedItemsReportFilters: {
    storefrontIds: [],
    includeManualShipments: false,
    whatStores: 'notHidden',
    orderStatus: 'shipped',
    customer: '',
    dateFilter: 'this-week',
    dateType: 'orderDate',
    startDate: '',
    endDate: '',
    countryCode: ''
  },
  financeMarginReportFilters: {
    brandId: '',
    warehouseId: '',
    dateFilter: 'this-week',
    startDate: '',
    endDate: ''
  },
  warehouseItemsInOutReportFilters: {
    tplFacilityId: '',
    tplCustomerId: '',
    dateFilter: 'this-week',
    startDate: '',
    endDate: ''
  },
  shipmentCountReportFilters: {
    dateFilter: 'this-week',
    startDate: '',
    endDate: ''
  },
  inventoryTotalReportFilters: {
    dateFilter: 'this-week',
    startDate: '',
    endDate: ''
  },
  factoringFinanceInvoiceFilters: {
    dateFilter: 'this-week',
    startDate: '',
    endDate: '',
    brandId: '',
    warehouseId: '',
  },
  orderFulfillmentReportFilters: {
    dateFilter: 'this-week',
    startDate: '',
    endDate: ''
  }
};

let defaultFilters = {
  type: '',
  status: '',
  search: '',
  projectId: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ManualShipmentModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state,
    statusList: [
      {
        value: 'Ready',
        text: 'Ready'
      },
      {
        value: 'Requested',
        text: 'Requested'
      },
      {
        value: 'Processing',
        text: 'Processing'
      },
      {
        value: 'Failed',
        text: 'Failed'
      }
    ],
    reportTypesList: [
      {
        value: 'WarehouseItemsInOutReport',
        text: 'Warehouse Transactions Summary'
      },
      {
        value: 'FactoringFinanceInvoiceReport',
        text: 'Factoring Invoice'
      },
      {
        value: 'OrderedStorefrontItemsReport',
        text: 'Ordered Storefront Items'
      },
      {
        value: 'OrderedWarehouseItemsReport',
        text: 'Ordered Warehouse Items'
      },
      {
        value: 'OrderFulfillmentsReport',
        text: 'Order Fulfillments'
      },
      {
        value: 'StorefrontShippedCount',
        text: 'Storefront Shipments Count'
      }
      // {
      //   value: 'InventoryStatusItemsReport',
      //   text: 'Inventory Status'
      // }
    ]
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

ManualShipmentModule.getters.getOrderTypeList = state => {
  return state.orderTypeList;
};
ManualShipmentModule.getters.getOrderStatusList = state => {
  return state.orderStatusList;
};

ManualShipmentModule.getters.getOrderTypeFilter = state => {
  let filters = [
    {
      value: '',
      text: 'All'
    }
  ];
  filters = filters.concat(state.orderTypeList);
  return filters;
};
ManualShipmentModule.getters.getOrderStatusFilter = state => {
  let filters = [
    {
      value: '',
      text: 'All'
    }
  ];
  filters = filters.concat(state.orderStatusList);
  return filters;
};
export default ManualShipmentModule;
