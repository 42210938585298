//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  quickView: false,
  orderNumber: '',
  orderKey: '',
  orderTotal: 0,
  amountPaid: 0,
  shippingAmount: 0,
  taxAmount: 0,
  projectId: '',
  storeId: '',
  items: [],
  orderStatus: 'awaiting_shipment',
  customerEmail: '',
  customerUsername: '',
  shipmentAddress: {
    name: '',
    addressLine1: ''
  },
  advancedOptions: {
    customField1: '',
    customField2: '',
    customField3: ''
  }
};

let defaultFilters = {
  showArchived: '',
  orderReship: '',
  projectId: '',
  trackingNumber: '',
  customerName: '',
  storeFront: '',
  orderStatus: '',
  reqService: '',
  omSku: '',
  wmsSku: '',
  orderNumber: '',
  customerUsername: '',
  orderDateStart: '',
  orderDateEnd: '',
  modifyDateStart: '',
  modifyDateEnd: '',
  storeId: '',
  sortBy: 'OrderDate',
  sortDesc: true,
  shipToCountryCode: '',
  shipToState: '',
  shipToName: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let defaultFields = [
  { key: 'projectId', label: 'Storefront', sortable: false },
  { key: 'storeId', label: 'Group', sortable: false },
  { key: 'orderDate', label: 'Aging', sortable: false },
  { key: 'orderStatus', label: 'Status', sortable: false },
  { key: 'orderNumber', label: 'Order #', sortable: false },
  { key: 'customerNotes', label: 'Notes', sortable: false },
  { key: 'createDate', label: 'Order Date', sortable: false },
  { key: 'itemsList', label: 'Items', sortable: false },
  { key: 'trackingNumber', label: 'Tracking #', sortable: false },
  { key: 'shipTo.name', label: 'Name', sortable: false },
  { key: 'tagIds', label: 'Tags', sortable: false }
];
export default {
  namespaced: true,
  state: () => ({
    possibleFields: [
      ...defaultFields,
      {
        key: 'customerEmail',
        label: 'Customer Email',
        sortable: false
      },
      {
        key: 'customerUsername',
        label: 'Customer Username',
        sortable: false
      },

      { key: 'billTo.name', label: 'Bill To Name', sortable: false },
      { key: 'billTo.phone', label: 'Bill To Phone', sortable: false },
      { key: 'billTo.country', label: 'Bill To Country', sortable: false },
      { key: 'billTo.state', label: 'Bill To State', sortable: false },
      {
        key: 'billTo.postalCode',
        label: 'Bill To Postal Code',
        sortable: false
      },
      {
        key: 'billTo.street1',
        label: 'Bill To Street Line 1',
        sortable: false
      },
      {
        key: 'billTo.street2',
        label: 'Bill To Street Line 2',
        sortable: false
      },
      {
        key: 'billTo.street3',
        label: 'Bill To Street Line 3',
        sortable: false
      },
      { key: 'shipTo.country', label: 'Ship To Country', sortable: false },
      { key: 'shipTo.city', label: 'Ship To City', sortable: false },
      { key: 'shipTo.state', label: 'Ship To State', sortable: false },
      { key: 'shipTo.phone', label: 'Ship To Phone', sortable: false },
      {
        key: 'shipTo.postalCode',
        label: 'Ship To Postal Code',
        sortable: false
      },
      {
        key: 'shipTo.street1',
        label: 'Ship To Street Line 1',
        sortable: false
      },
      {
        key: 'shipTo.street2',
        label: 'Ship To Street Line 2',
        sortable: false
      },
      {
        key: 'shipTo.street3',
        label: 'Ship To Street Line 3',
        sortable: false
      },
      {
        key: 'requestedShippingService',
        label: 'Requested Shipping Method',
        sortable: false
      },
      { key: 'serviceCode', label: 'ShipStation Service', sortable: false },
      { key: 'carrierCode', label: 'ShipStation Carrier', sortable: false },

      {
        key: 'advancedOptions.customField1',
        label: 'Custom Field 1',
        sortable: false
      },
      {
        key: 'advancedOptions.customField2',
        label: 'Custom Field 2',
        sortable: false
      },
      {
        key: 'advancedOptions.customField3',
        label: 'Custom Field 3',
        sortable: false
      },

      { key: 'modifyDate', label: 'Date Modified', sortable: false },
      { key: 'weightTotal', label: 'Weight Total', sortable: false },
      { key: 'shipByDate', label: 'Ship By Date', sortable: false },
      { key: 'shipDate', label: 'Ship Date', sortable: false },

      { key: 'orderTotal', label: 'Order Total', sortable: false },
      { key: 'amountPaid', label: 'Amount Paid', sortable: false },
      { key: 'shippingAmount', label: 'Shipping Amount', sortable: false },
      { key: 'taxAmount', label: 'Tax Amount', sortable: false },
      { key: 'onHoldReason', label: 'Hold Reason', sortable: false },
      { key: 'onHoldReasonDetails', label: 'Hold Details', sortable: false },

    ],
    formFields: defaultFields,
    fields: defaultFields,
    ...genedStore.state,
    pagination: {
      page: 1,
      perPage: 25
    },
  }),

  mutations: {
    setFormFields(state, payload) {
      state.formFields = payload;
    },
    setFields(state) {
      let arr = [];
      state.formFields.forEach((val, key) => {
        arr[key] = { ...val };
      });
      state.fields = arr;
    },
    resetFormFields(state) {
      state.formFields = state.fields;
    },
    resetFields(state) {
      state.fields = defaultFields;
      state.formFields = defaultFields;
    },
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters,
    getPossibleFields: state => {
      return state.possibleFields;
    },
    getFormFields: state => {
      return state.formFields;
    },
    getSortedFields: state => {
      let fieldsSorted = JSON.parse(JSON.stringify(state.fields));
      fieldsSorted.push({ key: 'id', label: 'Action', sortable: false });
      return fieldsSorted;
    }
  },

  actions: {
    ...genedStore.actions
  }
};
