<template>
  <div>
    <template v-if="inventoryStatus == 3">
      <span class="badge badge-danger"
        ><i class="fa fa-check" /> Urgent</span
      >
    </template>
    <template v-else-if="inventoryStatus == 2">
      <span class="badge badge-danger"
        ><i class="fa fa-check" /> Urgent</span
      >
    </template>
    <template v-else-if="inventoryStatus == 1">
      <span class="badge badge-warning"
        ><i class="fa fa-check" /> Warning</span
      >
    </template>
    <template v-else>
      <span class="badge badge-success badge-green"
        ><i class="fa fa-check" /> All Good</span
      >
    </template>
  </div>
</template>

<script>
export default {
  props: {
    inventoryStatus: {
      required: true
    }
  }
}
</script>

<style>

</style>
