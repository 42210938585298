
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  name: '',
  isThirdParty: false
};

let defaultFilters = {
  search: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ItemModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state,
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

export default ItemModule;
