//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  name: '',
  type: 0,
  items: [],
  warehouseId: '',
  brandId: '',
  warehouseProductId: ''
};

let defaultFilters = {
  warehouseId: '',
  brandId: '',
  name: '',
  sku: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
