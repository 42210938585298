export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "Auth.ForgotPassword" */ '@/views/pages/authentication/ForgotPassword.vue'
      );
    }
  },
  {
    path: '/resetPassword/:userid/:token',
    name: 'ResetPassword',
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "Auth.ResetPassword" */ '@/views/pages/authentication/ResetPassword.vue'
      );
    }
  },
  {
    path: '/checkout-success/:token',
    name: 'CheckoutSuccess',
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    },
    component: () => {
      return import(
        /* webpackChunkName: "Guest.CheckoutSuccess" */ '@/views/pages/miscellaneous/CheckoutSuccess.vue'
      );
    }
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full'
    }
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      isPublicNav: true,
      layout: 'full'
    }
  }
];
