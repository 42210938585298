<template>
  <form @submit.prevent data-vv-scope="itemForm">
    <b-row>
    <!-- The modal -->
    <section>
      <b-modal id="confirmItem" v-model="showConfirmItemForm" :centered="true" size="lg" title="Confirm Form">
        <b-form-group label="Order Number" v-if="confirmItem">
          <b-form-input name="form-input-orderNumber" v-model="confirmItem.orderNumber" />
        </b-form-group>

        <b-form-group label="From Warehouse" v-if="confirmItem">
          <b-v-select name="form-input-warehouseId" v-model="confirmItem.warehouseId" :searchable="true" :options="warehousesList" />
        </b-form-group>

        <template v-slot:modal-footer>
          <b-button type="reset" variant="outline-secondary" @click="showConfirmItemForm = false">
            Cancel
          </b-button>

          <b-button type="button" variant="primary" @click="MarkAsConfirmed()">
            Confirm
          </b-button>
        </template>
      </b-modal>
    </section>
      <div class="col-12 col-sm-9">
        <b-card class="mb-3" :header="heading">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="From Warehouse">
                  <b-v-select
                    v-validate="{ required: false }"
                    name="form-input-warehouseId"
                    :state="
                      validateStateScoped('itemForm', 'form-input-warehouseId')
                    "
                    v-model="itemForm.warehouseId"
                    :searchable="true"
                    :disabled="hasBeenShipped"
                    :options="warehousesList"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Client">
                  <b-v-select
                    v-validate="{ required: true }"
                    name="form-input-brandId"
                    :state="
                      validateStateScoped('itemForm', 'form-input-brandId')
                    "
                    v-model="itemForm.brandId"
                    :searchable="true"
                    :disabled="hasBeenShipped"
                    :options="brandsList"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Order Number">
                  <b-form-input
                    maxlength="38"
                    name="form-input-orderNumber"
                    :state="
                      validateStateScoped('itemForm', 'form-input-orderNumber')
                    "
                    v-validate="{ required: true }"
                    v-model="itemForm.orderNumber"
                    :disabled="hasBeenShipped"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Order Type">
                  <b-v-select
                    v-validate="{ required: true }"
                    name="form-input-orderType"
                    :state="
                      validateStateScoped('itemForm', 'form-input-orderType')
                    "
                    v-model="itemForm.orderType"
                    :disabled="hasBeenShipped"
                    :options="orderTypeListPossible"
                  ></b-v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Custom Ref">
                  <b-form-input
                    v-model="itemForm.customerPo"
                    :disabled="hasBeenShipped"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" v-if="hasBeenShipped">
                <b-form-group label="Status">
                  <b-form-input
                    v-model="itemForm.orderStatus"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

            </b-row>
        </b-card>
        <b-card class="mb-3" no-body>
          <template v-slot:header>
            <div class="form-row w-100">
              <b-col cols="12" md="6">
                <div class="card-header-small pt-1">Ship To Address</div>
              </b-col>
              <div
                class="col-12 col-md-6 align-items-right d-flex"
                v-if="!hasBeenShipped && ! has('pm.is_end_partner')"
              >
                <div class="col-auto p-1">Ship Again to:</div>
                <b-form-group class="col m-0">
                  <b-v-select
                    :showup="true"
                    v-model="itemForm.shipmentAddressId"
                    :searchable="true"
                    :disabled="hasBeenShipped"
                    :options="shipmentAddressList"
                  />
                </b-form-group>
              </div>
            </div>
          </template>
          <div class="card-body">
            <div
              v-if="hasBeenShippedOrPrepicked && !itemForm.id"
              class="alert alert-warning"
            >
              <b>You are sending this shipment to the address picked above.</b>
            </div>
            <div class="form-row">
              <b-col class="col-6">
                <b-form-group label="Ship To Name *">
                  <b-form-input
                    :state="
                      validateStateScoped('itemForm', 'form-input-address-name')
                    "
                    name="form-input-address-name"
                    v-validate="{ required: true }"
                    v-model="itemForm.shipmentAddress.name"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Country *">
                  <b-v-select
                    v-validate="{ required: false }"
                    name="form-input-shipmentAddress-countryCode"
                    :state="
                      validateStateScoped('itemForm', 'form-input-shipmentAddress-countryCode')
                    "
                    :disabled="hasBeenShippedOrPrepicked"
                    v-model="itemForm.shipmentAddress.countryCode"
                    :searchable="true"
                    :options="countryCodeList"
                  />
                  </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 1 *">
                  <b-form-input
                    :state="
                      validateStateScoped('itemForm', 'form-input-address-addressLine1')
                    "
                    name="form-input-address-addressLine1"
                    v-validate="{ required: true }"
                    v-model="itemForm.shipmentAddress.addressLine1"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="City">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.city"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 2">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.addressLine2"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col class="col-6 col-md-3">
                <b-form-group label="State *">
                  <b-v-select
                    v-if="itemForm.shipmentAddress.countryCode == 'US'"
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-state"
                    :state="
                      validateStateScoped('itemForm', 'form-input-shipmentAddress-state')
                    "
                    v-model="itemForm.shipmentAddress.state"
                    :searchable="true"
                    :disabled="hasBeenShippedOrPrepicked"
                    :options="USAStateCodeList"
                  />
                  <b-form-input
                    v-else
                    v-validate="{ required: false }"
                    name="form-input-shipmentAddress-state"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-state'
                      )
                    "
                    v-model="itemForm.shipmentAddress.state"
                  :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col class="col-6 col-md-3">
                <b-form-group label="Postcode">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.postcode"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 3">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.addressLine3"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Contact Email">
                  <b-form-input
                    type="email"
                    v-model="itemForm.shipmentAddress.email"
                    :disabled="hasBeenShippedOrPrepicked"
                  />
                </b-form-group>
              </b-col>
            </div>
          </div>
        </b-card>
        <b-card class="mb-3" header="Items To Ship" no-body>
          <div class="card-body">
            <div class="items">
              <b-table
                head-variant="light"
                :fields="itemForm.id ? shipItemsFieldsView : shipItemsFields"
                :items="itemForm.items"
                :bordered="true"
              >
                <template v-slot:cell(quantity)="data">
                  <span>
                    {{ data.item.quantity }}
                    {{ GetItemUOM(data.item.warehouseProductId) }}
                  </span>
                </template>
                <template v-slot:cell(actions)="data">
                  <div class="d-flex">
                    <button
                      :disabled="data.item.id"
                      class="btn btn-danger"
                      @click="data.item.id ? null : RemoveItem(data.item, data.index)"
                    >
                      Remove
                    </button>
                  </div>
                </template>
                <template v-slot:cell(sku)="data">
                  <span>
                    {{ GetItemSKU(data.item.warehouseProductId) }}
                  </span>
                </template>
                <template v-slot:cell(name)="data">
                  <span>
                    {{ GetItemName(data.item.warehouseProductId) }}
                  </span>
                </template>
              </b-table>
            </div>
          </div>
          <template v-slot:footer>
            <b-alert v-if="!itemForm.brandId || !itemForm.warehouseId" show>
              <b
                >Pick the Customer and Warehouse first to get a list of possible
                warehouse items to choose from.</b
              >
            </b-alert>
            <b-alert v-else-if="loadingWareItems" variant="warning" show>
              <b>Loading Items..</b>
            </b-alert>
            <div v-else-if="!itemForm.id" class="form-row">
              <div class="col-6">
                <b-form-group label="Pick Product">
                  <b-v-select
                    :showup="true"
                    v-model="itemsShipForm.warehouseProductId"
                    :searchable="true"
                    :options="productsList"
                  />
                </b-form-group>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="newProdQuantity">Available</label>
                  <money
                    class="form-control"
                    disabled
                    v-model="itemsShipForm.quantityAvailable"
                    min="0"
                  />
                </div>
              </div>
              <!-- <div class="col-1">
                <b-form-group label=".">
                  <b-button @click="AddWarehouseItem" variant="primary" block
                    ><i class="fa fa-plus"></i
                  ></b-button>
                </b-form-group>
              </div> -->
              <div class="col-6 col-md-3">
                <b-form-group label="Quantity">
                  <b-input-group>
                    <money
                      class="form-control"
                      v-model="itemsShipForm.quantity"
                      min="0"
                        />
                    <template #append>
                      <b-form-select
                        v-model="itemsShipForm.measurementUnit"
                        :disabled="true"
                        :options="uomList"
                      />
                    </template>
                  </b-input-group>
                </b-form-group>
              </div>
              <!-- <div class="col-md-2">
                <b-form-group label="Lot Number">
                  <b-form-input
                    class="form-control"
                    v-model="itemsShipForm.reqLotNumber"
                    placeholder="(optional)"
                  />
                </b-form-group>
              </div> -->
              <div class="col-6 col-md-2">
                <b-form-group label=".">
                  <b-button
                    type="button"
                    block
                    variant="primary"
                    @click="AddItem"
                  >
                    Add
                  </b-button>
                </b-form-group>
              </div>
            </div>
          </template>
        </b-card>
      </div>

      <aside class="col-12 col-sm-3">
        <b-card header="Actions">
          <b-button
            v-if="!hasBeenShipped"
            :disabled="savingItem"
            variant="primary"
            block
            type="submit"
            @click="SaveForm"
          >
            <span v-if="savingItem">Saving...</span>
            <span v-else>Save Changes</span>
          </b-button>
          <b-button
            @click="ConfirmItemForm(itemForm)"
            v-if="itemForm.id && itemForm.orderStatus == 'Draft' && has('pm.warehouses.bulkShipments.confirm')"
            class="btn btn-block btn-success"
          >
            Approve Order
          </b-button>
          <b-button
            @click="CancelOrder(itemForm)"
            v-if="itemForm.id && itemForm.orderStatus != 'Shipped' && itemForm.orderStatus != 'Cancelled'"
            class="btn btn-block btn-danger"
          >
            Cancel Order
          </b-button>
          <router-link
            target="_blank"
            v-if="itemForm.id && has('pm.customerBilling.billingEntries')"
            :to="{
              name: 'Billing.CustomerBillingLedger.Index',
              query: { manualShipmentId: itemForm.id }
            }"
            class="btn btn-block btn-info"
          >
            Client Billing Ledger
          </router-link>
          <router-link
            :disabled="savingItem"
            class="mt-2 btn btn-block btn-outline-danger"
            :to="{ name: 'Warehouses.ManualShipments.Index' }"
            exact
          >
            <span v-if="hasBeenShipped">Back To List</span>
            <span v-else>Cancel</span>
          </router-link>
        </b-card>
        <b-card header="Shipping Instructions" v-if="!itemForm.id">
          <div>
            <b-form-group label="Carrier:">
              <b-v-select
                id="form-input-shipCarrier"
                name="form-input-shipCarrier"
                :state="
                  validateStateScoped('itemForm', 'form-input-shipCarrier')
                "
                data-vv-as="Extensiv Fulfillment Carrier"
                v-validate="{ required: true, min: 1 }"
                v-model="itemForm.tplCarrierCode"
                :searchable="true"
                :options="tplCarrierList"
              />
              <b-form-invalid-feedback
                id="form-input-shipCarrier-feedback"
              >
                {{ veeErrors.first('form-input-shipCarrier') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div>
            <b-form-group label="Service:">
              <b-v-select
                id="form-input-shipService"
                name="form-input-shipService"
                :state="
                  validateStateScoped('itemForm', 'form-input-shipService')
                "
                data-vv-as="Service"
                v-validate="{ required: true }"
                v-model="itemForm.tplServiceCode"
                :searchable="true"
                :options="tplShipmentServicesList"
              />
              <b-form-invalid-feedback
                id="form-input-shipService-feedback"
              >
                {{ veeErrors.first('form-input-shipService') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-card>

        <div class="card" v-if="itemForm.id && itemForm.orderStatus == 'Shipped'">
            <div
              class="card-header c-pointer"
              v-b-toggle.shipmentInfo-options-collapse
            >
              <h4 class="card-header-title">Shipment Info</h4>

              <i class="fa fa-chevron-down"></i>
            </div>

            <div>
              <b-collapse visible id="shipmentInfo-options-collapse">
                <div class="card-body">
                  <b-form-group label="Carrier">
                    <b-form-input
                      :value="itemForm.carrierShippedWith"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group label="Service">
                    <b-form-input
                      :value="itemForm.carrierServiceShippedWith"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group label="Tracking Number">
                    <b-form-input
                      :value="itemForm.carrierTrackingNumber"
                      :disabled="true"
                    />
                  </b-form-group>

                  <b-form-group label="Shipped At">

                    <b-form-input
                      v-if="itemForm.shipDateTimeUtc && !itemForm.shipDateTimeUtc.endsWith('Z')"
                      :value="(itemForm.shipDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')"
                      :disabled="true"
                    />
                    <b-form-input
                      v-else
                      :value="(itemForm.shipDateTimeUtc) | moment('MMM Do YY, h:mm a')"
                      :disabled="true"
                    />
                  </b-form-group>
                </div>
              </b-collapse>
            </div>
        </div>
        <DiscussionsWidget v-bind="chatWidgetProps" v-if="chatWidgetProps.relatedId"></DiscussionsWidget>
      </aside>

    </b-row>
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  mixins: [crudMixinGen('manualShipments'), myCommonDataMixin],
  components: {
  },
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    chatWidgetProps: {
      startOpen: true,
      channelType: 'FeatureClientSupport',
      relatedDesc: 'Discussion for Small Parcel shipment.',
      relatedName: 'Small Parcel: ',
      relatedId: '',
      relatedType: 'SmallParcel'
    },
    possibleCarrierList: [],
    possibleServiceList: [],
    showConfirmItemForm: false,
    confirmItem: null,
    heading: 'Small parcel Shipments Form',
    // subheading: 'Here you create or edit your Scheduled Products.',
    // icon: 'pe-7s-graph text-success',
    tempSug: '',
    readyToLoad: false,
    loadingWareItems: false,

    shipmentAddressAll: [],
    wareItemsList: [],
    itemsShipForm: {
      measurementUnit: 'E',
      warehouseProductId: null,
      quantity: 0
    },
    shipItemsFields: [
      { key: 'sku', label: 'SKU', sortable: false },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Quantity', sortable: false },
      { key: 'actions' }
    ],
    shipItemsFieldsView: [
      { key: 'sku', label: 'SKU', sortable: false },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Quantity', sortable: false }
    ]
  }),

  computed: {
    tplCarrierList: {
      get() {
        let itmsList = [];
        let myItems = this.possibleCarrierList;
          itmsList.push({ value: '', text: 'Nothing' });
        myItems.forEach(itm => {
          itmsList.push({ value: itm.name, text: itm.name  +  ' (' + itm.description+ ')' });
        });
        return itmsList;
      },
      set(val) {
      }
    },
    tplShipmentServicesList: {
      get() {
        let itmsList = [];
        let myItems = this.possibleServiceList;
        itmsList.push({ value: '', text: 'Nothing' });
        if(this.itemForm.tplCarrierCode) {
          let carr = this.possibleCarrierList.find(a => a.name == this.itemForm.tplCarrierCode);
          if(carr) {
            myItems = myItems.filter(a => a.tplCarrierId == carr.id);
          } else {
            myItems = [];
          }
        } else {
            myItems = [];
        }
        myItems.forEach(itm => {
          itmsList.push({ value: itm.code, text: itm.description  +  ' (' + itm.code+ ')' });
        });
        return itmsList;
      },
      set(val) {
      }
    },
    // isExtensivWarehouse: {
    //   get() {
    //     let isTrue = false;
    //     if (this.itemForm.warehouseId) {
    //       let foundWare = this.myWarehouses.find(
    //         a => a.id == this.itemForm.warehouseId
    //       );
    //       if (foundWare) {
    //         isTrue = (foundWare.wmsType == 4);
    //       }
    //     }
    //     return isTrue;
    //   },
    //   set(old, val) {}
    // },
    ...mapGetters({
      countryCodeList: 'getCountryCodeList',
      USAStateCodeList: 'getUSAStateCodeList'
    }),
    ...mapStoreGetSet('manualShipments', {
      itemForm: ['getItemForm', 'setItemForm']
    }),
    ...mapState('manualShipments', ['orderTypeList']),
    orderTypeListPossible: {
      get() {
        let lst = [
          {
            value: '',
            text: '- Automatically Set - '
          },

          {
            value: 'Ecom',
            text: 'Ecom'
          },

          {
            value: 'Custom',
            text: 'Custom'
          },

          {
            value: 'Mass',
            text: 'Mass'
          },

          {
            value: 'Wholesale',
            text: 'Wholesale'
          },
        ];
        return lst;
      },
      set(old, val) {}
    },
    hasBeenShippedOrPrepicked: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          isTrue = true;
        }
        if (this.itemForm.shipmentAddressId != '-') {
          isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    },
    hasBeenShipped: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    },
    uomList: {
      get() {
        let arrs = [
          {
            value: 'E',
            text: 'Each'
          },
          {
            value: 'BX',
            text: 'Box'
          },
          {
            value: 'FT',
            text: 'Feet'
          },
          {
            value: 'IN',
            text: 'Inches'
          },
          {
            value: 'OZ',
            text: 'Ounces'
          },
          {
            value: 'LB',
            text: 'Pounds'
          },
          {
            value: 'KG',
            text: 'Kilograms'
          },
          {
            value: 'P',
            text: 'Pallet'
          }
        ];
        return arrs;
      },
      set(old, val) {}
    },
    shipmentAddressList: {
      get() {
        let arrs = [];
        arrs.push({
          value: '-',
          text: '- Enter New Address - '
        });
        this.shipmentAddressAll.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.name + ' (' + itm.addressLine1 + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
    productsList: {
      get() {
        let arrs = [];
        this.wareItemsList.forEach(itm => {
          arrs.push({
            value: itm.id,
            quantityAvailable: itm.quantityAvail,
            text: itm.item + ' (' + itm.description + ')' + (this.itemForm.warehouseId ? ('- Qty: '+itm.quantityAvail) : '')
          });
        });
        return arrs;
      },
      set(old, val) {}
    }
  },

  watch: {
    'id': function(val) {
      this.LoadItem(this.id);
    },
    '$route.query.id': function(val) {
      this.id = val;
      this.LoadItem(this.id);
    },
    'itemForm.shipmentAddressId': function(newVal) {
      if(this.shipmentAddressAll.length >0) {
        let foundShipTo = this.shipmentAddressAll.find(a => a.id == newVal);
        if (foundShipTo) {
          this.itemForm.shipmentAddress = foundShipTo;
        } else {
          this.itemForm.shipmentAddress = {
            id: '',
            name: '',
            addressLine1: ''
          };
        }
      }
    },
    'itemForm.brandId': function(newVal, oldVal) {
      this.LoadWareProductsFor(newVal, this.itemForm.warehouseId);
      this.AutoFillNameIfEmpty();
    },
    'itemForm.warehouseProductId': {
      handler: function(newVal, oldVal) {
        this.itemForm.items.forEach(a => {
          if (a.warehouseProductId == this.itemForm.warehouseProductId) {
            this.RemoveItem(a);
          }
        });
        let defVal = 'E';
        if (newVal) {
          let findProd = this.wareItemsList.find(a => a.id == newVal);
          if (findProd) {
            this.itemForm.measurementUnit = findProd.itemUnit;
          }
        } else {
          this.itemForm.measurementUnit = defVal;
        }
        this.AutoFillNameIfEmpty();
      },
      deep: true
    },
    'itemForm.warehouseId': function(newVal, oldVal) {
      this.LoadWareProductsFor(this.itemForm.brandId, newVal);
      this.AutoFillNameIfEmpty();
    },
    'itemsShipForm.warehouseProductId': {
      handler: function(newVal, oldVal) {
        let itmVal = 0;
        if(newVal) {
          let itm = this.productsList.find(a => a.value == newVal);
          if(itm) {
            itmVal = itm.quantityAvailable;
          }
        }
        this.itemsShipForm.quantityAvailable = itmVal;

        let defVal = 'E';
        if (newVal) {
          let findProd = this.wareItemsList.find(a => a.id == newVal);
          if (findProd) {
            this.itemsShipForm.measurementUnit = findProd.itemUnit;
          }
        } else {
          this.itemsShipForm.measurementUnit = defVal;
        }
      },
      deep: true
    }
  },
  meta() {
    let titl = this.itemForm.orderNumber ? 'Small Parcel: ' +this.itemForm.orderNumber : this.heading;
      return {
          title: titl,
          // ...
      };
  },

  mounted() {
    //this.$store.dispatch('fetchMyTPLData');
    this.loadWarehouseCarriersAndServices();

    if(!this.has('pm.is_end_partner')) {
      this.LoadAddresses(() => {
        if (this.id) {
          this.ResetForm();
          this.LoadItem(this.id);
        } else {
          this.ResetForm();
          this.readyToLoad = true;
        }
      });
    } else {
      if (this.id) {
        this.ResetForm();
        this.LoadItem(this.id);
      } else {
        this.ResetForm();
        this.readyToLoad = true;
      }
    }
  },

  methods: {
    loadWarehouseCarriersAndServices() {
      this.$http
        .get('/api/carrierAndServices')
        .then(resp => {
          if(resp.data.carriers) {
            this.possibleCarrierList = resp.data.carriers;
            this.possibleServiceList = resp.data.services;
          }
        })
        .catch(err => {});
    },
    ConfirmItemForm(item) {
      this.confirmItem = item;
      this.showConfirmItemForm = true;
    },
    MarkAsConfirmed() {
      if (!this.confirmItem || !this.confirmItem.id) {
        this.$swal({
          title: 'Error',
          text: "No Shipment Picked!",
          icon: 'error',
        })
        return
      }
      if (!this.confirmItem.warehouseId) {
        this.$swal({
          title: 'Error',
          text: "No Warehouse was Picked!",
          icon: 'error',
        })
        return
      }
      this.$swal({
        title: 'Are you sure you want to approve this shipment?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve!'
      }).then(result => {
        if (result.value) {
          this.$http
            .post('/api/common/manualShipments/confirm/' + this.confirmItem.id + '/' + this.confirmItem.warehouseId + '/' + this.confirmItem.orderNumber)
            .then(resp => {
              if (resp.data.success) {
                this.showConfirmItemForm = false;
                this.confirmItem = null;
                this.$swal('Done!', 'The order was approved.', 'success');
                this.loadItems();
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
            .catch(err => { });
        } else {
          this.showConfirmItemForm = false;
        }
      });
    },

    CancelOrder(item) {
        this.$http
          .post('/api/common/manualShipments/' + item.id + '/cancel')
          .then(resp => {
            this.LoadItem(this.id);
            this.$swal('Success', 'Order was cancelled.', 'success');
          })
          .catch(err => {
            this.$swal('Failed', 'Could not cancel.', 'error');
          });
    },
    RemoveItem(item, index = null) {
      if (item.id) {
        // should call API to remove it
        this.$http
          .delete('/api/common/manualShipmentItems/' + item.id)
          .then(respa => {})
          .catch(err => {});
      }
      if(index) {
        this.itemForm.items = this.itemForm.items.filter((a, k)=> k != index);
      } else {
        this.itemForm.items = this.itemForm.items.filter(
          a => a.warehouseProductId != item.warehouseProductId
        );
      }
    },

    AddItem() {
      if (
        this.itemsShipForm.warehouseProductId &&
        parseFloat(this.itemsShipForm.quantity) > 0
      ) {
        let fndOld = this.itemForm.items.find(
          a => a.warehouseProductId == this.itemsShipForm.warehouseProductId
        );
        this.itemForm.items = this.itemForm.items.filter(
          a => a.warehouseProductId != this.itemsShipForm.warehouseProductId
        );
        this.itemForm.items.push({
          manualShipmentId: this.itemForm.id ? this.itemForm.id : '-',
          quantity: parseFloat(this.itemsShipForm.quantity),
          warehouseProductId: this.itemsShipForm.warehouseProductId
        });
        this.itemsShipForm = {
          measurementUnit: 'E',
          warehouseProductId: null,
          quantity: 0
        };
      }
    },

    LoadItem(id) {
      this.$http
        .get('/api/common/manualShipments/' + id)
        .then(resp => {
          let item = resp.data;
          this.readyToLoad = true;
          this.itemForm = item;
          this.chatWidgetProps.relatedName = 'small Parcel: ' + item.orderNumber;
          this.chatWidgetProps.relatedId = item.id;
        })
        .catch(err => {});
    },

    LoadWareProductsFor(brandId, warehouseId) {
      if (!this.readyToLoad) {
        return;
      }
      if (this.loadingWareItems) {
        return;
      }
      this.wareItemsList = [];
      if (brandId && warehouseId) {
        this.loadingWareItems = true;
        this.$http
          .post('/api/warehouseProducts/poList/' + brandId + '/' + warehouseId)
          .then(resp => {
            this.wareItemsList = resp.data;
            this.loadingWareItems = false;
          })
          .catch(err => {
            this.wareItemsList = [];
            this.loadingWareItems = false;
          });
      }
    },

    LoadAddresses(hndl = () => {}) {
      this.$http
        .get('/api/common/manualShipments/shipmentAddress')
        .then(resp => {
          this.shipmentAddressAll = resp.data;
          hndl();
        })
        .catch(err => {});
    },

    SaveForm() {
      this.savingItem = true;

      this.$validator.validateAll('itemForm').then(result => {

        if (!result) {
          this.$swal({ title: 'Form Validation Errors!', icon: 'error' });
          this.savingItem = false;
            return;
        }

          if (
            this.itemForm.items.length > 0 &&
            this.itemForm.brandId &&
            (this.itemForm.shipmentAddressId != '-' ||
              (this.itemForm.shipmentAddress.name &&
                this.itemForm.shipmentAddress.addressLine1)) &&
            this.itemForm.warehouseId &&
            this.itemForm.orderNumber
          ) {
            this.$http
              .post(
                '/api/common/manualShipments' + (this.itemForm.id ? '/edit' : ''),
                this.itemForm
              )
              .then(resp => {
                if (resp.data.success) {
                  //this.$swal({ title: 'Item Was Saved.', icon: 'success' });
                  this.$router.push({
                    name: 'Warehouses.ManualShipments.Index'
                  });
                } else if (resp.data.errorMessage) {
                  this.$swal({ title: resp.data.errorMessage, icon: 'error' });
                } else {
                  this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
                }
                this.ResetForm();
                this.savingItem = false;
              })
              .catch(err => {
                if (err.response.data && err.response.data.errorMessage) {
                  this.$swal({
                    title: err.response.data.errorMessage,
                    icon: 'error'
                  });
                } else {
                  this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
                }
                console.error('saved po err', err);
                this.savingItem = false;
              });
          } else {
            this.$swal({ title: 'Fill out required fields!', icon: 'error' });
            this.savingItem = false;
          }
      });
    },

    AutoFillNameIfEmpty() {
      if (this.tempSug == this.itemForm.orderNumber) {
        this.AutoFillName();
      }
    },
    formatDateAsStamp(d) {
      var month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate() + 1),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      let yearStr = (year + '').replace('20', '');
      return [month, day, yearStr].join('');
    },

    AutoFillName() {
      let posOrdName = '';
      let brandCode = '';
      let warehouse = this.myWarehouses.find(
        a => a.id == this.itemForm.warehouseId
      );
      let brand = this.myBrands.find(
        a => a.id == this.itemForm.brandId
      );
      if (brand && brand.code) {
        brandCode = brand.code;
        posOrdName += 'SmallShip-' + (brandCode != '' ? brandCode + '-' : '') +  + this.formatDateAsStamp(new Date());
      }
      this.tempSug = posOrdName;
      this.itemForm.orderNumber = posOrdName;
    },

    GetItemUOM(prodId) {
      let nam = '';
      let prodFound = this.wareItemsList.find(a => a.id == prodId);
      if (prodFound) {
        let uomCode = prodFound.itemUnit;
        let uomItem = this.uomList.find(a => a.value == uomCode);
        if (uomItem) {
          nam = uomItem.text;
        }
      }
      return nam;
    },

    GetItemSKU(prodId) {
      let nam = '-';
      let prodFound = this.wareItemsList.find(a => a.id == prodId);
      if (prodFound) {
        nam = prodFound.item;
      }
      return nam;
    },

    GetItemName(prodId) {
      let nam = '-';
      let prodFound = this.wareItemsList.find(a => a.id == prodId);
      if (prodFound) {
        nam = prodFound.description;
      }
      return nam;
    }
  }
};
</script>
