import {setUser as SentrySetUser} from '@sentry/vue';
import userHub from '../../../utils/userHub';
import moment from 'moment-timezone';

const fetchRefreshedToken = oldToken => {
  return new Promise((resolve, reject) => {
    $http
      .post('/api/auth/refresh-token', {
        token: oldToken
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        console.error('fetchRefreshedToken', error);
        //alert('Your session has expired. Please log in again. Redirecting you to login now...');
        //window.location.replace('/login?_r='+ (Math.random().toString(36).substring(2, 5) +'&redirToUrl='+(location.pathname+location.search).substring(1)));
        reject(error);
      });
  });
};
export default {
  namespaced: true,

  state: () => ({
    userDataInSentryWasSet: false,
    retriesForRefreshToken: 0,
    dstActive: moment().tz('America/Los_Angeles').isDST(),
    dstLocalActive: moment().isDST(),  //isDST(new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDay())),
    env: 'prod',
    has_logged_in: false,
    last_login_check: new Date(),
    token: '',
    refreshToken: '',
    refreshTokenTracker: null,
    user: {
      fullName: 'Guest',
      firstName: 'Guest',
      lastName: '',
      email: '',
      id: ''
    },
    version: process.env.VUE_APP_VERSION,
    permissions: [],
    roles: []
  }),

  mutations: {
    updateLoggedInState(state, payload) {
      state.lastLoginCheck = new Date();
    },
    setRefreshToken(state, payload) {
      state.token = payload.jwtToken;
      //window.$cookie.set('auth_token', payload.jwtToken);
      if(payload.refreshToken)
      {
        state.refreshToken = payload.refreshToken;
        localStorage.setItem('refreshToken', payload.refreshToken);
      }
      localStorage.setItem('auth_token', state.token);
    },
    setToken(state, payload) {
      state.token = payload.auth_token;
      $http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window && window.$http)
        window.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window.mainVue && window.mainVue.$http)
      {
        window.mainVue.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      }
      localStorage.setItem('auth_token', state.token);
    },
    setUser(state, usr) {
      state.user = usr;
    },
    setUserPermissions(state, roles) {
      state.permissions = roles;
    },
    setUserRoles(state, roles) {
      state.roles = roles;
    },
    setEnv(state, env) {
      state.env = env;
    },
    setAuthHeader(state, payload) {
      state.has_logged_in = true;
      $http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window && window.$http)
        window.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window.mainVue && window.mainVue.$http)
      {
        window.mainVue.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      }
      localStorage.setItem('auth_token', state.token);
      userHub.$emit('user-logged-in', state.user);
    },
    setUserData(state, payload) {
      state.retriesForRefreshToken = 0;
      state.has_logged_in = true;
      if (payload.refreshToken) {
        state.refreshToken = payload.refreshToken;
        localStorage.setItem('refreshToken', payload.refreshToken);
      }
      localStorage.setItem('userData', JSON.stringify(payload.user));
      state.token = payload.jwtToken;
      state.user = payload.user;
      state.user = payload.user;
      state.env = payload.env;
      state.roles = payload.user.userRoles;
      state.permissions = payload.user.permissions;
      $http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window && window.$http)
        window.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      if(window.mainVue && window.mainVue.$http)
      {
        window.mainVue.$http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      }
      localStorage.setItem('auth_token', state.token);
      userHub.$emit('user-logged-in', state.user);
      if(state.userDataInSentryWasSet) {
        state.userDataInSentryWasSet = true;
        SentrySetUser({ username: response.fullName, email: response.email, id: response.id });
      }
    }
  },

  actions: {
    fetchRefreshedToken : fetchRefreshedToken,
    logout: ({ state, commit, rootState }) => {
      state.refreshToken = '';
      localStorage.removeItem('refreshToken');
      window.$cookie.set('auth_token', '');
      //window.$cookie.set('auth_token');
      state.has_logged_in = false;
      state.token = '';
      state.roles = [];
      state.permmisions = [];
      state.refreshTokenTracker = null;
      state.refreshToken = '';
      state.user = {};
    },

    refreshTokenOrLogout({ state, commit, rootState, dispatch }, payloadCnt) {
      return new Promise((resolve, reject) => {
        fetchRefreshedToken(state.refreshToken)
          .then(response => {
            resolve(response);
            state.retriesForRefreshToken = 0;
            commit('setUserData', {
              jwtToken: response.jwtToken,
              user: response,
              refreshToken: response.refreshToken,
              env: response.env
            });
          })
          .catch(err => {
            console.log('retriesForRefreshToken counts', state.retriesForRefreshToken)
            state.retriesForRefreshToken++;
            if(state.retriesForRefreshToken > 3 && !window.location.hostname.includes('localhost') )
            {
              dispatch('account/logout');
              window.location.replace('/login?_r='+ (Math.random().toString(36).substring(2, 5)));
            } else if (state.retriesForRefreshToken <=3) {
              setTimeout(() => {
                state.retriesForRefreshToken = 0;
                console.log('retrying refresh token');
                dispatch('account/refreshTokenOrLogout', state.retriesForRefreshToken);
              },1000);
            }
            //reject(err);
          });
      });
    },
    refreshToken({ state, commit, rootState }, oldToken) {
      return new Promise((resolve, reject) => {
        fetchRefreshedToken(oldToken)
          .then(response => {
            if (response.env == 'prod') {
              if(response.userRoles.includes('brand-user')){
                window.location.replace('https://lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
              }
            } else {
              if(response.userRoles.includes('brand-user')){
                window.location.replace('https://staging.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
              }
            }
            resolve(response);
            commit('setUserData', {
              jwtToken: response.jwtToken,
              user: response,
              refreshToken: response.refreshToken,
              env: response.env
            });

          })
          .catch(err => {
            state.retriesForRefreshToken++;
            if(state.retriesForRefreshToken > 10 && err.response && err.response.data && !window.location.hostname.includes('localhost') )
            {
              // should raise error and redirect to login
              if (err.response.data.env == 'prod') {
                if(response.userRoles.includes('brand-user') || !window.location.hostname.includes('admin')){
                  window.location.replace('https://lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                } else {
                  window.location.replace('https://admin.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                }
              } else {
                if(err.response.data.userRoles.includes('brand-user') || !window.location.hostname.includes('admin') ){
                  window.location.replace('https://staging.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                } else {
                  window.location.replace('https://adminstaging.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                }
              }
            }
            reject(err);
          });
      });
    },

    autoRefreshToken({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        if (state.refreshTokenTracker != null) {
          clearInterval(state.refreshTokenTracker);
        }
        state.refreshTokenTracker = setInterval(() => {
          let storageRefToken = localStorage.getItem('refreshToken');
          if (storageRefToken != state.refreshToken) {
            state.refreshToken = storageRefToken;
          }
          fetchRefreshedToken(state.refreshToken)
            .then(response => {
              document.dispatchEvent(
                new CustomEvent('swSiteUp', { isUp: true })
              );
              //SentrySetUser({ email: response.email, id: response.id });
              commit('setRefreshToken', response);
              state.retriesForRefreshToken = 0;
              resolve(response.refreshToken);
            })
            .catch(err => {
              state.retriesForRefreshToken++;
              if(state.retriesForRefreshToken > 10 && err.response && err.response.data && !window.location.hostname.includes('localhost') )
              {
                // should raise error and redirect to login
                if (err.response.data.env == 'prod') {
                  if(response.userRoles.includes('brand-user') || !window.location.hostname.includes('admin')){
                    window.location.replace('https://lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                  } else {
                    window.location.replace('https://admin.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                  }
                } else {
                  if(err.response.data.userRoles.includes('brand-user') || !window.location.hostname.includes('admin') ){
                    window.location.replace('https://staging.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                  } else {
                    window.location.replace('https://adminstaging.lacoreconnect.com/login?_r='+ (Math.random().toString(36).substring(2, 5)));
                  }
                }
              }
              reject(err);
            });
        }, 5 * 60 * 1000);
      });
    }
  },

  getters: {
    isDST: state => {
      return state.dstActive;
    },
    isLocalDST: state => {
      return state.dstLocalActive;
    },
    getEnv: state => {
      return state.env;
    },
    getLastLoggedIn: state => {
      return state.user;
    },
    getUserTimezone: (state) => {
      let timezone = -6;
      if(state.dstLocalActive) {
        timezone = -5;
      }
      if (state &&state.user) {
        timezone = state.user.timezone;
        if(state.dstLocalActive) {
          timezone++;
        }
      }
      return timezone;
    },
    getUserTimezonePCDiff:(state) => {
      let defaultTz = -8;
      let timezone = -6;
      if(state.dstLocalActive) {
        timezone = -5;
      }
      if (state.dstActive) {
        defaultTz = -7;
      }
      if (state &&state.user) {
        timezone = state.user.timezone;
        if(state.dstLocalActive) {
          timezone++;
        }
      }
      let diffTz = (defaultTz - timezone);
      return -diffTz;
    },
    getUserData: state => {
      return state.user;
    },
    getUserRoles: state => {
      return state.roles;
    },
    getUserPermissions: state => {
      return state.permissions;
    },
    getToken: state => {
      return state.token;
    },
    getRefreshToken: state => {
      if(state.refreshToken == null) {
        state.refreshToken = '';
      }
      return state.refreshToken;
    },
    isLoggedIn: state => {
      return state.has_logged_in;
    },
    hasUserPermission: state => perm => {
      let prm = state.permissions.includes(perm);
      return prm;
    },
    hasUserAnyPermissionLike: state => perm => {
      let prm = false;
      state.permissions.forEach((p) => {
        if(p.includes(perm)) {
          prm = true;
        }
      })
      return prm;
    },
    hasUserAnyPermissionLikeExceptView: state => perm => {
      let prm = false;
      state.permissions.forEach((p) => {
        if(p.includes(perm) && !p.includes('.view')) {
          prm = true;
        }
      })
      return prm;
    },
    hasUserRole: state => role => {
      return state.roles.includes(role);
    }
  }
};
