import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '@/store';
import userHub from './utils/userHub';
export default {
  install(Vue) {
    let itmStr = process.env.VUE_APP_API_URL;
    if(!itmStr) {
      itmStr = ''
    }
    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Critical)
      .withAutomaticReconnect([0, 1000, 10000, 20000])
      .withUrl(itmStr + '/notifications', {
        withCredentials: true,
        accessTokenFactory: () => {
          let token = store.getters['auth/getToken'];
          if(!token) {
            token = localStorage.getItem('auth_token');
          }
          return token;
        }
      })
      .build();

    store.commit('setNotifyBusStatus', false);
    //store.commit('setNotifyBus', userHub);
    Vue.prototype.$userHub = userHub;
    window.$userHub = userHub;
    window.$userHub.$connection = connection;

    function invokeSignalr(method, ...args) {
      try {
        if(userHub.isConnected) {
          connection.invoke(method, ...args)
        }
      } catch(err) {
        console.log(err);
      }
    }
    userHub.$on('CommunicationsLastTyped', (currentUserFirstName, channelId) => {
      if(userHub.isConnected) {
        invokeSignalr('CommunicationsLastTyped', currentUserFirstName, channelId)
      }
    });
    userHub.$on('LeaveRoom', room => {
      connection
      if(userHub.isConnected) {
        connection.invoke('LeaveRoom', room)
      }
    });
    userHub.$on('CommunicationsLastReadPing', (userId, roomId) => {
      if(userHub.isConnected) {
        connection.invoke('CommunicationsLastReadPing', userId, roomId)
      }
    });
    userHub.$on('JoinRoom', room => {
      if(userHub.isConnected) {
        connection.invoke('JoinRoom', room)
      }
    });

    connection.on('Notify', notification => {
      userHub.$emit('new-notification-added', { notification });
    });
    connection.on('OrderUpdated', updatePayload => {
      userHub.$emit('order-updated', { updatePayload });
    });
    connection.on('StorefrontProductUpdated', updatePayload => {
      userHub.$emit('storefront-product-updated', { updatePayload });
    });
    connection.on('Communications.Channel.JoinedtoRoom', userId => {
      userHub.$emit('communications-channel-joinedroom', userId);
    });
    connection.on('Communications.Channel.UserTyped', userId => {
      userHub.$emit('communications-channel-userTyped', userId);
    });
    connection.on('Communications.ChannelMemberUpdate', memberInfo => {
      setTimeout(() => {
        userHub.$emit('communications-channel-member-update', memberInfo);
      }, 200);
    });
    connection.on('Communications.UnreadCountUpdate', payload => {
      userHub.$emit('communications-unreadcount-update', payload);
    });
    connection.on('AlertNumbers.Update', payload => {
      userHub.$emit('alerts-count-update', payload);
    });
    connection.on('Communications.ChannelNewMessage', updatePayload => {
      userHub.$emit('communications-channel-newMessage', updatePayload);
    });
    connection.on('WarehouseProductUpdated', updatePayload => {
      userHub.$emit('warehouse-product-updated', { updatePayload });
    });
    connection.on('WmsProductUpdated', updatePayload => {
      userHub.$emit('wms-product-updated', { updatePayload });
    });

    // if connection closed, reopen it
    let startedPromise = null;
    let retries = 0;
    function start() {
      startedPromise = connection.start()
        .then(() => {
          retries = 0;
          userHub.isConnected = true;
          store.commit('setNotifyBusStatus', true);
          document.dispatchEvent(
            new CustomEvent('swSiteUp', { isUp: true })
          )
        })
        .catch(err => {
          if(!userHub.isConnected) {
            console.log('retries cnt', retries, err)
            retries++;
            store.commit('setNotifyBusStatus', false);
            userHub.isConnected = false;
            console.log('Retrying connection to websocket...');
            if(retries>7) {
              document.dispatchEvent(
                new CustomEvent('swSiteDown', { isUp: false })
              )
            }
            return new Promise((resolve, reject) =>
              setTimeout(
                () =>
                  start()
                    .then(resolve)
                    .catch(reject),
                2000
              )
            );
          }
      });
      return startedPromise;
    }

    connection.onclose(() => start());
    if(store.getters['account/isLoggedIn'] && connection.state !== 'Connected' && connection.state !== 'Connecting')
      start();

    userHub.$on('user-logged-in', room => {
      if(!userHub.isConnected && connection.state !== 'Connected' && connection.state !== 'Connecting') {
        start();
      }
    });
  }
};
