<template>
    <section>
      <b-card no-body class="communications widget" v-if="relatedId">
        <b-tabs card v-model="openTabId" nav-class="w-100">
          <template #tabs-end>
            <li role="presentation" class="nav-item align-self-center ml-auto">
              <b-button v-b-tooltip.hover="'Open Discussion in Comms Center!'" size="sm" variant="outline-info"
              @click="StartDiscussionAndOpen()"><i class="fa fa-external-link"></i></b-button>
            </li>
          </template>
          <b-tab :title="is('brand-user') ? 'Support' : 'Client Support'" active class="p-0">
            <ChatWidgetInSidebar v-bind="supportChannelProps"></ChatWidgetInSidebar>
          </b-tab>
          <b-tab class="p-0" title="Internal" v-if="!is('brand-user') && has('pm.communications.purchaseOrders.internalChannel')">
            <b-alert variant="info" show size="sm" class="w-100 mb-0">This channel is only visible to management.</b-alert>
            <ChatWidgetInSidebar v-bind="internalChannelProps"></ChatWidgetInSidebar>
          </b-tab>
        </b-tabs>
      </b-card>
    </section>
</template>
<script>
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import ChatWidgetInSidebar from './ChatWidgetInSidebar.vue';
export default {
  name: 'DiscussionsWidget',
  mixins: [myCommonDataMixin],
  components: {
    ChatWidgetInSidebar
  },
  props: {
    openInternalFirst: {
        type: Boolean,
        required: false,
        default: false
    },
    startOpen: {
        type: Boolean,
        required: false,
        default: true
    },
    channelType: {
        type: String,
        required: true
    },
    relatedDesc: {
        type: String,
        required: true
    },
    relatedName: {
        type: String,
        required: true
    },
    relatedId: {
        type: String,
        required: true
    },
    relatedType: {
        type: String,
        required: true
    }
  },
  computed: {
    supportChannelProps: {
      get() {
        return {
          startOpen: this.startOpen,
          channelType: this.channelType,
          relatedDesc: this.relatedDesc,
          relatedName: this.relatedName,
          relatedId: this.relatedId,
          relatedType: this.relatedType
        };
      },
      set() {
      }
    },
    internalChannelProps: {
      get() {
        return {
          startOpen: this.startOpen,
          channelType: 'FeatureInternalDisc',
          relatedDesc: 'Internal ' + this.relatedDesc,
          relatedName: this.relatedName,
          relatedId: this.relatedId,
          relatedType: this.relatedType
        };
      },
      set() {
      }
    },
  },
  data() {
    return {
      openTabId: 0,
      internalChannel: null
    };
  },
  mounted() {
    if(this.openInternalFirst && !this.is('brand-user')) {
      this.openTabId = 1;
    }
  },
  methods: {
    StartDiscussionAndOpen() {
      if(this.relatedId) {
        let chanType = this.channelType;
        if(this.openTabId == 1)
          chanType = 'FeatureInternalDisc';
        this.$http
          .post('/api/communications/channels/startDiscussionFor/' + chanType, {
            relatedId: this.relatedId, //this.itemForm.id,
            relatedType: this.relatedType,//'BoM',
            name: this.relatedName, //'Bill of Materials: ' + this.itemForm.name,
            description: this.relatedDesc, //'Internal discussion for BoM.'
            addUserIds: [this.$store.getters['account/getUserData'].id]
          })
          .then(resp => {
            if (resp.data.id) {
              this.internalChannel = resp.data;
              this.$router.push({name: 'Communications.Index', params: {openChannelId: resp.data.id}});
            }
          })
          .catch(err => {});
      }
    }
  }
};
</script>