<template>
  <b-card header="Search Documentation" v-if="!$store.getters['account/hasUserRole']('brand-user')">
    <div class="form-row">
      <b-form-group label="Prompt:" style="flex:1">
        <b-form-input v-model="queryTxt" />
      </b-form-group>
      <b-form-group label="." class="col-auto pl-1">
        <b-button variant="primary" block @click="queryAI" :disabled="isLoadingQuery">
          <b-spinner small class="mr-1" v-if="isLoadingQuery"></b-spinner>
          <span v-if="!isLoadingQuery">Query</span>
          <span v-else>Loading...</span>
        </b-button>
      </b-form-group>
    </div>
    <div v-if="respMessage || searchResults.length" class="mt-2">
      <div class="border p-2">
        <p>{{ respMessage }}</p>
        <b-list-group v-if="searchResults.length">
          <b-list-group-item
            :to="article.metadata.find(a => a.name == 'document_url').value"
            v-for="article in searchResults" :key="article.id"><i class="fa fa-book mr-1"></i> {{ article.metadata && article.metadata ? article.metadata.find(a => a.name == 'document_name').value : 'DOCUMENT' }}
          <span class="pull-right"><i class="fa fa-angle-right"></i></span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-card>
</template>

<script>
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'QueryAIWidget',
  mixins: [myCommonDataMixin],
  components: {
  },

  data: () => ({
    isLoadingQuery: false,
    queryTxt: '',
    searchResults: [],
    respMessage: ''
  }),

  computed: {
  },

  mounted() {
  },

  methods: {
    queryAI() {
      this.isLoadingQuery = true;
      this.$http
        .post('/api/queryAI', {
          query: this.queryTxt
        })
        .then(resp => {
          this.queryTxt = '';
          this.isLoadingQuery = false;
          this.respMessage = resp.data.summaryResp;
          this.searchResults = resp.data.items;
        })
        .catch(err => {
          this.isLoadingQuery = false;
          this.respMessage =  'No results';
          this.searchResults = [];
        });
    }
  }
};
</script>
