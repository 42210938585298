//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  ticketNumber: '',
  priority: 2,
  name: '',
  type: 'General Support',
  collaboratorUserIds: [],
  putIntoBackLog: false
};

let defaultFilters = {
  showOpen: 0,
  search: '',
  status: '',
  priority: -1
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,
  state: () => ({
    ...genedStore.state,
    typeList: [
      { value: 'Bug Report', text: 'Bug Report' },
      { value: 'New Feature', text: 'New Feature Suggestion' },
      { value: 'Feature Improvement', text: 'Feature Improvement' },
      { value: 'General Support', text: 'General Support' }
    ],
    tableOptions: {
      ...genedStore.state.tableOptions,
      perPage: 25,
      loadedAll: false
    }
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
