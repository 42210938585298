<template>
  <section>
    <div class="popups">
      <!-- The modal -->
      <b-modal
        id="order-items"
        v-model="showReshipForm"
        :centered="true"
        size="lg"
        title="ReShip"
        :no-close-on-backdrop="true"
      >
        <b-form-group label="Why?">
          <b-form-select v-model="reshipReason" :options="reshipReasonsList" />
        </b-form-group>
        <!-- <b-form-group label="Do Full Reship?" v-if="reshipReason != 'Other'">
          <b-form-select v-model="doFullReship">
            <option :value="true">
              Yes
            </option>
            <option :value="false">
              No
            </option>
          </b-form-select>
        </b-form-group> -->

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              type="submit"
              variant="primary"
              class="ml-2 float-right"
              @click.prevent="OrderReshipConfirm"
            >
              Continue to Next Step
            </b-button>
            <b-button
              type="submit"
              variant="secondary"
              class="ml-2 float-right"
              @click="showReshipForm = false"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
      <div v-if="splitOrderInf">
          <order-split-popup
          :key="splitOrderInf.id"
          :order.sync="splitOrderInf"
          :showSplitForm.sync="showSplitForm">
        </order-split-popup>
      </div>
      <b-modal
        id="order-items"
        v-model="showShipmentItemsModal"
        :centered="true"
        size="xl"
        title="Shipment Details"
      >
        <div v-if="activeShipment">
          <b-form-group label="Log:">
            <b-form-textarea
              :value="
                activeShipment.notes
              "
              :disabled="true"
              rows="7"
              max-rows="12"
            />
          </b-form-group>
        </div>

        <div v-if="shipmentItems && shipmentItems.length">
          <b-table
            class="m-0"
            :striped="true"
            :bordered="true"
            :outlined="true"
            :small="false"
            :hover="false"
            :dark="false"
            :responsive="true"
            :items="shipmentItems"
            head-variant="dark"
            :fields="shipmentItemsFields"
          >
            <template v-slot:cell(fullfillInfo)="data">
              <div
                v-if="data.item.fulfillmentInfo"
              >
                <div class="border-bottom pb-1 pt-1" v-for="fulfillInfoItem in data.item.fulfillmentInfo" :key=fulfillInfoItem.id>
                  <span>Qty: {{ fulfillInfoItem.quantity }}</span>
                  <span v-if="fulfillInfoItem.lpn" class="badge badge-secondary ml-1">From Pallet ID: {{ fulfillInfoItem.lpn }}</span>
                  <span v-if="fulfillInfoItem.lotNumber" class="badge badge-info ml-1">
                    Lot #: {{fulfillInfoItem.lotNumber}}
                  </span>
                  <span v-if="fulfillInfoItem.expirationDate"  class="badge badge-danger ml-1">
                    Expires: {{fulfillInfoItem.expirationDate | moment('MMMM Do YYYY')}}
                  </span>
                  <span v-if="fulfillInfoItem.serialNumbers && fulfillInfoItem.serialNumbers.length"  class="badge badge-warning ml-1">
                    Serial Numbers:
                    <span  v-for="(fulfillInfoItemSerialNum, key) in fulfillInfoItem.serialNumbers" :key="key">
                      {{fulfillInfoItemSerialNum}},
                    </span>
                  </span>
                </div>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(sku)="data">
              <div
                v-if="getImageUrlFor(data.item)"
                class="avatar d-block d-lg-inline-block mx-auto"
              >
                <img
                  :src="getImageUrlFor(data.item)"
                  alt=""
                  class="avatar-img pr-0 pr-lg-3 pb-3 pb-lg-0"
                />
              </div>

              <span
                style="font-size: 14px;"
                class="d-block d-lg-inline-block text-center"
                >{{ data.item.sku }}</span
              >
            </template>
          </b-table>
        </div>
        <div v-else>
          <p class="text-muted mb-0 text-center">No items available.</p>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              type="submit"
              variant="primary"
              size="mr-2 float-right"
              @click="showShipmentItemsModal = false"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

    <div v-if="!order || !order.id" class="mt-3 p-3 alert alert-warning text-center">
      <h3><i class="fa fa-spinner fa-spin mr-2"></i> Loading...</h3>
    </div>
    <section v-else>
      <div class="header">
        <div class="header-body">
          <div class="row align-items-end">
            <div class="col">
              <h1 class="header-title">
                Order details
              </h1>
            </div>
            <div class="col-auto">
              <div class="row align-items-center">

                <div class="col-auto" v-if="projectOfOrder">
                  <span class="small">Storefront</span>
                  <h5><router-link :to="{
                    name: 'Orders.Index.All',
                    query: {
                      projectId: order.projectId
                    }}">{{ projectOfOrder }}</router-link></h5>
                </div>
                <div class="col-auto" v-if="storeOfOrder">
                  <span class="small">Order Group</span>
                  <h5><router-link :to="{
                    name: 'Orders.Index.All',
                    query: {
                      projectId: order.projectId,
                      storeId: order.storeId
                    }}">{{ storeOfOrder }}</router-link></h5>
                </div>
                <div class="col-auto">
                  <span class="small">Source</span>
                  <h5>{{ order.sourceOfOrder }}</h5>
                </div>

                <div class="col-auto">
                  <span class="small">Order number</span>
                  <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                    <h5 :key="baseOrder.orderNumber">{{ order.orderNumber }}</h5>
                  </Transition>
                </div>

                <div class="col-auto">
                  <span class="small">Order status</span>
                  <h5>
                    <Transition mode="out-in" name="flash-fade" :duration="{enter: 2000, leave: 0}">
                      <router-link :key="baseOrder.orderStatus" :to="{
                      name: 'Orders.Index.All',
                      query: {
                        projectId: order.projectId,
                        storeId: order.storeId
                      }}" v-if="order.orderStatus == 'on_hold'" v-b-tooltip.hover
                            :title="'Reason: '+ (order.onHoldReason ? (order.onHoldReason + (order.onHoldReasonDetails ? ', Details: ' + order.onHoldReasonDetails : '')) : 'Unknown / External')" v-html="getOrderStatus(order)">
                      </router-link>
                      <router-link :key="baseOrder.orderStatus" :to="{
                      name: 'Orders.Index.All',
                      query: {
                        projectId: order.projectId,
                        orderStatus: order.orderStatus
                      }}" v-else v-html="getOrderStatus(order)" >
                      </router-link>
                    </Transition>
                  </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <b-row>
      <div class="col-12 col-lg-9">
        <b-row>
          <!-- Order details -->
          <div class="col-12 col-md-8">
            <div class="card">
              <div
                class="card-header c-pointer"
                v-b-toggle.order-details-collapse
              >
                <h4 class="card-header-title">Basic info</h4>

                <i class="fa fa-chevron-down"></i>
              </div>

              <b-collapse visible id="order-details-collapse">
                <div class="card-body">
                  <div class="form-row" v-if="editingOrder">
                    <b-col cols="12" md="6">
                      <b-form-group label="Order Number" description="Can contain numbers, letters, dashes or underscores.">
                        <b-form-input
                          v-validate="{ required: true, min: 3, max: 20, alpha_dash: true }"
                          v-model="order.orderNumber"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Order Key" description="Note: Should be the same as order number in general, or left alone. Can contain numbers, letters, dashes or underscores.">
                        <b-form-input
                          v-model="order.orderKey"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                    </b-col>
                  </div>
                  <div class="form-row">
                    <b-col cols="12" md="6">
                      <b-form-group label="Customer email">
                        <b-form-input
                          v-model="order.customerEmail"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Customer username">
                        <b-form-input
                          v-model="order.customerUsername"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Customer notes">
                        <b-form-textarea
                          :value="
                            order.customerNotes ? order.customerNotes : 'N/A'
                          "
                          :disabled="true"
                          rows="4"
                          max-rows="8"
                        />
                      </b-form-group>
                    </b-col>
                  </div>

                  <hr class="my-2" />
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Ship date" v-if="order.shipDate">
                        <b-form-input
                          :value="
                            order.shipDate | moment('add', '0 hours', 'MMMM Do YYYY')
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                      <b-form-group label="Ship date" v-else>
                        <b-form-input
                          value="N / A"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Ship by date">
                        <b-form-input
                          v-if="order.shipByDate"
                          :value="
                            order.shipByDate | moment('MMMM Do YYYY')
                          "
                          :disabled="true"
                        />
                        <b-form-input
                          v-else
                          value="N / A"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Created At">
                        <b-form-input
                          v-if="!order.createDate"
                          value="N / A"
                          :disabled="true"
                        />
                        <b-form-input
                        v-else
                          :value="
                            order.createDate | moment('add', $store.getters['account/getUserTimezonePCDiff'] + ' hours', 'MMMM Do YYYY h:mm a')
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Order Date">
                        <b-form-input
                          :value="
                            order.orderDate | moment('add', $store.getters['account/getUserTimezonePCDiff'] + ' hours', 'MMMM Do YYYY h:mm a')
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Last Modified">
                        <b-form-input
                          :value="
                            order.modifyDate | moment('add', $store.getters['account/getUserTimezonePCDiff'] + ' hours', 'MMMM Do YYYY h:mm a')
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" v-if="order.autoRemoveFromHoldAt">
                      <b-form-group label="Automatically Removed from Hold At:">
                        <b-form-input
                          v-if="order.autoRemoveFromHoldAt && !order.autoRemoveFromHoldAt.endsWith('Z')"
                          :value="(order.autoRemoveFromHoldAt + 'Z') | moment('MMM Do YY, h:mm a')"
                          :disabled="true"
                        />
                         <div>
                           <countdown v-if="order.removeFromHoldSecsUntil>0" :time="order.removeFromHoldSecsUntil" v-slot="{ days, hours, minutes, seconds }">
                              Time Remaining：{{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.
                            </countdown>
                            <span v-else>About to get removed from hold any second! (A minute or two delay possible for ShipStation storefronts)</span>
                         </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" v-else>
                      <b-form-group label="Hold until date (ShipStation)">
                        <b-form-input
                          v-if="order.holdUntilDate"
                          :value="
                            order.holdUntilDate | moment('add', $store.getters['account/getUserTimezonePCDiff'] + ' hours', 'MMMM Do YYYY')
                          "
                          :disabled="true"
                        />
                        <b-form-input
                          v-else
                          value="N / A"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <hr class="my-2" />

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Requested Shipping Method">
                        <b-form-input
                          v-model="order.requestedShippingService"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                      <b-form-group label="Tags" class="order-details-tags">
                        <span v-if="order.tagIds && order.tagIds.length">
                          <span
                            v-for="tagId in order.tagIds"
                            :key="tagId"
                            v-html="getTagById(order.projectId, tagId)"
                          />
                        </span>
                        <span v-else>No tags for this order.</span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-collapse>
            </div>
          </div>

          <!-- Pricing -->
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.pricing-collapse>
                <h4 class="card-header-title">Pricing and payment</h4>

                <i class="fa fa-chevron-down"></i>
              </div>

              <b-collapse visible id="pricing-collapse">
                <div class="card-body">
                  <b-form-group label="Payment date">
                    <b-form-input
                      :value="
                        order.paymentDate | moment('add', $store.getters['account/getUserTimezonePCDiff'] + ' hours', 'MMMM Do YYYY h:mm a')
                      "
                      :disabled="true"
                    />
                  </b-form-group>

                  <b-form-group label="Payment method">
                    <b-form-input
                      :value="order.paymentMethod ? order.paymentMethod : 'N/A'"
                      :disabled="true"
                    />
                  </b-form-group>

                  <hr class="my-3" />

                  <b-form-group label="Product total">
                    <b-form-input :disabled="true" :value="'$' + itemsTotal" />
                  </b-form-group>

                  <b-form-group label="Shipping amount">
                    <b-form-input
                      :value="'$' + order.shippingAmount"
                      :disabled="true"
                    />
                  </b-form-group>

                  <b-form-group label="Tax amount">
                    <b-form-input
                      :value="'$' + order.taxAmount"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group label="Order total">
                    <b-form-input
                      :value="'$' + order.orderTotal"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group label="Amount paid">
                    <b-form-input
                      :value="'$' + order.amountPaid"
                      :disabled="true"
                    />
                  </b-form-group>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-row>

        <b-row>
          <!-- Bill to -->
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.bill-to-collapse>
                <h4 class="card-header-title">Bill to</h4>

                <i v-if="order.billTo" class="fa fa-chevron-down"></i>
              </div>

              <div v-if="order.billTo">
                <b-collapse visible id="bill-to-collapse">
                  <div class="card-body">
                    <div v-if="order.billTo">
                        <b-row>
                          <b-form-group class="col-6" label="Name">
                            <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                              <b-form-input
                                :key="baseOrder.billTo.name"
                                v-model="order.billTo.name"
                                :disabled="!editingOrder"
                              />
                            </Transition>
                          </b-form-group>

                          <b-form-group class="col-6" label="Company">
                            <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
                              <b-form-input
                                :key="baseOrder.billTo.company"
                                v-model="order.billTo.company"
                                :disabled="!editingOrder"
                              />
                            </Transition>
                          </b-form-group>
                        </b-row>

                      <b-form-group label="Phone">
                        <b-form-input
                          v-model="order.billTo.phone"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                      <b-row>
                        <b-col cols="12" md="6">
                          <b-form-group label="Country">
                            <b-form-input
                              v-model="order.billTo.country"
                              :disabled="!editingOrder"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group label="State">
                            <b-form-input
                              v-model="order.billTo.state"
                              :disabled="!editingOrder"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="6">
                          <b-form-group label="Postal code">
                            <b-form-input
                              v-model="order.billTo.postalCode"
                              :disabled="!editingOrder"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group label="City">
                            <b-form-input
                              v-model="order.billTo.city"
                              :disabled="!editingOrder"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-form-group label="Street Line 1">
                        <b-form-input
                          v-model="order.billTo.street1"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>

                      <b-form-group label="Street Line 2">
                        <b-form-input
                          v-model="order.billTo.street2"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>

                      <b-form-group label="Street Line 3">
                        <b-form-input
                          v-model="order.billTo.street3"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                      <b-row>
                        <b-form-group class="col-6" label="Address verified">
                          <b-form-input
                            :value="order.billTo.addressVerified ? 'Yes' : 'No'"
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group class="col-6" label="Residential">
                          <b-form-input
                            :value="order.billTo.residential ? 'Yes' : 'No'"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-row>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div v-else>
                <div class="card-body">
                  <p class="mb-0">Bill to data is not available.</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Ship to -->
          <b-col cols="12" md="6">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.ship-to-collapse>
                <h4 class="card-header-title">Ship to</h4>

                <i v-if="order.shipTo" class="fa fa-chevron-down"></i>
              </div>

              <div v-if="order.shipTo">
                <b-collapse visible id="ship-to-collapse">
                  <div class="card-body">
                    <b-row>
                      <b-form-group class="col-6" label="Name">
                        <b-form-input
                          v-model="order.shipTo.name"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>

                      <b-form-group class="col-6" label="Company">
                        <b-form-input
                          v-model="order.shipTo.company"
                          :disabled="!editingOrder"
                        />
                      </b-form-group>
                    </b-row>

                    <b-form-group label="Phone">
                      <b-form-input
                        v-model="order.shipTo.phone"
                        :disabled="!editingOrder"
                      />
                    </b-form-group>

                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Country">
                          <b-form-input
                            v-model="order.shipTo.country"
                            :disabled="!editingOrder"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="State">
                          <b-form-input
                            v-model="order.shipTo.state"
                            :disabled="!editingOrder"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Postal code">
                          <b-form-input
                            v-model="order.shipTo.postalCode"
                            :disabled="!editingOrder"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="City">
                          <b-form-input
                            v-model="order.shipTo.city"
                            :disabled="!editingOrder"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-form-group label="Street Line 1">
                      <b-form-input
                        v-model="order.shipTo.street1"
                        :disabled="!editingOrder"
                      />
                    </b-form-group>

                    <b-form-group label="Street Line 2">
                      <b-form-input
                        v-model="order.shipTo.street2"
                        :disabled="!editingOrder"
                      />
                    </b-form-group>

                    <b-form-group label="Street Line 3">
                      <b-form-input
                        v-model="order.shipTo.street3"
                        :disabled="!editingOrder"
                      />
                    </b-form-group>

                    <b-row>
                      <b-form-group class="col-6" label="Address verified">
                        <b-form-input
                          :value="order.shipTo.addressVerified ? 'Yes' : 'No'"
                          :disabled="true"
                        />
                      </b-form-group>

                      <b-form-group class="col-6" label="Residential">
                        <b-form-input
                          :value="order.shipTo.residential ? 'Yes' : 'No'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-row>
                  </div>
                </b-collapse>
              </div>

              <div v-else>
                <div class="card-body">
                  <p class="mb-0">Ship to data is not available.</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- Dimensions -->
          <b-col cols="12" md="6">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.dimensions-collapse>
                <h4 class="card-header-title">Dimensions</h4>

                <i v-if="order.dimensions" class="fa fa-chevron-down"></i>
              </div>

              <div v-if="order.dimensions">
                <b-collapse visible id="dimensions-collapse">
                  <div class="card-body">
                    <b-row>
                      <b-form-group class="col-12 col-md-4" label="Width">
                        <b-form-input
                          :value="
                            order.dimensions.width
                              ? order.dimensions.width +
                                ' ' +
                                (order.dimensions.units
                                  ? order.dimensions.units
                                  : '')
                              : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>

                      <b-form-group class="col-12 col-md-4" label="Length">
                        <b-form-input
                          :value="
                            order.dimensions.length
                              ? order.dimensions.length +
                                ' ' +
                                (order.dimensions.units
                                  ? order.dimensions.units
                                  : '')
                              : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>

                      <b-form-group class="col-12 col-md-4" label="Height">
                        <b-form-input
                          :value="
                            order.dimensions.width
                              ? order.dimensions.width +
                                ' ' +
                                (order.dimensions.units
                                  ? order.dimensions.units
                                  : '')
                              : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-row>
                  </div>
                </b-collapse>
              </div>

              <div v-else>
                <div class="card-body">
                  <p class="mb-0">Dimensions are not available.</p>
                </div>
              </div>
            </div>
          </b-col>

          <!-- Weight -->
          <b-col cols="12" md="6">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.weight-collapse>
                <h4 class="card-header-title">Weight</h4>

                <i v-if="order.weight" class="fa fa-chevron-down"></i>
              </div>

              <div v-if="order.weight">
                <b-collapse visible id="weight-collapse">
                  <div class="card-body">
                    <b-row>
                      <b-form-group class="col-12 col-md-6" label="Value">
                        <b-form-input
                          :value="
                            order.weight.value ? order.weight.value : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>

                      <b-form-group class="col-12 col-md-6" label="Unit">
                        <b-form-input
                          :value="
                            order.weight.units ? order.weight.units : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-row>
                  </div>
                </b-collapse>
              </div>

              <div v-else>
                <div class="card-body">
                  <p class="mb-0">Weight is not available.</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <!-- Insurance options -->
          <b-col cols="12" md="6">
            <div class="card">
              <div
                class="card-header c-pointer"
                v-b-toggle.insurance-options-collapse
              >
                <h4 class="card-header-title">Insurance options</h4>

                <i v-if="order.insuranceOptions" class="fa fa-chevron-down"></i>
              </div>

              <div v-if="order.insuranceOptions">
                <b-collapse visible id="insurance-options-collapse">
                  <div class="card-body">
                    <b-row>
                      <b-form-group class="col-6" label="Insure shipment">
                        <b-form-input
                          :value="
                            order.insuranceOptions.insureShipment ? 'Yes' : 'No'
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                      <b-form-group class="col-6" label="Provider">
                        <b-form-input
                          :value="
                            order.insuranceOptions.provider
                              ? order.insuranceOptions.provider
                              : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </b-row>

                    <b-form-group label="Insured value">
                      <b-form-input
                        :value="
                          order.insuranceOptions.insuredValue
                            ? order.insuranceOptions.insuredValue
                            : 'N/A'
                        "
                        :disabled="true"
                      />
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>

              <div v-else>
                <div class="card-body">
                  <p class="mb-0">Insurance options are not available.</p>
                </div>
              </div>
            </div>
          </b-col>

          <!-- International options -->
          <b-col cols="12" md="6">
            <div class="card">
              <div
                class="card-header c-pointer"
                v-b-toggle.international-options-collapse
              >
                <h4 class="card-header-title">International options</h4>

                <i
                  v-if="order.internationalOptions"
                  class="fa fa-chevron-down"
                ></i>
              </div>

              <div v-if="order.internationalOptions">
                <b-collapse visible id="international-options-collapse">
                  <div class="card-body">
                    <b-form-group label="Contents">
                      <b-form-input
                        :value="
                          order.internationalOptions.contents
                            ? order.internationalOptions.contents
                            : 'N/A'
                        "
                        :disabled="true"
                      />
                    </b-form-group>

                    <b-form-group label="Non delivery">
                      <b-form-input
                        :value="
                          order.internationalOptions.nonDelivery
                            ? order.internationalOptions.nonDelivery
                            : 'N/A'
                        "
                        :disabled="true"
                      />
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>

              <div v-else>
                <div class="card-body">
                  <p class="mb-0">International options are not available.</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
              <div class="card">
                <div
                  class="card-header c-pointer"
                  v-b-toggle.advanced-options-collapse
                >
                  <h4 class="card-header-title">Advanced Options</h4>

                  <i class="fa fa-chevron-down"></i>
                </div>

                <div v-if="order.advancedOptions">
                  <b-collapse visible id="advanced-options-collapse">
                    <div class="card-body">
                      <b-row>
                        <b-form-group label="Bill to account" class="col-4">
                          <b-form-input
                            :value="order.advancedOptions.billToAccount ? 'Yes' : 'No'"
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Bill to country code" class="col-4">
                          <b-form-input
                            :value="
                              order.advancedOptions.billToCountryCode ? 'Yes' : 'No'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Parent Split Order" class="col-4">
                          <router-link
                            v-if="order.parentSplitOrderId"
                            target="_blank"
                            :to="{
                              name: 'Common.OrderDetails',
                              query: { id: order.parentSplitOrderId }
                            }"
                            class="btn btn-block btn-info"
                          >
                            View Order
                          </router-link>
                          <router-link
                            v-else-if="order.advancedOptions.parentId"
                            target="_blank"
                            :to="{
                              name: 'Common.OrderDetails',
                              query: { id: (order.projectId + '-' + order.advancedOptions.parentId) }
                            }"
                            class="btn btn-block btn-info"
                          >
                            View Order
                          </router-link>
                          <div v-else>
                            <b-form-input
                              v-if="!order.parentSplitOrderId"
                              value="N / A"
                              :disabled="true"
                            />
                          </div>
                        </b-form-group>

                        <b-form-group label="Bill to my other account" class="col-4">
                          <b-form-input
                            :value="
                              order.advancedOptions.billToMyOtherAccount ? 'Yes' : 'No'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Bill to party" class="col-4">
                          <b-form-input
                            :value="order.advancedOptions.billToParty ? 'Yes' : 'No'"
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Parent Reship Order" class="col-4">
                          <b-form-input
                            v-if="!order.parentReshipOrderId"
                            value="N / A"
                            :disabled="true"
                          />
                          <router-link
                          v-else
                            target="_blank"
                            :to="{
                              name: 'Common.OrderDetails',
                              query: { id: order.parentReshipOrderId }
                            }"
                            class="btn btn-block btn-info"
                          >
                            View Order
                          </router-link>
                        </b-form-group>

                        <b-form-group label="Bill to postal code" class="col-6">
                          <b-form-input
                            :value="
                              order.advancedOptions.billToPostalCode ? 'Yes' : 'No'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Contains alcohol" class="col-6">
                          <b-form-input
                            :value="
                              order.advancedOptions.containsAlcohol ? 'Yes' : 'No'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Custom field 1" class="col-12">
                          <b-form-input
                            v-model="order.advancedOptions.customField1"
                            :disabled="!editingOrder"
                          />
                        </b-form-group>

                        <b-form-group label="Custom field 2" class="col-12">
                          <b-form-input
                            :value="
                              order.advancedOptions.customField2
                                ? order.advancedOptions.customField2
                                : 'N/A'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Custom field 3" class="col-12">
                          <b-form-input
                            :value="
                              order.advancedOptions.customField3
                                ? order.advancedOptions.customField3
                                : 'N/A'
                            "
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Non machinable" class="col-6">
                          <b-form-input
                            :value="order.advancedOptions.nonMachinable ? 'Yes' : 'No'"
                            :disabled="true"
                          />
                        </b-form-group>

                        <b-form-group label="Saturday delivery" class="col-6">
                          <b-form-input
                            :value="
                              order.advancedOptions.saturdayDelivery ? 'Yes' : 'No'
                            "
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-row>
                    </div>
                  </b-collapse>
                </div>

                <div v-else>
                  <div class="card-body">
                    <p class="mb-0">Advanced options are not available.</p>
                  </div>
                </div>
              </div>
          </b-col>
          <b-col cols="12">
            <div class="card">
              <div class="card-header c-pointer" v-b-toggle.other-collapse>
                <h4 class="card-header-title">Other</h4>

                <i class="fa fa-chevron-down"></i>
              </div>

              <b-collapse id="other-collapse">
                <div class="card-body">
                  <b-row>
                    <div class="col-12 col-md-4">
                      <b-form-group label="Carrier code">
                        <b-form-input
                          :value="order.carrierCode ? order.carrierCode : 'N/A'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                      <b-form-group label="Package code">
                        <b-form-input
                          :value="order.packageCode ? order.packageCode : 'N/A'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                      <b-form-group label="Service code">
                        <b-form-input
                          :value="order.serviceCode ? order.serviceCode : 'N/A'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>
                  </b-row>

                  <b-row>
                    <div class="col-12 col-md-4">
                      <b-form-group label="Externally fulfilled">
                        <b-form-input
                          :value="order.externallyFulfilled ? 'Yes' : 'No'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                      <b-form-group label="Externally fulfilled by">
                        <b-form-input
                          :value="
                            order.externallyFulfilledBy
                              ? order.externallyFulfilledBy
                              : 'N/A'
                          "
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                      <b-form-group label="Is Gift?">
                        <b-form-input
                          :value="order.gift ? 'Yes' : 'No'"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>
                  </b-row>
                  <div>
                    <b-form-group label="Gift message" v-if="order.gift">
                      <b-form-textarea
                        :value="order.giftMessage ? order.giftMessage : 'N/A'"
                        :disabled="true"
                        rows="4"
                        max-rows="8"
                      />
                    </b-form-group>
                  </div>
                  <b-form-group label="Internal notes">
                    <b-form-textarea
                      :value="order.internalNotes ? order.internalNotes : 'N/A'"
                      :disabled="true"
                      rows="4"
                      max-rows="8"
                    />
                  </b-form-group>
                </div>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </div>

      <aside class="col-12 col-lg-3">
        <b-card>
          <template #header class="card-header">
            <h4 class="card-header-title">Actions</h4>
          </template>
          <div class="mb-2" v-if="projectInfoOfOrder != null && projectInfoOfOrder.fulfillmentViaService != 'ConnectAPI'">
            <div>
              <b-alert show variant="info" v-if="order.orderStatus == 'on_hold'">
                <div>
                  <b>Hold Reason: </b>
                  <span> {{ order.onHoldReason ? order.onHoldReason : 'Unknown / External' }}</span>
                </div>
                <div v-if="order.onHoldReasonDetails" class="border-top">
                  <span>{{ order.onHoldReasonDetails }}</span>
                </div>
              </b-alert>
            </div>
            <button
              v-if="
                  order.orderStatus != 'shipped' &&
                  order.orderStatus != 'cancelled' &&
                  (order.orderStatus != 'pending_fulfillment') &&
                  order.orderStatus != 'on_hold'
                  && !order.sentToFulfillService
              "
              @click="PutOnHold(order)"
              :disabled="loadingOrderChange"
              class="btn btn-warning btn-block"
            >
              Place On Hold
            </button>
            <button
              v-else-if="order.orderStatus == 'on_hold'"
              @click="ReleaseHold(order)"
              :disabled="loadingOrderChange"
              class="btn btn-warning btn-block"
            >
              Release Hold
            </button>
            <!-- <router-link
              v-if="
                (order.orderStatus == 'shipped')
              "
              :disabled="loadingOrderChange"
              :to="{ name: 'Storefronts.OrderReturns.CreateReturnLabel', params: { orderId: order.id } }"
              class="btn btn-info btn-block"
            >
              Create Return Label
            </router-link> -->
            <router-link
              v-if="
                (order.orderStatus == 'shipped') &&
               has('pm.orderReturns.confirmReceived')
              "
              :disabled="loadingOrderChange"
              :to="{ name: 'Storefronts.OrderReturns.ConfirmReturn', params: { orderId: order.id } }"
              class="btn btn-info btn-block"
            >
              Confirm Return
            </router-link>
            <button
              v-if="
               has('pm.orders.splitOrder') &&
                (order.orderStatus == 'on_hold' && !order.sentToFulfillService)
                && order.items.length > 0
              "
              @click="SplitShipmentOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-info btn-block"
            >
              Split Order
            </button>
            <button
              v-if="has('pm.orders.reship') &&
                (order.orderStatus == 'shipped' ||
                  order.orderStatus == 'cancelled')
              "
              @click="OrderReship(order)"
              :disabled="loadingOrderChange"
              class="btn btn-info btn-block mb-1"
            >
              Re-Ship Order
            </button>
            <button
              v-if="
              has('pm.orders.cancel') &&
                  projectOfOrder &&
                  order.orderStatus != 'shipped' &&
                  order.orderStatus != 'cancelled' &&
                  (!order.sentToFulfillService || (order.fulfilledByService == 'Crane' || order.fulfilledByService == 'Manual' && !$store.getters['account/hasUserRole']('brand-user')))
              "
              @click="CancelOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-danger btn-block"
            >
              Cancel Order
            </button>

            <button
              v-if="
                !is('brand-user') && has('pm.orders.markShipped') && ((order.orderStatus == 'pending_fulfillment') || order.orderStatus == 'on_hold')
                 && (projectInfoOfOrder && projectInfoOfOrder.fulfillmentViaService == 'Crane')
              "
              @click="MarkShippedOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-success btn-block"
            >
              Mark Shipped
            </button>
            <button
              v-else-if="
                has('pm.orders.markShipped') &&
                (
                  (
                    (order.orderStatus != 'shipped' || order.orderStatus != 'cancelled') && order.shipCodeMappingDto && order.shipCodeMappingDto.overrideFulfilledByService)
                   ||
                   (
                    (order.orderStatus == 'pending_fulfillment' || order.orderStatus == 'on_hold')
                    && order.fulfilledByService == 'Manual'
                   )
                )
              "
              @click="MarkShippedOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-success btn-block"
            >
              Mark Shipped
            </button>
            <button
              v-if="
                  has('pm.orders.awaitingShip') &&
                  (order.orderStatus == 'pending_fulfillment') && !order.sentToFulfillService
              "
              @click="AwaitShipOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-danger btn-block"
            >
              Place into Awaiting Shipment
            </button>

            <a
              v-if="
              has('pm.orders.downloadInvoice') &&
                order.shipTo != null &&
                order.fulfilledByService == 'Landmark' &&
                  (order.orderStatus == 'shipped' ||order.orderStatus == 'pending_fulfillment' || (order.orderStatus == 'awaiting_shipment'  && order.sentToFulfillService)) && order.sentToFulfillService
              "
              :href="$store.getters.getAppUrl + '/orders/download/landmark_invoice/'+order.id"
              class="btn btn-secondary btn-block"
            >
              Download Invoice
            </a>

            <button
              v-if="
              has('pm.orders.undoPending') &&
                projectInfoOfOrder &&
                  (order.orderStatus == 'pending_fulfillment' || order.orderStatus == 'on_hold' || order.orderStatus == 'awaiting_shipment') && order.sentToFulfillService &&
                  ((order.fulfilledByService == 'TPL' || order.fulfilledByService == 'Manual' || order.fulfilledByService == 'Landmark' || (order.fulfilledByService == 'Crane' && !has('pm.is_end_partner'))) || (order.fulfilledByService == 'ShipStation' && order.orderStatus == 'awaiting_shipment' && projectInfoOfOrder.onlyFulfillShipStationUserId == order.userId))
              "
              @click="ReProcessOrder(order)"
              :disabled="loadingOrderChange"
              class="btn btn-secondary btn-block"
            >
              <span v-if="(order.fulfilledByService == 'ShipStation' && order.orderStatus == 'awaiting_shipment' && projectInfoOfOrder.onlyFulfillShipStationUserId == order.userId)">Re-Queue for Fulfillment</span>
              <span v-else>Undo Pending Fulfillment</span>
            </button>
            <div
              class="d-flex mt-1 mb-1"
              v-if="has('pm.orders.edit') &&
                  projectOfOrder &&
                  order.orderStatus == 'on_hold' && editingOrder
              ">
              <div class="d-block w-100 mr-1">
                <button
                  @click="EditOrder()"
                :disabled="loadingOrderChange"
                  class="btn btn-success btn-block"
                >
                  Save Changes
                </button>
              </div>
              <div class="d-block w-100 ml-1">
                <button
                  @click="CancelEditingOrder()"
                :disabled="loadingOrderChange"
                  class="btn btn-outline-danger btn-block"
                >
                  Cancel Changes
                </button>
              </div>
            </div>
            <div class="d-block mt-1 mb-1" v-else-if="has('pm.orders.edit') &&
                    projectOfOrder &&
                    !order.sentToFulfillService &&
                    order.orderStatus == 'on_hold' && !editingOrder
                ">
              <button
                @click="StartEditingOrder()"
              :disabled="loadingOrderChange"
                class="btn btn-success btn-block"
              >
                Edit Order
              </button>
            </div>
            <button
              v-if="has('pm.orders.simulateSunbasket') &&
                  projectOfOrder && projectOfOrder == 'Sunbasket' &&
                  !order.sentToFulfillService &&
                  (order.orderStatus == 'on_hold' || (order.orderStatus == 'cancelled' && order.connectLog.includes('ORDER_ERROR')) )
              "
              @click="SimulateSunbasketOrder()"
              class="btn btn-info btn-block"
              :disabled="loadingOrderChange"
            >
              Validate Order in Sunbasket
            </button>
          </div>
          <router-link
            target="_blank"
            v-if="has('pm.other.systemLogs')"
            :to="{
              name: 'ActionLog',
              query: { orderId: order.id }
            }"
            class="btn btn-block btn-info"
          >
            Event Log for Order
          </router-link>
          <router-link
            target="_blank"
            v-if="has('pm.customerBilling.billingEntries')"
            :to="{
              name: 'Billing.CustomerBillingLedger.Index',
              query: { orderId: order.id }
            }"
            class="btn btn-block btn-outline-danger"
          >
            Client Billing Ledger
          </router-link>
          <router-link
            :to="{
              name: 'Orders.Index.All'
            }"
            class="btn btn-outline-secondary btn-block"
          >
            Back To Orders
          </router-link>

          <b-form-group label="Connect Log" class="mt-2" v-if="has('pm.orders.view.connectLog')">
            <Transition mode="out-in" name="flash-warning" :duration="{ enter: 1000, leave: 0 }">
              <b-form-textarea
                :key="baseOrder.connectLog"
                :value="order.connectLog"
                :disabled="true"
                rows="7"
                max-rows="12"
              />
            </Transition>
          </b-form-group>

          <b-form-group label="SS Assigned To" v-if="projectInfoOfOrder && projectInfoOfOrder.onlyFulfillShipStationUserId && ssAssignedOfOrder">
            <b-input-group>
              <b-form-input
                :value="ssAssignedOfOrder"
                :disabled="true"
              />
            </b-input-group>
          </b-form-group>
        </b-card>

        <div class="card">
          <div
            class="card-header c-pointer"
            v-b-toggle.fulfillment-options-collapse
          >
            <h4 class="card-header-title">Fulfillment Info</h4>

            <i class="fa fa-chevron-down"></i>
          </div>

          <div>
            <b-collapse visible id="fulfillment-options-collapse">
              <div class="card-body">
                <div class="form-row">
                  <b-form-group label="Pending Fulfillment" class="col-6 small">
                    <b-form-input
                    size="sm"
                      :value="order.sentToFulfillService ? 'Yes' : 'Not yet'"
                      :disabled="true"
                    />
                  </b-form-group>
                  <b-form-group label="Fulfilled In" class="col-6 small">
                    <b-form-input
                    size="sm"
                      :value="order.fulfilledByService ? (order.fulfilledByService == 'TPL' ? 'Extensiv' : order.fulfilledByService) : 'N / A'"
                      :disabled="true"
                    />
                  </b-form-group>
                </div>
                <b-form-group class="small" label="Fulfillment Started At">

                  <b-form-input
                  size="sm"
                    v-if="order.sentFulfillAt && !order.sentFulfillAt.endsWith('Z')"
                    :value="(order.sentFulfillAt + 'Z') | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                  <b-form-input
                  size="sm"
                    v-else
                    :value="(order.sentFulfillAt) | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group class="small" label="Fulfilled At">

                  <b-form-input
                  size="sm"
                    v-if="order.completedFulfillAt && !order.completedFulfillAt.endsWith('Z')"
                    :value="(order.completedFulfillAt + 'Z') | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                  <b-form-input
                  size="sm"
                    v-else
                    :value="(order.completedFulfillAt) | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group class="small" label="Fulfillment Order ID" description="The ID of the Order in the Fulfillment Service.">
                  <b-form-input
                    size="sm"
                    :value="
                      order.fulfillmentOrderId
                    "
                    :disabled="true"
                  />
                </b-form-group>
                <div v-if="has('pm.other.shipCodeMappings') && projectInfoOfOrder != null && projectInfoOfOrder.fulfillmentViaService != 'ConnectAPI'">
                    <router-link
                      target="_blank"
                      v-if="order.shipCodeMappingId"
                      :to="{
                        name: 'Storefronts.ShipCodeMappings.Edit',
                        params: { id: order.shipCodeMappingId }
                      }"
                      class="btn btn-block btn-sm btn-outline-secondary"
                    >
                      View ShipCode Mapping
                    </router-link>
                    <router-link
                      target="_blank"
                      v-else-if="order.possibleShipCodeMappingId"
                      :to="{
                        name: 'Storefronts.ShipCodeMappings.Edit',
                        params: { id: order.possibleShipCodeMappingId }
                      }"
                      class="btn btn-block btn-outline-secondary"
                    >
                      Review Matching ShipCode Mapping
                    </router-link>
                    <button
                      target="_blank"
                      v-else-if="!order.possibleShipCodeMappingId"
                      @click="OpenCreateMappingPage()"
                      class="btn btn-block btn-outline-success"
                    >
                      Create Matching ShipCode Mapping
                    </button>
                </div>
              </div>

              <section class="card-footer" v-if="is('developer') && projectInfoOfOrder && order.sentToFulfillService && (has('pm.is_lacore_admin') || has('pm.is_lacore_team_member'))">
                <div visible id="carrier-integration-fulfillment">
                    <div v-if="(has('pm.is_lacore_admin') || has('pm.is_lacore_team_member')) && !order.sentToCarrierIntegration">
                      <button
                        @click="RetryOrderWithCarrier(order)"
                        class="btn btn-block btn-warning btn-sm"
                      >
                        Sync with Carrier
                      </button>
                    </div>
                    <div v-if="(has('pm.is_lacore_admin') || has('pm.is_lacore_team_member')) && order.sentToCarrierIntegration && !order.labelSyncedCarrierIntegration">
                      <button
                        @click="GenLabelForOrder(order)"
                        class="btn btn-block btn-primary"
                      >
                        Generate a Label
                      </button>
                    </div>
                    <div class="form-row pt-3">
                      <b-form-group label="Synced w/ Carrier" class="col-6 small">
                        <b-form-input
                        size="sm"
                          v-if="order.sentCarrierIntegrationAt && !order.sentCarrierIntegrationAt.endsWith('Z')"
                          :value="(order.sentCarrierIntegrationAt + 'Z') | moment('MMM Do YY, h:mm a')"
                          :disabled="true"
                        />
                        <b-form-input
                        size="sm"
                          v-else
                          :value="'Not Yet'"
                          :disabled="true"
                        />
                      </b-form-group>
                      <b-form-group class="col-6 small" label="Label Generated">
                        <b-form-input
                        size="sm"
                          :value="order.labelSyncedCarrierIntegration ? 'Yes' : 'No'"
                          :disabled="true"
                        />
                      </b-form-group>
                      <b-form-group label="Carrier" class="col-6 small">
                        <b-form-input
                        size="sm"
                          :value="order.integratedWithCarrier"
                          :disabled="true"
                        />
                      </b-form-group>
                      <b-form-group label="Service" class="col-6 small">
                        <b-form-input
                        size="sm"
                          :value="order.integratedWithCarrierService"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>

                    <b-form-group label="Carrier Order ID" class="small text-secondary">
                      <b-form-input
                      size="sm"
                        :value="order.carrierIntegrationOrderId"
                        :disabled="true"
                      />
                    </b-form-group>
                    <div>
                      <div v-for="shipmentLabelInfo in order.orderSimpleLabels" :key="shipmentLabelInfo.id">
                        <router-link
                          target="_blank"
                          class="btn btn-block btn-info"
                          :to="'/downlodLabel/'+shipmentLabelInfo.id + '.png'"
                        >
                          Label: {{ shipmentLabelInfo.trackingNumber }}
                        </router-link>
                      </div>
                  </div>
                </div>
              </section>
            </b-collapse>
          </div>
        </div>

        <div class="card" v-if="projectInfoOfOrder && projectInfoOfOrder.virtualFulfillmentViaService == 'TPL_MIRRORED' &&
         has('pm.is_lacore_admin')">
          <div
            class="card-header c-pointer"
            v-b-toggle.virtual-fulfullment-options-collapse
          >
            <h4 class="card-header-title">Mirrored Order Info</h4>

            <i class="fa fa-chevron-down"></i>
          </div>

          <div>
            <b-collapse visible id="virtual-fulfullment-options-collapse">
              <div class="card-body">
                <div v-if="has('pm.is_lacore_admin') && order.virtuallyFailedInFulfillService">
                  <button
                    @click="RetryVirtualFulfillOrder(order)"
                    class="btn btn-block btn-warning"
                  >
                    Retry Mirroring Order
                  </button>
                </div>
                <b-form-group label="Mirrored to Virtual Fulfillment Service">
                  <b-form-input
                    :value="order.virtuallySentToFulfillService ? 'Yes' : 'No'"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group label="Virtual Fulfillment Service">
                  <b-form-input
                    :value="order.virtuallyFulfilledByService ? (order.virtuallyFulfilledByService == 'TPL' ? 'Extensiv' : order.virtuallyFulfilledByService) : 'N / A'"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group label="Mirorred At">
                  <b-form-input
                    v-if="order.virtuallySentFulfillAt && !order.virtuallySentFulfillAt.endsWith('Z')"
                    :value="(order.virtuallySentFulfillAt + 'Z') | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                  <b-form-input
                    v-else
                    :value="(order.virtuallySentFulfillAt) | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group label="Closed At">

                  <b-form-input
                    v-if="order.virtuallyCompletedFulfillAt && !order.virtuallyCompletedFulfillAt.endsWith('Z')"
                    :value="(order.virtuallyCompletedFulfillAt + 'Z') | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                  <b-form-input
                    v-else
                    :value="(order.virtuallyCompletedFulfillAt) | moment('MMM Do YY, h:mm a')"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group label="Virtual Fulfillment Order ID" description="The ID of the Order in the Virtual Fulfillment Service.">
                  <b-form-input
                    :value="
                      order.virtuallyFulfillmentOrderId
                    "
                    :disabled="true"
                  />
                </b-form-group>
              </div>
            </b-collapse>
          </div>
        </div>

        <DiscussionsWidget v-bind="chatWidgetProps" v-if="chatWidgetProps.relatedId"></DiscussionsWidget>

        <div class="card" v-if="projectInfoOfOrder && order.orderCheckouts != null && order.orderCheckouts.length && (has('pm.orders.checkout.view'))">
          <div
            class="card-header c-pointer"
            v-b-toggle.payment-integration-options-collapse
          >
            <h4 class="card-header-title">Payment Integration</h4>

            <i class="fa fa-chevron-down"></i>
          </div>

          <div>
            <b-collapse visible id="payment-integration-options-collapse">
              <div class="card-body">
                <!-- <div v-if="(has('pm.orders.checkout.create')) && order.orderStatus == 'awaiting_payment'">
                  <button
                    @click="RetryOrderWithCarrier(order)"
                    class="btn btn-block btn-warning"
                  >
                    Generate new Checkout
                  </button>
                </div> -->
                <div v-for="orderCheckout in order.orderCheckouts" :key="orderCheckout.id">
                  <div>
                    <b-form-group label="Checkout URL">
                      <b-input-group>
                        <b-form-input
                          :value="orderCheckout.checkoutUrl"
                          :ref="orderCheckout.id"
                          readonly
                        />
                        <b-input-group-append>
                          <b-button  @click="CopyToClipboard(orderCheckout.id)" size="sm" text="Copy" variant="secondary"><i class="fa fa-copy"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Checkout Status">
                      <b-form-input
                        :value="orderCheckout.status"
                        readonly
                      />
                    </b-form-group>
                  </div>
                  <div class="border-bottom pb-1 mb-1">
                    <b v-if="orderCheckout.paidAtDateTimeUtc">Paid At: {{ orderCheckout.paidAtDateTimeUtc | moment('MMM Do YY, h:mm a') }}</b>
                    <b v-else-if="orderCheckout.status != 'Expired' && orderCheckout.status != 'Cancelled'">Expires At: {{ orderCheckout.expiresAtDateTimeUtc | moment('MMM Do YY, h:mm a') }}</b>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <FinanceMarginWidget :id="id"></FinanceMarginWidget>

        <div class="card" v-if="order.subOrders && order.subOrders.length">
          <div
            class="card-header c-pointer"
            v-b-toggle.virtual-suborders-options-collapse
          >
            <h4 class="card-header-title">Related Orders</h4>

            <i class="fa fa-chevron-down"></i>
          </div>

          <div>
            <b-collapse visible id="virtual-suborders-options-collapse">
              <div class="card-body">

                <b-form-group v-for="subOrdr in order.subOrders" :key="subOrdr.orderId"
                :label="'Order ' + subOrdr.orderNumber + ', ' + (subOrdr.type == 'split' ? 'Split via Connect' : (subOrdr.type == 'split-ss' ? 'Split via ShipStation' : 'Reship'))"
                >
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Common.OrderDetails',
                      query: { id: subOrdr.orderId }
                    }"
                    class="btn btn-block btn-light"
                  >
                    View {{subOrdr.orderNumber}}
                  </router-link>
                </b-form-group>

              </div>
            </b-collapse>
          </div>
        </div>
      </aside>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card
          v-if="order.shipments && order.shipments.length > 0"
          class="mb-3"
          no-body
        >
          <template #header class="card-header">
            <h4 class="card-header-title">Shipments</h4>
          </template>

          <b-table
            class="m-0"
            :striped="true"
            :bordered="true"
            :outlined="true"
            :small="false"
            :hover="false"
            :dark="false"
            :responsive="true"
            :items="order.shipments"
            head-variant="dark"
            :fields="shipmentFields"
          >

            <template v-slot:cell(status)="data">
              <span  class="badge badge-secondary" v-if="data.item.status == 'Created'">Created</span>
              <span  class="badge badge-warning" v-else-if="data.item.status == 'InTransit'">InTransit</span>
              <span  class="badge badge-info" v-else-if="data.item.status == 'Shipped'">Shipped</span>
              <span  class="badge badge-success" v-else-if="data.item.status == 'Delivered'">Delivered</span>
              <span  class="badge badge-danger" v-else-if="data.item.status == 'Cancelled'">Cancelled</span>
              <span  class="badge badge-danger" v-else-if="data.item.status == 'DamagedInTransit'">Damaged In Transit</span>
              <span  class="badge badge-danger" v-else-if="data.item.status == 'LostInTransit'">LostInTransit</span>
              <span class="badge badge-secondary" v-else>{{ data.item.status }}</span>
            </template>
            <template v-slot:cell(trackingNumber)="data">
            <div>
              <span v-if="data.item.trackingUrl"><a target="_blank"
                class="badge"
                  :class="{
                    'badge-danger': data.item.isReturnLabel,
                    'badge-secondary': !data.item.isReturnLabel
                  }"
                :href="data.item.trackingUrl">{{ data.item.trackingNumber }}</a></span>
              <span v-else-if="data.item.trackingNumber">
                <a :href="'https://t.17track.net/en#nums=' + data.item.trackingNumber"
                  target="_blank"
                  class="badge"
                  :class="{
                    'badge-danger': data.item.isReturnLabel,
                    'badge-secondary': !data.item.isReturnLabel
                  }"
                  >{{ data.item.trackingNumber }}</a>
              </span>
              <span v-else>
                <span>{{ data.item.trackingNumber }}</span>
              </span>
            </div>
            <div class="border-top">
              <span class="text-secondary" v-if="data.item.shipDate">Shipped at: {{ data.item.shipDate | moment('MMMM Do YYYY, h:mm a') }}</span>
            </div>
            </template>
            <template v-slot:cell(receiveDate)="data">
              <span v-if="data.item.receiveDate">{{ data.item.receiveDate | moment('MMMM Do YYYY') }}</span>
              <span v-else>-</span>
            </template>

            <template v-slot:cell(carrierCode)="data">
              <div>
                <span v-if="data.item.carrierCode">{{ data.item.carrierCode  }}</span>
                <span v-else>-</span>
              </div>
              <div class="border-top" v-if="data.item.serviceCode">
                <span class="text-secondary">{{ data.item.serviceCode }}</span>
              </div>
            </template>
            <template v-slot:cell(dimensions)="data">
              <div v-if="data.item.dimensions && data.item.dimensions.units">
                <span>W: {{ data.item.dimensions.width }} {{ data.item.dimensions.units }}</span> |
                <span>H {{ data.item.dimensions.height }} {{ data.item.dimensions.units }}</span> |
                <span>L: {{ data.item.dimensions.length }} {{ data.item.dimensions.units }}</span>
              </div>
            </template>

            <template v-slot:cell(weight)="data">
              <div v-if="!is('brand-user') && data.item.weight && data.item.weight.value && data.item.grossWeight && data.item.grossWeight.units && data.item.grossWeight.value && parseFloat(data.item.weight.value) > parseFloat(data.item.grossWeight.value)">
                <b>Gross (DIMW): </b><span>{{ parseFloat(data.item.weight.value).toFixed(2) }}</span> (Orig: <span>{{ parseFloat(data.item.grossWeight.value).toFixed(2) }}</span>) {{ data.item.weight.units }}
              </div>
              <div v-else-if="data.item.weight && data.item.weight.units && data.item.weight.value">
                <b>Gross: </b><span>{{ parseFloat(data.item.weight.value).toFixed(2) }}</span> {{ data.item.weight.units }}
              </div>
              <div class="border-top" v-if="data.item.packagingWeight && data.item.packagingWeight.units && data.item.packagingWeight.value">
                <b>Packaging: </b><span>{{ parseFloat(data.item.packagingWeight.value).toFixed(2) }}</span> {{ data.item.packagingWeight.units }}
              </div>
            </template>

            <template v-slot:cell(fulfilledByService)="data">
              <span
               v-if="data.item.fulfilledByService == 'TPL'"
              >
                <span v-if="!has('pm.is_client')">Extensiv</span>
                <span v-else>LaCore Logistics</span>
              </span>
              <span v-else>
                {{ data.item.fulfilledByService }}
              </span>
            </template>

            <template v-slot:cell(id)="data">
              <button
                class="btn btn-primary"
                @click="ViewShipmentItems(data.item)"
              >
                Details
              </button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card v-if="editingOrder"  class="mb-3" header="Order Items" no-body>
          <div class="card-body">
            <div class="items">
              <b-table
                head-variant="light"
                :fields="orderItemFields"
                :items="itemForm.items"
                :bordered="true"
              >
                <template v-slot:cell(quantity)="data">
                    <b-form-input
                      type="number"
                      step="1"
                      key="form-iteminput-quantity"
                      name="form-iteminput-quantity"
                      class="form-control"
                      v-model="data.item.quantity"
                      min="1"
                    />
                </template>
                <template v-slot:cell(actions)="data">
                  <div class="d-flex">
                    <button
                      class="btn btn-danger"
                      @click="data.item.id ? RemoveItem(data.item) : RemoveItem2(data.item)"
                    >
                      Remove
                    </button>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div v-if="editThisSku == data.item.sku" class="form-row">
                    <b-form-input v-model="data.item.name" placeholder="Name" required />
                    <b-button variant="secondary" size="sm" @click="editThisSku = ''"><i class="fa fa-save"></i></b-button>
                  </div>
                  <div v-else>
                    <span>{{ data.item.name }}</span>
                    <b-button variant="secondary" size="sm" @click="editThisSku = data.item.sku"><i class="fa fa-edit"></i></b-button>
                  </div>
                </template>
                <template v-slot:cell(unitPrice)="data">
                    <b-form-input
                      type="number"
                      step="0.01"
                      key="form-iteminput-unitPrice"
                      name="form-iteminput-unitPrice"
                      class="form-control"
                      v-model="data.item.unitPrice"
                      min="0"
                    />
                </template>
                <template v-slot:cell(unitWeightOz)="data">
                    <b-form-input
                      type="number"
                      step="0.01"
                      key="form-iteminput-unitWeightOz"
                      name="form-iteminput-unitWeightOz"
                      class="form-control"
                      v-model="data.item.unitWeightOz"
                      min="0"
                      :disabled="projectOfOrder && !projectOfOrder.sendWeightsFromStorefront"
                    />
                </template>
              </b-table>
            </div>
          </div>
          <template v-slot:footer>
            <form
              @submit.prevent
              name="orderChangeItemForm"
              id="orderChangeItemForm"
              data-vv-scope="orderChangeItemForm"
            >
              <div class="alert alert-info p-2" v-if="!itemForm.projectId">
                <b>Please pick the storefront first in the above form to be able to add products to this order.</b>
              </div>
              <div class="alert alert-warning p-2" v-else-if="loadingProductItems">
                <b>Loading Products, please wait...</b>
              </div>
              <div class="form-row" v-else>
                <div class="col-6 col-md-4">
                  <b-form-group label="Pick Product">
                    <b-v-select
                      key="form-iteminput-productId"
                      v-validate="{ required: true }"
                      name="form-iteminput-productId"
                      :showup="true"
                      v-model="orderChangeItemForm.pickedProduct"
                      :searchable="true"
                      :allowNewValues="false"
                      :options="productsList"
                    />
                  </b-form-group>
                </div>
                <div class="col-6 col-md-2">
                  <b-form-group label="Quantity">
                    <b-input-group>
                      <b-form-input
                        type="number"
                        step="1"
                        key="form-iteminput-quantity"
                        v-validate="{
                          required: true,
                          numeric: true,
                          min_value: 1
                        }"
                        name="form-iteminput-quantity"
                        class="form-control"
                        v-model="orderChangeItemForm.quantity"
                        min="1"
                              />
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-6 col-md-2">
                  <b-form-group label="Unit Price">
                    <b-input-group>
                      <b-form-input
                        type="number"
                        step="0.01"
                        key="form-iteminput-unitPrice"
                        name="form-iteminput-unitPrice"
                        class="form-control"
                        v-model="orderChangeItemForm.unitPrice"
                        min="0"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-6 col-md-2">
                  <b-form-group label="Unit Weight">
                    <b-input-group>
                      <b-form-input
                        type="number"
                        step="0.01"
                        key="form-iteminput-unitWeightOz"
                        name="form-iteminput-unitWeightOz"
                        class="form-control"
                        v-model="orderChangeItemForm.unitWeightOz"
                        min="0"
                      :disabled="projectOfOrder && !projectOfOrder.sendWeightsFromStorefront"
                      />
                      <template #append>
                        <div class="btn btn-secondary active">oz</div>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-6 col-md-2">
                  <b-form-group label=".">
                    <b-button
                      type="button"
                      block
                      variant="primary"
                      @click="AddOrderItem"
                    >
                      Add
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </form>
          </template>
        </b-card>

        <b-card v-else no-body>
          <template #header class="card-header">
            <h4 class="card-header-title">Order Items</h4>
          </template>

          <b-table
            class="m-0 orderdetails-items-table"
            v-if="order.items && order.items.length > 0"
            :striped="true"
            :bordered="true"
            :outlined="true"
            :small="false"
            :hover="false"
            :dark="false"
            :responsive="true"
            :items="order.items"
            head-variant="dark"
            :fields="fieldsItems"
          >
            <template v-slot:cell(sku)="data">
              <div
                v-if="data.item.imageUrl"
                class="d-inline-block mr-1"
              >
                <img
                  :src="data.item.imageUrl"
                  alt=""
                  style="max-width: 50px"
                />
              </div>

              <span
                style="font-size: 14px;"
                class="d-block d-lg-inline-block text-center ml-1"
                >
                <div class="d-inline-block"  v-if="!has('pm.is_end_partner')">
                  <router-link :to="{name: 'Reports.StorefrontProductStatistics', query: {sku: data.item.sku, projectId: order.projectId}}">
                    <i v-b-tooltip.hover.top="'Fulfill as: ' + data.item.fulfillmentSku" v-if="data.item.fulfillmentSku && data.item.fulfillmentSku != data.item.sku">{{ data.item.sku }}</i>
                    <span v-else>{{ data.item.sku }}</span>
                  </router-link>
                </div>
                <div class="d-inline-block"  v-else>
                  <router-link :to="{name: 'apps-reports-storefront-stats', query: {sku: data.item.sku, projectId: order.projectId}}">
                    <i v-b-tooltip.hover.top="'Fulfill as: ' + data.item.fulfillmentSku" v-if="data.item.fulfillmentSku && data.item.fulfillmentSku != data.item.sku">{{ data.item.sku }}</i>
                    <span v-else>{{ data.item.sku }}</span>
                  </router-link>
                </div>

                  <div class="d-inline-block" :class="{ 'text-danger': true }"
                    v-if="data.item.notEnoughInventory"
                    ><i
                      v-b-tooltip.hover
                      class="fa fa-info-circle"
                      title="This order item did not have enough inventory to be fulfilled."
                    />
                  </div>
                </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    </section>

  </section>
</template>

<script>
//import vSelect from 'vue-select';
import OrderSplitPopup from '../../../components/OrderSplitPopup.vue'
import FinanceMarginWidget from '@/views/components/FinanceMarginWidget.vue';
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import DiscussionsWidget from '@/views/components/DiscussionsWidget';
export default {
  mixins: [myCommonDataMixin],
  name: 'OrderDetails',
  components: {
    DiscussionsWidget,
    FinanceMarginWidget: FinanceMarginWidget,
    OrderSplitPopup
  },
  // props: {
  //   id: {
  //     type: String,
  //     required: false
  //   }
  // },
  data() {
    return {
      chatWidgetProps: {
        startOpen: true,
        channelType: 'FeatureClientSupport',
        relatedDesc: 'Discussion for Order',
        relatedName: 'Storefront Order: ',
        relatedId: '',
        relatedType: 'SO'
      },

      shipStationUsers: [],
      //refreshinItem: '',
      loadingProductItems: false,
      editThisSku: '',
      id: '',
      storeProdsList: [],
      orderChangeItemForm: {
        unitPrice: 0,
        unitWeightOz: 0,
        sku: '',
        name: '',
        productId: null,
        quantity: 1
      },
      orderItemFields: [
        { key: 'sku', label: 'SKU', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'quantity', label: 'Quantity', sortable: false },
        { key: 'unitPrice', label: 'Price', sortable: false },
        { key: 'unitWeightOz', label: 'Weight', sortable: false },
        { key: 'actions' }
      ],

      loadingPage: false,
      fieldsItems: [
        { key: 'sku', label: 'Sku', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'quantity', label: 'Quantity', sortable: false },
        //{ key: 'fulfillmentSku', label: 'Fulfillment Sku', sortable: false },
        { key: 'unitPrice', label: 'Unit Price', sortable: false }
      ],
      shipmentFields: [
        {
          key: 'fulfilledByService',
          label: 'Fulfilled By',
          sortable: false
        },
        { key: 'trackingNumber', label: 'Tracking Number', sortable: false },
        //{ key: 'externalShipmentId', label: 'External ID', sortable: false },
        { key: 'carrierCode', label: 'Carrier', sortable: false },
        { key: 'dimensions', label: 'Dimensions', sortable: false },
        { key: 'weight', label: 'Weight', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'receiveDate', label: 'Delivered On', sortable: false },
        { key: 'id', label: 'Actions', sortable: false }
      ],
      shipmentItemsFields: [
        { key: 'sku', label: 'Sku', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'quantity', label: 'Quantity', sortable: false },
        { key: 'fullfillInfo', label: 'Fulfillment Info', sortable: false },
      ],
      //toPickOrderItems: [],
      toPickProducts: [],
      reshipItemsPicked: [],
      reshipItemForm: {
        productItm: null,
        quantity: 1,
        unitPrice: 0
      },
      showReshipForm: false,
      showSplitForm: false,
      splitOrderInf: null,
      loadingOrderChange: false,
      editingOrder: false,
      baseOrder: {
        orderDate: '',
        paymentDate: '',
        shipByDate: ''
      },
      originalOrder: null,
      reshipReason: 'Wrong Product Received',
      doFullReship: true,
      toReshipOrder: false,
      activeShipment: false,
      shipmentItems: false,
      showShipmentItemsModal: false,
      reshipReasonsList: [
        {
          value: 'Wrong Product Received',
          text: 'Wrong Product Received',
          sortable: false
        },
        {
          value: 'Damaged Product by Carrier',
          text: 'Damaged Product by Carrier',
          sortable: false
        },
        {
          value: 'Missing Packets in Box',
          text: 'Missing Packets in Box',
          sortable: false
        },
        {
          value: 'Missing Item from Order',
          text: 'Missing Item from Order',
          sortable: false
        },
        {
          value: 'Damaged Product in Box',
          text: 'Damaged Product in Box',
          sortable: false
        },
        {
          value: 'Returned To Sender',
          text: 'Returned To Sender',
          sortable: false
        },
        {
          value: 'Empty Packets in Box',
          text: 'Empty Packets in Box',
          sortable: false
        },
        {
          value: 'Package Lost in Transit',
          text: 'Package Lost in Transit',
          sortable: false
        },
        {
          value: 'Exchange',
          text: 'Exchange',
          sortable: false
        },
        {
          value: 'Other',
          text: 'Other',
          sortable: false
        }
      ],
      order: {
        orderDate: '',
        paymentDate: '',
        shipByDate: ''
      },
      tagsAll: []
    };
  },
  computed: {
    productsList: {
      get() {
        let arrs = [];
        this.storeProdsList.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.sku + ' (' + itm.name + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
    itemsTotal: {
      get() {
        let total = 0;
        if (this.order && this.order.items) {
          let items = this.order.items;
            items.forEach(itm => {
            let totalPrice = parseFloat(itm.unitPrice * itm.quantity);
            total += totalPrice;
          });
        }
        return total;
      },
      set(val, old) {
        // this.productListAll = val;
      }
    },
    // toPickProductsList: {
    //   get() {
    //     let list = this.toPickProducts;
    //     let arrs = [];
    //     list.forEach(itm => {
    //       arrs.push({
    //         label: itm.name + ' (' + itm.sku + ')',
    //         value: itm.productId
    //       });
    //     });
    //     return arrs;
    //   },
    //   set(val, old) {
    //     // this.productListAll = val;
    //   }
    // },
    ssAssignedOfOrder: {
      get() {
        let assignedTo = null;
        if (this.order && this.order.projectId && this.order.userId) {
          assignedTo = this.shipStationUsers.find(a => a.userId == this.order.userId);
        }
        return assignedTo == null ? '' : (assignedTo.name + ' ('+assignedTo.userName+')');
      },
      set(val, old) {
        // this.productListAll = val;
      }
    },
    projectInfoOfOrder: {
      get() {
        let project = null;
        if (this.order && this.order.projectId) {
          project = this.myProjects.find(a => a.id == this.order.projectId);
          if(project && project.onlyFulfillShipStationUserId && this.order.sourceOfOrder == 'ShipStation') {
            this.loadShipStationUsers(this.order.projectId);
          }
        }
        return project == null ? '' : project;
      },
      set(val, old) {
        // this.productListAll = val;
      }
    },
    projectOfOrder: {
      get() {
        let project = null;
        if (this.order && this.order.projectId) {
          project = this.myProjects.find(a => a.id == this.order.projectId);
        }
        return project == null ? '' : project.name;
      },
      set(val, old) {
        // this.productListAll = val;
      }
    },
    storeOfOrder: {
      get() {
        let store = null;
        if (this.order && this.order.storeId) {
          store = this.myStores.find(a => a.id == this.order.storeId);
        }
        return store == null ? '' : store.storeName;
      },
      set(val, old) {
        // this.productListAll = val;
      }
    },
    // projectsList: {
    //   get() {
    //     let arrs = [];
    //     this.projectAll.forEach(itm => {
    //       arrs.push({
    //         value: itm.id,
    //         text: itm.name + ' (' + itm.clientCode + ')'
    //       });
    //     });
    //     return arrs;
    //   },
    //   set(old, val) {}
    // }
  },
  watch: {
    'orderChangeItemForm.pickedProduct': function(newVal) {
      var prod = this.storeProdsList.find(a => a.id == newVal);
      if (prod) {
        this.orderChangeItemForm.productId = prod.productId;
        this.orderChangeItemForm.unitPrice = prod.price;
        this.orderChangeItemForm.unitWeightOz = parseFloat(prod.weightOz);
        if(!prod.weightOz) {
          this.orderChangeItemForm.unitWeightOz = 0;
        } else {
          this.orderChangeItemForm.unitWeightOz = parseFloat(prod.weightOz);
        }
        this.orderChangeItemForm.addProductSku = prod.sku;
      } else {
        this.orderChangeItemForm.addProductSku = newVal;
        this.orderChangeItemForm.productId = '';
        this.orderChangeItemForm.unitPrice = 0;
        this.orderChangeItemForm.unitWeightOz = 0;
      }
    },
    'orderChangeItemForm.productId': function(newVal) {
      var prod = this.storeProdsList.find(a => a.productId == newVal);
      if (prod) {
        this.orderChangeItemForm.unitPrice = prod.price;
        this.orderChangeItemForm.unitWeightOz = prod.weightOz
      }
    },
    'order.orderNumber': function(val) {
      // if(this.order.orderKey != val) {
      //   this.order.orderKey = val;
      // }
    },
    '$route.query.id': function(val) {
      this.id = val;
      this.order = {
        orderDate: '',
        paymentDate: '',
        shipByDate: ''
      };
      this.loadItem();
    },
    pagination: {
      handler: function(val, oldVal) {
        this.loadItem();
      },
      deep: true
    },
    showReshipForm: function(val) {
      if (!val) {
        this.doFullReship = true;
        this.reshipItemForm = {
          productItm: null,
          quantity: 1,
          unitPrice: 0
        };
        this.toPickProducts = [];
        this.reshipItemsPicked = [];
      }
    },
    'reshipItemForm.productItm': function(picked) {
      if(picked) {
        let productId = picked.value;
        if (productId) {
          //this.ProductPicked(productId);
        }
      }
    }
  },
  meta() {
    let titl = (this.order && this.order.orderNumber) ? ('Order #' + this.order.orderNumber) : 'Order Details';
      return {
          title: titl,
          // ...
      };
  },
  mounted() {
    let queryHas = this.$route.query;
    if (queryHas && queryHas.id) {
      this.id = queryHas.id;
    }
    this.loadItem();
    try {
      this.$userHub.$on('order-updated', (payload) => {
        this.loadItem();
      });
      this.$userHub.$emit('JoinRoom', 'Order-'+this.id)
    } catch {
      console.log('error order')
    }
  },
  beforeDestroy: function() {
    try {
      this.$userHub.$emit('LeaveRoom', 'Order-'+this.id)
    } catch {
      console.log('error order')
    }
  },
  methods: {
    CopyToClipboard(cipId) {
      const element = this.$refs[cipId][0].$el;
      element.select();
      element.setSelectionRange(0, 99999);
      window.document.execCommand('copy');
      this.$bvToast.toast(
          'URL for Checkout was copied to your clipboard!',
          {
            title: 'URL Copied!',
            variant: 'secondary',
            autoHideDelay: 1100
          }
        );
    },
    loadShipStationUsers(projectId) {
      this.$http
        .get('/api/common/projects/getShipStationUsers/'+projectId)
        .then(resp => {
          this.shipStationUsers = resp.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    // ItemRefresh(sku) {
    //   this.refreshinItem = sku;
    //   this.$http.get('/api/common/products/refreshForOrder/'+ sku + '/'+ this.itemForm.id).then(
    //     (response) => {
    //     this.refreshinItem  = '';
    //       if(response.data.success) {
    //         /* sweet alert success message */
    //       this.loadItem();
    //       } else {
    //         this.$swal.fire({
    //           title: 'Error',
    //           text: response.data.errorMessage,
    //           type: 'error',
    //           confirmButtonText: 'Ok'
    //         });
    //       }
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   );
    // },
    AddOrderItem() {
        if (
          (this.orderChangeItemForm.productId || this.orderChangeItemForm.addProductSku) &&
          parseFloat(this.orderChangeItemForm.quantity) > 0
        ) {
          if(this.orderChangeItemForm.productId) {
            let prod = this.storeProdsList.find(
              a => a.productId == this.orderChangeItemForm.productId
            );
            this.itemForm.items.push({
              orderId: this.itemForm.id,
              newItemToAdd: true,
              sku: prod.sku,
              name: prod.name,
              quantity: parseInt(this.orderChangeItemForm.quantity),
              unitPrice: parseFloat(this.orderChangeItemForm.unitPrice),
              weight: {
                value: parseFloat(this.orderChangeItemForm.unitWeightOz),
                units: 'ounces'
              },
              unitWeightOz: parseFloat(this.orderChangeItemForm.unitWeightOz),
              productId: this.orderChangeItemForm.productId
            });
          } else if(this.orderChangeItemForm.addProductSku){
            let prod = this.storeProdsList.find(
              a => a.id == this.orderChangeItemForm.addProductSku
            );
            let prod2 = this.storeProdsList.find(
              a => a.sku == this.orderChangeItemForm.addProductSku
            );
            if(prod) {
              this.itemForm.items.push({
                orderId: this.itemForm.id,
                orderItemId: 'itm' + new Date().getUTCMilliseconds(),
                newItemToAdd: true,
                sku: prod.sku,
                name: prod.name,
                quantity: parseInt(this.orderChangeItemForm.quantity),
                unitPrice: parseFloat(this.orderChangeItemForm.unitPrice),
                weight: {
                  value: parseFloat(this.orderChangeItemForm.unitWeightOz),
                  units: 'ounces'
                },
                unitWeightOz: parseFloat(this.orderChangeItemForm.unitWeightOz),
                productId: prod.productId
              });
            } else if(prod2) {
              this.itemForm.items.push({
                orderId: this.itemForm.id,
                orderItemId: 'itm' + new Date().getUTCMilliseconds(),
                newItemToAdd: true,
                sku: prod2.sku,
                name: prod2.name,
                quantity: parseInt(this.orderChangeItemForm.quantity),
                unitPrice: parseFloat(this.orderChangeItemForm.unitPrice),
                weight: {
                  value: parseFloat(this.orderChangeItemForm.unitWeightOz),
                  units: 'ounces'
                },
                unitWeightOz: parseFloat(this.orderChangeItemForm.unitWeightOz),
                productId: prod2.productId
              });
            } else {
              this.itemForm.items.push({
                orderId: this.itemForm.id,
                orderItemId: 'itm' + new Date().getUTCMilliseconds(),
                newItemToAdd: true,
                sku: this.orderChangeItemForm.addProductSku,
                name: this.orderChangeItemForm.addProductSku,
                quantity: parseInt(this.orderChangeItemForm.quantity),
                unitPrice: parseFloat(this.orderChangeItemForm.unitPrice),
                weight: {
                  value: parseFloat(this.orderChangeItemForm.unitWeightOz),
                  units: 'ounces'
                },
                unitWeightOz: parseFloat(this.orderChangeItemForm.unitWeightOz),
                productId: ''
              });
            }
          }
          this.orderChangeItemForm = {
            unitPrice: 0,
            unitWeightOz: 0,
            addProductSku: '',
            sku: '',
            name: '',
            productId: null,
            quantity: 1
          };
        }
    },

    RemoveItem(item) {
      this.itemForm.items = this.itemForm.items.filter(
        a => a.id != item.id
      );
    },

    RemoveItem2(item) {
      this.itemForm.items = this.itemForm.items.filter(
        a => a.sku != item.sku
      );
    },
    LoadProductsFor(projectId) {
      this.storeProdsList = [];
      if (projectId) {
        this.loadingProductItems = true;
        this.storeProdsList = [];
        this.$http
          .post('/api/common/products/byProject/' + projectId)
          .then(resp => {
            this.storeProdsList = resp.data;
            this.loadingProductItems = false;
          })
          .catch(err => {
            this.storeProdsList = [];
            this.loadingProductItems = false;
          });
      }
    },
    SplitShipmentOrder(order) {
      this.splitOrderInf = order;
      this.showSplitForm = true;
    },
    OpenCreateMappingPage() {
      this.$router.push({
        name: 'Storefronts.ShipCodeMappings.Create',
        query: { fulfillmentService: (this.projectInfoOfOrder ? this.projectInfoOfOrder.fulfillmentService : ''),  projectId: this.order.projectId, requestedService: this.order.requestedShippingService, orderCarrierCode: (this.order.carrierCode ? this.order.carrierCode : ''), orderServiceCode: (this.order.serviceCode ? this.order.serviceCode : '') }
      });
    },
    getImageUrlFor(shipItem) {
      let imageUrl = '';
      let fndOrderItem = this.order.items.find(
        a => a.itemId == shipItem.itemId
      );
      if (fndOrderItem) {
        imageUrl = fndOrderItem.imageUrl;
      }
      return imageUrl;
    },
    getTagById(projId, id) {
      let tagResp = '';
      let tagItem = false;
      tagItem = this.tagsAll.find(s => s.projectId == projId && s.shipStationTagId == id);
      if (tagItem) {
        tagResp = `<span class="badge badge-primary" style="background-color: ${tagItem.color}">${tagItem.name}</span> `;
      }
      return tagResp;
    },
    loadTags() {
      this.$http
        .post('/api/common/projects/tagsByProjectId/'+this.order.projectId,
        {})
        .then(resp => {
          this.tagsAll = resp.data;
        })
        .catch(err => {});
    },

    // RemoveReshipItem(id) {
    //   this.reshipItemsPicked = this.reshipItemsPicked.filter(
    //     s => s.id != id
    //   );
    // },

    // AddReshipItem() {
    //   let prodFound = this.toPickProducts.find(
    //     s => s.id == this.reshipItemForm.productItm.value
    //   );
    //   let qty = parseInt(this.reshipItemForm.quantity);
    //   let price = parseFloat(this.reshipItemForm.unitPrice);
    //   if (prodFound && qty > 0) {
    //     this.reshipItemsPicked.push({
    //       id: 'itm' + new Date().getUTCMilliseconds(),
    //       unitPrice: price,
    //       quantity: qty,
    //       lineItemKey: 'itm' + new Date().getUTCMilliseconds(),
    //       orderId: 'tmpId',
    //       sku: prodFound.sku,
    //       productId: this.reshipItemForm.productItm.value,
    //       name: prodFound.name
    //     });
    //     this.reshipItemForm.productItm.value = '';
    //     this.reshipItemForm.unitPrice = 0;
    //     this.reshipItemForm.quantity = 1;
    //   } else {
    //     this.$swal('You must fill out the fields!');
    //   }
    // },

    OrderReshipConfirm(order) {
      this.$router.push({
        name: 'apps-customer-orders-create',
        query: { reshipOrderId: this.order.id, reshippedReason: this.reshipReason }
      });
    },
    StartEditingOrder() {
      this.originalOrder = JSON.parse(JSON.stringify(this.order));
      this.editingOrder = true;
      let ordr = this.order;
      ordr.items.forEach((itm)=> {
        if(itm.weight) {
          if(itm.weight.units == 'ounces') {
              itm.unitWeightOz = itm.weight.value;
          } else if(itm.weight.units == 'grams') {
              itm.unitWeightOz = parseFloat( parseFloat(parseFloat(itm.weight.value) / 28.34952).toFixed(2) );
          }
        } else {
            itm.unitWeightOz = 0;
        }
      })
      this.itemForm = ordr;
      this.LoadProductsFor(this.order.projectId);
    },
    CancelEditingOrder() {
      this.originalOrder = null;
      this.editingOrder = false;
    },
    SimulateSunbasketOrder() {
      let order = this.order;
      this.loadingOrderChange = true;
      let swl = this.$swal({
        title: 'Validating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.$http
        .post('/api/common/orders/' + order.id + '/sunbasket-simulate', order)
        .then(resp => {
          this.loadingOrderChange = false;
          //this.editingOrder = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order was validated against Sunbasket. No issues detected.', 'success');
            this.loadItem();
          } else {
            this.$swal('Success!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
          this.loadingOrderChange = false;
            swl.close();
            console.error(err);
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
        });
    },
    EditOrder() {
      let order = this.order;
      this.$swal({
        title: 'Are you sure you want to save these changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save Order'
      }).then(result => {
        if (result.value) {
          let swl = this.$swal({
            title: 'Updating Order!',
            text: 'Please wait...',
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          });
          this.loadingOrderChange = true;
          this.$http
            .post('/api/common/orders/' + order.id + '/edit', order)
            .then(resp => {
              this.loadingOrderChange = false;
              this.editingOrder = false;
              swl.close();
              if(resp.data.success) {
                this.$swal('Success!', 'Order was changed.', 'success');
                order.orderStatus = 'on_hold';
                this.loadItem();
              } else {
                this.$swal('Success!', 'Something went wrong', 'error');
              }
            })
            .catch(err => {
                this.loadingOrderChange = false;
                swl.close();
                console.error(err);
                  if (err.response && err.response.data.errorMessage) {
                    this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
                  } else {
                    this.$swal({ title: 'Something went wrong!', icon: 'error' });
                  }
            });
        } else {
          this.order = this.originalOrder;
          this.editingOrder = false;
        }
      });
    },
    ReProcessOrder(order, warnIfAlloc = true) {
      let txt2 = 'Are you REALLY sure you want to do that?';
      let msgWArning = "This will remove the pending fulfillment status by placing the storefront order on hold, and cancelling the order in the warehouse!";
      if(order.fulfilledByService == 'Crane') {
        msgWArning = "This will remove the pending fulfillment status by placing the storefront order on hold! NOTE: This will NOT cancel the order at Crane! Make sure the order is already cancelled at Crane before using this!";
        txt2 = 'You sure you CANCELLED in Crane?';
      }
      if(!warnIfAlloc) {
        txt2 = 'Already allocated, remember! Are you REALLY sure?';
        msgWArning = "Order was already allocated and is being packed by someone at the warehouse, if you continue, the order will be unallocated and any pick ticket invalidated! You might need to contact someone at the floor to stop packing if you haven't already.";
      }
      this.$swal({
        title: 'Are you sure?',
        text: msgWArning,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Do it.'
      }).then(result => {
        if (result.value) {
            this.$swal({
              title: txt2,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, do it!'
            }).then(result => {
              if (result.value) {
                let swl = this.$swal({
                  title: 'Updating Order!',
                  text: 'Please wait...',
                  icon: 'warning',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false
                });
                this.loadingOrderChange = true;
                this.$http
                  .post('/api/common/orders/' + order.id + '/reprocess', {
                    warnIfAllocated: warnIfAlloc
                  })
                  .then(resp => {
                    this.loadingOrderChange = false;
                    swl.close();
                    if(resp.data.success) {
                      this.$swal('Success!', 'Order was placed into on hold. When ready, remove from hold, and it will be resent to the fulfillment service!', 'success');
                      order.orderStatus = 'on_hold';
                      this.loadItem();
                    } else {
                      this.$swal('Success!', 'Something went wrong', 'error');
                    }
                  })
                  .catch(err => {
                      this.loadingOrderChange = false;
                      swl.close();
                      console.error(err);
                      if (err.response && err.response.data.errorMessage) {
                        if(err.response.data.errorMessage == 'This order is already being packed! Are you sure?') {
                          this.ReProcessOrder(order, false);
                        } else {
                          this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
                        }
                      } else {
                        this.$swal({ title: 'Something went wrong!', icon: 'error' });
                      }
                  });
              }
            });
        }
      });
    },
    MarkShippedOrder(order) {
      this.$swal({
        title: 'Are you sure you want to mark this order shipped?',
        text: 'You won\'t be able to undo this.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Do it.'
      }).then(result => {
        if (result.value) {
            this.$swal({
              title: 'Are you REALLY sure?',
              text: 'You can enter a tracking number, or leave it empty if you do not have one.',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Mark Shipped!',
              input: 'text',
              inputLabel: 'Tracking Number',
              inputPlaceholder: 'Enter the tracking number',
              inputAttributes: {
                maxlength: 30,
                autocapitalize: 'off',
                autocorrect: 'off'
              }
            }).then(result => {
              //if (result.value) {
                let trackingNumber = result.value;
                let swl = this.$swal({
                  title: 'Updating Order!',
                  text: 'Please wait...',
                  icon: 'warning',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false
                });
                this.loadingOrderChange = true;
                this.$http
                  .post('/api/common/orders/' + order.id + '/markShipped', {
                    trackingNumber: trackingNumber
                  })
                  .then(resp => {
                    this.loadingOrderChange = false;
                    swl.close();
                    if(resp.data.success) {
                      this.$swal('Success!', 'Order was marked shipped.', 'success');
                      order.orderStatus = 'shipped';
                      this.loadItem();
                    } else {
                      this.$swal('Success!', 'Something went wrong', 'error');
                    }
                  })
                  .catch(err => {
                      this.loadingOrderChange = false;
                      swl.close();
                      console.error(err);
                      if (err.response && err.response.data.errorMessage) {
                        this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
                      } else {
                        this.$swal({ title: 'Something went wrong!', icon: 'error' });
                      }
                  });
              //}
            });
        }
      });
    },
    AwaitShipOrder(order) {
      this.$swal({
        title: 'Are you sure you want to place this order into awaiting shipment?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Do it.'
      }).then(result => {
        if (result.value) {
            this.$swal({
              title: 'Are you REALLY sure you want to do that?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, do it!'
            }).then(result => {
              if (result.value) {
                let swl = this.$swal({
                  title: 'Updating Order!',
                  text: 'Please wait...',
                  icon: 'warning',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false
                });
                this.loadingOrderChange = true;
                this.$http
                  .post('/api/common/orders/' + order.id + '/awaitShip')
                  .then(resp => {
                    this.loadingOrderChange = false;
                    swl.close();
                    if(resp.data.success) {
                      this.$swal('Success!', 'Order was placed into awaiting shipment.', 'success');
                      order.orderStatus = 'on_hold';
                      this.loadItem();
                    } else {
                      this.$swal('Success!', 'Something went wrong', 'error');
                    }
                  })
                  .catch(err => {
                      this.loadingOrderChange = false;
                      swl.close();
                      console.error(err);
                      if (err.response && err.response.data.errorMessage) {
                        this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
                      } else {
                        this.$swal({ title: 'Something went wrong!', icon: 'error' });
                      }
                  });
              }
            });
        }
      });
    },
    CancelOrder(order) {
      let txt2 = 'Are you REALLY sure you want to do that?';
      let msgWArning = "This will cancel the order in the storefront as well!";
      if(order.fulfilledByService == 'Crane') {
        msgWArning = "This will cancel the order in the storefront as well! NOTE: This will NOT cancel the order at Crane! Make sure the order is already cancelled at Crane before using this!";
        txt2 = 'You sure you CANCELLED in Crane?';
      }
      this.$swal({
        title: 'Are you sure you want to cancel this order?',
        text: msgWArning,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Cancel it.'
      }).then(result => {
        if (result.value) {
            this.$swal({
              title: 'Are you REALLY sure you want to cancel?',
              text: txt2,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, cancel it!'
            }).then(result => {
              if (result.value) {
                let swl = this.$swal({
                  title: 'Updating Order!',
                  text: 'Please wait...',
                  icon: 'warning',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false
                });
                this.loadingOrderChange = true;
                this.$http
                  .post('/api/common/orders/' + order.id + '/cancel')
                  .then(resp => {
                    this.loadingOrderChange = false;
                    swl.close();
                    if(resp.data.success) {
                      this.$swal('Success!', 'Order was cancelled.', 'success');
                      order.orderStatus = 'on_hold';
                      this.loadItem();
                    } else {
                      this.$swal('Success!', 'Something went wrong', 'error');
                    }
                  })
                  .catch(err => {
                      this.loadingOrderChange = false;
                      swl.close();
                      console.error(err);
                      if (err.response && err.response.data.errorMessage) {
                        this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
                      } else {
                        this.$swal({ title: 'Something went wrong!', icon: 'error' });
                      }
                  });
              }
            });
        }
      });
    },
    ReleaseHold(order) {
      let swl = this.$swal({
        title: 'Updating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.loadingOrderChange = true;
      this.$http
        .delete('/api/common/orders/' + order.id + '/hold')
        .then(resp => {
          this.loadingOrderChange = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order was released.', 'success');
            order.orderStatus = 'awaiting_shipment';
            this.loadItem();
          } else {
            this.$swal('Error!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
          this.loadingOrderChange = false;
          swl.close();
          console.error(err);
          if (err.response && err.response.data.errorMessage) {
            this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
          } else {
            this.$swal({ title: 'Something went wrong!', icon: 'error' });
          }
        });
    },
    PutOnHold(order) {
      let swl = this.$swal({
        title: 'Updating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.loadingOrderChange = true;
      this.$http
        .post('/api/common/orders/' + order.id + '/hold')
        .then(resp => {
          this.loadingOrderChange = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order was put on hold.', 'success');
            order.orderStatus = 'on_hold';
            this.loadItem();
          } else {
            this.$swal('Success!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
            this.loadingOrderChange = false;
            swl.close();
            console.error(err);
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
        });
    },
    RetryVirtualFulfillOrder(order) {
      let swl = this.$swal({
        title: 'Updating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.loadingOrderChange = true;
      this.$http
        .post('/api/common/orders/' + order.id + '/virtualRetry')
        .then(resp => {
          this.loadingOrderChange = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order is queued for getting mirrored to warehouse.', 'success');
            order.orderStatus = 'on_hold';
            this.loadItem();
          } else {
            this.$swal('Success!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
            this.loadingOrderChange = false;
            swl.close();
            console.error(err);
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
        });
    },
    GenLabelForOrder(order) {
      let swl = this.$swal({
        title: 'Updating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.loadingOrderChange = true;
      this.$http
        .post('/api/common/orders/' + order.id + '/generateLabel')
        .then(resp => {
          this.loadingOrderChange = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order is queued. Generating label...', 'success');
            order.orderStatus = 'on_hold';
            this.loadItem();
          } else {
            this.$swal('Success!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
            this.loadingOrderChange = false;
            swl.close();
            console.error(err);
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
        });
    },
    RetryOrderWithCarrier(order) {
      let swl = this.$swal({
        title: 'Updating Order!',
        text: 'Please wait...',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      this.loadingOrderChange = true;
      this.$http
        .post('/api/common/orders/' + order.id + '/carrierSync')
        .then(resp => {
          this.loadingOrderChange = false;
          swl.close();
          if(resp.data.success) {
            this.$swal('Success!', 'Order is queued. Will be synced with carrier in a minute.', 'success');
            order.orderStatus = 'on_hold';
            this.loadItem();
          } else {
            this.$swal('Success!', 'Something went wrong', 'error');
          }
        })
        .catch(err => {
            this.loadingOrderChange = false;
            swl.close();
            console.error(err);
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: 'Failed!', text: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
        });
    },
    OrderReship(order) {
      this.reshipItemForm.productId = '';
      this.reshipItemForm.unitPrice = 0;
      this.reshipItemForm.quantity = 1;
      this.toPickProducts = [];

      this.$http
        .post('/api/common/products/byProject/' + order.projectId)
        .then(resp => {
          this.toReshipOrder = order;
          this.reshipItemsPicked = order.items;
          this.toPickProducts = resp.data;
          this.showReshipForm = true;
        })
        .catch(err => {});
    },

    loadItem() {
      this.$http
        .get('/api/common/orders/' + this.id)
        .then(resp => {
          this.baseOrder = JSON.parse(JSON.stringify(resp.data));
          let ordr = resp.data;
          if(ordr.items) {
            ordr.items.forEach((it) => {
              if(it.adjustment) {
              it['_rowVariant'] = 'danger';
              }
            })
          }
          this.order = ordr;
          this.chatWidgetProps.relatedName = 'Storefront Order: ' + this.order.orderNumber;
          this.chatWidgetProps.relatedId = this.order.id;

          this.loadTags();
        })
        .catch(err => {
          console.error(err);
        });
      this.loadingPage = true;
    },
    getOrderStatus(order) {
      let stat = order.orderStatus;
      switch (stat) {
        case 'on_hold':
          return '<span class="badge badge-secondary">OnHold</span>';
        case 'open':
          return '<span class="badge badge-primary">Open</span>';
        case 'awaiting_shipment':
          return '<span class="badge badge-warning">Awaiting Shipment '+(order.sentToFulfillService ? ' [at fulfillment]' : '')+'</span>';
        case 'shipping':
          return '<span class="badge badge-warning">Shipping</span>';
        case 'shipped':
          return '<span class="badge badge-success">Shipped</span>';
        case 'cancelled':
          return '<span class="badge badge-danger">Canceled</span>';
        case 'awaiting_payment':
          return '<span class="badge badge-danger">Awaiting Payment</span>';
        case 'pending_fulfillment':
          return '<span class="badge badge-danger">Pending Fulfillment</span>';
        default:
          return '<span class="badge badge-primary">Open</span>';
      }
    },
    ViewShipmentItems(shipment) {
      this.activeShipment = shipment;
      this.shipmentItems = shipment.shipmentItems;
      this.showShipmentItemsModal = true;
    }
  }
};
</script>
