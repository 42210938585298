import Vue from 'vue';
import VueRouter from 'vue-router';
//import ability from '../libs/acl/ability';
// Routes
import { canNavigate, mustBeAuthorized } from '@/libs/acl/routeProtection';
import apps from './routes/apps';
import dashboard from './routes/dashboard';
import auth from './routes/auth';
//import uiElements from "./routes/ui-elements/index";
//import pages from "./routes/pages";
//import chartsMaps from "./routes/charts-maps";
//import formsTable from "./routes/forms-tables";
//import others from "./routes/others";
import store from '@/store/index';
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'dashboard-main'
      }
    },
    ...apps,
    ...dashboard,
    ...auth,
    //...chartsMaps,
    //...formsTable,
    //...uiElements,
    //...others,

    {
      path: '*',
      redirect: 'error-404'
    }
  ]
});

/* auth middleguard */
router.beforeEach((to, from, next) => {
  if (mustBeAuthorized(to)) {
    let storageRefToken = localStorage.getItem('refreshToken');
    if(!store.getters['account/getRefreshToken'] && storageRefToken) {
      store.commit('account/setRefreshToken', {
        refreshToken: storageRefToken,
        jwtToken: ''
      });
    }
    if (store.getters['account/isLoggedIn']) {
      if(canNavigate(to)) {
        next();
      } else {
        //localStorage.removeItem('refreshToken');
        return next({
          name: 'misc-not-authorized'
        });
      }
    } else {
      if (storageRefToken) {
        store
          .dispatch(
            'account/refreshToken',
            storageRefToken
          )
          .then(response => {
            let userData = response;
            userData.user = JSON.parse(JSON.stringify(userData));
            store.commit('account/setUserData', userData);
            if (!userData.userRoles.includes('brand-user')) {
              if (userData.env == 'prod') {
                window.location.replace('https://admin.lacoreconnect.com/login?_r=' + (Math.random().toString(36).substr(2, 5)));
              } else {
                window.location.replace('https://stagingadmin.lacoreconnect.com/login?_r=' + (Math.random().toString(36).substr(2, 5)));
              }
            }
            store.dispatch('account/autoRefreshToken');
            if(canNavigate(to)) {
              next();
            } else {
              //localStorage.removeItem('refreshToken');
              return next({
                name: 'misc-not-authorized'
              });
            }
          })
          .catch(err => {
            console.error(err);
            localStorage.removeItem('refreshToken');
            return next({
              name: 'auth-login'
            });
          });
      } else {
        return next({
          name: 'auth-login'
        });
      }
    }
  } else {
    next();
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
