//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  projectId: '',
  name: '',
  color: '',
  useFor: ''
};

let defaultFilters = {
  projectId: '',
  name: '',
  useFor: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters,
    systemUseOptions: state => {
      return [
        {
          value: 'MISSING_SKU',
          text: 'MISSING_SKU'
        },
        {
          value: 'DUPLICATE_ORDER_NUMBER',
          text: 'DUPLICATE_ORDER_NUMBER'
        },
        {
          value: 'UNKNOWN_ERROR',
          text: 'UNKNOWN_ERROR'
        },
        {
          value: 'CONFIG_ERROR',
          text: 'CONFIG_ERROR'
        },
        {
          value: 'NOT_ENOUGH_INVENTORY',
          text: 'NOT_ENOUGH_INVENTORY'
        },
        {
          value: 'SENT_TO_FULFILLMENT',
          text: 'SENT_TO_FULFILLMENT'
        },
        {
          value: 'SKU_FORCED_HOLD',
          text: 'SKU_FORCED_HOLD'
        },
        {
          value: 'NO_ORDER_ITEMS',
          text: 'NO_ORDER_ITEMS'
        },
        {
          value: 'FULFILLED',
          text: 'FULFILLED'
        },
        {
          value: 'ORDER_MIRRORED',
          text: 'ORDER_MIRRORED'
        },
        {
          value: 'ORDER_MIRRORED_CLOSED',
          text: 'ORDER_MIRRORED_CLOSED'
        },
      ];
    }
  },

  actions: {
    ...genedStore.actions
  },
};
