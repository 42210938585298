//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  name: '',
  items: [],
  warehouseId: '',
  brandId: '',
  quantity: 1,
  otherFees: 0,
  quantityToOrder: 1,
  percentMarkup: 10,
  warehouseProductId: '',
  unitWeightMetric: 1,
  wastePerItemPercent: 8,
  moqWarningMultiple: 3,
  lastPurchasedWeeksWarning: 26,
  planningLeadTimeDaysWarning: 35
};

let defaultFilters = {
  warehouseId: '',
  brandId: '',
  name: '',
  sku: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};
