<template>
  <div>
    <b-row>
      <div class="col-12 col-sm-9">
        <div>
          <b-card class="mb-3" no-body>
            <template v-slot:header>
              <b v-if="itemForm.type == 0">
                Purchase Order Request
              </b>
              <b v-else-if="itemForm.type == 1">
                Warehouse Transfer Request
              </b>
            </template>
            <div class="card-body">
              <div>
                <div class="form-row">

                  <div class="col-6">
                    <b-form-group label="Client:">
                      <b-v-select
                        v-model="itemForm.brandId"
                        :searchable="true"
                        :disabled="isBeingEdited"
                        :options="brandsList"
                      />
                    </b-form-group>
                  </div>

                  <div class="col-6">
                    <b-form-group label="Expected Date">
                      <b-input-group>
                        <b-form-input
                          :disabled="hasBeenShipped"
                          v-validate="{ required: true }"
                          id="form-input-expectedDate"
                          name="form-input-expectedDate"
                          v-model="itemForm.expectedDate"
                          type="date"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            :reset-button="true"
                            :disabled="hasBeenShipped"
                            v-model="itemForm.expectedDate"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="form-input-expectedDate"
                            @context="onContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-invalid-feedback
                        id="form-input-expectedDate-feedback"
                      >
                        {{ veeErrors.first('form-input-expectedDate') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-6">
                    <b-form-group label="Vendor:">
                      <b-v-select
                        v-validate="{ required: true }"
                        v-model="itemForm.vendorId"
                        :searchable="true"
                        :disabled="(isBeingEdited && itemForm.vendorId)"
                        :options="vendorsList"
                      />
                    </b-form-group>
                  </div>
                  <!-- <div class="col-6">
                      <b-form-group label="Vendor:">
                        <b-v-select
                          v-model="itemForm.vendorId"
                          :searchable="true"
                          :disabled="hasBeenShipped"
                          :options="vendorsList"
                        />
                      </b-form-group>
                    </div> -->

                  <div class="col-6">
                    <b-form-group label="PO Number">
                      <b-input-group>
                        <b-form-input
                          id="orderNumber"
                          v-model="itemForm.orderNumber"
                          name="orderNumber"
                          maxlength="38"
                          placeholder="Fill out field above to autogenerate this"
                          type="text"
                          :disabled="hasBeenShipped"
                        />
                        <b-input-group-append v-if="!hasBeenShipped">
                          <b-button
                            text="Button"
                            variant="secondary"
                            @click="autoFillOrderNumber()"
                          >
                            <i class="fa fa-bolt" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-12">
                    <b-form-group label="External Ref Number" description="Optional, can be Ref for PO in External system like MS Dynamics GP or other WMS/ERP systems.">
                      <b-input-group>
                        <b-form-input
                          id="externalRefNum"
                          v-model="itemForm.externalRefNum"
                          name="externalRefNum"
                          maxlength="38"
                          type="text"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <div v-if="itemForm.type == 0 && !has('pm.is_end_partner') && !this.has('pm.is_vendor_partner')" class="col-3">
                    <div class="form-group">
                      <label for="shippingFee">Shipping Fee</label>
                      <money
                        class="form-control"
                        v-model="itemForm.shippingFee"
                        :disabled="false"
                        placeholder="0.00"
                        min="0"
                        v-bind="{ prefix: '$ ' }"
                      />
                    </div>
                  </div>
                  <div v-if="itemForm.type == 0 && !has('pm.is_end_partner') && !has('pm.is_vendor_partner')" class="col-3">
                    <div class="form-group">
                      <label for="otherFees">Other Fees</label>

                      <money
                        class="form-control"
                        v-model="itemForm.otherFees"
                        :disabled="false"
                        placeholder="0.00"
                        min="0"
                        v-bind="{ prefix: '$ ' }"
                      />
                    </div>
                  </div>
                  <div v-if="!itemForm.id" class="col-12">
                    <b-form-group>
                      <label for="userNote">Note</label>
                      <b-input-group>
                        <b-form-input
                          id="userNote"
                          v-model="itemForm.userNote"
                          name="userNote"
                          placeholder="Leave a Note (optional)"
                          type="text"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <!-- <div v-if="itemForm.type == 0" class="form-check">
                  <label for="isPaid" class="form-check-label"
                    ><input
                      id="isPaid"
                      v-model="itemForm.isPaid"
                      class="form-check-input moveFocus"
                      name="isPaid"
                      type="checkbox"
                      :disabled="isBeingEdited"
                    />
                    Is Paid</label
                  >
                </div> -->
              </div>
            </div>
          </b-card>

          <div v-if="loadingWareItems" class="alert alert-info">
            <h5>Loading Items...</h5>
          </div>
          <div
            v-else-if="!productsList.length && itemForm.brandId"
            class="alert alert-warning"
          >
            <h5>No Items to Choose From!</h5>
          </div>
          <div
            v-else-if="!productsList.length && !itemForm.brandId"
            class="alert alert-warning"
          >
            <h5>
              You need to select a brand before picking items!
            </h5>
          </div>
          <b-card v-else-if="productsList.length && itemForm.brandId" no-body>
            <template v-slot:header>
              <b>
                Requested Items
              </b>
            </template>
            <div class="formitems-table">
              <b-table
                :striped="true"
                :bordered="true"
                :outlined="true"
                :small="false"
                :hover="false"
                :dark="false"
                :items="itemForm.items"
                head-variant="dark"
                :fields="fields"
              >
                <template v-slot:cell(purchaseOrderItemId)="data">
                  <div class="border-bottom">
                    {{
                      data.item.warehouseProductItem
                    }}
                  </div>
                  <div>
                    {{
                      data.item.warehouseProductDescription
                    }}
                  </div>
                </template>
                <template v-slot:cell(quantity)="data">
                  <span v-if="hasBeenShipped">
                    {{ parseFloat(data.item.quantity) }}
                  </span>
                  <div v-else>
                    <money
                      class="form-control"
                      v-model="data.item.quantity"
                      min="1"
                    />
                  </div>
                </template>
                <template v-slot:cell(quantityTotalShipped)="data">
                  <span>{{ parseFloat(data.item.quantityTotalShipped) }}</span>
                </template>
                <template v-slot:cell(quantityTotalReceived)="data">
                  <span>{{ parseFloat(data.item.quantityTotalReceived) }}</span>
                </template>
                <template v-slot:cell(itemCost)="data">
                  <span>$ {{ parseFloat(data.item.itemCost) }}</span>
                </template>
                <template v-slot:cell(costTotal)="data">
                  <span
                    >$
                    {{
                      parseFloat(data.item.quantity) *
                        parseFloat(data.item.itemCost)
                    }}</span
                  >
                </template>
                <template v-slot:cell(id)="data">
                  <span
                    v-if="!hasBeenShipped"
                    class="btn btn-danger"
                    @click="RemovePOItem(data.item, data.index)"
                  >
                    Remove
                  </span>
                </template>
              </b-table>
            </div>

            <template
              v-slot:footer
              v-if="productsList.length && !hasBeenShipped"
            >
              <div class="form-row">
                <div
                  :class="{
                    'col-md-6': itemForm.type == 1,
                    'col-md-7': itemForm.type == 0
                  }"
                >
                  <div class="form-group">
                    <label for="pickWmsProduct">Pick Product:</label
                    ><b-v-select
                      :showup="true"
                      id="pickWmsProduct"
                      v-model="itemChange.warehouseProductItem"
                      :searchable="true"
                      name="pickWmsProduct"
                      :options="productsList"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="newProdQuantity">Quantity</label>
                    <money
                      class="form-control"
                      v-model="itemChange.quantity"
                      min="0"
                    />
                  </div>
                </div>
                <div v-if="itemForm.type == 0 && !has('pm.is_end_partner') && !has('pm.is_vendor_partner')" class="col-md-1">
                  <div class="form-group">
                    <label for="newItemCost">Price:</label>
                    <money
                      class="form-control"
                      v-model="itemChange.itemCost"
                      min="0"
                      v-bind="{ prefix: '$ ' }"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label style="opacity:0">.</label>
                    <div>
                      <b-button
                        type="button"
                        block
                        variant="primary"
                        class="moveFocus"
                        @click="AddRequestItem"
                      >
                        Request
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </div>
      </div>

      <aside class="col-12 col-sm-3">
        <b-card header="Actions">
          <b-button
            v-if="!hasBeenShipped"
            :disabled="savingItem"
            variant="primary"
            block
            type="submit"
            @click="SaveForm"
          >
            <span v-if="savingItem">Saving...</span>
            <span v-else>Save Order</span>
          </b-button>
          <router-link
            :disabled="savingItem"
            v-if="itemForm.id"
            class="mt-2 btn btn-block btn-outline-danger"
            :to="{
              name: 'Warehouses.PurchaseOrders.Index'
            }"
          >
            <span v-if="hasBeenShipped">Back To List</span>
            <span v-else>Cancel</span>
          </router-link>
        </b-card>
      </aside>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'Warehouses.PurchaseOrders.View',
  mixins: [crudMixinGen('purchaseOrders2'), myCommonDataMixin],
  props: {
    id: {
      type: String,
      default: '',
      required: false
    },
    redirToName: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    //type: 0,
    isBeingEdited: false,
    wareItemsList: [],
    itemChange: {
      warehouseProductItem: '',
      itemCost: 0,
      quantity: 1,
      totalCost: 0
    },
    tempSug: '',
    readyToLoad: false,
    loadingWareItems: false,
    fieldsAll: [
      { key: 'warehouseProductItem', label: 'Item', sortable: false },
      { key: 'quantity', label: 'Requested Qty', sortable: false },
      { key: 'itemCost', label: 'Unit Price', sortable: false },
      { key: 'costTotal', label: 'Total', sortable: false },
      // { key: "quantityShipped", label: "Qty Shipped", sortable: false },
      // { key: "quantityReceived", label: "Qty Received", sortable: false },
      // { key: "quantityDamaged", label: "Qty Damaged", sortable: false },
      { key: 'id', label: 'Action', sortable: false }
    ]
  }),

  computed: {
    ...mapGetters('account', ['getUserData', 'isLoggedIn']),
    hasBeenShipped: {
      get() {
        let isTrue = false;
        // if (this.itemForm.orderStatus != 0) {
        //   isTrue = true;
        // }
        return isTrue;
      },
      set(val) {}
    },
    hasBeenCompleted: {
      get() {
        let isTrue = false;
        if (this.itemForm.orderStatus == 3 || this.itemForm.orderStatus == 4) {
          isTrue = true;
        }
        return isTrue;
      },
      set(val) {}
    },
    fields: {
      get() {
        let list = JSON.parse(JSON.stringify(this.fieldsAll));
        list = list.filter(s => s.key != 'purchaseOrderItemId2');
        if (!this.isBeingEdited) {
          list = list.filter(s => s.key != 'quantityShipped');
        }
        if (!this.hasBeenCompleted) {
          list = list.filter(s => s.key != 'quantityReceived');
          list = list.filter(s => s.key != 'quantityDamaged');
        }
        if (this.itemForm.type == 1) {
          list = list.filter(s => s.key != 'itemCost');
          list = list.filter(s => s.key != 'costTotal');
        }
        if (this.hasBeenShipped) {
          list = list.filter(s => s.key != 'id');
          list.push({
            key: 'quantityTotalShipped',
            label: 'Total Shipped',
            sortable: false
          });
          list.push({
            key: 'quantityTotalReceived',
            label: 'Total Received',
            sortable: false
          });
        }
        return list;
      },
      set(val) {}
    },

    productsList: {
      get() {
        let arrs = [];
        this.wareItemsList.forEach(itm => {
          arrs.push({
            value: itm.item,
            itemCost: itm.itemCost,
            description: itm.description,
            text: itm.item + ' (' + itm.description + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    }
  },

  watch: {
    'itemChange.warehouseProductId': function(newVal, oldVal) {
      let itmVal = 0;
      if (newVal) {
        let itm = this.productsList.find(a => a.value == newVal);
            console.log('itemChange.warehouseProductId', newVal, itm)
        if (itm) {
          itmVal = itm.quantityAvailable;
          if(itm.itemCost) {
            console.log('itm.itemCost', itm.itemCost)
            this.itemChange.itemCost = itm.itemCost;
          }
        }
      }
      this.itemChange.quantityAvailable = itmVal;
    },
    'itemForm.brandId': function(newVal, oldVal) {
        this.LoadWareProductsFor(newVal);
      this.autoFillOrderNumberIfEmpty();
    },
    'itemForm.expectedDate': function(newVal, oldVal) {
      this.autoFillOrderNumberIfEmpty();
    }
  },

  mounted() {
    if (this.id) {
      this.LoadItem(this.id);
    } {
      this.ResetForm();
      this.readyToLoad = true;
      this.itemForm.byUserId = this.getUserData.id;
    }
  },

  methods: {
    onContext(ctx) {
      this.itemForm.expectedDate = ctx.selectedYMD;
    },
    autoFillOrderNumberIfEmpty() {
      if (this.tempSug == this.itemForm.orderNumber) {
        this.autoFillOrderNumber();
      }
    },
    autoFillOrderNumber() {
      let posOrdNumbr = '';
      let brand = this.myBrands.find(a => a.id == this.itemForm.brandId);
      if (brand) {
        posOrdNumbr = brand.code;
        let toWare = this.myWarehouses.find(
          a => a.id == this.itemForm.toWarehouseId
        );
        if (toWare) {
          if (toWare.code) {
            posOrdNumbr += '-' + toWare.code;
          }
        }
        if (this.itemForm.expectedDate) {
          posOrdNumbr += '-' + this.formatDateAsStamp(this.itemForm.expectedDate);
        }
      }
      this.tempSug = posOrdNumbr;
      this.itemForm.orderNumber = posOrdNumbr;
    },

    formatDateAsStamp(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate() + 1),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let yearStr = (year + '').replace('20', '');
      return [month, day, yearStr].join('');
    },

    RemovePOItem(item, index) {
      if (item.id) {
        this.$http
          .delete('/api/common/purchaseOrders/removeItem/' + item.id)
          .then(resp => {
            this.itemForm.items = this.itemForm.items.filter(
              s => s.warehouseProductItem != item.warehouseProductItem
            );
          })
          .catch(err => {
          });
      } else {
        this.itemForm.items = this.itemForm.items.filter((s, k)=> k != index);
      }
    },
    AddRequestItem() {
      this.itemChange.itemCost = parseFloat(this.itemChange.itemCost);
      let wareProd = this.productsList.find(a => a.value == this.itemChange.warehouseProductItem);
      if(wareProd) {
        this.itemForm.items = this.itemForm.items.filter(
          s => s.warehouseProductItem != this.itemChange.warehouseProductItem
        );
        this.itemForm.items.push({
            warehouseProductItem: this.itemChange.warehouseProductItem,
            warehouseProductDescription: wareProd.description,
            itemCost: this.itemChange.itemCost,
            quantity: this.itemChange.quantity,
            totalCost: parseFloat(this.itemChange.quantity) * parseFloat(this.itemChange.itemCost),
        });
      }
      this.itemChange.warehouseProductId = null;
      this.itemChange.quantity = 0;
      this.itemChange.itemCost = 0;
    },

    ResetForm(wmsId) {
      this.itemChange = {
        warehouseProductItem: '',
        warehouseProductDescription: '',
        itemCost: 0,
        quantity: 1,
        totalCost: 0
      };
      this.itemForm = {
        type: 0,
        projectId: 't',
        orderNumber: '',
        expectedDate: '',
        shippingFee: 0,
        otherFees: 0,
        items: []
      };
    },

    LoadWareProductsFor(brandId) {
      if (!this.readyToLoad) {
        return;
      }
      if (this.loadingWareItems) {
        return;
      }
      this.wareItemsList = [];
      if (brandId) {
        this.loadingWareItems = true;
        this.$http
          .post('/api/common/purchaseOrders/pickItems/' + brandId)
          .then(resp => {
            this.wareItemsList = resp.data;
            this.loadingWareItems = false;
            if(!this.itemForm.id)
              this.itemForm.items = [];
          })
          .catch(err => {
            console.error('get purchaseOrders/pickItems err', err);
            this.wareItemsList = [];
            this.loadingWareItems = false;
          });
      }
    },

    LoadItem(id) {
      this.$http
        .get('/api/common/purchaseOrders/' + id)
        .then(resp => {
          this.readyToLoad = true;
          let item = resp.data;
          this.itemForm = item;
          this.isBeingEdited = true;
        })
        .catch(err => {
          console.error('get purchaseOrder err', err);
        });
    },

    SaveForm() {
      this.itemForm.shippings = null;
      this.itemForm.orderNumber = this.itemForm.orderNumber.replace(/[\W_]+/g, '-');
      if (this.itemForm.items.length < 1) {
        this.$swal(
          'Error!',
          'You need pick at least one warehouse item!',
          'error'
        );
        return;
      }
      if (
        this.itemForm.items.length >= 1 &&
        this.itemForm.orderNumber &&
        // this.itemForm.toWarehouseId &&
        // this.itemForm.toWarehouseId != this.itemForm.fromWarehouseId &&
        this.itemForm.brandId &&
        this.itemForm.expectedDate
      ) {
        if (this.itemForm.type == 1) {
          if (!this.itemForm.fromWarehouseId) {
            this.$swal(
              'Error!',
              'You need to pick the from warehouse!',
              'error'
            );
            return;
          }
        }
        this.itemForm.shippingFee = parseFloat(this.itemForm.shippingFee);
        this.itemForm.otherFees = parseFloat(this.itemForm.otherFees);
        this.itemForm.items.forEach(it => {
          it.purchaseOrderId = 'tmp';
          it.wmsProduct = null;
        });
        this.savingItem = true;
        this.$http
          .post('/api/common/purchaseorders' + (this.itemForm.id ? '/edit' : ''), this.itemForm)
          .then(resp => {
            if (!this.redirToName) {
              this.$router.push({ name: 'Warehouses.PurchaseOrders.Index', params: {id: resp.data.id} });
            } else {
              this.$router.push({ name: this.redirToName });
            }
            console.info(
              'router tried to do push to ',
              '/po/' + (this.itemForm.type == 0 ? '1' : '0')
            );
            this.ResetForm();
            this.savingItem = false;
            this.$swal('Success!', 'Item was Saved!', 'success');
          })
          .catch(err => {
            this.savingItem = false;
            console.error('saved po err', err, err.response);
            this.$swal('Error!', err.response.data.message, 'error');
          });
      } else {
        this.savingItem = false;
        this.$swal('Error!', 'Fill out all fields!', 'error');
      }
    }
  }
};
</script>
