//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  shipmentAddress: {
    id: '',
    name: '',
    countryCode: 'US',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    state: '',
    postcode: '',
    email: '',
    city: ''
  },
  orderType: 'BulkShipment',
  orderStatus: 'Open',
  items: [],
  brandId: '',
  warehouseId: null,
  orderNumber: '-',
  shipmentAddressId: '-'
};

let defaultFilters = {
  excludeTypes: [],
  warehouseId: '',
  brandId: '',
  addr: '',
  orderNumber: '',
  orderStatus: '',
  orderType: 'BulkShipment'
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ManualShipmentModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state,
    orderTypeList: [
      { value: 'Ecom', text: 'Ecom' },
      { value: 'BulkShipment', text: 'Bulk Shipment' },
      { value: 'Custom', text: 'Custom' },
      { value: 'Mass', text: 'Mass' },
      { value: 'Wholesale', text: 'Wholesale' },
      //{ value: 'TeamGift', text: 'TeamGift' },
    ],
    orderStatusList: [
      { value: 'Draft', text: 'Draft' },
      { value: 'Open', text: 'Open' },
      { value: 'AwaitingShipment', text: 'AwaitingShipment' },
      { value: 'Shipped', text: 'Shipped' },
      { value: 'Cancelled', text: 'Cancelled' },
      //{ value: 'Received', text: 'Received' }
    ]
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

ManualShipmentModule.getters.getOrderTypeList = state => {
  return state.orderTypeList;
};
ManualShipmentModule.getters.getOrderStatusList = state => {
  return state.orderStatusList;
};

ManualShipmentModule.getters.getOrderTypeFilter = state => {
  let filters = [
    {
      value: '',
      text: 'All'
    }
  ];
  filters = filters.concat(state.orderTypeList);
  return filters;
};
ManualShipmentModule.getters.getOrderStatusFilter = state => {
  let filters = [
    {
      value: '',
      text: 'All'
    }
  ];
  filters = filters.concat(state.orderStatusList);
  return filters;
};
export default ManualShipmentModule;
