import store from '@/store';

export const canNavigate = to => {
  if(to.meta == null || to.meta.permissionRequired == null) {
    return true;
  } else {
    if(store.getters['account/hasUserPermission'](to.meta.permissionRequired)) {
      return true;
    }
    else {
      return false;
    }
  }
}

  export const mustBeAuthorized = to => {
    let onlyAuth = false;
    if(to.meta && to.meta.isPublicNav) {
      onlyAuth = false;
    } else {
      onlyAuth = true;
    }
    return onlyAuth
  }

export const _ = undefined;