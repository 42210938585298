<template>
  <section>
    <div class="popups">
      <b-sidebar
        v-model="showFilters"
        right
        shadow
        backdrop
        bg-variant="white"
        no-header
      >
        <template>
          <div class="container sidebar-header">
            <b-row>
              <div class="col-5">
                <b-button
                  variant="primary"
                  @click="
                    applyFilters();
                    loadItems();
                    showFilters = false;
                  "
                  >Apply</b-button
                >
              </div>

              <div class="col-7 text-right">
                <b-button
                  type="reset"
                  variant="outline-danger"
                  @click="resetFormFilters()"
                >
                  Reset
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="
                    showFilters = false;
                    formFilters = { ...itemsFilters };
                  "
                >
                  Cancel
                </b-button>
              </div>
            </b-row>
          </div>

          <div class="px-3 py-2">
            <!-- <b-form-group label="Order Status:">
              <b-v-select
                v-model="formFilters.orderStatus"
                :searchable="true"
                :options="orderStatusFilter"
              />
            </b-form-group>
            <b-form-group label="Order Type:">
              <b-v-select
                v-model="formFilters.orderType"
                :searchable="true"
                :options="orderTypeFilter"
              />
            </b-form-group> -->
            <b-form-group label="Storefront:">
              <b-v-select
                v-model="formFilters.projectId"
                :searchable="true"
                :options="projectsList"
              />
            </b-form-group>
            <b-form-group label="Order Number:">
              <b-form-input v-model="formFilters.orderNumber" />
            </b-form-group>
            <b-form-group label="Reference Number:">
              <b-form-input v-model="formFilters.referenceNumber" />
            </b-form-group>
            <b-form-group label="Customer Name:">
              <b-form-input v-model="formFilters.name" />
            </b-form-group>

            <b-form-group
              label="From Receive Date:"
              description="From Date must be before To Date"
            >
              <b-form-datepicker
                :reset-button="true"
                v-model="formFilters.receiveDateStart"
                class="form-control flatpickr-input"
                placeholder="Select date"
                name="orderDateStart"

              />
            </b-form-group>
            <b-form-group  class="border-bottom mb-1 pb-2"  label="To Receive Date:">
              <b-form-datepicker
                :reset-button="true"
                v-model="formFilters.receiveDateEnd"
                class="form-control flatpickr-input"
                placeholder="Select date"
                name="orderDateEnd"

              />
            </b-form-group>

          </div>
        </template>
      </b-sidebar>
    </div>

    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h1 class="header-title">
              Order Returns
            </h1>

            <ActiveFiltersCard :itemsFilters="itemsFilters" :resetFilterWithKey="resetFilterWithKey" :loadItems="loadItems">
              <template v-slot:filterVal(projectId)="data">
                <span
                  v-if="myProjects && myProjects.find(a => a.id == data.item)"
                  >{{ myProjects.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
              <template v-slot:filterVal(brandId)="data">
                <span
                  v-if="myBrands && myBrands.find(a => a.id == data.item)"
                  >{{ myBrands.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
              <template v-slot:filterVal(warehouseId)="data">
                <span
                  v-if="myWarehouses && myWarehouses.find(a => a.id == data.item)"
                  >{{ myWarehouses.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
            </ActiveFiltersCard>
          </div>
          <div class="col-auto">
            <b-button variant="info" class="mr-1" @click="ExportPage">
              <i class="fa fa-cloud-download mr-2"></i>
              Export
            </b-button>
            <b-button variant="primary" @click="showFilters = !showFilters">
              <i class="fa fa-filter mr-2"></i>
              Filters
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <TableListing
      class="mb-0"
      :loading-page="loadingItems"
      :failed-loading="failedLoadItems"
      head-variant="dark"
      :items.sync="items"
      :fields.sync="fields"
      :pagination.sync="pagination"
      :options.sync="tableOptions"
      v-on:pagination-changed="loadItems"
    >

      <template v-slot:cell(projectId)="data">
        {{ GetProjectName(data.item.projectId) }}
      </template>

      <template v-slot:cell(createDateTimeUtc)="data">
        <span
          v-if="
            data.item.createDateTimeUtc &&
              !data.item.createDateTimeUtc.endsWith('Z')
          "
          >{{
            (data.item.createDateTimeUtc + 'Z') | moment('MMMM Do, h:mm a')
          }}</span
        >
        <span v-else-if="data.item.createDateTimeUtc">{{
          data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
        }}</span>
      </template>
      <template v-slot:cell(receiveDate)="data">
        <span
          v-if="
            data.item.createDateTimeUtc &&
              !data.item.createDateTimeUtc.endsWith('Z')
          "
          >{{
            (data.item.createDateTimeUtc + 'Z') | moment('MMMM Do YYYY')
          }}</span
        >
        <span v-else-if="data.item.createDateTimeUtc">{{
          data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
        }}</span>
      </template>
      <template v-slot:cell(status)="data">
        <span class="badge badge-success" v-if=" data.item.status == 'Received'">{{ data.item.status }}</span>
        <span class="badge badge-danger" v-else-if="data.item.status == 'ReceivedDamaged'">{{ data.item.status }}</span>
        <span class="badge badge-secondary" v-else>{{ data.item.status }}</span>
      </template>
      <template v-slot:cell(id)="data">
        <router-link
          class="btn btn-info ml-1"
          :to="{
            name: 'Storefronts.OrderReturns.Edit',
            params: { orderReturnId: data.item.id }
          }"
        >
          View
        </router-link>

        <b-dropdown
          v-if="!has('pm.is_end_partner')"
          :disabled="(data.item.status == 'Received' || data.item.status == 'ReceivedDamaged')"
          id="dropdown-right"
          right
          text="Change"
          variant="primary"
          boundary="viewport"
          class="m-2"
          >
          <b-dropdown-item
            class="icon-dropitem"
            @click="ReceivedItem(data.item)"
          >
            <i class="fa fa-check-circle-o mr-2 text-success" />
            <span class="text-success">Mark As Received</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item
            class="icon-dropitem"
            @click="CancelledItem(data.item)"
          >
            <i class="fa fa-times-circle-o mr-2 text-danger" />
            <span class="text-danger">Mark As Cancelled</span>
          </b-dropdown-item> -->
        </b-dropdown>

      </template>
    </TableListing>
  </section>
</template>

<script>
import TableListing from '@/views/components/TableListing';
import ActiveFiltersCard from '@/views/components/ActiveFiltersCard';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Storefronts.OrderReturns.Index',
  mixins: [crudMixinGen('returns'), myCommonDataMixin],
  components: {
    ActiveFiltersCard,
    TableListing
  },

  data: () => ({
    fields: [
      { key: 'projectId', label: 'Storefront', sortable: false },
      { key: 'orderNumber', label: 'Order Number', sortable: false },
      { key: 'referenceNum', label: 'Reference Num', sortable: false },
      { key: 'storefrontShipFromName', label: 'From', sortable: false },
      { key: 'status', label: 'Status', sortable: false },
      { key: 'createDateTimeUtc', label: 'Created At', sortable: false },
      //{ key: 'trackingNumber', label: 'Tracking #', sortable: false },
      { key: 'receiveDate', label: 'Received At', sortable: false },
      { key: 'id', label: 'Action', sortable: false }
    ]
  }),

  computed: {
    ...mapGetters('manualShipments', {
      orderTypeFilter: 'getOrderTypeFilter',
      orderStatusFilter: 'getOrderStatusFilter'
    })
  },
  mounted() {
    this.loadItems();
  },

  methods: {
    ExportPage() {
      let form= document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/order-returns');
      var params = this.itemsFilters;
      for (var key in params) {
        var hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form); // Not sure if this step is necessary
      form.submit();
    },
    GetProjectName(projId) {
      let projName = '';
      let fnd = this.myProjects.find(s => s.id == projId);
      if (fnd) {
        projName = fnd.name;
      }
      return projName;
    },
    // CancelledItem(item) {
    //   this.$http
    //     .post('/api/common/orderReturns/markAsCancelled/' + item.id)
    //     .then(resp => {
    //       this.$swal('Success!', 'Order Return cancelled!', 'success');
    //     })
    //     .catch(err => {
    //       this.$swal('Error!', 'Order Return could not be marked as cancelled!', 'error');
    //     });
    // },
    ReceivedItem(item) {
      this.$http
        .post('/api/common/orderReturns/markAsReceived/' + item.id)
        .then(resp => {
          this.$swal('Success!', 'Order Return received!', 'success');
        })
        .catch(err => {
          this.$swal('Error!', 'Order Return could not be marked as received!', 'error');
        });
    }
  }
};
</script>
