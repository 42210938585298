<template>
  <section class="changelog-page row">
    <div class="col-md-8">
      <b-row>
        <div class="col-6" v-for="kbGroup in kbGroups" :key="kbGroup.id">
          <b-card :header="kbGroup.name">
            <div class="vue-prosemirror changelogentry" style="color: #495057;" >
                <p>{{kbGroup.description}}</p>
                <div class="text-right">
                    <router-link class="btn btn-info mr-1" :to="{
                        name: 'ViewKnowledgeGroup',
                        params: { slug: kbGroup.slug }
                      }">
                      Open <i class="fa fa-angle-right"></i>
                    </router-link>
                  </div>
              </div>
          </b-card>
        </div>
      </b-row>
    </div>
  </section>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'ChangelogPublic',
  mixins: [crudMixinGen('docs/changelog'), myCommonDataMixin],
  components: {
  },

  data: () => ({
    kbGroups: []
  }),
  computed: {
  },

  mounted() {
    this.loadAllItems();
  },

  methods: {
    loadAllItems(hnl = () => {}) {
      this.$http
        .get('/api/docs/knowledgebaseGroups')
        .then(resp => {
          this.kbGroups = resp.data;
          hnl();
        })
        .catch(err => {});
    }
  }
};
</script>
