<template>
  <section class="changelog-page">
  <div class="d-flex">
    <div>
      <router-link class="btn btn-warning"
        :to="{
          name: 'DocsHome'
        }" exact>
        <i class="fa fa-angle-left"></i>
        Back
      </router-link>
    </div>
    <h3 class="pl-3">{{ itemForm.name }}</h3>
  </div>

    <div class="row pt-3">
      <div class="col-7">
        <div class="row pb-3" v-if="itemForm.subGroups">
          <div class="col-6" v-for="kbGroup in itemForm.subGroups" :key="kbGroup.id">
            <b-card :header="kbGroup.name">
              <div class="vue-prosemirror changelogentry" style="color: #495057;" >
                  <p>{{kbGroup.description}}</p>
                  <div class="text-right">
                      <router-link class="btn btn-info mr-1" :to="{
                          name: 'ViewKnowledgeGroup',
                          params: { slug: kbGroup.slug }
                        }">
                        Open <i class="fa fa-angle-right"></i>
                      </router-link>
                    </div>
                </div>
            </b-card>
          </div>
        </div>
        <div class=" article-list">
          <b-list-group>
            <b-list-group-item
              :to="{
                    name: 'ViewKnowledgeArticle',
                    params: { slug: article.slug }
                  }"
              v-for="article in groupPages" :key="article.id"><i class="fa fa-book mr-1"></i> {{ article.name }}
            <span class="pull-right"><i class="fa fa-angle-right"></i></span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'ChangelogPublic',
  mixins: [crudMixinGen('docs/knowledgebaseGroups'), myCommonDataMixin],
  props: {
    slug: {
      type: String,
      default: '',
      required: false
    }
  },
  components: {
  },
  watch: {
    'slug': function(val) {
      this.slug = val;
      this.loadGroupBySlug(this.slug)
    },
  },

  data: () => ({
    groupPages: []
  }),
  computed: {
  },

  mounted() {
    if (this.slug) {
      this.loadGroupBySlug(this.slug)
      ;
    }
  },

  methods: {
    loadGroupBySlug(slug) {
      this.$http.get('/api/docs/knowledgebaseGroups/'+slug)
        .then((resp) => {
          this.itemForm = resp.data;
          this.loadPages(this.itemForm.id);
        })
    },
    loadPages(groupId, hnl = () => {}) {
      this.$http
        .get('/api/docs/knowledgebasePages/inGroup/'+groupId)
        .then(resp => {
          this.groupPages = resp.data;
          hnl();
        })
        .catch(err => {});
    }
  }
};
</script>
