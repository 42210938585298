//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  overrideToUseManualFulfill: false,
  active: true,
  fulfillmentServiceCode: '',
  fulfillmentServiceName: '',
  fulfillmentCarrierCode: '',
  fulfillmentCarrierName: '',
  orderServiceCode: '',
  orderCarrierCode: '',
  requestedService: '',
  customFields: [],
  status: 'Active',
  fulfillmentService: 'TPL',
  projectId: ''
};

let defaultFilters = {
  status: 'all',
  fulfillmentService: '',
  fulfillmentServiceCode: '',
  fulfillmentServiceName: '',
  fulfillmentCarrierCode: '',
  fulfillmentCarrierName: '',
  orderServiceCode: '',
  orderCarrierCode: '',
  requestedService: '',

  projectId: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};
