import Vue from 'vue';
import Vuex from 'vuex';

// Modules

import { AutomationModule, ContentTemplatesModule } from '@/shared';

import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import account from './account';

import GlobalModule from './modules/global';
import ModuleWarehouseItems from './modules/warehouse-items';
import ModuleWarehouseProductLocations from './modules/warehouseProductLocations';
import ModuleOrders from './modules/orders';
import ModuleReturns from './modules/returns';

import ModuleASNs from './modules/warehouseShipments';
import ModulePurchaseOrders from './modules/purchaseOrders';
import ModuleWarehouseStoreTransfers from './modules/warehouseTrans';
import ModuleManualShipments from './modules/manual-shipments';
import ModuleBulkShipments from './modules/bulk-shipments';
import ReportWmsItems from './modules/report-wms-items';
import ReportProductItems from './modules/report-product-items';
import CustomerInvoicesModule from './modules/customer-invoices';
import RndProjectsModule from './modules/rndProjects';

import {DocsKnowledgebasePagesModule} from '@/shared.js';
import {DocsKnowledgebaseGroupsModule} from '@/shared.js';
import {DocsChangelogModule} from '@/shared.js';

import WebhooksLogModule from './modules/webhooks-log';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    g: GlobalModule,
    app,
    appConfig,
    'account': account,
    verticalMenu,
    orders: ModuleOrders,
    bulkShipments: ModuleBulkShipments,
    warehouseShipments: ModuleASNs,
    returns: ModuleReturns,
    rndProjects: RndProjectsModule,

    'docs/knowledgebasePages': DocsKnowledgebasePagesModule,
    'docs/knowledgebaseGroups': DocsKnowledgebaseGroupsModule,
    'docs/changelog': DocsChangelogModule,

    'customerInvoices': CustomerInvoicesModule,
    'report-wms-items': ReportWmsItems,
    'report-product-items': ReportProductItems,
    'manualShipments': ModuleManualShipments,
    'purchaseOrders2': ModulePurchaseOrders,
    'warehouseTransfers': ModuleWarehouseStoreTransfers,
    'webhooks-log': WebhooksLogModule,
    'warehouseProducts': ModuleWarehouseItems,
    'warehouseProductLocations': ModuleWarehouseProductLocations,

    automation: AutomationModule,
    contentTemplates: ContentTemplatesModule,
    'app-ecommerce': {}
  },
  strict: process.env.DEV
});
