<template>
  <section>
    <div class="popups">
      <!-- The modal -->

      <b-sidebar
        v-model="showFilters"
        right
        shadow
        backdrop
        bg-variant="white"
        no-header
      >
        <template>
          <div class="container sidebar-header">
            <b-row>
              <div class="col">
                <b-button
                  variant="primary"
                  @click="
                    applyFilters();
                    loadItems();
                    showFilters = false;
                  "
                  >Apply</b-button
                >
              </div>

              <div class="col-auto text-right">
                <b-button
                  type="reset"
                  variant="outline-danger"
                  @click="resetFormFilters()"
                >
                  Reset
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="
                    showFilters = false;
                    formFilters = { ...itemsFilters };
                  "
                >
                  Cancel
                </b-button>
              </div>
            </b-row>
          </div>

          <div class="px-3 py-2">
            <b-form-group label="Client:">
              <b-v-select
                v-model="formFilters.brandId"
                :searchable="true"
                :options="brandsFilter"
              />
            </b-form-group>
            <b-form-group label="Warehouse:">
              <b-v-select
                v-model="formFilters.warehouseId"
                :searchable="true"
                :options="warehousesFilter"
              />
            </b-form-group>
            <b-form-group label="Location:">
              <b-form-input v-model="formFilters.location" />
            </b-form-group>
            <b-form-group label="Pallet #:">
              <b-form-input v-model="formFilters.lpn" />
            </b-form-group>
            <b-form-group label="Lot Number:">
              <b-form-input v-model="formFilters.lotNumber" />
            </b-form-group>
            <b-form-group label="Item:">
              <b-form-input v-model="formFilters.item" />
            </b-form-group>
            <b-form-group label="Description:">
              <b-form-input v-model="formFilters.description" />
            </b-form-group>
            <b-form-group
              label="From Expiration Date:"
              description="From Date must be before To Date"
            >
              <b-form-datepicker
                :reset-button="true"
                v-model="formFilters.expirationDateStart"
                class="form-control flatpickr-input"
                placeholder="Select date"
                name="expirationDateStart"
                required
              />
            </b-form-group>
            <b-form-group  class="mb-1 pb-2"  label="To Expiration Date:">
              <b-form-datepicker
                :reset-button="true"
                v-model="formFilters.expirationDateEnd"
                class="form-control flatpickr-input"
                placeholder="Select date"
                name="expirationDateEnd"
                required
              />
            </b-form-group>
          </div>
        </template>
      </b-sidebar>
    </div>
    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h1 class="header-title">
              Warehouse Inventory By Location
            </h1>

            <ActiveFiltersCard :itemsFilters="itemsFilters" :resetFilterWithKey="resetFilterWithKey" :loadItems="loadItems">
              <template v-slot:filterWrap(orderType)>
                <span> </span>
              </template>
              <template v-slot:filterVal(projectId)="data">
                <span
                  v-if="myProjects && myProjects.find(a => a.id == data.item)"
                  >{{ myProjects.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
              <template v-slot:filterVal(brandId)="data">
                <span
                  v-if="myBrands && myBrands.find(a => a.id == data.item)"
                  >{{ myBrands.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
              <template v-slot:filterVal(warehouseId)="data">
                <span
                  v-if="myWarehouses && myWarehouses.find(a => a.id == data.item)"
                  >{{ myWarehouses.find(a => a.id == data.item).name }}</span
                >
                <span v-else>-</span>
              </template>
            </ActiveFiltersCard>
          </div>
          <div class="col-auto">
            <!-- <router-link
              :to="{ name: 'Warehouses.BulkShipments.Create' }"
              class="btn btn-success mr-2"
            >
              <i class="mr-2 fa fa-plus" />
              Add New
            </router-link> -->
            <b-button class="mr-2" variant="info" @click="ExportPage" :disabled="downloadPending">
              <b-spinner small v-if="downloadPending" class="mr-1"></b-spinner>
              <i class="fa fa-cloud-download mr-2"></i>
              Export
            </b-button>
            <b-button variant="primary" @click="showFilters = !showFilters">
              <i class="fa fa-filter mr-2"></i>
              Filters
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <TableListing
      class="mb-0"
      :fixed="false"
      :loading-page="loadingItems"
      :failed-loading="failedLoadItems"
      head-variant="dark"
      :items.sync="itemsColored"
      :fields.sync="fields"
      :pagination.sync="pagination"
      :options.sync="tableOptions"
      v-on:pagination-changed="loadItems"
    >

      <template v-slot:cell(createDateTimeUtc)="data">
        <span
          v-if="
            data.item.createDateTimeUtc &&
              !data.item.createDateTimeUtc.endsWith('Z')
          "
          >{{
            (data.item.createDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')
          }}</span
        >
        <span v-else-if="data.item.createDateTimeUtc">{{
          data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
        }}</span>
      </template>
      <template v-slot:cell(expirationDate)="data">
        <span
          v-if="
            data.item.expirationDate &&
              !data.item.expirationDate.endsWith('Z')
          "
          >{{
            (data.item.expirationDate + 'Z') | moment('MMMM Do YYYY')
          }}</span
        >
        <span v-else-if="data.item.expirationDate">{{
          data.item.expirationDate | moment('MMMM Do YYYY')
        }}</span>
      </template>
      <template v-slot:cell(status)="data">
        <span v-if="data.item.isQuarantined" class="badge badge-danger">Quarantined</span>
        <span v-else-if="data.item.isOnHold" class="badge badge-info">On Hold</span>
        <span v-else class="badge badge-success">Available</span>
      </template>

      <template v-slot:cell(warehouseProduct.brandId)="data">
        <span
          v-if="myBrands && myBrands.find(a => a.id == data.item.warehouseProduct.brandId)"
          >{{ myBrands.find(a => a.id == data.item.warehouseProduct.brandId).name }}</span
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(warehouseProduct.warehouseId)="data">
        <span
          v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.warehouseProduct.warehouseId)"
          >{{ myWarehouses.find(a => a.id == data.item.warehouseProduct.warehouseId).name }}</span
        >
        <span v-else>-</span>
      </template>
      <template v-slot:cell(warehouseProduct.item)="data">
        <div>
          <span>{{ data.item.warehouseProduct.item }}</span>
        </div>
        <div class="border-top">
          <span class="text-secondary">{{ data.item.warehouseProduct.description }}</span>
        </div>
      </template>
      <!-- <template v-slot:cell(id)="data">
        <button
        v-if="data.item.orderStatus == 'Draft'"
          type="button"
          class="btn btn-success ml-1"
          @click="ConfirmItemForm(data.item)"
        >
          Confirm
        </button>
        <router-link
          class="btn btn-info ml-1"
          :to="{
            name: 'Warehouses.BulkShipments.View',
            params: { id: data.item.id }
          }"
        >
          View
        </router-link>
      </template> -->
    </TableListing>
  </section>
</template>

<script>
import { TableListing, ActiveFiltersCard } from '@/views/components/custom';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'WarehouseProductLocations.Index',
  mixins: [crudMixinGen('warehouseProductLocations'), myCommonDataMixin],
  components: {
    TableListing,
    ActiveFiltersCard
  },

  data: () => ({
    downloadPending: false,
    fields: [
      { key: 'warehouseProduct.brandId', label: 'Client', sortable: true },
      { key: 'warehouseProduct.warehouseId', label: 'Warehouse', sortable: true },
      { key: 'warehouseProduct.item', label: 'Item', sortable: true },
      //{ key: 'warehouseProduct.description', label: 'Description', sortable: true },
      { key: 'lotNumber', label: 'Lot Number', sortable: true },
      { key: 'warehouseLocation.code', label: 'Pallet #', sortable: true },
      { key: 'warehouseLocation.parentLocation.code', label: 'Location', sortable: true },
      { key: 'quantity', label: 'On Hand', sortable: true },
      { key: 'quantityOnHold', label: 'On Hold', sortable: true },
      { key: 'quantityUnavailable', label: 'Unavail.', sortable: true },
      { key: 'status', label: 'Status', sortable: false },
      { key: 'expirationDate', label: 'Expires At', sortable: true },
      { key: 'referenceNum', label: 'Ship. Ref #', sortable: true },
      //{ key: 'createDateTimeUtc', label: 'Created At', sortable: false },
    ]
  }),

  computed: {
    itemsColored: {
      get() {
        let itms = this.items;
        itms.forEach((itm) => {
          if(itm.expectedDate && new Date(itm.expirationDate).setDate(new Date(itm.expirationDate).getDate() + 1) < new Date()) {
            itm['_cellVariants'] = { expirationDate: 'warning' };
          }
        });
        return itms;
      },
      set(val) {
        this.items = val;
      }
    }
  },
  mounted() {
    this.loadItems();
  },

  methods: {
    ExportPage() {
      let downloadFileUrl = process.env.VUE_APP_API_URL + '/api/common/warehouseProductLocations/download/list';
      //let formReq = JSON.parse(JSON.stringify(this.itemsFilters));
      //formReq.fields = this.tableColumns;
      let downlParams = this.itemsFilters;

      let form_data = new FormData();
      for (var key in downlParams) {
          form_data.append(key, downlParams[key]);
      }
      this.downloadPending = true;
      this.$http.post(downloadFileUrl, form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
        .then(response => {
          const href = window.URL.createObjectURL(response.data);
          let fileName = 'exported_items_inv_location.xlsx';
          const anchorElement = document.createElement('a');

          anchorElement.href = href;
          anchorElement.download = fileName;

          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
          this.downloadPending = false;
        })
        .catch(error => {
          this.downloadPending = false;
          this.$swal('Error', 'Error downloading file', 'error');
          console.log('error: ', error);
        });
    },
  }
};
</script>
