import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
let defaultFilters = {
  brandId: '',
  isDue: '',
  orderStatus: 'Non-Archived',
  itemSKU: '',
  orderNumber: '',
  type: 0
};

export default {
  namespaced: true,

  state: () => ({
    loadingItems: false,
    items: [],
    pagination: {
      ...pagination
    },
    tableOptions: {
      ...tableOptions
    },
    filters: {
      ...defaultFilters
    },
    formFilters: {
      ...defaultFilters
    }
  }),

  mutations: {
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setFormFilters(state, payload) {
      state.formFilters = { ...payload };
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    applyFilters(state) {
      state.pagination.page = 1;
      state.filters = {
        ...state.formFilters
      };
    },
    resetFilters(state) {
      state.filters = defaultFilters;
      state.formFilters = defaultFilters;
    },
    setItems(state, items) {
      state.items = items;
    }
  },

  getters: {
    getPagination: state => {
      return state.pagination;
    },
    getItems: state => {
      return state.items;
    },
    getFilters: state => {
      return state.filters;
    },
    getFormFilters: state => {
      return state.formFilters;
    }
  },
  actions: {
    fetchItems(
      { state, commit, rootState },
      { saveToStore, cancelToken } = {
        saveToStore: true,
        cancelToken: false
      }
    ) {
      return new Promise((resolve, reject) => {
        state.loadingItems = true;
        apiHelpers
          .fetchItems(state.filters, state.pagination, cancelToken)
          .then(resp => {
            state.tableOptions.totalRows = resp.data.total;
            if (saveToStore) {
              commit('setItems', resp.data.items);
              state.loadingItems = false;
              resolve(resp);
            }
          })
          .catch(err => {
            state.loadingItems = false;
            reject(err);
          });
      });
    }
  }
};
