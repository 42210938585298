<template>
  <section>
    <b-alert show variant="warning" v-if="!readyToLoad">
      <b>Loading:</b> Please wait...
    </b-alert>
    <b-row v-else>
      <div class="popups">
        <!-- The modal -->
        <section data-vv-scope="confirmSplitForm">
          <b-modal id="confirmItem" v-model="showConfirmSplitItemForm" :centered="true" size="md" title="Split Into Multiple">
            <b-form-group label="Split into" v-if="splitItemWhat">
              <b-form-input v-model="splitAddNum" min="2" type="number" />
            </b-form-group>

            <template v-slot:modal-footer>
              <b-button type="reset" variant="outline-secondary" @click="showConfirmSplitItemForm = false">
                Cancel
              </b-button>

              <b-button type="button" variant="primary" @click="SplitItemConfirm()">
                Confirm
              </b-button>
            </template>
          </b-modal>
        </section>

        <section data-vv-scope="confirmDuplicateForm">
          <b-modal id="confirmItem" v-model="showConfirmItemForm" :centered="true" size="md" title="Confirm Multiple Add">
            <b-form-group label="How many">
              <b-form-input v-model="multipleAddNum" type="number" />
            </b-form-group>

            <template v-slot:modal-footer>
              <b-button type="reset" variant="outline-secondary" @click="showConfirmItemForm = false">
                Cancel
              </b-button>

              <b-button type="button" variant="primary" @click="ConfirmMultipleAdd()">
                Confirm
              </b-button>
            </template>
          </b-modal>
        </section>
      </div>

      <div class="col-12 col-sm-9">
        <b-card class="mb-3" :header="heading" no-body v-if="poItem">
          <form
            @submit.prevent
            name="itemForm"
            id="itemForm"
            data-vv-scope="itemForm"
          >
            <div class="card-body">
              <b-row>

                <b-col cols="12" md="6" id="form-wrap-brandId">
                  <b-form-group label="Client">
                    <b-v-select
                      v-validate="{ required: true }"
                      id="form-input-brandId"
                      name="form-input-brandId"
                      :state="
                        validateStateScoped('itemForm', 'form-input-brandId')
                      "
                      v-model="itemForm.brandId"
                      :searchable="true"
                      :disabled="itemForm.id || purchaseOrderId != ''"
                      :options="brandsList"
                    />
                  </b-form-group>
                </b-col>

                <b-col class="col-6 text-right">
                  <b-form-group class="pt-3 mt-3 mb-0">
                    <b-form-checkbox
                      v-model="itemForm.needsQC"
                      name="check-button"
                      switch
                    >
                      Needs to run Quality Control before receiving.
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group label="From Warehouse">
                    <b-v-select
                      v-validate="{ required: false }"
                      name="form-input-fromWarehouseId"
                      :state="
                        validateStateScoped(
                          'itemForm',
                          'form-input-fromWarehouseId'
                        )
                      "
                      v-model="itemForm.fromWarehouseId"
                      :searchable="true"
                      :disabled="itemForm.id"
                      :options="warehouseFromList"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" id="form-wrap-toWarehouseId">
                  <b-form-group label="To Warehouse">
                    <b-v-select
                      v-validate="{ required: true }"
                      id="form-input-toWarehouseId"
                      name="form-input-toWarehouseId"
                      :state="
                        validateStateScoped(
                          'itemForm',
                          'form-input-toWarehouseId'
                        )
                      "
                      v-model="itemForm.toWarehouseId"
                      :searchable="true"
                      :disabled="itemForm.id"
                      :options="warehouseToList"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" v-if="!itemForm.fromWarehouseId || itemForm.fromWarehouseId == null">
                  <b-form-group label="Vendor">
                    <b-v-select
                      v-validate="{ required: true }"
                      name="form-input-vendorId"
                      :state="
                        validateStateScoped('itemForm', 'form-input-vendorId')
                      "
                      v-model="itemForm.vendorId"
                      :searchable="true"
                      :disabled="itemForm.vendorId && (itemForm.id || purchaseOrderId != '')"
                      :options="vendorsList"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" id="form-wrap-expectedDate">
                  <b-form-group label="Expected Arrival Date">
                      <b-input-group>
                        <b-form-input
                          :disabled="hasBeenShipped"
                          v-validate="{ required: true }"
                          id="form-input-expectedDate"
                          name="form-input-expectedDate"
                          :state="
                            validateStateScoped(
                              'itemForm',
                              'form-input-expectedDate'
                            )
                          "
                          v-model="itemForm.expectedDate"
                          type="date"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                          :reset-button="true"
                            :disabled="hasBeenShipped"
                            v-model="itemForm.expectedDate"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="form-input-expectedDate"
                            @context="onContext"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-invalid-feedback
                        id="form-input-expectedDate-feedback"
                      >
                        {{ veeErrors.first('form-input-expectedDate') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" id="form-wrap-customReferenceNumber">
                  <b-form-group label="Reference Number">
                    <b-form-input
                      maxlength="38"
                      v-validate="{ required: true }"
                      id="form-input-customReferenceNumber"
                      name="form-input-customReferenceNumber"
                      :state="
                        validateStateScoped('itemForm', 'form-input-customReferenceNumber')
                      "
                      v-model="itemForm.customReferenceNumber"
                      :disabled="itemForm.id"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Notes:">
                    <b-form-textarea
                      v-model="itemForm.notes"
                      :disabled="hasBeenShipped"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </form>
        </b-card>

        <b-card
          class="mb-3"
          id="po-requested-items-card"
          header="Requested Items by PO"
          no-body
          v-if="poItem && poItem.id && poItem.items && poItem.items.length"
        >
          <div class="card-body">
            <div class="items">
              <b-table
                head-variant="light"
                :fields="requestedItemFields"
                :items="poItem.items"
                :bordered="true"
              >
                <template v-slot:cell(warehouseProductItem)="data">
                  <span>
                    {{ data.item.warehouseProductItem }}
                  </span>
                </template>
                <template v-slot:cell(warehouseProductDescription)="data">
                  <span>
                    {{ data.item.warehouseProductDescription }}
                  </span>
                </template>
                <template v-slot:cell(quantity)="data">
                  <div
                    v-b-tooltip.hover
                    title="Initially requested quantity to ship."
                  >
                    {{ data.item.quantity }} <i class="fa fa-info-circle"></i>
                  </div>
                </template>
                <template v-slot:cell(quantityTotalShipped)="data">
                  <div v-b-tooltip.hover title="Total Units Shipped so far." class="quantityTotalShipped-poreq">
                    {{ data.item.quantityTotalShipped }}
                    <i class="fa fa-info-circle"></i>
                  </div>
                </template>
                <template v-slot:cell(quantityLeft)="data">
                  <div
                  class="quantityLeft-poreq"
                    v-if="data.item.quantityTotalShipped <= data.item.quantity"
                    v-b-tooltip.hover
                    title="How many you still need to ship."
                  >
                    {{
                      parseFloat(
                        parseFloat(data.item.quantity) -
                          parseFloat(data.item.quantityTotalShipped) -
                          countCurrentlyEntered(data.item.warehouseProductItem)
                      ).toFixed(2)
                    }}
                    (Now:
                    {{ countCurrentlyEntered(data.item.warehouseProductItem) }})
                    <i class="fa fa-info-circle"></i>
                  </div>
                  <span
                    v-else
                    v-b-tooltip.hover
                    title="How many you still need to ship."
                    >0 (Now:
                    {{ countCurrentlyEntered(data.item.warehouseProductItem) }})
                    <i class="fa fa-info-circle"></i>
                  </span>
                </template>

                <template v-slot:cell(sku)="data">
                  <span>
                    {{ data.item.sku }}
                  </span>
                </template>
                <template v-slot:cell(expiresDate)="data">
                  <span>
                    {{ data.item.expiresDate }}
                  </span>
                </template>
                <template v-slot:cell(name)="data">
                  <span>
                    {{ data.item.name }}
                  </span>
                </template>
              </b-table>
            </div>
          </div>
        </b-card>

        <b-card class="mb-3" header="Items To Ship" no-body>
          <div class="card-body">
            <div class="items formitems-table">
              <b-table
                head-variant="light"
                :fields="isWT ? shipItemsFieldsWT : (itemForm.id ? shipItemsFieldsView : shipItemsFields)"
                :items="orderedShipItems"
                :bordered="true"
              >
                <template v-slot:cell(quantityShipped)="data">
                  <span>
                    {{ data.item.quantityShipped }}
                    {{ GetItemUOM(data.item.warehouseProductId) }}
                  </span>
                </template>

                <template v-slot:cell(actions)="data">
                  <div class="d-flex">
                    <button
                      v-b-tooltip.hover
                      title="Remove Stack"
                      :disabled="data.item.id"
                      class="btn btn-danger ml-1"
                      @click="data.item.id ? null : RemoveItem(data.item, data.index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      v-b-tooltip.hover
                      title="Clone Stack (on same pallet)"
                      :disabled="data.item.id"
                      class="btn btn-info ml-1"
                      @click="DuplicateItem(data.item)"
                    >
                      <i class="fa fa-copy"></i>
                    </button>
                    <button
                      v-b-tooltip.hover
                      title="Split into Stacks (on same pallet)"
                      :disabled="data.item.id"
                      class="btn btn-secondary ml-1"
                      @click="SplitItemAsk(data.item)"
                    >
                      <i class="fa fa-usb"></i>
                    </button>
                  </div>
                </template>
                <template v-slot:cell(sku)="data">
                  <div class="border-bottom">
                    <span>{{ data.item.sku }}</span>
                  </div>
                  <div class="text-grey text-sm">
                  <h6>{{ data.item.name }}</h6>
                  </div>
                </template>

                <template v-slot:cell(palletNum)="data">
                  <div class="text-center">
                    <span class="d-inline-block" :style="'margin-bottom: -2px; margin-right: 2px; border-radius: 4px; border: 1px solid transparent; width: 15px; height: 15px; background-color:'+data.item.palletColor"></span>
                    <span>{{data.item.palletNum}}</span>
                  </div>
                </template>
                <template v-slot:cell(lpn)="data">
                  <b-form-input v-model="data.item.lpn" />
                </template>
                <template v-slot:cell(quantityShipped)="data">
                  <b-form-input v-model="data.item.quantityShipped" />
                </template>
                <template v-slot:cell(lotNumber)="data">
                  <b-form-input v-model="data.item.lotNumber" :disabled="!canItDoLotNumber(data.item.warehouseProductId)" />
                </template>
                <template v-slot:cell(expiresDate)="data">
                  <b-form-input v-model="data.item.expiresDate" :disabled="!canItDoExpirationDate(data.item.warehouseProductId)" type="date" />
                </template>
              </b-table>
            </div>
          </div>
          <template v-slot:footer>
            <b-alert v-if="!itemForm.brandId || !itemForm.toWarehouseId" show>
              <b
                >Pick the Customer and Warehouse first to get a list of possible
                warehouse items to choose from.</b
              >
            </b-alert>
            <b-alert v-else-if="!itemForm.id && loadingWareItems" variant="warning" show>
              <b>Loading Items..</b>
            </b-alert>
            <div v-else-if="!itemForm.id" class="form-row">
              <div class="col-4">
                <b-form-group label="Pick Product">
                  <b-v-select
                    :showup="true"
                    v-model="itemsShipForm.warehouseProductId"
                    :searchable="true"
                    :options="productsList"
                  />
                </b-form-group>
              </div>
              <div class="col-4" v-if="!isWT">
                <b-form-group label="Pallet LPN (Licence Plate Number)">
                  <b-input-group>
                    <b-form-input placeholder="Leave empty to autogen" v-model="itemsShipForm.lpn" />
                      <template #append>
                        <b-dropdown right  text="Existing" variant="info" :disabled="!existingPallets.length">
                          <b-dropdown-item v-on:click="updatePickedPallet(pallet.value)" v-for="(pallet, pkey) in existingPallets" :key="pkey">{{ pallet.name }}</b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6 col-md-4">
                <b-form-group label="Quantity">
                  <b-input-group>
                    <money
                      class="form-control"
                      v-model="itemsShipForm.quantityShipped"
                      min="0"
                        />
                    <template #append>
                      <b-form-select
                        v-model="itemsShipForm.measurementUnit"
                        :disabled="true"
                        :options="uomList"
                      />
                    </template>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-4" v-if="!isWT">
                <b-form-group label="Expiration Date">
                  <b-form-datepicker
                    :disabled="!pickedItemCanTrackExpiredDate"
                    v-model="itemsShipForm.expiresDate"
                    :min="new Date()"
                    locale="en"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
              <div class="col-4" v-if="!isWT">
                <b-form-group label="Lot Number">
                  <b-form-input
                    :disabled="!pickedItemCanTrackLotNumber"
                    v-model="itemsShipForm.lotNumber"
                  />
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Weight">
                  <b-input-group>
                    <money
                      class="form-control"
                      v-model="itemsShipForm.weight"
                      min="0"
                      @keyup="autocalcShipItemWeight = false"
                    />
                    <template #append>
                      <div class="btn btn-secondary active">KG</div>
                    </template>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-4">
                <b-form-group label="Landing Cost" description="Value of items (total, not for single item, including transportation and rest)">
                  <b-input-group>
                    <template #prepend>
                      <div class="btn btn-secondary active">$</div>
                    </template>
                    <money
                      class="form-control"
                      v-model="itemsShipForm.landingCost"
                      min="0"
                      :disabled="!pickedItemCanTrackLandingCost"
                      @keyup="autocalcShipItemValue = false"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label=".">
                  <b-button
                    type="button"
                    block
                    variant="primary"
                    @click="AddItem(false)"
                  >
                    Add Pallet
                  </b-button>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label=".">
                  <b-button
                    type="button"
                    block
                    variant="secondary"
                    @click="QuestionMultipleItem"
                  >
                    Add Multiple Pallets
                  </b-button>
                </b-form-group>
              </div>
            </div>
          </template>
        </b-card>
      </div>

      <aside class="col-12 col-sm-3">
        <b-card header="Actions" id="card-actions">
          <!-- <div class="w-100">
            <b-form-group label="After this do..." description="Note: Field not working, just consmetics for now.">
              <b-v-select
                :searchable="true"
                v-model="itemForm.afterAction"
                :options="afterActionsList"
              />
            </b-form-group>
        </div> -->
          <b-button
            v-if="!hasBeenShipped"
            :disabled="savingItem"
            variant="primary"
            block
            type="submit"
            @click="SaveForm"
          >
            <span v-if="savingItem">Saving...</span>
            <span v-else>Save</span>
          </b-button>
          <router-link
            :disabled="savingItem"
            class="mt-2 btn btn-block btn-outline-danger"
            :to="{ name: 'Warehouses.PurchaseOrders.Index' }"
          >
            <span v-if="hasBeenShipped">Back To List</span>
            <span v-else>Cancel</span>
          </router-link>
          <b-button
            block
            v-if="!shipmentId"
            variant="outline-info"
            :disabled="savingItem"
            @click="$tours['generalFormTour'].start()"
          >
            <span><i class="fa fa-life-ring mr-1"></i>Need Help?</span>
          </b-button>
        </b-card>
        <b-card header="Previous Form Requests" v-if="!shipmentId && previousFormRequests.length">
          <b-list-group class="manyoptions-scroll">
            <b-list-group-item @click="ContinueFormRequest(formReq.createFormRequestId)" button v-for="formReq in previousFormRequests" :key="formReq.createFormRequestId">Prefill: {{ formReq.shipmentReferenceNumber }}</b-list-group-item>
          </b-list-group>
        </b-card>
      </aside>

      <v-tour name="generalFormTour" :steps="generalFormTourSteps" :options="{ highlight: true }">
        <template slot-scope="tour">
          <div class="tour-backdrop" v-if="tour.steps[tour.currentStep]">
            <transition name="fade">
              <v-step
                v-if="tour.steps[tour.currentStep]"
                :key="tour.currentStep"
                :step="tour.steps[tour.currentStep]"
                :previous-step="tour.previousStep"
                :next-step="tour.nextStep"
                :stop="tour.stop"
                :skip="tour.skip"
                :is-first="tour.isFirst"
                :is-last="tour.isLast"
                :labels="tour.labels"
              >
                <template v-if="tour.currentStep === 2">
                  <div slot="actions">
                    <button @click="tour.previousStep" class="btn btn-primary">Back</button>
                    <button @click="tour.nextStep" class="btn btn-primary">Next!</button>
                  </div>
                </template>
              </v-step>
          </transition>
          </div>
        </template>
      </v-tour>
    </b-row>
  </section>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  mixins: [crudMixinGen('warehouseShipments'), myCommonDataMixin],
  components: {},
  props: {
    isWT: {
      type: Boolean,
      default: false,
      required: false
    },
    shipmentId: {
      type: String,
      default: '',
      required: false
    },
    recreateFromShipmentId: {
      type: String,
      default: '',
      required: false
    },
    purchaseOrderId: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    previousFormRequests: [],
    createFormRequestId: '',

    autocalcShipItemValue: true,
    autocalcShipItemWeight: true,

    allWarehousesListed: [],
    showConfirmSplitItemForm: false,
    splitItemWhat: null,
    splitAddNum: 2,
    showConfirmItemForm: false,
    isSimpleASN: false,
    shipmentType: 0,
    loadingWareItems: false,
    heading: 'Create ASN / WT',
    // subheading: 'Here you create or edit your Scheduled Products.',
    // icon: 'pe-7s-graph text-success',
    tempSug: '',
    tempSugOrderNumber: '',
    readyToLoad: false,
    poItem: false,
    lpnNum: 1,
    multipleAddNum: 2,
    shipmentAddressAll: [],
    wareItemsList: [],
    itemsShipForm: {
      expiresDate: '',
      lotNumber: '',
      sku: '',
      lpn: '',
      weight: 0,
      landingCost: 0,
      palletType: 'Pallet',
      measurementUnit: 'E',
      warehouseProductId: null,
      quantityShipped: 0
    },
    shipItemsFieldsWT: [
      { key: 'sku', label: 'Item', sortable: false },
      { key: 'quantityShipped', label: 'Quantity', sortable: false },
      { key: 'actions' }
    ],
    shipItemsFields: [
      { key: 'palletNum', label: 'PLT #', sortable: false },
      { key: 'sku', label: 'SKU', sortable: false },
      //{ key: 'name', label: 'Name', sortable: false },
      { key: 'lpn', label: 'LPN', sortable: false },
      { key: 'quantityShipped', label: 'Quantity', sortable: false },
      { key: 'lotNumber', label: 'Lot Number', sortable: false },
      { key: 'expiresDate', label: 'Exp Date', sortable: false },
      { key: 'actions' }
    ],
    shipItemsFieldsView: [
      { key: 'sku', label: 'SKU', sortable: false },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'quantityShipped', label: 'Quantity', sortable: false }
    ],

    requestedItemFields: [
      { key: 'warehouseProductItem', label: 'SKU', sortable: false },
      { key: 'warehouseProductDescription', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Qty Req', sortable: false },
      { key: 'quantityTotalShipped', label: 'Shipped', sortable: false },
      { key: 'quantityLeft', label: 'Still to Ship', sortable: false },
      { key: 'quantityTotalReceived', label: 'Received', sortable: false }
    ]
  }),

  computed: {
    ...mapStoreGetSet('warehouseShipments', {
      itemForm: ['getItemForm', 'setItemForm']
    }),

    existingPallets: {
      get() {
        let itms = JSON.parse(JSON.stringify(this.itemForm.items));
        itms.sort((a,b) => (a.lpn > b.lpn) ? 1 : ((b.lpn > a.lpn) ? -1 : 0));
        let pallets = [];
        let i = 1;
        itms.forEach((itm) => {
          if(!pallets.filter(a => a.value == itm.lpn).length) {
            pallets.push({
              name: 'PLT #' + i + ' - ' + itm.lpn,
              value: itm.lpn
            });
            i++;
          }
        })
        return pallets;
      },
      set(val) {

      }
    },

    generalFormTourSteps: {
      get() {
       let steps = [
          {
            target: '#form-wrap-brandId',
            header: {
              title: 'Get Started',
            },
            content: `Let's start creating our first ASN! First thing, you need to pick under what brand are the items you are sending.`
            + (this.purchaseOrderId ? '<hr/>Since you are creating an ASN Against a PO, the Customer is preselected for you.' : ''),
            params: {
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#form-wrap-toWarehouseId',
            content: 'Next is the warehouse. This is the location you are sending the items to.',
            params: {
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#form-wrap-expectedDate',
            content: `And now the date you expect the items to arrive at the destination.
            It is alright if you do not know the exact date it will get to the warehouse, an approximation is good enough.`,
            params: {
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#form-wrap-customReferenceNumber',  // We're using document.querySelector() under the hood
            params: {
              enableScrolling: false,
              highlight: true
            },
            content: `And lastly is the reference number. This will be used on the packing slip, the main ID by which the warehouse will identify your shipment with.`
          },
        ];
        if(this.purchaseOrderId) {
          steps.push({
            target: '#po-requested-items-card',  // We're using document.querySelector() under the hood
            params: {
              highlight: true
            },
            content: `Since this is a ASN <b>against a PO</b>, you will see a the items that were requested in the PO listed here.`
          });
          steps.push({
            target: '.quantityTotalShipped-poreq',  // We're using document.querySelector() under the hood
            params: {
              enableScrolling: false,
              highlight: true
            },
            content: `You can see how many were shipped so far, and how many you stil need to ship here. <br>The goal is to fulfill the requested quantity for each item, via one or more ASNs.`
          });
          steps.push({
            target: '.quantityLeft-poreq',  // We're using document.querySelector() under the hood
            params: {
              enableScrolling: false,
              highlight: true
            },
            content: `Once this value hits zero, that means you've shipped all the products required in the PO.`
          });
        }
        steps.push({
          target: '#card-actions',  // We're using document.querySelector() under the hood
          params: {
            highlight: true
          },
          content: `That's it! Once ready, just <u>Create</u> the ASN, and Logistics/the 4PL will be ready to receive your shipment!`
        });
        return steps;
      }
    },

    orderedShipItems: {
      get() {
        let palletNum = 0;
        let oldLPN = ''
        let itms = this.itemForm.items;
        itms.sort((a,b) => (a.lpn > b.lpn) ? 1 : ((b.lpn > a.lpn) ? -1 : 0));
        itms.forEach((it) => {
          if(oldLPN != it.lpn) {
            oldLPN = it.lpn;
            palletNum++;
            it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
            it.palletNum = palletNum;
          } else {
            it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
            it.palletNum = palletNum;
          }
        })
        return itms;
      },
      set(val) {
        this.itemForm.items = val;
      }
    },

    pickedItemShouldTrackLandingCost: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackCost == 2
            ) {
              isTrue = true;
            }
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
    pickedItemCanTrackLandingCost: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackCost
            ) {
              isTrue = true;
            }
          } else {
              isTrue = true;
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
    pickedItemShouldTrackExpiredDate: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackExpirationDate == 2
            ) {
              isTrue = true;
            }
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
    pickedItemCanTrackExpiredDate: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackExpirationDate
            ) {
              isTrue = true;
            }
          } else {
              isTrue = true;
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },

    pickedItemShouldTrackLotNumber: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackLotNumber == 2
            ) {
              isTrue = true;
            }
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
    pickedItemCanTrackLotNumber: {
      get() {
        let isTrue = false;
        if (this.itemsShipForm.warehouseProductId) {
          let findProd = this.wareItemsList.find(
            a => a.id == this.itemsShipForm.warehouseProductId
          );
          if (findProd && findProd.tplWarehouseItem) {
            if (
              findProd.tplWarehouseItem.options &&
              findProd.tplWarehouseItem.options.trackBys.trackLotNumber
            ) {
              isTrue = true;
            }
          } else {
              isTrue = true;
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
    allWarehouses: {
      get() {
        if (this.has('pm.is_end_partner') || this.has('pm.is_warehouse_partner'))  {
          return this.myWarehouses
        }
        return this.allWarehousesListed;
      },
      set(old, val) {}
    },
    hasBeenShipped: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          //isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    },
    uomList: {
      get() {
        let arrs = [
          {
            value: 'E',
            text: 'Each'
          },
          {
            value: 'BX',
            text: 'Box'
          },
          {
            value: 'FT',
            text: 'Feet'
          },
          {
            value: 'IN',
            text: 'Inches'
          },
          {
            value: 'OZ',
            text: 'Ounces'
          },
          {
            value: 'LB',
            text: 'Pounds'
          },
          {
            value: 'KG',
            text: 'Kilograms'
          },
          {
            value: 'P',
            text: 'Pallet'
          }
        ];
        return arrs;
      },
      set(old, val) {}
    },
    // afterActionsList: {
    //   get() {
    //     let arrs = [];
    //     arrs.push({
    //       value: '',
    //       text: '- Do Nothing - '
    //     });
    //     arrs.push({
    //       value: 'createPO',
    //       text: 'Create Purchase Order'
    //     });
    //     // arrs.push({
    //     //   value: 'createPOandShip',
    //     //   text: 'Create Purchase Order & Shipment'
    //     // });
    //     arrs.push({
    //       value: 'createWT',
    //       text: 'Create Warehouse Transfer'
    //     });
    //     // arrs.push({
    //     //   value: 'createWTandShip',
    //     //   text: 'Create Warehouse Transfer & Shipment'
    //     // });
    //     return arrs;
    //   },
    //   set(old, val) {}
    // },
    // palletTypeList: {
    //   get() {
    //     let arrs = [];
    //     arrs.push({
    //       value: '',
    //       text: '- Select Pallet Type - '
    //     });
    //     arrs.push({
    //       value: 'Pallet',
    //       text: 'Pallet'
    //     });
    //     return arrs;
    //   },
    //   set(old, val) {}
    // },
    productsList: {
      get() {
        let arrs = [];
        let filteredProds = this.wareItemsList;
        if (this.poItem && this.poItem.id && this.poItem.items && this.poItem.items.length) {
          filteredProds = filteredProds.filter(itm => {
            let isTrue = false;
            this.poItem.items.forEach(poItm => {
              if (itm.item == poItm.warehouseProductItem) {
                isTrue = true;
              }
            });
            return isTrue;
          });
        }
        // if(this.isWT) {
        //   filteredProds.forEach(itm => {
        //     arrs.push({
        //       value: itm.id,
        //       text:
        //         itm.item +
        //         ' (' +
        //         itm.description +
        //         ')' +
        //         ' [Available: ' +
        //         itm.quantityAvailable +
        //         ']'
        //     });
        //   });
        // } else {
          filteredProds.forEach(itm => {
            arrs.push({
              value: itm.id,
              text:
                itm.item +
                ' (' +
                itm.description +
                ')' + (
                  this.isWT ? (
                 ' [Available: ' +
                 itm.quantityAvail +
                 ']') : ''
                )
            });
          });
          //}
        return arrs;
      },
      set(old, val) {}
    },

    warehouseToList: {
      get() {
        let arrs = [];
        this.allWarehouses
          //.filter(a => a.wmsType != 4) // for now, only manual warehouses
          .filter(a => a.id != this.itemForm.fromWarehouseId)
          .forEach(itm => {
            arrs.push({ value: itm.id, text: itm.fullName });
          });
        return arrs;
      },
      set(old, val) {}
    },

    warehouseFromList: {
      get() {
        let arrs = [{ value: '', text: 'Externally Sourced' }];
        let myWares = this.myWarehouses.filter(a => a.id);
        myWares
          .filter(a => a.id != this.itemForm.toWarehouseId)
          .forEach(itm => {
            arrs.push({ value: itm.id, text: itm.fullName });
          });
        return arrs;
      },
      set(old, val) {}
    }
  },

  watch: {
    'itemForm.shipmentAddressId': function(newVal) {
      let foundShipTo = this.shipmentAddressAll.find(a => a.id == newVal);
      if (foundShipTo) {
        this.itemForm.shipmentAddress = foundShipTo;
      } else {
        this.itemForm.shipmentAddress = {
          id: '',
          name: '',
          addressLine1: ''
        };
      }
      this.SetShipmentItemDefaults();
    },
    'itemsShipForm.warehouseProductId': function(newVal) {
      let foundPickedProd = this.wareItemsList.find(a => a.id == newVal);
      if (foundPickedProd) {
        this.SetShipmentItemDefaults(foundPickedProd);
      } else {
        this.itemsShipForm.weight = 0;
        this.itemsShipForm.landingCost = 0;
      }
      this.autocalcShipItemValue = true;
      this.autocalcShipItemWeight = true;
    },
    'itemsShipForm.quantityShipped': function(newVal) {
      let foundPickedProd = this.wareItemsList.find(a => a.id == this.itemsShipForm.warehouseProductId);
      if (foundPickedProd) {
        this.RecalcShipmentItemDefaults(foundPickedProd);
      } else {
        this.itemsShipForm.weight = 0;
        this.itemsShipForm.landingCost = 0;
      }
    },
    'itemForm.brandId': function(newVal, oldVal) {
      this.LoadWareProductsFor(newVal, this.itemForm.fromWarehouseId, this.itemForm.toWarehouseId);
      this.LoadUniqueLPN();
      this.AutoFillNameIfEmpty();
    },
    'itemForm.fromWarehouseId': function (newVal) {
      this.LoadWareProductsFor(this.itemForm.brandId, newVal, this.itemForm.toWarehouseId);
      this.AutoFillNameIfEmpty();
    },
    'itemForm.toWarehouseId': function (newVal) {
      this.LoadWareProductsFor(this.itemForm.brandId, this.itemForm.fromWarehouseId, newVal);
      this.LoadUniqueLPN();
      this.AutoFillNameIfEmpty();
    },
    'itemForm.expectedDate': function (newVal) {
      this.AutoFillNameIfEmpty();
    },
  },

  mounted() {
    this.createFormRequestId = uuidv4();
    this.LoadFormRequestsIntoUI();
    this.PrepareForm();
  },

  methods: {
    PrepareForm(aftr = () => {}) {
      this.readyToLoad = false;
      this.lpnNum = 1;
      this.itemForm.items = [];
      this.orderedShipItems = [];
      this.LoadAllWarehouses();
      if (this.purchaseOrderId) {
        this.heading = 'Create Shipment against PO';
        this.shipmentType = 0;
        //this.ResetForm();
        this.LoadItem(this.purchaseOrderId, aftr);
      } else if (this.shipmentId) {
        this.heading = 'Confirm Shipment';
        this.ResetForm();
        this.LoadShipment(this.shipmentId, aftr);
      }  else if (this.recreateFromShipmentId) {
        this.heading = 'Recreate Shipment';
        this.ResetForm();
        this.$http
          .get('/api/common/warehouseShipments/' + this.recreateFromShipmentId)
          .then(resp => {
            this.poItem = null;
            let item = resp.data;
            this.readyToLoad = true;
            item.items.forEach((it) => {
              it.id = null;
              it.sku = it.warehouseProduct.item;
              it.name = it.warehouseProduct.description;
              if(it.expiresDate){
                it.expiresDateOriginal = it.expiresDate;
                it.expiresDate = new Date(it.expiresDate).toLocaleDateString('en-CA');
              }
              it.warehouseProduct = null;
            })
            item.realStatus = 'Open';
            item.id = null;
            this.readyToLoad = true;
            this.poItem = {
              id: '-',
              type: item.type,
              items: []
            };
            this.shipmentType = item.type;
            this.itemForm = JSON.parse(JSON.stringify(item));
            this.itemForm.items = JSON.parse(JSON.stringify(item.items));
            this.itemForm.customReferenceNumber += '-RE';
            aftr();
          })
          .catch(err => {});
      }
      else if(this.isWT) {
        this.heading = 'Create ASN / WT';
        this.shipmentType = 1;
        this.ResetForm();
        this.itemForm.purchaseOrderId = '-';
        this.poItem = {
          id: '-',
          type: 1,
          items: []
        };
        this.readyToLoad = true;
      } else {
        this.isSimpleASN = true;
        this.heading = 'Create ASN';
        this.shipmentType = 0;
        this.ResetForm();
        this.itemForm.purchaseOrderId = '-';
        this.itemForm.name = 'Shipment' + ' #1';
        this.poItem = {
          id: '-',
          type: 0,
          items: []
        };
        this.readyToLoad = true;
      }
    },
    convertPoundsToKilograms(pounds) {
      let kilograms = parseFloat(pounds) * 0.453592;
      return kilograms;
    },
    onContext(ctx) {
      this.itemForm.expectedDate = ctx.selectedYMD;
    },
    palletColorGen(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substring(-2);
      }
      return colour;
    },
    canItDoLotNumber(wareProdId) {
      let isTrue = false;
      if (wareProdId) {
        let findProd = this.wareItemsList.find(
          a => a.id == wareProdId
        );
        if (findProd && findProd.tplWarehouseItem) {
          if (
            findProd.tplWarehouseItem.options &&
            findProd.tplWarehouseItem.options.trackBys.trackLotNumber
          ) {
            isTrue = true;
          }
        } else {
              isTrue = true;
          }
      }
      return isTrue;
    },
    canItDoExpirationDate(wareProdId) {
      let isTrue = false;
      if (wareProdId) {
        let findProd = this.wareItemsList.find(
          a => a.id == wareProdId
        );
        if (findProd && findProd.tplWarehouseItem) {
          if (
            findProd.tplWarehouseItem.options &&
            findProd.tplWarehouseItem.options.trackBys.trackExpirationDate
          ) {
            isTrue = true;
          }
        } else {
              isTrue = true;
          }
      }
      return isTrue;
    },
    countCurrentlyEntered(wareItem) {
      let qtyNowShipping = 0;
      this.itemForm.items.forEach(itm => {
        if (itm.sku == wareItem) {
          qtyNowShipping = qtyNowShipping + parseFloat(itm.quantityShipped);
        }
      });
      return qtyNowShipping;
    },
    SplitItemAsk(item) {
      this.showConfirmSplitItemForm = true;
      this.splitItemWhat = item;
    },
    SplitItemConfirm() {
      if(this.splitItemWhat) {
        let qtyNow = this.splitItemWhat.quantityShipped;
        let qtyPosLeft = qtyNow % this.splitAddNum;
        if(qtyPosLeft == 0) {
          let qtyNew = parseInt(qtyNow / this.splitAddNum);
          this.splitItemWhat.quantityShipped = qtyNew;
            let dupliItem = JSON.parse(JSON.stringify(this.splitItemWhat));
            if (dupliItem.id) {
              dupliItem.id = null;
            }
            for(let i = 1; i<=(this.splitAddNum-1); i++) {
                this.itemForm.items.push(dupliItem);
            }
        } else {
          let qtyNew = parseInt(qtyNow / this.splitAddNum);
          let totalSplit = 0;
          let dupliItem = JSON.parse(JSON.stringify(this.splitItemWhat));
          dupliItem.quantityShipped = qtyNew;
          for(let i = 1; i<=(this.splitAddNum-1); i++) {
              totalSplit += qtyNew;
              this.itemForm.items.push(dupliItem);
          }
          this.splitItemWhat.quantityShipped = this.splitItemWhat.quantityShipped - totalSplit;
        }
        this.showConfirmSplitItemForm = false;
        this.splitItemWhat = null;
      }
    },
    DuplicateItem(item) {
      let dupliItem = JSON.parse(JSON.stringify(item));
      if (dupliItem.id) {
        dupliItem.id = null;
      }
      this.itemForm.items.push(dupliItem);
    },
    RemoveItem(item, index) {
      if (item.id) {
        // should call API to remove it
        this.$http
          .delete('/api/common/manualShipmentItems/' + item.id)
          .then(respa => {})
          .catch(err => {});
      }
        this.itemForm.items = this.itemForm.items.filter((a, k) => k != index);
    },

    QuestionMultipleItem() {
      this.showConfirmItemForm = true;
    },
    ConfirmMultipleAdd() {
      if(this.AddItem(true)) {
        for(var i = 1; i<=(this.multipleAddNum-1); i++) {
          this.AddItem(true);
        }
        this.itemsShipForm = {
          expiresDate: '',
          lotNumber: '',
          sku: '',
          lpn: '',
          weight: 0,
          landingCost: 0,
          palletType: 'Pallet',
          measurementUnit: 'E',
          warehouseProductId: null,
          quantityShipped: 0
        };
        this.lpnNum++;
        this.itemsShipForm.lpn = 'LCN-'+this.padWithStr(this.lpnNum + '','0', 8);
        this.showConfirmItemForm = false;
        this.autocalcShipItemValue = true;
        this.autocalcShipItemWeight = true;
      }
    },
    AddItem(doMulti = false) {
      if (
        this.itemsShipForm.warehouseProductId &&
        parseFloat(this.itemsShipForm.quantityShipped) > 0
      ) {
        if(this.pickedItemShouldTrackLandingCost && !this.itemsShipForm.landingCost) {
          this.$swal({ title: 'Landing Cost is required for this item!', icon: 'error' });
          return false;
        }
        if(this.pickedItemShouldTrackExpiredDate && !this.itemsShipForm.expiresDate) {
          this.$swal({ title: 'Expiration Date is required for this item!', icon: 'error' });
          return false;
        }
        if(this.pickedItemShouldTrackLotNumber && !this.itemsShipForm.lotNumber) {
          this.$swal({ title: 'Lot Number is required for this item!', icon: 'error' });
          return false;
        }
        let fndOld = this.wareItemsList.find(
          a => a.id == this.itemsShipForm.warehouseProductId
        );
        // this.itemForm.items = this.itemForm.items.filter(
        //   a => a.warehouseProductId != this.itemsShipForm.warehouseProductId
        // );
        let poItem = this.poItem.items.find(
          a => a.warehouseProductItem == fndOld.item || (a.warehouseProductId == this.itemsShipForm.warehouseProductId)
        );
        this.itemForm.items.push({
          sku: fndOld.item,
          name: fndOld.description,
          lotNumber: this.pickedItemCanTrackLotNumber
            ? this.itemsShipForm.lotNumber
            : null,
          expiresDate: this.pickedItemCanTrackExpiredDate
            ? this.itemsShipForm.expiresDate
            : null,
          weight: this.itemsShipForm.weight,
          landingCost: this.itemsShipForm.landingCost,
          lpn: this.itemsShipForm.lpn,
          palletType: this.itemsShipForm.palletType,
          //manualShipmentId: this.itemForm.id ? this.itemForm.id : '-',
          warehouseProductId: this.itemsShipForm.warehouseProductId,
          quantityShipped: parseFloat(this.itemsShipForm.quantityShipped),
          purchaseOrderItemId: !poItem ? null : poItem.id
        });
        if(!doMulti) {
          this.itemsShipForm = {
            sku: '',
            weight: 0,
            quantity: 0,
            palletType: 'Pallet',
            measurementUnit: 'E',
            warehouseProductId: null,
            quantityShipped: 0
          };
        }
        this.lpnNum++;
        this.itemsShipForm.lpn = 'LCN-'+this.padWithStr(this.lpnNum + '','0', 8);
        //this.LoadUniqueLPN();
        return true;
      } else {
        return false;
      }
    },
    padWithStr(str, padString, length) {
        while (str.length < length)
            str = padString + str;
        return str;
    },

    LoadShipment(id, aftr = () => {}) {
      this.$http
        .get('/api/common/warehouseShipments/' + id)
        .then(resp => {
          this.poItem = null;
          let item = resp.data;
          this.readyToLoad = true;
          item.items.forEach((it) => {
            it.sku = it.warehouseProduct.item;
            it.name = it.warehouseProduct.description;
            it.warehouseProduct = null;
          })
          this.shipmentType = item.type;
          this.itemForm = item;
          this.readyToLoad = true;
          aftr();
        })
        .catch(err => {
          this.readyToLoad = true;
        });
    },

    LoadItem(id, aftr = () => {}) {
      this.$http
        .get('/api/common/purchaseOrders/' + id)
        .then(resp => {
          let item = resp.data;
          this.readyToLoad = true;
          this.poItem = item;
          this.itemForm.shipOrderId = item.shippings.length + 1;
          this.itemForm.name = 'Shipment' + ' #' + (item.shippings.length + 1);
          this.itemForm.customReferenceNumber =
            item.orderNumber + '-' + (item.shippings.length + 1);
          //this.itemForm.toWarehouseId = item.toWarehouseId;
          //this.itemForm.fromWarehouseId = item.fromWarehouseId;
          this.itemForm.brandId = item.brandId;
          this.itemForm.vendorId = item.vendorId;

          //this.itemForm.items = [];
          if (this.$route.query.retryShipmentId) {
            let shipmentOld = item.shippings.find(
              a => a.id == this.$route.query.retryShipmentId
            );
            if (shipmentOld) {
              let shipItemsRetry = shipmentOld.items;
              shipItemsRetry.forEach(it => {
                it.id = null;
              });
              this.itemForm.name =
                'Shipment' + ' #' + shipmentOld.shipOrderId + '-RE';
              this.itemForm.customReferenceNumber =
                item.orderNumber + '-' + shipmentOld.shipOrderId + '-RE';
              this.itemForm.items = shipItemsRetry;
            }
          }
          aftr();
        })
        .catch(err => {
          this.readyToLoad = true;
        });
    },

    LoadAllWarehouses() {
        if (this.has('pm.is_end_partner') || this.has('pm.is_warehouse_partner'))  {
        this.allWarehousesListed = this.myWarehouses;
      } else {
        this.$http
        .get('/api/allwarehouses')
        .then(resp => {
            this.allWarehousesListed = resp.data;
            let currentUser = this.$store.getters['account/getUserData'];
            console.log('currentUser.defaultFromWarehouseId', currentUser.defaultFromWarehouseId)
            if(currentUser && currentUser.defaultFromWarehouseId) {
              this.itemForm.fromWarehouseId = currentUser.defaultFromWarehouseId;
            }
        })
        .catch(err => {
        });
      }
    },

    LoadUniqueLPN(brandId, warehouseId) {
      this.$http
          .post('/api/common/warehouseShipments/genUniqueLPN', {

          })
          .then(resp => {
            if (resp.data.success) {
              let lpn = resp.data.lpn;
              this.lpnNum = resp.data.lpnNum;
              this.itemsShipForm.lpn = lpn;
            }
          })
          .catch(err => {
          });
    },
    LoadWareProductsFor(brandId, fromWarehouseId, toWarehouseId) {
      if (!this.readyToLoad) {
        return;
      }
      if (this.loadingWareItems) {
        return;
      }
      this.wareItemsList = [];
      if (brandId && toWarehouseId) {
        let queryUrl = '';
        if (this.isWT) {
          if(!fromWarehouseId) {
            return;
          }
            queryUrl = '/api/warehouseProducts/itemsWTPickList/' +
              brandId + '/' + toWarehouseId + '/' + fromWarehouseId;
        } else if (this.purchaseOrderId) {
            queryUrl = '/api/warehouseProducts/itemsShipmentPickList/' +
              this.purchaseOrderId +
              '/' +
              brandId +
              '/' +
              toWarehouseId;
        } else {
          queryUrl = '/api/warehouseProducts/itemsShipmentPickList/' +
            '-' +
            '/' +
            brandId +
            '/' +
            toWarehouseId;
        }
        this.loadingWareItems = true;
        this.$http
          .post(queryUrl
          )
          .then(resp => {
            if(!this.shipmentId && !this.recreateFromShipmentId) {
              this.itemForm.items = []
            }
            this.wareItemsList = resp.data;
            this.loadingWareItems = false;
          })
          .catch(err => {
            this.wareItemsList = [];
            this.loadingWareItems = false;
          });
      }
    },

    ContinueFormRequest(requestformId) {
      let saveToArray = false;
      let payloadArr = [];
      let getPayloadArrayStr = localStorage.getItem('CreateShipment-formRequests');
      if(getPayloadArrayStr) {
        let getPayloadArray = JSON.parse(getPayloadArrayStr);
        if(getPayloadArray) {
          payloadArr = getPayloadArray;
          let fndExistingPayload = getPayloadArray.find(a => a.createFormRequestId == requestformId);
          if(fndExistingPayload != null) {
            this.readyToLoad = false;
            if(fndExistingPayload.pageProps) {
              this.isWT = fndExistingPayload.pageProps.isWT;
              this.shipmentId = fndExistingPayload.pageProps.shipmentId;
              this.recreateFromShipmentId = fndExistingPayload.pageProps.recreateFromShipmentId;
              this.purchaseOrderId = fndExistingPayload.pageProps.purchaseOrderId;
            }
            this.PrepareForm(() => {
              this.itemForm = JSON.parse(JSON.stringify(fndExistingPayload.itemForm));
            });
          }
        }
      }
    },

    LoadFormRequestsIntoUI() {
      let payloadArr = [];
      let getPayloadArrayStr = localStorage.getItem('CreateShipment-formRequests');
      if(getPayloadArrayStr) {
        let getPayloadArray = JSON.parse(getPayloadArrayStr);
        if(getPayloadArray) {
        console.log('getPayloadArray', getPayloadArray)
          getPayloadArray.reverse().forEach(itm => {
            payloadArr.push({
              when: itm.when,
              createFormRequestId: itm.createFormRequestId,
              shipmentReferenceNumber: itm.itemForm.customReferenceNumber
            });
          });
        }
      }
      console.log('payloadArr', payloadArr)
      this.previousFormRequests = payloadArr;
    },

    SaveFormRequestPayload() {
      let saveToArray = false;
      let payloadArr = [];
      let getPayloadArrayStr = localStorage.getItem('CreateShipment-formRequests');
      if(getPayloadArrayStr) {
        let getPayloadArray = JSON.parse(getPayloadArrayStr);
        if(getPayloadArray) {
          payloadArr = getPayloadArray;
          let fndExistingPayload = getPayloadArray.find(a => a.createFormRequestId == this.createFormRequestId);
          if(fndExistingPayload == null) {
            saveToArray = true;
          }
        } else {
          saveToArray = true;
        }
      } else {
        saveToArray = true;
      }
      if(saveToArray && !this.shipmentId) {
        payloadArr = payloadArr.reverse().slice(0, 19).reverse();
        payloadArr.push({
          when: new Date().toISOString(),
          pageProps: {
            isWT: this.isWT,
            shipmentId: this.shipmentId,
            recreateFromShipmentId: this.recreateFromShipmentId,
            purchaseOrderId: this.purchaseOrderId
          },
          createFormRequestId: this.createFormRequestId,
          itemForm: this.itemForm
        });
        localStorage.setItem('CreateShipment-formRequests', JSON.stringify(payloadArr));
        this.createFormRequestId = uuidv4();
      }
    },

    SaveForm() {
      this.savingItem = true;
      this.$validator.validateAll('itemForm').then(result => {
        if (this.itemForm.items.length <= 0 || !result) {
          this.$swal({ title: 'Fill out required fields!', icon: 'error' });
          this.savingItem = false;
          return;
        }
        let shipment = {
          type: this.shipmentType,
          name: this.itemForm.name,
          customReferenceNumber: this.itemForm.customReferenceNumber,
          toWarehouseId: this.itemForm.toWarehouseId,
          fromWarehouseId: this.itemForm.fromWarehouseId,
          shipOrderId: this.itemForm.shipOrderId,
          purchaseOrderId: this.poItem != null ? this.poItem.id : null,
          notes: this.poItem != null ? this.poItem.notes : null,
          expectedDate: this.itemForm.expectedDate,
          items: this.itemForm.items
        };
        shipment.vendorId = this.itemForm.vendorId;
        shipment.brandId = this.itemForm.brandId;
        if(this.isWT) {
          shipment.name = this.itemForm.customReferenceNumber;
        }
        if(this.shipmentId) {
          shipment.id = this.shipmentId;
        }
        //if (this.itemForm.createPackingSlip) {
          shipment.userPackingList = 'Packing List for ' + this.itemForm.name;
        //}
        this.SaveFormRequestPayload();
        this.$http
          .post('/api/common/purchaseOrders/sendShipment', shipment)
          .then(resp => {
            if (resp.data.success) {
              this.$swal({ title: 'Shipment was sent to WMS!', icon: 'success' });
              if(this.purchaseOrderId) {
                this.$router.push({
                  name: 'Warehouses.PurchaseOrders.View',
                  params: { id: this.purchaseOrderId }
                });
              } else if(this.isWT) {
                this.$router.push({
                  name: 'Warehouses.WarehouseTransfers.View',
                  params: { id: resp.data.item.id }
                });
              } else {
                this.$router.push({
                  name: 'Warehouses.AdvancedShipNotice.View',
                  params: { id: resp.data.item.id }
                });
              }
            } else if (resp.data.errorMessage) {
              this.$swal({ title: resp.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Shipment Was NOT Saved!', icon: 'error' });
            }
            this.ResetForm();
            this.savingItem = false;
          })
          .catch(err => {
            if (err.response.data && err.response.data.errorMessage) {
              this.$swal({
                title: err.response.data.errorMessage,
                icon: 'error'
              });
            } else {
              this.$swal({ title: 'Shipment Was NOT Saved!', icon: 'error' });
            }
            console.error('saved po err', err);
            this.savingItem = false;
          });
      });
    },

    RecalcShipmentItemDefaults(wareItem) {
      if(this.autocalcShipItemWeight)
      {
      let kgWeight = this.convertPoundsToKilograms(wareItem.weight * parseFloat(this.itemsShipForm.quantityShipped));
      this.itemsShipForm.weight = kgWeight;
      }

      if(this.autocalcShipItemValue)
      {
        let singleItemCost = wareItem.itemCost;
        if(this.poItem && this.poItem.id && this.poItem.items && this.poItem.items.length) {
          let poItem = this.poItem.items.find(a => a.warehouseProductItem == wareItem.item);
          if(poItem) {
            singleItemCost = poItem.itemCost;
            console.log('singleItemCost from PO', singleItemCost)
          }
        }
        console.log('singleItemCost from item', singleItemCost)
        this.itemsShipForm.landingCost =  singleItemCost * parseFloat(this.itemsShipForm.quantityShipped);
      }

    },

    SetShipmentItemDefaults(wareItem) {
      let kgWeight = this.convertPoundsToKilograms(wareItem.weight * parseFloat(this.itemsShipForm.quantityShipped));
      this.itemsShipForm.weight = kgWeight;
      let singleItemCost = wareItem.itemCost;
      this.itemsShipForm.landingCost = singleItemCost * parseFloat(this.itemsShipForm.quantityShipped);
    },

    AutoFillNameIfEmpty() {
      if (this.tempSug == this.itemForm.name) {
        this.AutoFillName();
      }
      if(this.tempSugOrderNumber == this.itemForm.customReferenceNumber) {
        this.AutoFillRefNum();
      }
      this.AutoFillRefNumIfEmpty();
    },

    AutoFillRefNumIfEmpty() {
      if(!this.itemForm.customReferenceNumber) {
        this.AutoFillRefNum();
      }
    },

    AutoFillRefNum() {
      let posOrdNumbr = '';
      let brand = this.myBrands.find(a => a.id == this.itemForm.brandId);
      if (brand) {
        posOrdNumbr = brand.code;
        let fromWare = this.myWarehouses.find(
          a => a.id == this.itemForm.fromWarehouseId
        );
        if (fromWare) {
          if (fromWare.code) {
            posOrdNumbr += '-' + fromWare.code;
          }
        }

        let toWare = this.myWarehouses.find(
          a => a.id == this.itemForm.toWarehouseId
        );
        if (toWare) {
          if (toWare.code) {
            posOrdNumbr += '-' + toWare.code;
          }
        }
        if (this.itemForm.expectedDate) {
          posOrdNumbr += '-' + this.formatDateAsStamp(this.itemForm.expectedDate);
        }
      }
      this.tempSugOrderNumber = posOrdNumbr;
      this.itemForm.customReferenceNumber = posOrdNumbr;
    },
    formatDateAsStamp(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate() + 1),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let yearStr = (year + '').replace('20', '');
      return [month, day, yearStr].join('');
    },
    AutoFillName() {
      let posOrdName = '';
      let item = this.wareItemsList.find(
        a => a.id == this.itemForm.warehouseProductId
      );
      if (item) {
        posOrdName += 'Build Plan for ' + item.item;
      }
      this.tempSug = posOrdName;
      this.itemForm.name = posOrdName;
    },

    updatePickedPallet(palletId) {
      console.log('palletId', palletId)
      this.itemsShipForm.lpn = palletId;
      console.log('palletId', this.itemsShipForm.lpn)
    },

    GetItemUOM(prodId) {
      let nam = '';
      let prodFound = this.wareItemsList.find(a => a.id == prodId);
      if (prodFound) {
        let uomCode = prodFound.itemUnit;
        let uomItem = this.uomList.find(a => a.value == uomCode);
        if (uomItem) {
          nam = uomItem.text;
        }
      }
      return nam;
    },
  }
};
</script>
