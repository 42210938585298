<template>
  <b-row>
    <h4 class="col-12 pb-2">{{itemForm.type == 1 ? heading : 'Advanced Ship Notice (ASN): ' + itemForm.customReferenceNumber}}</h4>
    <div class="col-12 col-sm-9">
      <b-card
          no-body
        >
          <b-card-header header-tag="div" class="w-100">
            <div class="col-12 w-100 px-0">
              <div class="form-row pb-1 border-bottom">
                <div class="col p-0">
                  <div
                    :id="'shipnum' + itemForm.id"
                    class="d-inline-block mr-1"
                    v-b-tooltip.hover
                    :title="itemForm.name"
                    ><i class="fa fa-tag text-primary"
                  /></div>
                  <span class="px-2 border border-info">{{ (itemForm.customReferenceNumber) }}</span>
                  <div
                    v-if="itemForm.notes"
                    :id="'notest' + itemForm.id"
                    class="d-inline-block ml-2"
                    ><i class="fa fa-info-circle text-warning"
                  /></div>
                  <b-popover
                    v-if="itemForm.notes"
                    class="popovermappings"
                    boundary="window"
                    :target="'notest' + itemForm.id"
                    triggers="hover"
                    placement="right"
                  >
                    <template v-slot:title>
                      Notes:
                    </template>
                    <div>
                      {{ itemForm.notes }}
                    </div>
                  </b-popover>
                </div>
                <div class="col-auto ml-auto p-0 d-flex">
                  <div class="pr-2 border-right mr-2">
                    <span
                      v-if="itemForm.syncedWithWarehouse"
                      class="badge badge-success"
                      v-b-tooltip.hover
                      title="Synced with Warehouse."
                    >
                      <i class="fa fa-refresh"></i>
                    </span>
                    <div v-else>
                      <span
                        v-if="itemForm.syncFailed"
                        class="badge badge-warning mr-2"
                        v-b-tooltip.hover
                        title="WMS Sync in Progress."
                      >
                        <i class="fa fa-refresh"></i>
                      </span>

                      <span
                        v-if="itemForm.syncFailed"
                        class="badge badge-danger"
                        v-b-tooltip.hover
                        title="PO Sync Failed with Warehouse. Manager notified. Please Wait."
                      >
                        <i class="fa fa-arrow-circle-down"></i>
                      </span>
                      <span
                        v-if="itemForm.syncFailedCo"
                        class="badge badge-danger"
                        v-b-tooltip.hover
                        title="CO Sync Failed with Warehouse. Manager notified. Please Wait."
                      >
                        <i class="fa fa-arrow-circle-up"></i>
                      </span>
                    </div>
                  </div>
                  <div class="pr-2 border-right mr-2">
                    <b>Status: </b>
                    <u>
                      <template v-if="itemForm.realStatus == 'Open'">
                        <label class="badge badge-info"
                          ><i class="fa fa-check" /> Open</label
                        >
                        <span
                          v-b-tooltip.hover
                          title="ASN"
                        >
                          <i class="fa fa-info-circle ml-1"></i>
                        </span>
                      </template>
                      <template v-else-if="itemForm.realStatus == 'In Transit'">
                        <label class="badge badge-secondary"
                          ><i class="fa fa-check" /> In Transit</label
                        >
                      </template>
                      <template v-else-if="itemForm.realStatus == 'cancelled'">
                        <label class="badge badge-danger"
                          ><i class="fa fa-check" /> Cancelled</label
                        >
                      </template>
                      <template v-else-if="itemForm.realStatus == 'closed'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else>
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> {{itemForm.realStatus}}</label
                        >
                      </template>
                    </u>
                  </div>
                  <div
                    class="pr-1"
                    v-if="itemForm.packingListId"
                  >
                    <b-button
                      v-b-tooltip.hover
                      title="Download Packing List"
                      variant="info"
                      size="sm"
                      @click="
                        DownloadPackingListPdfItem(itemForm)
                      "
                      ><i class="fa fa-file-pdf-o"></i
                    ></b-button>
                  </div>
                </div>
              </div>

              <div class="form-row pt-2 pb-1 border-bottom">
                <div class="col border-right" v-if="itemForm.fromWarehouseId">
                  <b>From Warehouse: </b>
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == itemForm.fromWarehouseId)"
                    >{{ myWarehouses.find(a => a.id == itemForm.fromWarehouseId).name }}</span
                  >
                  <span v-else>N / A</span>
                </div>
                <div class="col border-right">
                  <b>To Warehouse: </b>
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == itemForm.toWarehouseId)"
                    >{{ myWarehouses.find(a => a.id == itemForm.toWarehouseId).name }}</span
                  >
                  <span v-else>N / A</span>
                </div>
                <div class="col">
                  <b>By User </b>
                  <span
                    v-if="itemForm.byUserInfo"
                    >{{ itemForm.byUserInfo.fullName }}</span
                  >
                  <span v-else>-</span>
                </div>
                <div class="col" v-if="itemForm.needsQC">
                  <b-form-group class="mb-0">
                      <b-form-checkbox
                        v-model="itemForm.finishedQC"
                        name="check-button"
                        switch
                        :disabled="itemForm.finishedQC"
                        @change="FinishedQTNow(itemForm.id)"
                      >
                      <span v-if="itemForm.finishedQC">Quality Control: Done</span>
                      <span v-else>Quality Control: Required</span>
                      </b-form-checkbox>
                </b-form-group>
                </div>
              </div>
              <div class="form-row pt-2">
                <div class="col  border-right">
                  <b>Created At: </b>
                  <span>
                    {{
                      '(' +
                        fullHumanDate(
                          itemForm.createDateTimeUtc,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                </div>
                <div class="col  border-right">
                  <b>Received At: </b>
                  <span v-if="itemForm.receivedAtDateTimeUtc">
                    {{
                      '(' +
                        fullHumanDate(
                          itemForm.receivedAtDateTimeUtc,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="col  border-right">
                  <b>Expected At: </b>
                  <span v-if="itemForm.expectedDate">
                    {{
                      '(' +
                        fullHumanDate(
                          itemForm.expectedDate,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="col  border-right">
                  <b>Vendor: </b>
                  <span v-if="itemForm.vendorId">
                    <span
                          v-if="myVendors && myVendors.find(a => a.id == itemForm.vendorId)"
                          >{{ myVendors.find(a => a.id == itemForm.vendorId).name }}</span
                        >
                        <span v-else>-</span>
                    </span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-card-body class="text-left p-0">
            <b-table
              v-if="
                itemForm.items &&
                  itemForm.items.filter(a => a.quantityShipped > 0).length >
                    0
              "
              :striped="true"
              :bordered="true"
              :outlined="true"
              :small="false"
              :hover="false"
              :dark="false"
              :responsive="true"
              :items="OrderedByLPN(itemForm.items.filter(a => a.quantityShipped > 0))"
              head-variant="light"
              :fields="shipmentItemfields"
            >
              <template v-slot:cell(palletNum)="data">
                <div class="text-center">
                  <span class="d-inline-block" :style="'margin-bottom: -2px; margin-right: 2px; border-radius: 4px; border: 1px solid transparent; width: 15px; height: 15px; background-color:'+data.item.palletColor"></span>
                  <span>{{data.item.palletNum}}</span>
                </div>
              </template>
              <template v-slot:cell(warehouseProductId)="data">
                <div class="border-bottom">
                  <span>{{
                    data.item.warehouseProduct
                      ? data.item.warehouseProduct.item
                      : '-'
                  }}</span>
                </div>
                <div>
                  <span>{{
                    (data.item.warehouseProduct
                      ? data.item.warehouseProduct.description
                      : '-') | truncate(30)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(realStatus)="data">
                <u>
                  <template v-if="data.item.realStatus == 'Open'">
                    <label class="badge badge-info"
                      ><i class="fa fa-check" /> Open</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'In Transit'">
                    <label class="badge badge-secondary"
                      ><i class="fa fa-check" /> In Transit</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'cancelled'">
                    <label class="badge badge-danger"
                      ><i class="fa fa-check" /> Cancelled</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'closed'">
                    <label class="badge badge-success badge-green"
                      ><i class="fa fa-check" /> Received</label
                    >
                  </template>
                  <template v-else>
                    <label class="badge badge-success badge-green"
                      ><i class="fa fa-check" /> {{data.item.realStatus}}</label
                    >
                  </template>
                </u>
              </template>
              <template v-slot:cell(quantityShipped)="data">
                <div>
                  {{ parseFloat(data.item.quantityShipped) }}
                </div>
              </template>
              <template v-slot:cell(quantityReceived)="data">
                <div>{{ parseFloat(data.item.quantityReceived) }}</div>
              </template>
              <template v-slot:cell(quantityDamaged)="data">
                <div>{{ parseFloat(data.item.quantityDamaged) }}</div>
              </template>
              <template v-slot:cell(expiresDate)="data">
                <div>
                 <span v-if="data.item.expiresDate"> {{ data.item.expiresDate | moment('MMMM Do YYYY') }}</span>
                 <span v-else>-</span>
                 <button @click="data.toggleDetails" class="badge badge-primary"><i class="fa fa-plus"></i></button>
                </div>
              </template>

              <template #row-details="data">
                <b-card>
                  <b-row class="mb-0">
                    <b-col sm="4" class="border-right">
                       <b-row class="mb-2">
                        <b-col sm="6" class="text-sm-right"><b>Items Value:</b></b-col>
                        <b-col sm="6">${{ data.item.stackValue }}</b-col>
                      </b-row>
                    </b-col>

                    <b-col sm="4" class="border-right">
                       <b-row class="mb-2">
                        <b-col sm="6" class="text-sm-right"><b>Weight:</b></b-col>
                        <b-col sm="6">{{ data.item.weight }} kg</b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
    </div>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <b-button
          v-b-tooltip.hover
          block
          title="Download Packing List"
          variant="outline-info"
          size="sm"
          @click="
            DownloadPackingListPdfItem(itemForm)
          "
          ><i class="fa fa-file-pdf-o"></i> Download Packing List
        </b-button>
          <b-button
            block
            variant="danger"
            v-if="has('pm.warehouses.asn.update') && itemForm.realStatus != 'cancelled' && itemForm.realStatus != 'closed'"
            @click="MarkAsCancelled(itemForm)"
          >
            <i class="fa fa-trash mr-2" /> Mark as Cancelled
          </b-button>
          <b-button
          v-if="itemForm.realStatus == 'cancelled'"
          :to="{ name: 'Warehouses.AdvancedShipNotice.ReCreate', params: { recreateFromShipmentId: itemForm.id } }"
          block
          variant="outline-danger"
          >Re-Create</b-button>
        <template v-if="itemForm.type == 0">
          <!--If it is a ASN-->
          <b-button
          block
            variant="success"
            v-if="has('pm.warehouses.asn.confirm') && itemForm.realStatus == 'Draft'"
            :to="{ name: 'Warehouses.AdvancedShipNotice.ConfirmShipment', params: { shipmentId: itemForm.id } }"
          >
            <i class="fa fa-bus mr-2" /> Confirm
          </b-button>
          <b-button
            block
            variant="secondary"
            v-if="has('pm.warehouses.asn.update') && itemForm.realStatus == 'Open'"
            @click="MarkAsInTransit(itemForm)"
          >
            <i class="fa fa-bus mr-2" />
            <span>Mark as In Transit</span>
          </b-button>
          <b-button
          block
              v-if="has('pm.warehouses.asn.receive') && itemForm.realStatus != 'closed' && itemForm.realStatus != 'cancelled'"
              variant="primary" :to="{ name: 'Warehouses.WarehouseTransfers.ReceiveShipment', params: { shipmentId: itemForm.id } }"
            >
              <i class="fa fa-dropbox mr-2" />
              Receive Items
            </b-button>
        </template>
        <template v-else-if="itemForm.type == 1">
          <!--If it is a WT-->
          <b-button
          block
            variant="success"
            v-if="has('pm.warehouses.wt.confirm') && itemForm.realStatus == 'Draft'"
            :to="{ name: 'Warehouses.warehouseTransfers.Confirm', params: { shipmentId: itemForm.id } }"
          >
            <i class="fa fa-bus mr-2" /> Confirm
          >
          </b-button>
          <b-button
            block
            variant="primary"
            v-if="isExtensivFromWarehouse && has('pm.warehouses.wt.update') && itemForm.realStatus == 'Open'"
            :to="'/warehouses/warehouseTransfers/'+itemForm.id+'/allocate'"
          >
            <i class="fa fa-bus mr-2" />
            <span>Allocate and Mark as In Transit</span>
          </b-button>
          <b-button
          block
            variant="secondary"
            v-if="has('pm.warehouses.wt.update') && itemForm.realStatus == 'Open'"
            @click="MarkAsInTransit(itemForm)"
          >
            <i class="fa fa-bus mr-2" />
            <span v-if="isExtensivFromWarehouse">Auto-Ship &amp; Mark as In Transit</span>
            <span v-else>Mark as In Transit</span>
          </b-button>
          <b-button
          block
              v-if="has('pm.warehouses.wt.receive') && itemForm.realStatus != 'closed' && itemForm.realStatus != 'cancelled'"
              variant="primary" :to="{ name: 'Warehouses.WarehouseTransfers.ReceiveShipment', params: { shipmentId: itemForm.id } }"
            >
              <i class="fa fa-dropbox mr-2" />
              Receive Items
            </b-button>
        </template>
        <router-link
          v-if="itemForm.purchaseOrderId"
          class="mt-2 btn btn-block btn-warning"
          :to="{ name: 'Warehouses.PurchaseOrders.View', params: {id: itemForm.purchaseOrderId} }"
        >
          <span>Open Purchase Order</span>
        </router-link>
        <router-link
          target="_blank"
          v-if="has('pm.customerBilling.billingEntries')"
          :to="{
            name: 'Billing.CustomerBillingLedger.Index',
            query: { warehouseShipmentId: itemForm.id }
          }"
          class="btn btn-block btn-info"
        >
          Client Billing Ledger
        </router-link>
        <router-link
        v-if="itemForm.type == 1"
          class="mt-2 btn btn-block btn-outline-warning"
          :to="{ name: 'Warehouses.WarehouseTransfers.Index' }"
          exact
        >
          <span>Back to Warehouse Transfers</span>
        </router-link>
        <router-link
        v-if="itemForm.type == 0"
          class="mt-2 btn btn-block btn-outline-warning"
          :to="{ name: 'Warehouses.AdvancedShipNotice.Index' }"
          exact
        >
          <span>Back to ASNs</span>
        </router-link>
      </b-card>
      <b-card header="More">
        <b-form-group label="Connect Log" class="mt-2">
              <b-form-textarea
                :value="itemForm.connectLog"
                :disabled="true"
                rows="7"
                max-rows="12"
              />
          </b-form-group>
      </b-card>
      <DiscussionsWidget v-bind="chatWidgetProps" v-if="chatWidgetProps.relatedId"></DiscussionsWidget>
    </aside>
  </b-row>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import DiscussionsWidget from '@/views/components/DiscussionsWidget';
export default {
  name: 'Warehouses.WarehouseTransfers.View',
  mixins: [crudMixinGen('warehouseTransfers'), myCommonDataMixin],

  components: {
    DiscussionsWidget
  },

  data: () => ({
    chatWidgetProps: {
      startOpen: true,
      channelType: 'FeatureClientSupport',
      relatedDesc: 'Discussion for ASN',
      relatedName: 'ASN: ',
      relatedId: '',
      relatedType: 'ASN'
    },

    id: '',
    shipmentItemfields: [
      { key: 'palletNum', label: 'PLT #', sortable: false },
      { key: 'warehouseProductId', label: 'Item', sortable: false },
      { key: 'lpn', label: 'LPN', sortable: false },
      { key: 'quantityShipped', label: 'Qty Ship', sortable: false },
      { key: 'quantityReceived', label: 'Qty Received', sortable: false },
      { key: 'lotNumber', label: 'Lot Number', sortable: false },
      //{ key: 'quantityDamaged', label: 'Qty Outstanding', sortable: false },
      //{ key: 'boxes', label: 'Boxes', sortable: false },
      //{ key: 'dimensions', label: 'Dimensions', sortable: false },
      { key: 'expiresDate', label: 'Expires', sortable: false },
      //{ key: 'lpn', label: 'LPN', sortable: false },
    ],
    heading: 'Warehouse Transfer',
  }),

  computed: {
    isExtensivFromWarehouse: {
      get() {
        let isTrue = false;
        if (this.itemForm.fromWarehouseId) {
          let foundWare = this.myWarehouses.find(
            a => a.id == this.itemForm.fromWarehouseId
          );
          if (foundWare) {
            isTrue = (foundWare.wmsType == 4);
          }
        }
        return isTrue;
      },
      set(old, val) {}
    },
  },
  watch: {
    '$route.query.id': function(val) {
      this.id = val;
      this.LoadItem(this.id).then(() => {
        this.chatWidgetProps.relatedId = this.itemForm.id;
        if(this.itemForm.type == 1)
        {
          this.chatWidgetProps.relatedName = 'WT: ' + this.itemForm.customReferenceNumber;
          this.chatWidgetProps.relatedType = 'WT';
          this.chatWidgetProps.relatedDesc = 'Discussion for WT';
        }
        else
        {
          this.chatWidgetProps.relatedName = 'ASN: ' + this.itemForm.customReferenceNumber;
          this.chatWidgetProps.relatedType = 'ASN';
          this.chatWidgetProps.relatedDesc = 'Discussion for ASN';
        }
      });
    },
  },

  meta() {
    let titl = this.itemForm.type == 1 ?  'WT: ' +this.itemForm.customReferenceNumber : 'ASN: ' +this.itemForm.customReferenceNumber;
      return {
          title: titl,
          // ...
      };
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.ResetForm();
      this.LoadItem(this.id).then(() => {
        this.chatWidgetProps.relatedId = this.itemForm.id;
        if(this.itemForm.type == 1)
        {
          this.chatWidgetProps.relatedName = 'WT: ' + this.itemForm.customReferenceNumber;
          this.chatWidgetProps.relatedType = 'WT';
          this.chatWidgetProps.relatedDesc = 'Discussion for WT';
        }
        else
        {
          this.chatWidgetProps.relatedName = 'ASN: ' + this.itemForm.customReferenceNumber;
          this.chatWidgetProps.relatedType = 'ASN';
          this.chatWidgetProps.relatedDesc = 'Discussion for ASN';
        }
      });
    } else {
      this.ResetForm();
    }
  },

  methods: {
    MarkAsCancelled(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!'
      }).then(result => {
        if (result.value) {
          this.$http
            .post('/api/common/warehouseShipments/markAsCancelled/' + item.id, item)
            .then(resp => {
              if(resp.data.success) {
                this.LoadItem(this.id);
                this.$swal('Done!', 'The shipment was cancelled.', 'success');
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
            .catch(err => {});
        }
      });
    },
    MarkAsInTransit(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mark it as in transit!'
      }).then(result => {
        if (result.value) {
          this.$http
            .post('/api/common/warehouseShipments/markAsInTransit/' + item.id, item)
            .then(resp => {
              if(resp.data.success) {
                this.LoadItem(this.id);
                this.$swal('Done!', 'The shipment was set to in transit.', 'success');
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
            .catch(err => {});
        }
      });
    },
    FinishedQTNow(itemId) {
      this.$http
        .post('/api/common/warehouseShipments/finishQC/'+ itemId)
        .then(resp => {
            //this.loadItems();
        })
        .catch(err => {});
    },
    palletColorGen(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substring(-2);
      }
      return colour;
    },
    OrderedByLPN(shipmentItems) {
      let palletNum = 0;
      let oldLPN = ''
      let itms = shipmentItems;
      itms.sort((a,b) => (a.lpn > b.lpn) ? 1 : ((b.lpn > a.lpn) ? -1 : 0));
      itms.forEach((it) => {
        if(oldLPN != it.lpn) {
          oldLPN = it.lpn;
          palletNum++;
          it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
          it.palletNum = palletNum;
        } else {
          it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
          it.palletNum = palletNum;
        }
      })
      return itms;
    },
    DownloadPackingListPdfItem(wareShipment) {
      // if(wareShipment.id && wareShipment.realStatus != 'In Transit' && wareShipment.realStatus != 'closed') {
      //   this.$swal('Error!', 'You can only download packing list for warehouse shipments that are at least in transit.', 'error');
      //   return;
      // }
      if(wareShipment.allocationRequest) {
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/pdf/wareship-packing-list/' + wareShipment.id);
        document.body.appendChild(form); // Not sure if this step is necessary
        form.submit();
      } else {
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/pdf/packing-list/' + wareShipment.packingListId);
        document.body.appendChild(form); // Not sure if this step is necessary
        form.submit();
      }
    },
    fullHumanDate(date, str) {
      return this.$moment
        .utc(date)
        .local()
        .format(str);
    },
  }
};
</script>
