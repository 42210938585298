var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"changelog-page"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{staticClass:"btn btn-warning",attrs:{"to":{
        name: 'DocsHome'
      },"exact":""}},[_c('i',{staticClass:"fa fa-angle-left"}),_vm._v(" Back ")])],1),_c('h3',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.itemForm.name))])]),_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"col-7"},[(_vm.itemForm.subGroups)?_c('div',{staticClass:"row pb-3"},_vm._l((_vm.itemForm.subGroups),function(kbGroup){return _c('div',{key:kbGroup.id,staticClass:"col-6"},[_c('b-card',{attrs:{"header":kbGroup.name}},[_c('div',{staticClass:"vue-prosemirror changelogentry",staticStyle:{"color":"#495057"}},[_c('p',[_vm._v(_vm._s(kbGroup.description))]),_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-info mr-1",attrs:{"to":{
                        name: 'ViewKnowledgeGroup',
                        params: { slug: kbGroup.slug }
                      }}},[_vm._v(" Open "),_c('i',{staticClass:"fa fa-angle-right"})])],1)])])],1)}),0):_vm._e(),_c('div',{staticClass:" article-list"},[_c('b-list-group',_vm._l((_vm.groupPages),function(article){return _c('b-list-group-item',{key:article.id,attrs:{"to":{
                  name: 'ViewKnowledgeArticle',
                  params: { slug: article.slug }
                }}},[_c('i',{staticClass:"fa fa-book mr-1"}),_vm._v(" "+_vm._s(article.name)+" "),_c('span',{staticClass:"pull-right"},[_c('i',{staticClass:"fa fa-angle-right"})])])}),1)],1)]),_c('div',{staticClass:"col-md-5"},[_c('QueryAIWidget')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }