<template>
  <section class="channel-window" v-if="channel" :class="{'channel-window-big': !sidebarView, 'channel-window-mini': sidebarView}">
    <!-- Chat Box-->
      <div class="px-4 py-2 border-bottom" v-if="!sidebarView">
        <div class="mb-0 py-1 clearfix">
          <b-button
              variant="outline-danger"
              class="btn-exitchannel mr-2"
              size="sm"
              @click="activeChannel = null"
            >
              <i class="fa fa-times"></i>
            </b-button>
          <h5 class="d-inline-block m-0">Channel: {{ getChannelName(channel) }}</h5>
          <div class="d-inline-block pull-right">
            <template v-if="channel.relatedEntityType == 'PO'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Warehouses.PurchaseOrders.View', params: {id: channel.relatedEntityId}}"
              >
              <span class="d-none d-sm-inline">Open Purchase Order</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'BoM'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Manufacturing.BillOfMaterials.Edit', params: {id: channel.relatedEntityId}}"
              >
               <span class="d-none d-sm-inline"> Open BoM</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'BulkShipment'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Warehouses.BulkShipments.View', params: {id: channel.relatedEntityId}}"
              >
              <span class="d-none d-sm-inline">Open Bulk</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'SmallParcel'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Warehouses.ManualShipments.Edit', params: {id: channel.relatedEntityId}}"
              >
              <span class="d-none d-sm-inline">Open Small Parcel</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'ASN'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Warehouses.AdvancedShipNotice.View', params: {id: channel.relatedEntityId}}"
              >
              <span class="d-none d-sm-inline">Open ASN</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'WT'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Warehouses.WarehouseTransfers.View', params: {id: channel.relatedEntityId}}"
              >
                <span class="d-none d-sm-inline">Open WT</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <template v-else-if="channel.relatedEntityType == 'SO'">
              <router-link
                class="btn btn-sm btn-outline-primary ml-2"
                :to="{name: 'Common.OrderDetails', query: {id: channel.relatedEntityId}}"
              >
                <span class="d-none d-sm-inline">Open Storefront Order</span>
                <i class="fa fa-external-link ml-1"></i>
              </router-link>
            </template>
            <b-button
                variant="outline-info"
                class="ml-2"
                size="sm"
                v-b-toggle.chatdetails-sidebar-right
              >
                <i class="fa fa-cogs"></i>
                <span class="d-none  ml-2 d-sm-inline">Channel Settings</span>
              </b-button>
          </div>
        </div>
      </div>

      <div v-if="channelMessages.length == 0" class="px-3 py-1 chat-box chat-messages-box" :class="{'dm-discussion': channel.type == 'DirectMessages', 'internal-discussion': channel.type.includes('Internal')}">
        <!-- Sender/Receiver Message-->
        <div v-if="channelMessages.length == 0" class="media channel-msg-wrapper">
          <b-alert show variant="secondary" class="w-100">
            No messages so far.
          </b-alert>
        </div>
      </div>
      <div v-else class="px-3 py-1 chat-box chat-messages-box" :class="{'dm-discussion': channel.type == 'DirectMessages', 'internal-discussion': channel.type.includes('Internal')}">

        <div class="media channel-msg-wrapper" v-for="channelMsg in channelMessages" :key="channelMsg.id" :class="{'ml-auto': (channelMsg.appUserId == currentUserId)}">
          <b-avatar v-if="channelMsg.appUserId != currentUserId && channelMsg.appUser && channelMsg.appUser.avatarUrl"  class="rounded-circle" :src="channelMsg.appUser.avatarUrl"></b-avatar>
          <b-avatar v-else-if="channelMsg.appUserId != currentUserId"  class="rounded-circle" :src="'https://ui-avatars.com/api/?name='+channelMsg.appUser.fullName"></b-avatar>
          <div class="media-body ml-3">
            <div><h6>{{ channelMsg.appUser.fullName }}</h6></div>
            <div class="rounded py-2 px-3" :class="{'mb-1': sidebarView, 'mb-1': !sidebarView, 'bg-light': (channelMsg.appUserId != currentUserId), 'bg-primary': (channelMsg.appUserId == currentUserId)}">
              <div class="channel-msg-content text-small mb-0" :class="{'text-muted': (channelMsg.appUserId != currentUserId), 'text-white': (channelMsg.appUserId == currentUserId)}">
                <vue-markdown>{{ channelMsg.content }}</vue-markdown></div>
              </div>
            <p class="small text-muted" :class="{'mb-0': sidebarView, 'mb-1': !sidebarView}" v-if="channelMsg.createDateTimeUtc.endsWith('Z')">{{ (channelMsg.createDateTimeUtc) | moment('h:mm a | MMM Do') }}</p>
            <p class="small text-muted" :class="{'mb-0': sidebarView, 'mb-1': !sidebarView}" v-else-if="!channelMsg.createDateTimeUtc.endsWith('Z')">{{ (channelMsg.createDateTimeUtc + 'Z') | moment('h:mm a | MMM Do') }}</p>
            <div v-if="channelMsg.attachments">
              <div v-for="attachmnt in channelMsg.attachments" :key="attachmnt.id">
                <template v-if="attachmnt.type == 'Image'">
                  <a class="d-inline-block mt-1" target="_blank" :href="attachmnt.url"><b-img-lazy thumbnail
                    rounded class="w-100" responsive :alt="attachmnt.name" :src="attachmnt.url" /></a>
                </template>
                <template v-else>
                  <b-media class="mb-1">
                    <template #aside>
                      <div class="bg-grey rounded bg-rounded">
                        <template v-if="attachmnt.type == 'PDF'">
                          <i class="fa fa-file-pdf-o"></i>
                        </template>
                        <template v-else-if="attachmnt.type == 'Document'">
                          <i class="fa fa-file-word-o"></i>
                        </template>
                        <template v-else-if="attachmnt.type == 'Spreadsheet'">
                          <i class="fa fa-file-excel-o"></i>
                        </template>
                        <template v-else-if="attachmnt.type == 'Zip'">
                          <i class="fa fa-zip-o"></i>
                        </template>
                        <template v-else-if="attachmnt.type == 'Image'">
                          <i class="fa fa-file-picture-o"></i>
                        </template>
                        <template v-else>
                          <i class="fa fa-file-code-o"></i>
                        </template>
                      </div>
                    </template>
                    <b class="mt-0"><a target="_blank" :href="attachmnt.url" download> {{ attachmnt.name }}</a>
                  </b>
                  </b-media>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div ref="scrollToMe">
          <div v-if="lastUserTyped" class="small text-muted">
            <span>{{ lastUserTyped }} is typing...</span>
          </div>
          <div v-else class="small text-muted">
            <span>&nbsp;</span>
          </div>
          <div class="text-right small" v-if="fileAttachments.length">
            <b>Attachments: </b>
            <span v-for="attc in fileAttachments" :key="attc.id">{{ attc.name }}, </span>
          </div>
        </div>

      </div>

      <input
            id="fileImport"
            ref="fileImport"
            type="file"
            class="d-none"
            accept=".pdf,.zip,.xls,.xlsx,.docx,.png,.jpeg,.jpg,.gif,.webm"
            name="fileImport"
            @change="ImportFileHandler()"
          />
      <!-- Typing area -->
      <form class="bg-light" @submit.prevent>
        <div class="input-group">
          <textarea-autosize
          :min-height="15"
          :disabled="isSendingMessage"
          type="text" @input="delayedTyped" placeholder="Type a message, then press the 'send' button." v-model="chatMsg" aria-describedby="button-addon2" class="form-control rounded-0 border-0 pt-2 pb-1 bg-light" />
          <div class="input-group-append">
            <div>
              <label v-b-tooltip.hover="'Attach a file to the message!'" id="send-attachment" for="fileImport" style="line-height: 2.4;" class="btn btn-link m-0 hvrbtn"><i class="fa fa-upload"></i></label>
            </div>
            <div>
              <button v-b-tooltip.hover="'Send the message!'" id="send-attachment" @click="SendMsg()" style="line-height: 2.4;" class="btn btn-link m-0 hvrbtn"><i class="fa fa-paper-plane"></i></button>
            </div>
          </div>
        </div>
      </form>

      <b-sidebar id="chatdetails-sidebar-right" title="Channel - Details" right shadow
        bg-variant="white"
        backdrop
        no-header
        >
        <div class="container sidebar-header">
          <b-row>
            <div class="col">
              <h5>Channel Config</h5>
            </div>

            <div class="col-auto text-right">
              <b-button
                variant="outline-danger"
                class="ml-1"
                size="sm"
                v-b-toggle.chatdetails-sidebar-right
              >
                Close
              </b-button>
            </div>
          </b-row>
        </div>
        <div class="px-3 py-2">
          <div class="mb-2">
            <b-button variant="danger" @click="LeaveChannel(channel)" v-if="channel.type != 'DirectMessages'" block>Leave Channel</b-button>
          </div>
          <b-card header="Members">
            <b-list-group flush>
              <b-list-group-item v-for="member in channel.members" :key="member.id" class="p-0 pb-2 w-100 d-flex">
                <b-avatar v-if="member.appUser && member.appUser.avatarUrl" :src="member.appUser.avatarUrl"></b-avatar>
                <b-avatar v-else :src="'https://ui-avatars.com/api/?name='+member.appUser.fullName"></b-avatar>
                <div class="ml-2 flex-grow-1 pr-1">
                  <div class="border-bottom">{{ member.appUser.fullName }}</div>
                  <div class="text-secondary">{{ member.appUser.title }}</div>
                </div>
                <div v-if="is('developer') || is('superadmin')">
                  <b-button v-if="channel.type != 'DirectMessages'" @click="RemoveMemberOfChannel(channel, member.appUser.id)" variant="outline-danger" size="sm" class="ml-auto">
                    <i class="fa fa-times"></i>
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
            <template v-slot:footer>
              <b-alert  v-if="channel.type == 'DirectMessages'" show variant="warning">You cannot invite others to a DM channel.</b-alert>
              <div v-else class="w-100 d-flex">
                <div class="mb-1" v-if="addMemberFormVisibleFor == channel.id">
                  <b-form-group label="Pick User:" label-for="item-shareWith">
                    <b-v-select
                      v-model="addMemberWithuserId"
                      :searchable="true"
                      :options="membersToPick"
                    />
                  </b-form-group>
                  <div>
                    <b-button variant="primary" @click="AddMemberTo(channel)"  block>Add To Channel</b-button>
                  </div>
                </div>
                <b-button v-else variant="secondary" @click="ToggleAddMemberFormFor(channel)" block>Add Member</b-button>
              </div>
            </template>
          </b-card>
        </div>
      </b-sidebar>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import VueMarkdown from 'vue-markdown'
export default {
  mixins: [myCommonDataMixin],
  name: 'Communications.ChannelWindow',
  components: {
    VueMarkdown
  },
  props: {
    sidebarView: {
      type: Boolean,
      required: false,
      default: false
    },
    channel: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    addMemberFormVisibleFor: null,
    addMemberWithuserId: null,
    usersCanPickAll: [],

    fileAttachments: [],
    newMsgAudio: false,
    timeoutTrackClearTyping: false,
    lastUserTyped: false,
    lastSentTypedEvent: new Date(),
    delayedTypingTracker: false,
    refreshReadHandler: false,
    refreshPeriodicReadHandler: false,
    refreshHandler: false,
    chatMsg: '',
    isSendingMessage: false,
    loadingPage: false,
    pagination: {
      perPage: 25,
      page: 1
    },
    tableOptions: {
      totalRows: 0
    },
    channelMessages: [],
    channelMessagesFilter: {}
  }),
  computed: {
    ...mapGetters(['getNotifyBusStatus']),
    membersToPick: {
      get() {
        let arrs = [];
        //arrs.push({ value: '', text: 'Any User' });
        this.usersCanPickAll.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.fullName + (itm.title ? ' (' + itm.title + ')' : '')
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
    activeChannelId: {
      get() {
        return this.channel.id;
      },
      set(val) {

      }
    },
    activeChannel: {
      get() {
        return this.channel;
      },
      set(val) {
        this.$emit('update:channel', val);
      }
    },
    currentUserId: {
      get() {
        return this.$store.getters['account/getUserData'].id;
      },
      set(val) {

      }
    },
    currentUserFirstName: {
      get() {
        return this.$store.getters['account/getUserData'].firstName;
      },
      set(val) {

      }
    }
  },
  watch: {
    getNotifyBusStatus: {
      handler: function(val, oldVal) {
        this.unhookFromWebhookChannel(oldVal, () => {
          this.hookToWebhookChannel();
        });
      },
      deep: true
    },
    activeChannelId: {
      handler: function(val, oldVal) {
        this.loadMessages();
        this.chatMsg = '';
        this.fileAttachments = [];
        if(this.refreshPeriodicReadHandler) {
          clearInterval(this.refreshPeriodicReadHandler);
        }
        this.unhookFromWebhookChannel(oldVal, () => {
          this.hookToWebhookChannel();
        });
      },
      deep: true
    },
    pagination: {
      handler: function(val, oldVal) {
        this.loadItems();
      },
      deep: true
    }
  },
  mounted() {
    this.newMsgAudio = new Audio('/sounds/new_msg.wav');
    this.newMsgAudio.muted = true;
    this.loadMessages();
    this.hookToWebhookChannel();
  },
  beforeDestroy: function() {
    if(this.refreshPeriodicReadHandler) {
      clearInterval(this.refreshPeriodicReadHandler);
    }
    this.unhookFromWebhookChannel(this.activeChannelId);
  },

  methods: {
    getChannelName(channel) {
      let channelName = '';
      if(channel.type != 'DirectMessages') {
        channelName = channel.name;
      } else {
        let otherUserFullName = '';
        if(channel.members != null) {
          let fndMemberInfo = channel.members.find(x => x.appUserId != this.currentUserId);
          if(fndMemberInfo != null) {
            otherUserFullName = fndMemberInfo.appUser.fullName;
          } else {
            let fndMemberInfo = channel.members.find(x => x.appUserId == this.currentUserId);
            otherUserFullName = fndMemberInfo.appUser.fullName + ' (My Notes)';
          }
          if(otherUserFullName) {
            channelName = otherUserFullName;
          }
        } else {
          channelName = 'Other';
        }
      }
      return channelName;
    },
    loadPossibleMembers(chnl) {
      this.$http
        //.get('/api/userspublic/collab')
        .get('/api/communications/channels/members-to-collab/'+ chnl.id)
        .then(resp => {
          this.usersCanPickAll = resp.data;
          hnl();
        })
        .catch(err => {});
    },
    ImportFileHandler() {
      let $this = this;
      let reader = new FileReader();
      $this.isFileUploading = true;
      let filePicked = this.$refs.fileImport.files[0];
      reader.onload = function(e) {
        let formData = new FormData();
        formData.append('userId', this.currentUserId);
        formData.append('formFile', filePicked, filePicked.name);
        //formData.append("projectId", $this.projectId);
        $this.$http
          .post('/createChannelAttachment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resp => {
            $this.isFileUploading = false;
            if (resp.data.url) {
              if(!$this.fileAttachments)
              $this.fileAttachments = [];
              resp.data.attachments.forEach((attc) => {
                $this.fileAttachments.push(attc);
              })
              setTimeout(() => {
                $this.scrollToElement();
              }, 50);
            } else {
            $this.$swal('File upload failed!');
            }
          })
          .catch(err => {
            console.error(err);
            $this.isFileUploading = false;
            $this.$swal('File upload failed!');
          });
      };
      reader.readAsDataURL(filePicked);
    },
    pingTyping() {
      try {
        //console.log('pingTyping', this.currentUserFirstName)
        this.lastSentTypedEvent = new Date();
        this.$userHub.$emit('CommunicationsLastTyped', this.currentUserFirstName, this.activeChannelId)
      } catch {
        //console.log('error communications-LeaveRoom')
      }
    },
    delayedTyped(e) {
    let secondsSinceLastSend = Math.abs(this.lastSentTypedEvent.getTime() - new Date().getTime());
      if(this.delayedTypingTracker && secondsSinceLastSend < 2000) {
        clearTimeout(this.delayedTypingTracker);
      }
      this.delayedTypingTracker = setTimeout(() => {
        secondsSinceLastSend = Math.abs(this.lastSentTypedEvent.getTime() - new Date().getTime());
        if(secondsSinceLastSend > 2000) {
          if(this.chatMsg && this.chatMsg.length>1) {
            this.pingTyping();
            if (e.keyCode === 13 && !e.shiftKey) {
              this.SendMsg();
            }
          }
        }
      }, 300);
    },
    unhookFromWebhookChannel(oldChannelId, after = null) {
      if(this.refreshHandler) {
        clearInterval(this.refreshHandler);
        this.refreshHandler = false;
      }
      if(this.refreshReadHandler) {
        clearInterval(this.refreshReadHandler);
        this.refreshReadHandler = false;
      }
      try {
        this.$userHub.$emit('LeaveRoom', 'Communications.Channel-'+oldChannelId)
      } catch(err) {
        console.log('error communications-LeaveRoom', err)
      }
      if(after) {
        after();
      }
    },
    updateLastMessageForCurrentUser(channelMsg) {
      if(this.channel != null && this.channel.members != null) {
        let fndMemberInfo = this.channel.members.find(x => x.appUserId == this.currentUserId);
        if(fndMemberInfo != null) {
          console.log('channelMsg.createDateTimeUtc', channelMsg.createDateTimeUtc)
          fndMemberInfo.lastRead =  channelMsg.createDateTimeUtc;
          fndMemberInfo.lastWrite =  channelMsg.createDateTimeUtc;
        }
      }
    },
    pingLastReadForChannel() {
      if(this.refreshReadHandler) {
        clearInterval(this.refreshReadHandler);
        this.refreshReadHandler = false;
      }
      this.refreshReadHandler = setTimeout(() => {
        try {
          if(this.channel != null && this.channel.members != null) {
            let fndMemberInfo = this.channel.members.find(x => x.appUserId == this.currentUserId);
            if(fndMemberInfo != null) {
              let ureadToRemove = fndMemberInfo.unreadMessages;
              fndMemberInfo.unreadMessages = 0;
              this.$userHub.$emit('CommunicationsRemoveUnreadNum',ureadToRemove)
            }
          }
          this.$userHub.$emit('CommunicationsLastReadPing', this.currentUserId, this.activeChannelId)
        } catch(err) {
          console.error('err pingLastReadForChannel', err)
        }
      }, 40);
    },
    hookToWebhookChannel() {
      try {
        this.$userHub.$on('communications-channel-userTyped', (userTyped) => {
            if(userTyped != this.currentUserFirstName) {
              if(this.timeoutTrackClearTyping)
                clearTimeout(this.timeoutTrackClearTyping);
              this.lastUserTyped = userTyped;
              this.timeoutTrackClearTyping = setTimeout(() => {
                this.lastUserTyped = false;
              }, 3000);
            }
        });

        this.$userHub.$on('communications-channel-newMessage', (messagePayload) => {
          if(messagePayload.channelId == this.channel.id && this.channelMessages.find(a => a.id == messagePayload.id) == null) {
            this.channelMessages.push(messagePayload);
            this.updateLastMessageForCurrentUser(messagePayload);
            setTimeout(() => {
              this.scrollToElement();
              this.pingLastReadForChannel();
            }, 50);
          }
        });
        this.pingLastReadForChannel();
        this.refreshPeriodicReadHandler = setInterval(() => {
          this.pingLastReadForChannel();
        }, 30 * 1000);
        this.$userHub.$emit('JoinRoom', 'Communications.Channel-'+this.activeChannelId)
      } catch {
        console.error('err hookToWebhookChannel')
      }
    },
    ToggleAddMemberFormFor(chnl) {
      if(this.addMemberFormVisibleFor == chnl.id) {
        this.addMemberFormVisibleFor = null;
      } else {
        this.addMemberFormVisibleFor = chnl.id;
        this.loadPossibleMembers(chnl);
      }
    },
    RemoveMemberOfChannel(chnl, userId) {
      this.$http
        .post('/api/communications/channels/kickFromChannel/' + chnl.id + '/' + userId)
        .then(resp => {
          if(resp.data.success) {
            this.channel.members = this.channel.members.filter(a => a.appUserId != userId);
            this.$swal('User removed from channel.');
          }
        })
        .catch(err => {
          console.error('RemoveMemberOfChannel err', err);
        });
    },
    AddMemberTo(chnl) {
      if(!this.addMemberWithuserId) {
        this.$swal('Please select a user to add to the channel.');
        return;
      }
      this.$http
        .post('/api/communications/channels/addMember/' + chnl.id + '/' + this.addMemberWithuserId)
        .then(resp => {
          this.addMemberFormVisibleFor = null;
          if(resp.data.success) {
            this.channel.members.push(resp.data.item);
            this.$swal('User added to channel.');
          }
        })
        .catch(err => {
          console.error('AddMemberTo err', err);
        });
    },
    LeaveChannel(chnl) {
      this.$http
        .post('/api/communications/channels/leave/' + chnl.id)
        .then(resp => {
          this.$emit('update:channel', null);
          this.channel = null;
          this.$router.push({name: 'Communications.Index'})
        })
        .catch(err => {
          console.error('LeaveChannel err', err);
        });
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'instant', block: 'end'});
      }
    },

    SendMsg() {
      if(this.chatMsg) {
        this.isSendingMessage = true;
        this.$http.post('/api/communications/channelMessages/post', {
          channelId: this.activeChannelId,
          content: this.chatMsg,
          appUserId: this.$store.getters['account/getUserData'].id,
          attachments: this.fileAttachments
        }).then(resp => {
          this.chatMsg = '';
          this.fileAttachments = [];
          let messagePayload = resp.data;
          this.isSendingMessage = false;
          if(this.channelMessages.find(a => a.id == messagePayload.id) == null) {
            this.channelMessages.push(messagePayload);
            this.updateLastMessageForCurrentUser(messagePayload);
            setTimeout(() => {
              this.scrollToElement();
            }, 50);
          }
        }).catch(err => {
          this.isSendingMessage = false;
          console.error('SendMsg err', err);
        })
      }
    },
    loadMessages() {
      if (this.loadingPage) {
        return;
      }
      this.loadingPage = true;
      this.channelMessagesFilter.page = parseInt(this.pagination.page);
      this.channelMessagesFilter.perPage = parseInt(this.pagination.perPage);
      this.channelMessagesFilter.channelId = this.activeChannelId;
      this.$http
        .post('/api/communications/channelMessages/listItems', this.channelMessagesFilter)
        .then(resp => {
          this.loadingPage = false;
          // this.pagination.page = resp.data.page
          this.tableOptions.totalRows = resp.data.total;
          // this.pagination.totalPages = resp.data.totalPages
          this.channelMessages = resp.data.items.reverse();
          setTimeout(() => {
            this.scrollToElement();
          }, 50);
        })
        .catch(err => {
          console.error('channelMessages err', err);
          this.loadingPage = false;
        });
    }
  }
};
</script>
