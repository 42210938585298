<template>
  <div class="quick-search border-right">
    <div class="quick-search-inner">
    <b-input-group>
      <b-input-group-prepend class="d-none d-lg-block">
          <b-form-select
            name="form-input-searchType"
            v-model="searchType"
            :options="searchTypeFilter"
          />
      </b-input-group-prepend>
        <b-form-input
          type="text"
          class="form-control"
          v-on:blur="hideResults"
          placeholder="Search Orders by Ref # or Tracking #, Bulk Shipments, WTs, ASNs, POs, and more."
          aria-label="Quick Search"
          v-model="search"
          @input="delayedSearchItems"
          @keyup.enter="searchItems"
        />

      <b-input-group-append class="d-none d-sm-inline-block">
          <b-v-select  v-if="searchType != ''"
            style="width: auto; min-width: 190px;"
            name="form-input-projectId"
            v-model="projectId"
            :searchable="true"
            :options="projectsFilter2"
          />
        <div>
          <button
            class="btn btn-outline-secondary"
            type="button"
            v-on:blur="hideResults"
            @click="searchItems"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
      </b-input-group-append>
    </b-input-group>
    <b-list-group v-if="showSearchResults" class="quick-search-results" @click="pickedResult()">
      <b-list-group-item variant="info" class="flex-column align-items-start" v-if="loadingSearch">
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1">Loading Results...</h6>
          <small></small>
        </div>
      </b-list-group-item>
      <b-list-group-item exact :to="searchResultLink(searchItem)" class="flex-column align-items-start" v-else-if="searchResultItems && searchResultItems.length"
      v-for="(searchItem, key) in searchResultItems" :key="key">
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1" v-html="highlightSearchQuery(searchItem.shortDescription)"></h6>
          <small v-if="searchItem.sideTag1">{{searchItem.sideTag1}}</small>
        </div>

        <p  v-if="searchItem.description" v-html="searchItem.description">
        </p>

        <small v-if="searchItem.sideTag2">{{searchItem.sideTag2}}</small>
      </b-list-group-item>
      <b-list-group-item variant="warning" class="flex-column align-items-start" v-else>
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1">No Search Results!</h6>
          <small v-if="!shouldFetchMyCommonDataHere"></small>
        </div>
      </b-list-group-item>
    </b-list-group>
    </div>
  </div>
</template>

<script>
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  name: 'HeaderQuickSearch',
  mixins: [myCommonDataMixin],
  components: {
  },
  data() {
    return {
      projectId: '',
      searchType: 'Order =',
      loadingSearch: false,
      search: '',
      showSearchResults: false,
      searchResultItems: [],
      delayedSearchTracker: false,
      searchTypeFilter: [
        {value: '', text: 'Any'},
        {value: 'Order =', text: 'Storefront Order: Exact'},
        {value: 'Order %', text: 'Storefront Order: Similar'},
        {value: 'Tracking =', text: 'SO Tracking: Exact'},
        {value: 'Tracking %', text: 'SO Tracking: Similar'},
      ]
    };
  },
  watch: {
    'projectId': function(val) {
      this.searchItems();
    },
    'searchType':  function(val) {
      this.searchItems();
    },
  },
  methods: {
    pickedResult() {
      this.search = '';
      this.showSearchResults = false;
    },
    hideResults() {
      setTimeout(() => {
        this.showSearchResults = false;
      }, 300);
    },
    searchResultLink(searchItem) {
      if(searchItem.type == 'CustomerOrder') {
        return {
          name: 'Common.OrderDetails',
          query: {
            id: searchItem.orderId
          }
        };
      } else if(searchItem.type == 'PurchaseOrder') {
        return {
          name: 'Warehouses.PurchaseOrders.View',
          params: {
            id: searchItem.poId
          }
        };
      } else if(searchItem.type == 'ASN') {
        return {
          name: 'Warehouses.AdvancedShipNotice.View',
          params: {
            id: searchItem.poId
          }
        };
      } else if(searchItem.type == 'WTOrder') {
        return {
          name: 'Warehouses.WarehouseTransfers.View',
          params: {
            id: searchItem.poId
          }
        };
      } else if(searchItem.type == 'ManualShipment') {
        return {
          name: 'Warehouses.ManualShipments.Edit',
          params: {
            id: searchItem.manualShipmentId
          }
        };
      } else if(searchItem.type == 'BulkShipment') {
        return {
          name: 'Warehouses.BulkShipments.View',
          params: {
            id: searchItem.manualShipmentId
          }
        };
      } else if(searchItem.type == 'WarehouseItem') {
        return {
          name: 'Warehouses.Items',
          query: {
            itemId: searchItem.otherItemId
          }
        };
      }
      return {
        name: 'Common.Orders.Index'
      };
    },
    highlightSearchQuery(item) {
     let retItem = item;
      if (this.search) {
        let searchQueryWord = this.search.trim();
        if (searchQueryWord) {
          let searchQueryWordRegex = new RegExp(searchQueryWord, 'g');
          retItem = retItem.replace(
            searchQueryWordRegex,
            '<u>' + searchQueryWord + '</u>'
          );
        }
      }
     return retItem;
    },
    queryGeneralSearch(query) {
      if(query) {
        this.loadingSearch = true;
        $http.post('/api/common/search', {
          query: query,
          projectId: this.projectId,
          searchType: this.searchType
        }).then(response => {
          this.loadingSearch = false;
          this.showSearchResults = true;
          this.searchResultItems = response.data;
        }).catch((err) =>{
          console.error('Failed to run queryGeneralSearch', err)
        });
      } else {
        this.loadingSearch = false;
        this.showSearchResults = false;
        this.searchResultItems = []
      }
    },
    searchItems() {
      if(this.delayedSearchTracker) {
        clearTimeout(this.delayedSearchTracker);
      }
      if(this.search) {
        this.loadingSearch = true;
        this.showSearchResults = true;
      } else {
        this.loadingSearch = false;
        this.showSearchResults = false;
      }
      if(this.search) {
        this.queryGeneralSearch(this.search.trim());
      }
    },
    delayedSearchItems() {
      if(this.delayedSearchTracker) {
        clearTimeout(this.delayedSearchTracker);
      }
      if(this.search && this.search.length>3) {
        this.loadingSearch = true;
        this.showSearchResults = true;
      } else {
        this.loadingSearch = false;
        this.showSearchResults = false;
      }
      this.delayedSearchTracker = setTimeout(() => {
        if(this.search && this.search.length>3) {
          this.queryGeneralSearch(this.search.trim());
        } else {
          this.loadingSearch = false;
          this.showSearchResults = false;
        }
      }, 800);
    }
  },
  computed: {
    shouldFetchMyCommonDataHere: {
      get() {
        return false
      },
      set(old, val) {}
    },
    projectsFilter2: {
      get() {
        let arrs = [{ value: '', text: 'All' }];
        arrs = arrs.concat(this.projectsList);
        return arrs;
      }
    }
  },
  mounted() {
    if (this.has('pm.is_client') || this.has('pm.is_vendor_partner')) {
      this.searchType = 'Order %';
    } else {
      this.searchType = '';
    }
  }
};
</script>
