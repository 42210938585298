export const fetchItemsGen = (basePath, endpoint = 'list') => {
  let fn = (filters, pagination, cancelToken = false) => {
    return new Promise((resolve, reject) => {
      let req = null;
      let reqParams = {
        ...filters,
        ...pagination
      };
      let optParams = {}
      if (cancelToken) {
        optParams.cancelToken = cancelToken;
      }

      if (endpoint) {
        req = $http.post(basePath + '/' + endpoint, reqParams, optParams);
      } else {
        req = $http.get(basePath, optParams);
      }
      req
        .then(resp => {
          resolve(resp);
        })
        .catch(error => {
          console.error('fetchItems error', error);
          reject(error);
        });
    });
  };
  return fn;
};

export const deleteItemGen = basePath => {
  let fn = id => {
    return new Promise((resolve, reject) => {
      $http
        .delete(basePath + '/' + id)
        .then(resp => {
          resolve(resp);
        })
        .catch(error => {
          console.error('fetchItems error', error);
          reject(error);
        });
    });
  };
  return fn;
};

export const getItemByIdGen = basePath => {
  let fn = id => {
    return new Promise((resolve, reject) => {
      $http
        .get(basePath + '/' + id)
        .then(resp => {
          resolve(resp);
        })
        .catch(error => {
          console.error('fetchItems error', error);
          reject(error);
        });
    });
  };
  return fn;
};

export const addItemGen = basePath => {
  let fn = item => {
    return new Promise((resolve, reject) => {
      $http
        .post(basePath, item)
        .then(resp => {
          resolve(resp);
        })
        .catch(error => {
          console.error('fetchItems error', error);
          reject(error);
        });
    });
  };
  return fn;
};

export const editItemGen = basePath => {
  let fn = item => {
    return new Promise((resolve, reject) => {
      $http
        .post(basePath + '/edit', item)
        .then(resp => {
          resolve(resp);
        })
        .catch(error => {
          console.error('fetchItems error', error);
          reject(error);
        });
    });
  };
  return fn;
};

export default {
  fetchItemsGen,
  deleteItemGen,
  getItemByIdGen,
  addItemGen,
  editItemGen
};
