//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';

let defaultForm = {
  receiveDate: '',
  warehouseId: '',
  carrierCode: '',
  serviceCode: '',
  orderId: '',
  confirmation: 'none',
  items: []
};

let defaultFilters = {
  receiveDateStart: '',
  receiveDateEnd: '',
  warehouseId: '',
  brandId: '',
  addr: '',
  orderNumber: '',
  orderStatus: '',
  orderType: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

let ItemModule = {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  }
};

export default ItemModule;
