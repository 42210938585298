<template>
  <b-row>
    <!-- The modal -->
    <section>
      <b-modal id="confirmItem" v-model="showConfirmItemForm" :centered="true" size="lg" title="Confirm Form">
        <b-form-group label="Order Number" v-if="confirmItem">
          <b-form-input name="form-input-orderNumber" v-model="confirmItem.orderNumber" />
        </b-form-group>

        <b-form-group label="From Warehouse" v-if="confirmItem">
          <b-v-select name="form-input-warehouseId" v-model="confirmItem.warehouseId" :searchable="true" :options="warehousesList" />
        </b-form-group>

        <template v-slot:modal-footer>
          <b-button type="reset" variant="outline-secondary" @click="showConfirmItemForm = false">
            Cancel
          </b-button>

          <b-button type="button" variant="primary" @click="MarkAsConfirmed()">
            Confirm
          </b-button>
        </template>
      </b-modal>
    </section>
    <section class="col-12">
      <form @submit.prevent class="row" data-vv-scope="itemForm">
        <div class="col-12  col-sm-9">
          <b-card class="mb-3" :header="heading">
              <b-row>
                <div class="col-12 col-md-6">
                  <b-form-group label="From Warehouse">
                    <b-v-select
                      v-validate="{ required: false }"
                      name="form-input-warehouseId"
                      :state="
                        validateStateScoped('itemForm', 'form-input-warehouseId')
                      "
                      v-model="itemForm.warehouseId"
                      :searchable="true"
                      :disabled="hasBeenShipped"
                      :options="warehousesList"
                    />
                  </b-form-group>
                </div>

                <div class="col-12 col-md-6">
                  <b-form-group label="Client">
                    <b-v-select
                      v-validate="{ required: true }"
                      name="form-input-brandId"
                      :state="
                        validateStateScoped('itemForm', 'form-input-brandId')
                      "
                      v-model="itemForm.brandId"
                      :searchable="true"
                      :disabled="hasBeenShipped"
                      :options="brandsList"
                    />
                  </b-form-group>
                </div>
               <!-- <div class="col-12 col-md-6" v-if="!is('brand-user')">
                  <b-form-group label="Custom Reference #">
                    <b-form-input
                      maxlength="38"
                      name="form-input-customReferenceNumber"
                      :state="
                        validateStateScoped('itemForm', 'form-input-customReferenceNumber')
                      "
                      v-validate="{ required: true }"
                      v-model="itemForm.customReferenceNumber"

                      :disabled="hasBeenShipped"
                    />
                  </b-form-group>
                </div> -->
                <div class="col-12 col-md-6">
                  <b-form-group label="Order #">
                    <b-form-input
                      maxlength="28"
                      name="form-input-orderNumber"
                      :state="
                        validateStateScoped('itemForm', 'form-input-orderNumber')
                      "
                      v-validate="{ required: false }"
                      v-model="itemForm.orderNumber"

                      :disabled="hasBeenShipped"
                    />
                  </b-form-group>
                </div>

                <!-- <div class="col-12 col-md-6" v-if="!is('brand-user')">
                  <b-form-group label="Order Type">
                    <b-v-select
                      v-validate="{ required: true }"
                      name="form-input-orderType"
                      :state="
                        validateStateScoped('itemForm', 'form-input-orderType')
                      "
                      v-model="itemForm.orderType"
                      :disabled="true"
                      :options="orderTypeListPossible"
                    ></b-v-select>
                  </b-form-group>
                </div> -->

                <div class="col-12 col-md-6" v-if="hasBeenShipped">
                  <b-form-group label="Status">
                    <b-form-input
                      v-model="itemForm.orderStatus"
                      :disabled="true"
                    />
                  </b-form-group>
                </div>
              </b-row>
          </b-card>
          <b-card class="mb-3" no-body>
            <template v-slot:header>
              <div class="form-row w-100">
                <div class="col-12 col-md-6">
                  <div class="card-header-small pt-1">Ship To Address</div>
                </div>
                <div
                  class="col-12 col-md-6 align-items-right d-flex"
                  v-if="!hasBeenShipped && ! has('pm.is_end_partner')"
                >
                  <div class="col-auto p-1">Ship Again to:</div>
                  <b-form-group class="col m-0">
                    <b-v-select
                      :showup="true"
                      v-model="itemForm.shipmentAddressId"
                      :searchable="true"
                      :disabled="hasBeenShipped"
                      :options="shipmentAddressList"
                    />
                  </b-form-group>
                </div>
              </div>
            </template>
            <div class="card-body">
              <div
                v-if="hasBeenShippedOrPrepicked && !itemForm.id"
                class="alert alert-warning"
              >
                <b>You are sending this shipment to the address picked above.</b>
              </div>
              <div class="form-row">
                <div class="col-6">
                  <b-form-group label="Ship To Name *">
                    <b-form-input
                      id="form-input-shipmentAddress-name"
                      name="form-input-shipmentAddress-name"
                      :state="validateStateScoped('itemForm', 'form-input-shipmentAddress-name')"
                      v-validate="{ required: true }"
                      v-model="itemForm.shipmentAddress.name"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="Country *">
                    <b-v-select
                      v-validate="{ required: true }"
                      id="form-input-shipmentAddress-countryCode"
                      name="form-input-shipmentAddress-countryCode"
                      :state="validateStateScoped('itemForm', 'form-input-shipmentAddress-countryCode')"
                      :disabled="hasBeenShippedOrPrepicked"
                      v-model="itemForm.shipmentAddress.countryCode"
                      :searchable="true"
                      :options="countryCodeList"
                    />
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="Address Line 1 *">
                    <b-form-input
                      id="form-input-shipmentAddress-addressLine1"
                      name="form-input-shipmentAddress-addressLine1"
                      :state="validateStateScoped('itemForm', 'form-input-shipmentAddress-addressLine1')"
                      v-validate="{ required: true }"
                      v-model="itemForm.shipmentAddress.addressLine1"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="City">
                    <b-form-input
                      v-model="itemForm.shipmentAddress.city"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="Address Line 2">
                    <b-form-input
                      v-model="itemForm.shipmentAddress.addressLine2"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-6 col-md-3">
                  <b-form-group label="State *">
                    <b-v-select
                      v-if="itemForm.shipmentAddress.countryCode == 'US'"
                      v-validate="{ required: true }"
                      name="form-input-shipmentAddress-state"
                      :state="
                        validateStateScoped('itemForm', 'form-input-shipmentAddress-state')
                      "
                      v-model="itemForm.shipmentAddress.state"
                      :searchable="true"
                      :disabled="hasBeenShippedOrPrepicked"
                      :options="USAStateCodeList"
                    />
                    <b-form-input
                      v-else
                      v-validate="{ required: false }"
                      name="form-input-shipmentAddress-state"
                      :state="
                        validateStateScoped(
                          'itemForm',
                          'form-input-shipmentAddress-state'
                        )
                      "
                      v-model="itemForm.shipmentAddress.state"
                    :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-6 col-md-3">
                  <b-form-group label="Postcode">
                    <b-form-input
                      name="form-input-shipmentAddress-postcode"
                      :state="validateStateScoped('itemForm', 'form-input-shipmentAddress-postcode')"
                      v-validate="{ required: false }"
                      v-model="itemForm.shipmentAddress.postcode"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="Address Line 3">
                    <b-form-input
                      v-model="itemForm.shipmentAddress.addressLine3"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-group label="Contact Email">
                    <b-form-input
                      type="email"
                      v-model="itemForm.shipmentAddress.email"
                      :disabled="hasBeenShippedOrPrepicked"
                    />
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-card>
          <b-card class="mb-3" header="Items To Ship" no-body>
            <div class="card-body">
              <div class="items">
                <b-table
                  head-variant="light"
                  :fields="itemForm.id ? shipItemsFieldsView : shipItemsFields"
                  :items="itemForm.items"
                  :bordered="true"
                >
                  <template v-slot:cell(quantity)="data">
                    <span>
                      {{ data.item.quantity }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <div class="d-flex">
                      <button
                        :disabled="data.item.id"
                        class="btn btn-danger"
                        @click="data.item.id ? null : RemoveItem(data.item, data.index)"
                      >
                        Remove
                      </button>
                    </div>
                  </template>
                  <template v-slot:cell(sku)="data">
                    <span>
                      {{ (data.item.warehouseProductItem) }}
                    </span>
                  </template>
                  <template v-slot:cell(name)="data">
                    <span>
                      {{ GetItemName(data.item.warehouseProductItem) }}
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
            <template v-slot:footer>
              <b-alert v-if="!itemForm.brandId" show>
                <b
                  >Pick the Customer first to get a list of possible
                  warehouse items to choose from.</b
                >
              </b-alert>
              <b-alert v-else-if="loadingWareItems" variant="warning" show>
                <b>Loading Items..</b>
              </b-alert>
              <div v-else-if="!itemForm.id" class="form-row">
                <div class="col-4">
                  <b-form-group label="Pick Product">
                    <b-v-select
                      :showup="true"
                      v-model="itemsShipForm.warehouseProductItem"
                      :searchable="true"
                      :options="productsList"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="newProdQuantity">Available</label>
                    <money
                      class="form-control"
                      disabled
                      v-model="itemsShipForm.quantityAvailable"
                      min="0"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <b-form-group label="Quantity">
                    <b-input-group>
                      <money
                        class="form-control"
                        v-model="itemsShipForm.quantity"
                        min="0"
                          />
                      <template #append>
                        <b-form-select
                          v-model="itemsShipForm.measurementUnit"
                          :disabled="true"
                          :options="uomList"
                        />
                      </template>
                    </b-input-group>
                  </b-form-group>
                </div>
                  <div class="col-md-2">
                    <b-form-group label="Lot Number">
                      <b-form-input
                        class="form-control"
                        v-model="itemsShipForm.reqLotNumber"
                        placeholder="(optional)"
                      />
                    </b-form-group>
                  </div>
                <div class="col-6 col-md-2">
                  <b-form-group label=".">
                    <b-button
                      type="button"
                      block
                      variant="primary"
                      @click="AddItem"
                    >
                      Add
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </template>
          </b-card>
        </div>
        <aside class="col-12 col-sm-3">
          <b-card header="Actions">
            <b-button
              v-if="!hasBeenShipped"
              :disabled="savingItem"
              variant="primary"
              block
              type="submit"
              @click="SaveForm"
            >
              <span v-if="savingItem">Saving...</span>
              <span v-else>Save Changes</span>
            </b-button>
            <b-button
              :to="{ name: 'Warehouses.BulkShipments.AllocateShipment', params: {bulkShipmentId: itemForm.id} }"
              v-if="itemForm.id && itemForm.orderStatus == 'Open' && has('pm.warehouses.bulkShipments.confirm')"
              class="btn btn-block btn-primary"
            >
              Allocate and Ship Order
            </b-button>
            <b-button
              @click="ConfirmItemForm(itemForm)"
              v-if="itemForm.id && itemForm.orderStatus == 'Draft' && has('pm.warehouses.bulkShipments.confirm')"
              class="btn btn-block btn-success"
            >
              Approve Order
            </b-button>
            <b-button
              @click="CancelOrder(itemForm)"
              v-if="itemForm.id && itemForm.orderStatus != 'Shipped' && itemForm.orderStatus != 'Cancelled'"
              class="btn btn-block btn-danger"
            >
              Cancel Order
            </b-button>
            <b-button
              v-b-tooltip.hover
              v-if="itemForm.id && itemForm.allocationRequest && has('pm.warehouses.bulkShipments.confirm')"
              title="Download Packing List"
              variant="info"
              size="sm"
              block
              @click="DownloadPackingListPdfItem(itemForm)">
              <i class="fa fa-file-pdf-o"></i> <span>Download Packing List</span>
            </b-button>
            <router-link
              target="_blank"
              v-if="itemForm.id && has('pm.customerBilling.billingEntries')"
              :to="{
                name: 'Billing.CustomerBillingLedger.Index',
                query: { manualShipmentId: itemForm.id }
              }"
              class="btn btn-block btn-info"
            >
              Client Billing Ledger
            </router-link>
            <router-link
              :disabled="savingItem"
              class="mt-2 btn btn-block btn-outline-danger"
              :to="{ name: 'Warehouses.BulkShipments.Index' }"
              exact
            >
              <span v-if="hasBeenShipped">Back To List</span>
              <span v-else>Cancel</span>
            </router-link>
          </b-card>

          <div class="card" v-if="itemForm.id && itemForm.orderStatus == 'Shipped'">
              <div
                class="card-header c-pointer"
                v-b-toggle.shipmentInfo-options-collapse
              >
                <h4 class="card-header-title">Shipment Info</h4>

                <i class="fa fa-chevron-down"></i>
              </div>

              <div>
                <b-collapse visible id="shipmentInfo-options-collapse">
                  <div class="card-body">
                    <b-form-group label="Carrier">
                      <b-form-input
                        :value="itemForm.carrierShippedWith"
                        :disabled="true"
                      />
                    </b-form-group>
                    <b-form-group label="Service">
                      <b-form-input
                        :value="itemForm.carrierServiceShippedWith"
                        :disabled="true"
                      />
                    </b-form-group>
                    <b-form-group label="Tracking Number">
                      <b-form-input
                        :value="itemForm.carrierTrackingNumber"
                        :disabled="true"
                      />
                    </b-form-group>

                    <b-form-group label="Shipped At">

                      <b-form-input
                        v-if="itemForm.shipDateTimeUtc && !itemForm.shipDateTimeUtc.endsWith('Z')"
                        :value="(itemForm.shipDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')"
                        :disabled="true"
                      />
                      <b-form-input
                        v-else
                        :value="(itemForm.shipDateTimeUtc) | moment('MMM Do YY, h:mm a')"
                        :disabled="true"
                      />
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>
          </div>

          <b-card header="Shipping Instructions" v-if="!itemForm.id">
            <div>
              <b-form-group label="Carrier:">
                <b-v-select
                  id="form-input-shipCarrier"
                  name="form-input-shipCarrier"
                  :state="
                    validateStateScoped('itemForm', 'form-input-shipCarrier')
                  "
                  data-vv-as="Extensiv Fulfillment Carrier"
                  v-validate="{ required: false, min: 1 }"
                  v-model="itemForm.tplCarrierCode"
                  :searchable="true"
                  :options="tplCarrierList"
                />
                <b-form-invalid-feedback
                  id="form-input-shipCarrier-feedback"
                >
                  {{ veeErrors.first('form-input-shipCarrier') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Service:">
                <b-v-select
                  id="form-input-shipService"
                  name="form-input-shipService"
                  :state="
                    validateStateScoped('itemForm', 'form-input-shipService')
                  "
                  data-vv-as="Service"
                  v-validate="{ required: false }"
                  v-model="itemForm.tplServiceCode"
                  :searchable="true"
                  :options="tplShipmentServicesList"
                />
                <b-form-invalid-feedback
                  id="form-input-shipService-feedback"
                >
                  {{ veeErrors.first('form-input-shipService') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-card>

          <b-card header="Attachments" no-body class="communications" v-if="itemForm.id">
            <div class="card-body">
              <div v-if="itemForm.attachments">
                <b-media class="border-bottom mb-1 pb-1" v-for="attachmnt in itemForm.attachments" :key="attachmnt.id">
                  <template #aside>
                    <div class="bg-grey rounded bg-rounded">
                      <template v-if="attachmnt.type == 'PDF'">
                        <i class="fa fa-file-pdf-o"></i>
                      </template>
                      <template v-else-if="attachmnt.type == 'Document'">
                        <i class="fa fa-file-word-o"></i>
                      </template>
                      <template v-else-if="attachmnt.type == 'Spreadsheet'">
                        <i class="fa fa-file-excel-o"></i>
                      </template>
                      <template v-else-if="attachmnt.type == 'Zip'">
                        <i class="fa fa-zip-o"></i>
                      </template>
                      <template v-else-if="attachmnt.type == 'Image'">
                        <i class="fa fa-file-picture-o"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-file-code-o"></i>
                      </template>
                    </div>
                  </template>
                  <b class="mt-0"><a target="_blank" :href="attachmnt.url" download> {{ attachmnt.name }}</a>
                </b>
                  <p class="mb-0">
                    <span class="small">Added on: {{ fullHumanDate(
                              attachmnt.addedAtDateTimeUtc,
                              'MMMM Do YYYY, h:mm a'
                            ) }}</span></p>
                </b-media>
              </div>
              <input
                id="fileImportManualShip"
                ref="fileImportManualShip"
                type="file"
                class="d-none"
                accept=".pdf,.zip,.xls,.xlsx,.docx,.doc,.txt,.png,.jpeg,.jpg,.gif,.webm"
                name="fileImportManualShip"
                @change="ImportFileHandler()"
              />
              <label for="fileImportManualShip" class="mt-2 btn btn-block btn-outline-primary">
                <span><i class="fa fa-file-o"></i> Attach a Document</span>
              </label>
            </div>
          </b-card>
          <DiscussionsWidget v-bind="chatWidgetProps" v-if="chatWidgetProps.relatedId"></DiscussionsWidget>
        </aside>
      </form>
    </section>

  </b-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import DiscussionsWidget from '@/views/components/DiscussionsWidget';
export default {
  mixins: [crudMixinGen('bulkShipments'), myCommonDataMixin],
  components: {
    DiscussionsWidget
  },
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    chatWidgetProps: {
      startOpen: true,
      channelType: 'FeatureClientSupport',
      relatedDesc: 'Discussion for Bulk Shipment',
      relatedName: 'Bulk Shipment: ',
      relatedId: '',
      relatedType: 'BulkShipment'
    },

    showConfirmItemForm: false,
    confirmItem: null,
    heading: 'Bulk Shipments Form',
    // subheading: 'Here you create or edit your Scheduled Products.',
    // icon: 'pe-7s-graph text-success',
    tempSug: '',
    readyToLoad: false,
    loadingWareItems: false,

    //showWarehouseItemForm: false,

    // wareitemForm: {
    //   id: null,
    //   status: 'active',
    //   type: 'Stock Item',
    //   wholesalePrice: 0,
    //   factoringPercentage: 0,
    //   description: '',
    //   inventoryStatus: 0,
    //   weight: 0,
    //   brandId: '',
    //   warehouseId: '',
    //   itemUnit: 'E',
    //   item: ''
    // },

    shipmentAddressAll: [],
    wareItemsList: [],
    itemsShipForm: {
      measurementUnit: 'E',
      warehouseProductId: null,
      warehouseProductItem: null,
      quantity: 0
    },
    possibleCarrierList: [],
    possibleServiceList: [],
    shipItemsFields: [
      { key: 'sku', label: 'SKU', sortable: false },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Quantity', sortable: false },
      { key: 'reqLotNumber', label: 'Req. Lot Number', sortable: false },
      { key: 'actions' }
    ],
    shipItemsFieldsView: [
      { key: 'sku', label: 'SKU', sortable: false },
      { key: 'name', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Quantity', sortable: false }
    ]
  }),

  computed: {
    tplCarrierList: {
      get() {
        let itmsList = [];
        let myItems = this.possibleCarrierList;
          itmsList.push({ value: '', text: 'Nothing' });
        myItems
        .filter(a => a.canShipBulk)
        .forEach(itm => {
          itmsList.push({ value: itm.name, text: itm.name  +  ' (' + itm.description+ ')' });
        });
        return itmsList;
      },
      set(val) {
      }
    },
    tplShipmentServicesList: {
      get() {
        let itmsList = [];
        let myItems = this.possibleServiceList;
        itmsList.push({ value: '', text: 'Nothing' });
        if(this.itemForm.tplCarrierCode) {
          let carr = this.possibleCarrierList.find(a => a.name == this.itemForm.tplCarrierCode);
          if(carr) {
            myItems = myItems.filter(a => a.tplCarrierId == carr.id);
          } else {
            myItems = [];
          }
        } else {
            myItems = [];
        }
        myItems.forEach(itm => {
          itmsList.push({ value: itm.code, text: itm.description  +  ' (' + itm.code+ ')' });
        });
        return itmsList;
      },
      set(val) {
      }
    },
    ...mapGetters({
      countryCodeList: 'getCountryCodeList',
      USAStateCodeList: 'getUSAStateCodeList'
    }),
    ...mapState('bulkShipments', ['orderTypeList']),
    hasBeenShippedOrPrepicked: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          isTrue = true;
        }
        if (this.itemForm.shipmentAddressId != '-') {
          isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    },
    hasBeenShipped: {
      get() {
        let isTrue = false;
        if (this.itemForm.id) {
          isTrue = true;
        }
        return isTrue;
      },
      set(old, val) {}
    },
    uomList: {
      get() {
        let arrs = [
          {
            value: 'E',
            text: 'Each'
          },
          {
            value: 'BX',
            text: 'Box'
          },
          {
            value: 'FT',
            text: 'Feet'
          },
          {
            value: 'IN',
            text: 'Inches'
          },
          {
            value: 'OZ',
            text: 'Ounces'
          },
          {
            value: 'LB',
            text: 'Pounds'
          },
          {
            value: 'KG',
            text: 'Kilograms'
          },
          {
            value: 'P',
            text: 'Pallet'
          }
        ];
        return arrs;
      },
      set(old, val) {}
    },
    shipmentAddressList: {
      get() {
        let arrs = [];
        arrs.push({
          value: '-',
          text: '- Enter New Address - '
        });
        this.shipmentAddressAll.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.name + ' (' + itm.addressLine1 + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
    productsList: {
      get() {
        let arrs = [];
        this.wareItemsList.forEach(itm => {
          arrs.push({
            value: itm.item,
            quantityAvailable: itm.quantityAvail,
            text: itm.item + ' (' + itm.description + ')' + (this.itemForm.warehouseId ? ('- Qty: '+itm.quantityAvail) : '')
          });
        });
        return arrs;
      },
      set(old, val) {}
    }
  },

  watch: {
    'id': function(val) {
      this.LoadItem(this.id);
    },
    '$route.query.id': function(val) {
      this.id = val;
      this.LoadItem(this.id);
    },
    'itemForm.shipmentAddressId': function(newVal) {
      if(this.shipmentAddressAll.length >0) {
        let foundShipTo = this.shipmentAddressAll.find(a => a.id == newVal);
        if (foundShipTo) {
          this.itemForm.shipmentAddress = foundShipTo;
        } else {
          this.itemForm.shipmentAddress = {
            id: '',
            name: '',
            addressLine1: ''
          };
        }
      }
    },
    'itemForm.brandId': function(newVal, oldVal) {
      this.LoadWareProductsFor(newVal, this.itemForm.warehouseId);
      this.AutoFillNameIfEmpty();
    },
    'itemsShipForm.warehouseProductItem': {
      handler: function(newVal, oldVal) {
        this.itemForm.items.forEach(a => {
          if (a.warehouseProductItem == newVal) {
            this.RemoveItem(a);
          }
        });
        let defVal = 'E';
        if (newVal) {
          let findProd = this.wareItemsList.find(a => a.id == newVal);
          if (findProd) {
            this.itemForm.measurementUnit = findProd.itemUnit;
          }
        } else {
          this.itemForm.measurementUnit = defVal;
        }
        this.AutoFillNameIfEmpty();
      },
      deep: true
    },
    'itemForm.warehouseId': function(newVal, oldVal) {
      this.LoadWareProductsFor(this.itemForm.brandId, newVal);
      this.AutoFillNameIfEmpty();
    },
    deep: true
  },

  meta() {
    let titl = this.itemForm.orderNumber ? 'Bulk Shipment: ' +this.itemForm.orderNumber : this.heading;
      return {
          title: titl,
          // ...
      };
  },

  mounted() {
    //this.$store.dispatch('fetchMyTPLData');
    this.loadWarehouseCarriersAndServices();
    this.ResetForm();
    this.itemForm.shipmentAddress = {
      id: '',
      name: '',
      countryCode: 'US',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      state: '',
      postcode: '',
      email: '',
      city: ''
    };
    // if(this.has('pm.is_end_partner')) {
    //   this.itemForm.orderNumber = '-';
    // } else {
    //   this.itemForm.orderNumber = '';
    // }
    this.itemForm.orderNumber = '';
    this.readyToLoad = true;
    if (this.id) {
      this.LoadItem(this.id);
    }
    if(!this.has('pm.is_end_partner')) {
      this.LoadAddresses();
    }
  },

  methods: {
    loadWarehouseCarriersAndServices() {
      this.$http
        .get('/api/carrierAndServices')
        .then(resp => {
          if(resp.data.carriers) {
            this.possibleCarrierList = resp.data.carriers;
            this.possibleServiceList = resp.data.services;
          }
        })
        .catch(err => {});
    },
    DownloadPackingListPdfItem(manualShipment) {
      // if(wareShipment.id && wareShipment.realStatus != 'In Transit' && wareShipment.realStatus != 'closed') {
      //   this.$swal('Error!', 'You can only download packing list for warehouse shipments that are at least in transit.', 'error');
      //   return;
      // }
      let form= document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/pdf/manualship-packing-list/' + manualShipment.id);
      document.body.appendChild(form); // Not sure if this step is necessary
      form.submit();
    },
    formatDateAsStamp(d) {
      var month = '' + (parseInt(d.getMonth()) + 1),
        day = '' + (d.getDate() + 1),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let yearStr = (year + '').replace('20', '');
      return [month, day, yearStr].join('');
    },
    fullHumanDate(date, str) {
      return this.$moment
        .utc(date)
        .local()
        .format(str);
    },
    ImportFileHandler() {
      let $this = this;
      let reader = new FileReader();
      $this.isFileUploading = true;
      let filePicked = this.$refs.fileImportManualShip.files[0];
      reader.onload = function(e) {
        let formData = new FormData();
        formData.append('manualShipmentId', $this.itemForm.id);
        formData.append('formFile', filePicked, filePicked.name);
        //formData.append("projectId", $this.projectId);
        $this.$http
          .post('/attachToManualShip', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resp => {
            $this.isFileUploading = false;
            if (resp.data.url) {
              $this.itemForm.attachments = resp.data.attachments;
              $this.$swal('File Attached!');
            } else {
              $this.$swal('Something went wrong');
            }
          })
          .catch(err => {
            console.error(err);
            $this.isFileUploading = false;
            $this.$swal('Something went wrong');
          });
      };
      reader.readAsDataURL(filePicked);
    },
    ConfirmItemForm(item) {
      this.confirmItem = item;
      this.showConfirmItemForm = true;
    },
    MarkAsConfirmed() {
      if (!this.confirmItem || !this.confirmItem.id) {
        this.$swal({
          title: 'Error',
          text: "No Shipment Picked!",
          icon: 'error',
        })
        return
      }
      if (!this.confirmItem.warehouseId) {
        this.$swal({
          title: 'Error',
          text: "No Warehouse was Picked!",
          icon: 'error',
        })
        return
      }
      this.$swal({
        title: 'Are you sure you want to approve this bulk shipment?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve!'
      }).then(result => {
        if (result.value) {
          this.showConfirmItemForm = false;
          this.$http
            .post('/api/common/manualShipments/confirm/' + this.confirmItem.id + '/' + this.confirmItem.warehouseId + '/' + this.confirmItem.orderNumber)
            .then(resp => {
              if (resp.data.success) {
                this.confirmItem = null;
                this.$swal('Done!', 'The order was approved.', 'success');
                this.loadItems();
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
          .catch(err => {
            if (err.response && err.response.data.errorMessage) {
              this.$swal({ title: err.response.data.errorMessage, icon: 'error' });
            } else {
              this.$swal({ title: 'Something went wrong!', icon: 'error' });
            }
            console.error('wmsProducts/map err', err);
            this.isMappingProgress = false;
          });
        } else {
          this.showConfirmItemForm = false;
        }
      });
    },

    CancelOrder(item) {
        this.$http
          .post('/api/common/manualShipments/' + item.id + '/cancel')
          .then(resp => {
            this.LoadItem(this.id);
            this.$swal('Success', 'Bulk Shipment was cancelled.', 'success');
          })
          .catch(err => {
            this.$swal('Failed', 'Could not cancel.', 'error');
          });
    },
    RemoveItem(item, index) {
      if (item.id) {
        // should call API to remove it
        this.$http
          .delete('/api/common/manualShipmentItems/' + item.id)
          .then(respa => {})
          .catch(err => {});
      }
      if(index) {
        this.itemForm.items = this.itemForm.items.filter((a, k)=> k != index);
      } else {
        this.itemForm.items = this.itemForm.items.filter(
          a => a.warehouseProductId != item.warehouseProductId
        );
      }
    },

    AddItem() {
      if (
        (this.itemsShipForm.warehouseProductId || this.itemsShipForm.warehouseProductItem) &&
        parseFloat(this.itemsShipForm.quantity) > 0
      ) {
          let fndWmsItem = this.wareItemsList.find(
            a => a.item == this.itemsShipForm.warehouseProductItem
          );
        if (this.itemsShipForm.warehouseProductItem) {
          this.itemForm.items = this.itemForm.items.filter(
            a => a.item != this.itemsShipForm.warehouseProductItem
          );
        } else {
          this.itemForm.items = this.itemForm.items.filter(
            a => a.warehouseProductId != this.itemsShipForm.warehouseProductId
          );
        }
        this.itemForm.items.push({
          manualShipmentId: this.itemForm.id ? this.itemForm.id : '-',
          quantity: parseFloat(this.itemsShipForm.quantity),
          warehouseProductItem: this.itemsShipForm.warehouseProductItem,
          //warehouseProductId: fndWmsItem.id
        });
        this.itemsShipForm = {
          measurementUnit: 'E',
          warehouseProductId: null,
          quantity: 0
        };
      }
    },

    LoadItem(id) {
      if(id != 'create') {
        this.$http
          .get('/api/common/manualShipments/' + id)
          .then(resp => {
            let item = resp.data;
            this.readyToLoad = true;
            this.itemForm = item;
            this.chatWidgetProps.relatedName = 'Bulk: ' + item.orderNumber;
            this.chatWidgetProps.relatedId = item.id;
          })
          .catch(err => {});
      }
    },

    LoadWareProductsFor(brandId, warehouseId) {
      if (!this.readyToLoad) {
        return;
      }
      if (this.loadingWareItems) {
        return;
      }
      this.wareItemsList = [];
      if(warehouseId && brandId) {
        this.loadingWareItems = true;
        this.$http
          .post('/api/common/purchaseOrders/pickItems/' + brandId +'/' + warehouseId)
          .then(resp => {
            this.wareItemsList = resp.data;
            this.loadingWareItems = false;
          })
          .catch(err => {
            this.wareItemsList = [];
            this.loadingWareItems = false;
          });
      }
      else if (brandId) {
        this.loadingWareItems = true;
        this.$http
          .post('/api/common/purchaseOrders/pickItems/' + brandId)
          .then(resp => {
            this.wareItemsList = resp.data;
            this.loadingWareItems = false;
          })
          .catch(err => {
            this.wareItemsList = [];
            this.loadingWareItems = false;
          });
      }
    },

    LoadAddresses(hndl = () => {}) {
      this.$http
        .get('/api/common/manualShipments/shipmentAddress')
        .then(resp => {
          this.shipmentAddressAll = resp.data;
          hndl();
        })
        .catch(err => {});
    },

    SaveForm() {
      this.savingItem = true;

      this.$validator.validateAll('itemForm').then(result => {

        if (!result) {
          this.$swal({ title: 'Form Validation Errors!', icon: 'error' });
          this.savingItem = false;
            return;
        }
        // to uppercase customReferenceNumber
        if(this.itemForm.orderNumber)
          this.itemForm.orderNumber = this.itemForm.orderNumber.toUpperCase().replace(' ', '-');

          if (
            this.itemForm.items.length > 0 &&
            this.itemForm.brandId &&
            (this.itemForm.shipmentAddressId != '-' ||
              (this.itemForm.shipmentAddress.name &&
                this.itemForm.shipmentAddress.addressLine1))
          ) {
            this.$http
              .post(
                '/api/common/manualShipments/createBulkShipment',
                this.itemForm
              )
              .then(resp => {
                if (resp.data.success) {
                  //this.$swal({ title: 'Item Was Saved.', icon: 'success' });
                  this.$router.push({
                    name: 'Warehouses.BulkShipments.View',
                    params: { id: resp.data.item.id }
                  });
                } else if (resp.data.errorMessage) {
                  this.$swal({ title: resp.data.errorMessage, icon: 'error' });
                } else {
                  this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
                }
                this.ResetForm();
                this.savingItem = false;
              })
              .catch(err => {
                this.savingItem = false;
                if (err.response.data && err.response.data.errorMessage) {
                  this.$swal({
                    title: err.response.data.errorMessage,
                    icon: 'error'
                  });
                } else {
                  this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
                }
                console.error('saved po err', err);
              });
          } else {
            this.$swal({ title: 'Fill out required fields!', icon: 'error' });
            this.savingItem = false;
          }
      });
    },

    AutoFillNameIfEmpty() {
      if (this.tempSug == this.itemForm.orderNumber) {
        this.AutoFillName();
      }
    },

    AutoFillName() {
      let posOrdName = '';
      let brandCode = '';
      let warehouse = this.myWarehouses.find(
        a => a.id == this.itemForm.warehouseId
      );
      let brand = this.myBrands.find(
        a => a.id == this.itemForm.brandId
      );
      if (brand) {
        brandCode = brand.code;
      }
      if (warehouse) {
        posOrdName += 'BULKSHIP-' + (brandCode != '' ?  brandCode + '-' : '') + this.formatDateAsStamp(new Date());
      }
      this.tempSug = posOrdName;
      this.itemForm.orderNumber = posOrdName;
    },

    // GetItemUOM(prodId) {
    //   let nam = '';
    //   let prodFound = this.wareItemsList.find(a => a.id == prodId);
    //   if (prodFound) {
    //     let uomCode = prodFound.itemUnit;
    //     let uomItem = this.uomList.find(a => a.value == uomCode);
    //     if (uomItem) {
    //       nam = uomItem.text;
    //     }
    //   }
    //   return nam;
    // },

    // GetItemSKU(prodId) {
    //   let nam = '-';
    //   let prodFound = this.wareItemsList.find(a => a.id == prodId);
    //   if (prodFound) {
    //     nam = prodFound.item;
    //   }
    //   return nam;
    // },

    GetItemName(prodId) {
      let nam = '-';
      let prodFound = this.wareItemsList.find(a => a.item == prodId);
      if (prodFound) {
        nam = prodFound.description;
      }
      return nam;
    }
  }
};
</script>
