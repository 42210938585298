import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { mapMutations, mapState, mapGetters } from 'vuex';

export const myCommonDataMixin = {
  computed: {
    ...mapGetters({
      myProjects: 'getMyProjects',
      myStores: 'getMyStores',
      myBrands: 'getMyBrands',
      myVendors: 'getMyVendors',
      myContentTemplates: 'getMyContentTemplates',
      myWarehouses: 'getMyWarehouses',
      myTPL: 'getMyTPL',
      projectsList: 'getProjectsList',
      vendorsList: 'getVendorsList',
      brandsList: 'getBrandsList',
      warehousesListAll: 'getWarehousesListAll',
      warehousesList: 'getWarehousesList'
    }),
    projectsFilter: {
      get() {
        let arrs = [{ value: '', text: 'All' }];
        arrs = arrs.concat(this.projectsList);
        return arrs;
      },
      set(old, val) {}
    },
    warehousesFilter: {
      get() {
        let arrs = [{ value: '', text: 'All' }, ...this.warehousesListAll];
        return arrs;
      },
      set(old, val) {}
    },
    brandsFilter: {
      get() {
        let arrs = [{ value: '', text: 'All' }, ...this.brandsList];
        return arrs;
      },
      set(old, val) {}
    },
    vendorsFilter: {
      get() {
        let arrs = [{ value: '', text: 'All' }, ...this.vendorsList];
        return arrs;
      },
      set(old, val) {}
    }
  },
  methods: {
    has(permission) {
      return this.$store.getters['account/hasUserPermission'](permission);
    },
    hasAny(permissionLike) {
      return this.$store.getters['account/hasUserAnyPermissionLike'](permissionLike);
    },
    hasAnyExceptView(permissionLike) {
      return this.$store.getters['account/hasUserAnyPermissionLikeExceptView'](permissionLike);
    },
    is(role) {
      return this.$store.getters['account/hasUserRole'](role);
    },
  }
};

export const ValidationMixin = {
  methods: {
    validateStateScoped: function(scope, ref) {
      if (this.veeFields['$' + scope]) {
        if (
          this.veeFields['$' + scope][ref] &&
          (this.veeFields['$' + scope][ref].dirty ||
            this.veeFields['$' + scope][ref].validated)
        ) {
          return !this.veeErrors.has(ref, scope);
        }
      }
      return null;
    },
    validateState: function(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};

export let paginationMixinOpts = {
  // ToDo: implement page mix opts
  mapPagination: true,
  mapTableOpts: true,
  mapItemsList: true
};

export const paginationMixinGen = (storeModule, opts = {}) => {
  opts = { ...paginationMixinOpts, ...opts };
  let mixin = {
    computed: {
      ...mapStoreGetSet(storeModule, {
        pagination: ['getPagination', 'setPagination'],
        items: ['getItems', 'setItems'],
        itemsFilters: ['getFilters', 'setFilters'],
        formFilters: ['getFormFilters', 'setFormFilters']
      }),
      ...mapState(storeModule, ['failedLoadItems','loadingItems', 'tableOptions'])
    },
    methods: {
      ...mapMutations(storeModule, {
        setFormFilters: 'setFormFilters',
        setFilters: 'setFilters',
        resetFilterWithKey: 'resetFilterWithKey',
        resetFormFilters: 'resetFormFilters',
        resetFilters: 'resetFilters'
      }),
      ...ValidationMixin.methods
    }
  };
  return mixin;
};

export let crudMixinOpts = {
  ...paginationMixinOpts,
  mapPagination: true,
  mapLoadItems: true,
  mapLoadItem: true,
  mapSaveItem: true,
  mapDeleteItem: true,
  mapAddItem: true,
  mapResetItem: true,
  mapEditItem: true
};

export const crudMixinGen = (storeModule, opts = {}) => {
  //const cancelItemsToken = axios.CancelToken.source();
  opts = { ...crudMixinOpts, ...opts };
  let mixin = {
    meta() {
      let titl = (this.$route && this.$route.meta && this.$route.meta.label) ? this.$route.meta.label : 'LaCore';
        return {
            title: titl,
            // ...
        };
    },
    ...paginationMixinGen(storeModule)
  };
  let mixinData= { };
    mixin.computed = {
    ...mixin.computed,
    ...mapStoreGetSet(storeModule, {
      cancelItemsTokens: ['getCancelTokens', 'setCancelTokens'],
      savingItem: ['getSavingItem', 'setSavingItem'],
      itemForm: ['getItemForm', 'setItemForm'],
      showFilters: ['isFormFiltersShown', 'setShowFilters'],
      showItemForm: ['isFormShown', 'setFormShown']
    })
    // ...mapState(storeModule, ['showItemForm', 'itemForm'])
  };
  mixin.methods['applyFilters'] = function () {
    this.$store.commit(storeModule + '/applyFilters');
  }

  if (opts.mapLoadItems) {

    mixin.methods['loadItems'] = function() {
      return new Promise((resolve, reject) => {
      //if (this.loadingItems) {
        if (!this.cancelItemsTokens) {
          this.cancelItemsTokens = [];
        }
        if (this.cancelItemsTokens.length > 0) {
            this.cancelItemsTokens.forEach((cnc) => {
            if (cnc) {
              cnc.cancel('Loading different items list now!');
              cnc = false;
            }
          })
        } else if (this.loadingItems){
          return;
        }
      //}
      const newCancelItemsToken = window.$http.CancelToken.source();
      //this.cancelItemsTokens.push(newCancelItemsToken);
      this.$store.commit(storeModule + '/setNewCancelToken', newCancelItemsToken);
      this.$store
        .dispatch(storeModule + '/fetchItems', { saveToStore: true, cancelToken: newCancelItemsToken.token})
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        });
      });
    };
  }
  if (opts.mapSaveItem) {
    mixin.methods['SaveItem'] = function(validationScope = null, item = false) {
      return new Promise((resolve, reject) => {
        let itemToSave = item ? item : this.itemForm;
        let validCheck = null;
        if (validationScope) {
          validCheck = this.$validator.validateAll(validationScope);
        } else {
          validCheck = this.$validator.validateAll();
        }
        validCheck.then(result => {
          if (!result || !itemToSave) {
            reject('Form Validation errors!');
            return;
          }
          this.$store
            .dispatch(storeModule + '/saveItem', itemToSave, false)
            .then(resp => {
              this.showItemForm = false;
              this.itemForm = Object.assign({}, resp.data);
              resolve(resp);
            })
            .catch(err => {
              this.$swal('Error!', 'Form was Not Saved!', 'error');
              reject(err);
            });
        });
      });
    };
  }

  if (opts.mapDeleteItem) {
    mixin.methods['DeleteItem'] = function(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: 'Are you REALLY sure?',
            text: 'This will delete it for good!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure!'
          }).then(result => {
            if (result.value) {
              this.$store
                .dispatch(storeModule + '/deleteItem', id, false)
                .then(resp => {
                  this.$swal('Deleted!', 'Item has been deleted.', 'success');
                })
                .catch(err => {});
            }
          });
        }
      });
    };
  }
  if (opts.mapLoadItem) {
    mixin.methods['LoadItem'] = function(id) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(storeModule + '/getItemById', id, false)
          .then(resp => {
            this.itemForm = Object.assign({}, resp.data);
            resolve(resp);
          })
          .catch(err => {reject(err)});
      });
    };
  }
  if (opts.mapResetItem) {
    mixin.methods['ResetForm'] = function() {
      this.$store.commit(storeModule + '/resetForm');
    };
  }

  if (opts.mapAddItem) {
    mixin.methods['AddItem'] = function() {
      this.ResetForm();
      this.showItemForm = true;
    };
  }

  if (opts.mapEditItem) {
    mixin.methods['EditItem'] = function(item) {
      this.itemForm = Object.assign(this.itemForm, item);
      this.showItemForm = true;
    };
  }
  return mixin;
};

export default {
  ValidationMixin,

  crudMixinOpts,
  crudMixinGen,

  myCommonDataMixin: myCommonDataMixin,

  paginationMixinOpts,
  paginationMixinGen
};
