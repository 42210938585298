import store from '../../store';
import { pagination, tableOptions } from './filters.js';
export const mapStoreGetSet = (module, items) => {
  let mapped = {};
  Object.entries(items).forEach(entry => {
    const [key, el] = entry;
    let newMapped = {};
    newMapped[key] = {
      get() {
        return store.getters[module + '/' + el[0]];
      },
      set(val) {
        if (el.length > 1) store.commit(module + '/' + el[1], val);
      }
    };
    mapped = {
      ...newMapped,
      ...mapped
    };
  });
  return mapped;
};
export const mapCrudStoreGen = (defaultForm, defaultFilters, apiHelpers) => {
  let storeGen = {};
  storeGen.state = {
    showItemForm: false,
    showFilters: false, // used if form in modal dialog or sidebar

    CancelTokens: [],
    savingItem: false,
    loadingItems: false,
    failedLoadItems: false,
    itemForm: {
      ...JSON.parse(JSON.stringify(defaultForm)),
    },
    items: [],
    pagination: {
      ...pagination
    },
    tableOptions: {
      ...tableOptions,
      loadedAll: false
    },
    filters: {
      ...defaultFilters
    },
    formFilters: {
      ...defaultFilters
    }
  };
  storeGen.mutations = {
    setShowFilters(state, payload) {
      state.showFilters = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setFormFilters(state, payload) {
      state.formFilters = Object.assign({}, payload);
    },
    setFilters(state, payload) {
      state.pagination.page = 1;
      state.filters = Object.assign({}, payload);
    },
    applyFilters(state) {
      state.filters = Object.assign({}, state.formFilters);
    },
    resetFilters(state) {
      state.filters = Object.assign({}, defaultFilters);
      state.formFilters = Object.assign({}, defaultFilters);
    },
    resetFilterWithKey(state, key) {
      if(defaultFilters[key]) {
        state.formFilters[key] = defaultFilters[key];
        state.filters = {
          ...state.formFilters
        };
      } else {
        state.formFilters[key] = null;
        state.filters = {
          ...state.formFilters
        };
      }
    },
    resetFormFilters(state) {
      state.formFilters = Object.assign({}, defaultFilters);
    },
    setItems(state, items) {
      state.items = items;
    },
    setSavingItem(state, boolFlag) {
      state.savingItem = boolFlag;
    },
    setFormShown(state, toShowFlag) {
      state.showItemForm = toShowFlag;
    },
    setItemForm(state, itemForm) {
      state.itemForm = Object.assign({}, itemForm);
    },
    resetForm(state) {
      state.itemForm = Object.assign({}, defaultForm);
    },
    setCancelTokens(state, payload) {
      state.CancelTokens = payload;
    },
    setNewCancelToken(state, payload) {
      state.CancelTokens.push(payload);
    },
  };
  storeGen.getters = {
    getCancelTokens: state => {
      return state.CancelTokens;
    },
    isFormFiltersShown: state => {
      return state.showFilters;
    },
    isFormShown: state => {
      return state.showItemForm;
    },
    getSavingItem: state => {
      return state.savingItem;
    },
    getItemForm: state => {
      return state.itemForm;
    },
    getPagination: state => {
      return state.pagination;
    },
    getItems: state => {
      return state.items;
    },
    getFilters: state => {
      return state.filters;
    },
    getFormFilters: state => {
      return state.formFilters;
    }
  };

  storeGen.actions = {
    fetchItems(
      { state, commit, rootState },
      obj = { saveToStore : true, cancelToken: false }
    ) {
      let cancelToken = obj.cancelToken;
      let saveToStore = obj.saveToStore;
      return new Promise((resolve, reject) => {
        state.loadingItems = true;
        apiHelpers
          .fetchItems(state.filters, state.pagination, cancelToken)
          .then(resp => {
            if(resp && resp.data)  {
              if (saveToStore) {
                state.tableOptions.totalRows = resp.data.total;
                commit('setItems', resp.data.items);
              }
              state.loadingItems = false;
              if(state.failedLoadItems)
                state.failedLoadItems = false;
              resolve(resp);
            } else {
              state.failedLoadItems = true;
              state.loadingItems = false;
              state.tableOptions.totalRows = 0;
              commit('setItems', []);
            }
          })
          .catch(err => {
            state.failedLoadItems = true;
            state.loadingItems = false;
            reject(err);
          });
      });
    },

    saveItem({ state, commit, rootState }, item, saveToStore = true) {
      state.savingItem = true;
      return new Promise((resolve, reject) => {
        let apiAction = null;
        if (item.id) {
          apiAction = apiHelpers.editItem(item);
        } else {
          apiAction = apiHelpers.addItem(item);
        }
        apiAction
          .then(resp => {
            if (saveToStore) {
              let filteredItems = state.items.filter(a => a.id != resp.data.id);
              let itemChanged = resp.data;
              filteredItems.push(itemChanged);
              commit('setItems', filteredItems);
            }
            state.savingItem = false;
            resolve(resp);
          })
          .catch(err => {
            state.savingItem = false;
            reject(err);
          });
      });
    },

    getItemById({ state, commit, rootState }, id) {
      return new Promise((resolve, reject) => {
        apiHelpers
          .getItemById(id)
          .then(resp => {
            state.tableOptions.totalRows--;
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteItem({ state, commit, rootState }, id) {
      return new Promise((resolve, reject) => {
        apiHelpers
          .deleteItem(id)
          .then(resp => {
            state.tableOptions.totalRows--;
            let filteredItems = state.items.filter(a => a.id != id);
            commit('setItems', filteredItems);
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  };

  return storeGen;
};
