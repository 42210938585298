<template>
  <b-row>
    <b-col cols="12" sm="9" v-if="itemForm">
      <b-card class="mb-1" :header="heading" no-body>
        <div class="card-body">
          <b-row>

            <b-col cols="6">
              <b-form-group label="Order Number">
                <b-input-group class="mb-1">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: true }"
                    id="form-input-orderNumber"
                    name="form-input-orderNumber"
                    v-model="itemForm.orderNumber"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-orderNumber-feedback"
                >
                  {{ veeErrors.first('form-input-orderNumber') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Client:">
                <b-v-select
                  disabled="disabled"
                  id="form-input-brandId"
                  name="form-input-brandId"
                  v-validate="{ required: true }"
                  :state="validateState('form-input-brandId')"
                  v-model="itemForm.brandId"
                  :searchable="true"
                  :options="brandsList"
                />
                <b-form-invalid-feedback id="form-input-brandId-feedback">
                  {{ veeErrors.first('form-input-brandId') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Vendor:">
                <b-v-select
                  disabled="disabled"
                  id="form-input-vendorId"
                  name="form-input-vendorId"
                  v-validate="{ required: true }"
                  :state="validateState('form-input-vendorId')"
                  v-model="itemForm.vendorId"
                  :searchable="true"
                  :options="vendorsList"
                />
                <b-form-invalid-feedback id="form-input-vendorId-feedback">
                  {{ veeErrors.first('form-input-vendorId') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Requested At">
                <b-input-group class="mb-1">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: true }"
                    id="form-input-createDateTimeUtc"
                    name="form-input-createDateTimeUtc"
                    :value="fullHumanDate(
                          itemForm.createDateTimeUtc,
                          'MMMM Do YYYY, h:mm a'
                        )"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-createDateTimeUtc-feedback"
                >
                  {{ veeErrors.first('form-input-createDateTimeUtc') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Expected Date">
                <b-input-group class="mb-1">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: true }"
                    id="form-input-expectedDate"
                    name="form-input-expectedDate"
                    :value="fullHumanDate(
                          itemForm.expectedDate,
                          'MMMM Do YYYY'
                        )"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-expectedDate-feedback"
                >
                  {{ veeErrors.first('form-input-expectedDate') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Requested By">
                <b-input-group class="mb-1">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: false }"
                    id="form-input-warehouseShipmentFee"
                    name="form-input-warehouseShipmentFee"
                    :value="(itemForm.byUserInfo ? itemForm.byUserInfo.fullName : '')"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-warehouseShipmentFee-feedback"
                >
                  {{ veeErrors.first('form-input-warehouseShipmentFee') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Shippping Fee">
                <b-input-group class="mb-1" prepend="$">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: false }"
                    id="form-input-shippingFee"
                    name="form-input-shippingFee"
                    v-model="itemForm.shippingFee"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-shippingFee-feedback"
                >
                  {{ veeErrors.first('form-input-shippingFee') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Other Fees">
                <b-input-group class="mb-1" prepend="$">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: false }"
                    id="form-input-warehouseShipmentFee"
                    name="form-input-warehouseShipmentFee"
                    v-model="itemForm.otherFees"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-warehouseShipmentFee-feedback"
                >
                  {{ veeErrors.first('form-input-warehouseShipmentFee') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Total">
                <b-input-group class="mb-1" prepend="$">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: false }"
                    id="form-input-total"
                    name="form-input-total"
                    v-model="itemForm.total"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-total-feedback"
                >
                  {{ veeErrors.first('form-input-total') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="External Ref Num">
                <b-input-group class="mb-1">
                  <b-form-input
                    :disabled="true"
                    v-validate="{ required: false }"
                    id="form-input-externalRefNum"
                    name="form-input-externalRefNum"
                    v-model="itemForm.externalRefNum"
                    type="text"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="form-input-externalRefNum-feedback"
                >
                  {{ veeErrors.first('form-input-externalRefNum') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card v-if="itemForm.id" no-body class="mt-3">
        <template v-slot:header>
          <b>
            Requested Items
          </b>
        </template>
        <b-table
          :striped="true"
          :bordered="true"
          :outlined="true"
          :small="false"
          :hover="false"
          :dark="false"
          :items="itemForm.items"
          head-variant="dark"
          :fields="itemfields"
        >
          <template v-slot:cell(purchaseOrderItemId)="data">
            <div class="border-bottom">
              {{
                data.item.warehouseProduct
                  ? data.item.warehouseProduct.item
                  : '-'
              }}
            </div>
            <div>
              {{
                data.item.warehouseProduct
                  ? data.item.warehouseProduct.description
                  : '-'
              }}
            </div>
          </template>
          <template v-slot:cell(warehouseProductItem)="data">
            <div class="border-bottom">{{ (data.item.warehouseProductItem) }}</div>
            <div>{{ data.item.warehouseProductDescription }}</div>
          </template>
          <template v-slot:cell(quantity)="data">
            <span>{{ parseFloat(data.item.quantity) }}</span>
          </template>
          <template v-slot:cell(quantityTotalShipped)="data">
            <span>{{ parseFloat(data.item.quantityTotalShipped) }}</span>
          </template>
          <template v-slot:cell(quantityTotalReceived)="data">
            <span>{{ parseFloat(data.item.quantityTotalReceived) }}</span>
          </template>
          <template v-slot:cell(quantityLeft)="data">
            <span
              v-if="data.item.quantityTotalShipped <= data.item.quantity"
              v-b-tooltip.hover
              title="How many still need to be shipped."
            >
              {{
                parseFloat(
                  parseFloat(data.item.quantity) -
                    parseFloat(data.item.quantityTotalShipped)
                ).toFixed(2)
              }}
              <i class="fa fa-info-circle"></i>
            </span>
            <span
              v-else
              v-b-tooltip.hover
              title="How many still need to shipped."
              >0
              <i class="fa fa-info-circle"></i>
            </span>
          </template>

          <template v-slot:cell(itemCost)="data">
            <span>$ {{ parseFloat(data.item.itemCost) }}</span>
          </template>
          <template v-slot:cell(costTotal)="data">
            <span
              >$
              {{
                parseFloat(data.item.quantity) *
                  parseFloat(data.item.itemCost)
              }}</span
            >
          </template>
        </b-table>
      </b-card>
      <div v-if="has('pm.warehouses.purchaseOrders.workOrders.view')">
        <b-card  no-body>
        <template v-slot:header>
          <b>
            Manufacturing
          </b>
        </template>
          <b-table

          :striped="true"
              :bordered="true"
              :outlined="true"
              :small="false"
              :hover="false"
              :dark="false"
              :responsive="true"
              :items="workOrderItems"
              head-variant="dark"
              :fields="workOrderFields"
          >
            <template v-slot:cell(id)="data">
              <div class="d-flex"  v-if="data.item.type != 3 && data.item.type != 4">
                <div class="d-inline-block">
                  <b-dropdown
                    id="dropdown-right"
                    right
                    boundary="viewport"
                    text="Change"
                    variant="primary"
                    class="mr-2"
                  >
                    <b-dropdown-item
                      v-if="data.item.type != 2"
                      class="icon-dropitem"
                      @click="ChangeStatusWith(2, data.item)"
                    >
                      <i class="fa fa-industry mr-2 text-primary" />
                      <span>Mark as Producing</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="icon-dropitem"
                      @click="ChangeStatusWith(3, data.item)"
                    >
                      <i class="fa fa-check-circle-o mr-2 text-success" /><span
                        >Mark as Complete</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="icon-dropitem"
                      @click="ChangeStatusWith(4, data.item)"
                    >
                      <i class="fa fa-times-circle-o mr-2 text-danger" /><span
                        >Mark as Cancelled</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div v-else>
                <div class="border-bottom">
                  <b v-if="data.item.type == 3">Produced At:</b>
                  <b v-else-if="data.item.type == 4">Cancelled At:</b>
                </div>
                <div>
                    <span
                      v-if="
                        data.item.completedDateTimeUtc &&
                          !data.item.completedDateTimeUtc.endsWith('Z')
                      "
                      >{{
                        (data.item.completedDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')
                      }}</span
                    >
                    <span v-else-if="data.item.completedDateTimeUtc">{{
                      data.item.completedDateTimeUtc | moment('MMM Do YY, h:mm a')
                    }}</span>
                    <span v-else-if="data.item.expectedEndDate">{{
                      data.item.expectedEndDate | moment('MMM Do YY, h:mm a')
                    }}</span>
                    <span v-else>{{
                      data.item.modifiedDateTimeUtc | moment('MMM Do YY, h:mm a')
                    }}</span>
                </div>
              </div>
            </template>

            <template v-slot:cell(type)="data">
              <template v-if="data.item.type == 0">
                <label class="badge badge-info">Open</label>
              </template>
              <template v-else-if="data.item.type == 1">
                <label class="badge badge-secondary"
                  ><i class="fa fa-hourglass mr-1" /> Scheduled</label
                >
              </template>
              <template v-else-if="data.item.type == 2">
                <label class="badge badge-warning"
                  ><i class="fa fa-industry mr-1" /> Producing</label
                >
              </template>
              <template v-else-if="data.item.type == 3">
                <label class="badge badge-success"
                  ><i class="fa fa-check-circle-o mr-1" /> Completed</label
                >
              </template>
              <template v-else-if="data.item.type == 4">
                <label class="badge badge-danger"
                  ><i class="fa fa-times-circle-o mr-1" /> Cancelled
                </label>
              </template>
            </template>
            <template v-slot:cell(purchaseOrderItemId)="data">
            {{ GetForManufacturingItemName(data.item.purchaseOrderItemId) }}
            </template>
            <template v-slot:cell(warehouseId)="data">
            <div v-if="myWarehouses">
                <span
                  v-if="myWarehouses && myWarehouses.find(a => a.id == data.item.warehouseId)"
                  >{{ myWarehouses.find(a => a.id == data.item.warehouseId).name }}</span
                >
                <span v-else>N / A</span>
              </div>
            </template>
            <template v-slot:cell(createDateTimeUtc)="data">
              <span
                v-if="
                  data.item.createDateTimeUtc &&
                    !data.item.createDateTimeUtc.endsWith('Z')
                "
                >{{
                  (data.item.createDateTimeUtc + 'Z') | moment('MMM Do YY, h:mm a')
                }}</span
              >
              <span v-else-if="data.item.createDateTimeUtc">{{
                data.item.createDateTimeUtc | moment('MMM Do YY, h:mm a')
              }}</span>
            </template>
            <template v-slot:cell(expectedEndDate)="data">
              <span
                v-if="
                  data.item.expectedEndDate &&
                    !data.item.expectedEndDate.endsWith('Z')
                "
                >{{
                  (data.item.expectedEndDate + 'Z') | moment('MMM Do YY, h:mm a')
                }}</span
              >
              <span v-else-if="data.item.expectedEndDate">{{
                data.item.expectedEndDate | moment('MMM Do YY, h:mm a')
              }}</span>
            </template>
          </b-table>
        </b-card>
      </div>

      <div v-if="itemForm.shippings">
        <h2 class="mt-3 pb-2 mb-2"><b><i class="fa fa-tags mr-2"></i>Shipments</b></h2>
        <b-card
          v-for="warehouseShipment in itemForm.shippings.filter(a => a.items)"
          :key="warehouseShipment.id"
          no-body
        >
          <b-card-header header-tag="div" class="w-100">
            <div class="col-12 w-100 px-0">
              <div class="form-row pb-1 border-bottom">
                <div class="col p-0">
                  <div
                    :id="'shipnum' + warehouseShipment.id"
                    class="d-inline-block mr-1"
                    v-b-tooltip.hover
                    :title="warehouseShipment.name"
                    ><i class="fa fa-tag text-primary"
                  /></div>
                  <div class="d-inline-block">
                    <span class="px-2 border border-info">{{ (warehouseShipment.customReferenceNumber ? warehouseShipment.customReferenceNumber : (itemForm.orderNumber + '-' + warehouseShipment.shipOrderId)) }}</span>
                    <b-button variant="primary" size="sm" class="ml-2" :to="{name: (warehouseShipment.type == 1 ? 'Warehouses.WarehouseTransfers.View' : 'Warehouses.AdvancedShipNotice.View'), params: { id: warehouseShipment.id } }">View <i class="fa fa-angle-right"></i></b-button>
                  </div>
                  <div
                    v-if="warehouseShipment.notes"
                    :id="'notest' + warehouseShipment.id"
                    class="d-inline-block ml-2"
                    ><i class="fa fa-info-circle text-warning"
                  /></div>
                  <b-popover
                    v-if="warehouseShipment.notes"
                    class="popovermappings"
                    boundary="window"
                    :target="'notest' + warehouseShipment.id"
                    triggers="hover"
                    placement="right"
                  >
                    <template v-slot:title>
                      Notes:
                    </template>
                    <div>
                      {{ warehouseShipment.notes }}
                    </div>
                  </b-popover>
                </div>
                <div class="col-auto ml-auto p-0 d-flex">
                  <div
                    v-if="warehouseShipment.hasInIRMS && hasBeenShipped"
                    class="pr-2 border-right mr-2"
                  >
                    <button
                      class="btn btn-sm btn-primary"
                      @click="
                        RefreshPoItem(
                          itemForm.orderNumber + '-' + warehouseShipment.shipOrderId
                        )
                      "
                    >
                      Refresh
                    </button>
                  </div>
                  <div class="pr-2 border-right mr-2">
                    <span
                      v-if="warehouseShipment.syncedWithWarehouse"
                      class="badge badge-success"
                      v-b-tooltip.hover
                      title="Synced with Warehouse."
                    >
                      <i class="fa fa-refresh"></i>
                    </span>
                    <div v-else>
                      <span
                        v-if="warehouseShipment.syncFailed"
                        class="badge badge-warning mr-2"
                        v-b-tooltip.hover
                        title="WMS Sync in Progress."
                      >
                        <i class="fa fa-refresh"></i>
                      </span>

                      <span
                        v-if="warehouseShipment.syncFailed"
                        class="badge badge-danger"
                        v-b-tooltip.hover
                        title="PO Sync Failed with Warehouse. Manager notified. Please Wait."
                      >
                        <i class="fa fa-arrow-circle-down"></i>
                      </span>
                      <span
                        v-if="warehouseShipment.syncFailedCo"
                        class="badge badge-danger"
                        v-b-tooltip.hover
                        title="CO Sync Failed with Warehouse. Manager notified. Please Wait."
                      >
                        <i class="fa fa-arrow-circle-up"></i>
                      </span>
                    </div>
                  </div>
                  <div class="pr-2 border-right mr-2">
                    <b>Status: </b>
                    <u>
                      <template v-if="warehouseShipment.realStatus == 'Open'">
                        <label class="badge badge-info"
                          ><i class="fa fa-check" /> Open</label
                        >
                        <span
                          v-b-tooltip.hover
                          title="ASN"
                        >
                          <i class="fa fa-info-circle ml-1"></i>
                        </span>
                      </template>
                      <template v-else-if="warehouseShipment.realStatus == 'In Transit'">
                        <label class="badge badge-secondary"
                          ><i class="fa fa-check" /> In Transit</label
                        >
                      </template>
                      <template v-else-if="warehouseShipment.realStatus == 'cancelled'">
                        <label class="badge badge-danger"
                          ><i class="fa fa-check" /> Cancelled</label
                        >
                      </template>
                      <template v-else-if="warehouseShipment.realStatus == 'closed'">
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> Received</label
                        >
                      </template>
                      <template v-else>
                        <label class="badge badge-success badge-green"
                          ><i class="fa fa-check" /> {{warehouseShipment.realStatus}}</label
                        >
                      </template>
                    </u>
                  </div>
                  <div
                    class="pr-1"
                    v-if="warehouseShipment.packingListId"
                  >
                    <b-button
                      v-b-tooltip.hover
                      title="Download Packing List"
                      variant="info"
                      size="sm"
                      @click="
                        DownloadPackingListPdfItem(warehouseShipment)
                      "
                      ><i class="fa fa-file-pdf-o"></i
                    ></b-button>
                  </div>
                </div>
              </div>

              <div class="form-row pt-2 pb-1 border-bottom">
                <div class="col border-right" v-if="warehouseShipment.fromWarehouseId">
                  <b>From Warehouse: </b>
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == warehouseShipment.fromWarehouseId)"
                    >{{ myWarehouses.find(a => a.id == warehouseShipment.fromWarehouseId).name }}</span
                  >
                  <span v-else>N / A</span>
                </div>
                <div class="col border-right">
                  <b>To Warehouse: </b>
                  <span
                    v-if="myWarehouses && myWarehouses.find(a => a.id == warehouseShipment.toWarehouseId)"
                    >{{ myWarehouses.find(a => a.id == warehouseShipment.toWarehouseId).name }}</span
                  >
                  <span v-else>N / A</span>
                </div>
                <div class="col">
                  <b>By User </b>
                  <span
                    v-if="warehouseShipment.byUserInfo"
                    >{{ warehouseShipment.byUserInfo.fullName }}</span
                  >
                  <span v-else>-</span>
                </div>
              </div>
              <div class="form-row pt-2">
                <div class="col  border-right">
                  <b>Created At: </b>
                  <span>
                    {{
                      '(' +
                        fullHumanDate(
                          warehouseShipment.createDateTimeUtc,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                </div>
                <div class="col  border-right">
                  <b>Received At: </b>
                  <span v-if="warehouseShipment.receivedAtDateTimeUtc">
                    {{
                      '(' +
                        fullHumanDate(
                          warehouseShipment.receivedAtDateTimeUtc,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="col  border-right">
                  <b>Expected At: </b>
                  <span v-if="warehouseShipment.expectedDate">
                    {{
                      '(' +
                        fullHumanDate(
                          warehouseShipment.expectedDate,
                          'MMMM Do, h:mm a'
                        ) +
                        ')'
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div class="col  border-right">
                  <b>Vendor: </b>
                  <span v-if="warehouseShipment.vendorId">
                    <span
                          v-if="myVendors && myVendors.find(a => a.id == warehouseShipment.vendorId)"
                          >{{ myVendors.find(a => a.id == warehouseShipment.vendorId).name }}</span
                        >
                        <span v-else>-</span>
                    </span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-card-body class="text-left p-0">
            <b-table
              v-if="
                warehouseShipment.items &&
                  warehouseShipment.items.filter(a => a.quantityShipped > 0).length >
                    0
              "
              :striped="true"
              :bordered="true"
              :outlined="true"
              :small="false"
              :hover="false"
              :dark="false"
              :responsive="true"
              :items="OrderedByLPN(warehouseShipment.items.filter(a => a.quantityShipped > 0))"
              head-variant="light"
              :fields="shipmentItemfields"
            >
              <template v-slot:cell(palletNum)="data">
                <div class="text-center">
                  <span class="d-inline-block" :style="'margin-bottom: -2px; margin-right: 2px; border-radius: 4px; border: 1px solid transparent; width: 15px; height: 15px; background-color:'+data.item.palletColor"></span>
                  <span>{{data.item.palletNum}}</span>
                </div>
              </template>
              <template v-slot:cell(warehouseProductId)="data">
                <div class="border-bottom">
                  <span>{{
                    data.item.warehouseProduct
                      ? data.item.warehouseProduct.item
                      : '-'
                  }}</span>
                </div>
                <div>
                  <span>{{
                    (data.item.warehouseProduct
                      ? data.item.warehouseProduct.description
                      : '-') | truncate(30)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(realStatus)="data">
                <u>
                  <template v-if="data.item.realStatus == 'Open'">
                    <label class="badge badge-info"
                      ><i class="fa fa-check" /> Open</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'In Transit'">
                    <label class="badge badge-secondary"
                      ><i class="fa fa-check" /> In Transit</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'cancelled'">
                    <label class="badge badge-danger"
                      ><i class="fa fa-check" /> Cancelled</label
                    >
                  </template>
                  <template v-else-if="data.item.realStatus == 'closed'">
                    <label class="badge badge-success badge-green"
                      ><i class="fa fa-check" /> Received</label
                    >
                  </template>
                  <template v-else>
                    <label class="badge badge-success badge-green"
                      ><i class="fa fa-check" /> {{data.item.realStatus}}</label
                    >
                  </template>
                </u>
              </template>
              <template v-slot:cell(quantityShipped)="data">
                <div>
                  {{ parseFloat(data.item.quantityShipped) }}
                </div>
              </template>
              <template v-slot:cell(quantityReceived)="data">
                <div>{{ parseFloat(data.item.quantityReceived) }}</div>
              </template>
              <template v-slot:cell(quantityDamaged)="data">
                <div>{{ parseFloat(data.item.quantityDamaged) }}</div>
              </template>
              <template v-slot:cell(expiresDate)="data">
                <div>
                 <span v-if="data.item.expiresDate"> {{ data.item.expiresDate | moment('MMMM Do YYYY') }}</span>
                 <span v-else>-</span>
                 <button @click="data.toggleDetails" class="badge badge-primary"><i class="fa fa-plus"></i></button>
                </div>
              </template>

              <template #row-details="data">
                <b-card>
                  <b-row class="mb-0">
                    <b-col sm="4" class="border-right">
                       <b-row class="mb-2">
                        <b-col sm="6" class="text-sm-right"><b>Pallet Type:</b></b-col>
                        <b-col sm="6">{{ data.item.palletType }}</b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col sm="4" class="border-right">
                       <b-row class="mb-2">
                        <b-col sm="6" class="text-sm-right"><b>Items Value:</b></b-col>
                        <b-col sm="6">${{ data.item.stackValue }}</b-col>
                      </b-row>
                    </b-col>

                    <b-col sm="4" class="border-right">
                       <b-row class="mb-2">
                        <b-col sm="6" class="text-sm-right"><b>Weight:</b></b-col>
                        <b-col sm="6">{{ data.item.weight }} kg</b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

            </b-table>
          </b-card-body>
        </b-card>
      </div>
    </b-col>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <b-button
          variant="secondary"
          block
            v-if="has('pm.warehouses.purchaseOrders.edit') && itemForm.orderStatus == 1 || itemForm.orderStatus == 0"
            :to="{
              name: 'Warehouses.PurchaseOrders.Edit',
              params: { id: itemForm.id }
            }">
            <i class="fa fa-pencil mr-2" />
            <span>Edit PO</span>
        </b-button>
        <button
          class="mt-2 btn btn-block btn-primary"
          @click="MarkAsOpen(itemForm)"
          v-if="has('pm.warehouses.purchaseOrders.confirm') && itemForm.id && (itemForm.orderStatus == 1 || itemForm.orderStatus == 6)"
        >
          <span>Confirm Purchase Order</span>
        </button>
        <button
          class="mt-2 btn btn-block btn-outline-danger"
          v-if="has('pm.warehouses.purchaseOrders.complete') && (itemForm.orderStatus == '3' || itemForm.orderStatus == '4')"

          @click="MarkAs('active', itemForm)"
        >
          <i class="fa fa-times-circle-o mr-2 text-danger" />
          <span>Un-Archive</span>
        </button>
        <button
          class="mt-2 btn btn-block btn-info"
          @click="MarkOnHold(itemForm,'Tech Hold')"
          v-if="has('pm.warehouses.purchaseOrders.confirm') && itemForm.id && itemForm.orderStatus == 1"
        >
          <span>Put On Tech Hold</span>
        </button>
        <button
          class="mt-2 btn btn-block btn-info"
          @click="MarkOnHold(itemForm, 'Credit Hold')"
          v-if="has('pm.warehouses.purchaseOrders.confirm') && itemForm.id && itemForm.orderStatus == 1"
        >
          <span>Put On Credit Hold</span>
        </button>
        <router-link
          class="mt-2 btn btn-block btn-primary"
          :to="{ name: 'Warehouses.PurchaseOrders.CreateShipment', params: { purchaseOrderId: itemForm.id } }"
          v-if="has('pm.warehouses.asn.create') && itemForm.orderStatus != 4 && itemForm.orderStatus != 6 && itemForm.type == 0 && itemForm.orderStatus != 1"
        >
        <i class="fa fa-bus mr-2" /> Ship Items
        </router-link>
        <router-link
          class="mt-2 btn btn-block btn-warning"
          :to="{
            name: 'Warehouses.PurchaseOrders.CreateWorkOrderBatch',
            params: { purchaseOrderId: itemForm.id }
          }"
          v-if="has('pm.warehouses.purchaseOrders.workOrders.create') && itemForm.orderStatus != '6' && itemForm.orderStatus != '3' && itemForm.orderStatus != '4'"
          >
          <i class="fa fa-industry mr-2" /> <span v-b-tooltip.hover="'Create Work Orders'">Enter Work Orders</span>
        </router-link>
        <b-button
          variant="success"
          v-if="has('pm.warehouses.purchaseOrders.complete') && itemForm.orderStatus != 1 && itemForm.orderStatus != '3' && itemForm.orderStatus != '4'"
            class="btn-block text-white"
            @click="MarkAs('completed', itemForm)"
          >
            <i class="fa fa-check-circle-o mr-2 text-success" />
            <span>Mark as Complete</span>
          </b-button>
        <button
          class="mt-2 btn btn-block btn-outline-danger"
          v-if="has('pm.warehouses.purchaseOrders.cancel') && itemForm.orderStatus != '3' && itemForm.orderStatus != '4'"

          @click="MarkAs('cancelled', itemForm)"
        >
          <i class="fa fa-times-circle-o mr-2 text-danger" />
          <span>Mark as Cancelled</span>
        </button>
        <router-link
          class="mt-2 btn btn-block btn-outline-warning"
          :to="{ name: 'Warehouses.PurchaseOrders.Index' }"
          exact
        >
          <span>Back to POs</span>
        </router-link>
      </b-card>
      <b-card v-if="is('manufacturing-manager') && itemForm.id" header="Assigned to:">
        <div class="border b p-2 pr-3" v-for="(usr) in itemForm.assignedUsers" :key="usr.id">
        <i class="fa fa-user ml-2"></i> {{usr.fullName}} ({{usr.email}})
        </div>
        <template v-slot:footer>
          <b-form-group label="Assign who:" label-for="item-shareWith">
            <b-v-select
              v-model="assignToUserId"
              :searchable="true"
              :options="pickUsersList"
            />
          </b-form-group>
          <b-button block variant="info" @click="AssignTo">
            Assign
          </b-button>
        </template>
      </b-card>

      <DiscussionsWidget v-bind="chatWidgetProps" v-if="chatWidgetProps.relatedId"></DiscussionsWidget>
      <b-card header="Attachments" no-body class="communications" v-if="itemForm.id">
        <div class="card-body">
          <div v-if="itemForm.attachments">
            <b-media class="border-bottom mb-1 pb-1" v-for="attachmnt in itemForm.attachments" :key="attachmnt.id">
              <template #aside>
                <div class="bg-grey rounded bg-rounded">
                  <template v-if="attachmnt.type == 'PDF'">
                    <i class="fa fa-file-pdf-o"></i>
                  </template>
                  <template v-else-if="attachmnt.type == 'Document'">
                    <i class="fa fa-file-word-o"></i>
                  </template>
                  <template v-else-if="attachmnt.type == 'Spreadsheet'">
                    <i class="fa fa-file-excel-o"></i>
                  </template>
                  <template v-else-if="attachmnt.type == 'Zip'">
                    <i class="fa fa-zip-o"></i>
                  </template>
                  <template v-else-if="attachmnt.type == 'Image'">
                    <i class="fa fa-file-picture-o"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-file-code-o"></i>
                  </template>
                </div>
              </template>
              <b class="mt-0"><a target="_blank" :href="attachmnt.url" download> {{ attachmnt.name }}</a>
             </b>
              <p class="mb-0">
                <span class="small">Added on: {{ fullHumanDate(
                          attachmnt.addedAtDateTimeUtc,
                          'MMMM Do YYYY, h:mm a'
                        ) }}</span></p>
            </b-media>
          </div>
          <input
            id="fileImportPo"
            ref="fileImportPo"
            type="file"
            class="d-none"
            accept=".pdf,.zip,.xls,.xlsx,.docx,.doc,.txt,.png,.jpeg,.jpg,.gif,.webm"
            name="fileImportPo"
            @change="ImportFileHandler()"
          />
          <label for="fileImportPo" class="mt-2 btn btn-block btn-outline-primary">
            <span><i class="fa fa-file-o"></i> Attach a Document</span>
          </label>
        </div>
      </b-card>
      <b-card header="Connect Log">
          <b-form-group class="mt-0">
                <b-form-textarea
                  :value="itemForm.notes"
                  :disabled="true"
                  rows="7"
                  max-rows="12"
                />
            </b-form-group>
        </b-card>
    </aside>
  </b-row>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
import DiscussionsWidget from '@/views/components/DiscussionsWidget';
export default {
  components: { DiscussionsWidget },
  name: 'Warehouses.PurchaseOrders.View',
  mixins: [crudMixinGen('purchaseOrders2'), myCommonDataMixin],
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    chatWidgetProps: {
      startOpen: true,
      channelType: 'FeatureClientSupport',
      relatedDesc: 'Discussion on a PO.',
      relatedName: 'Purchase Order: ',
      relatedId: '',
      relatedType: 'PO'
    },
    isFileUploading: false,
    vendorUsers: [],
    assignToUserId: '',
    loadingWorkOrderItems: false,
    workOrderItems: [],
    workOrderFields: [
      { key: 'orderNumber', label: 'Order Ref #', sortable: false },
      { key: 'purchaseOrderItemId', label: 'Item', sortable: false },
      { key: 'warehouseId', label: 'Warehouse', sortable: false },
      { key: 'type', label: 'Status', sortable: false },
      //{ key: 'brandId', label: 'Client', sortable: false },
      { key: 'quantity', label: 'Qty', sortable: false },
      //{ key: 'createDateTimeUtc', label: 'Created At', sortable: false },
      { key: 'expectedEndDate', label: 'Expected At', sortable: false },
      { key: 'id', label: 'Action', sortable: false }
    ],
    // workOrderTableOptions: {
    //   perPage: 100,
    //   page: 1
    // },

    itemfields: [
      { key: 'warehouseProductItem', label: 'SKU', sortable: false },
      //{ key: 'warehouseProductDescription', label: 'Name', sortable: false },
      { key: 'quantity', label: 'Requested', sortable: false },
      { key: 'quantityTotalManu', label: 'Produced', sortable: false },
      { key: 'quantityLeftToManu', label: 'Still to Produce', sortable: false },
      { key: 'quantityLeft', label: 'Still to Ship', sortable: false },
      { key: 'quantityTotalShipped', label: 'Shipped', sortable: false },
      { key: 'quantityLeft', label: 'Still to Ship', sortable: false },
      { key: 'quantityTotalReceived', label: 'Received', sortable: false }
    ],
    shipmentItemfields: [
      { key: 'palletNum', label: 'PLT #', sortable: false },
      { key: 'warehouseProductId', label: 'Item', sortable: false },
      { key: 'lpn', label: 'LPN', sortable: false },
      { key: 'quantityShipped', label: 'Qty Shipped', sortable: false },
      { key: 'quantityReceived', label: 'Qty Received', sortable: false },
      { key: 'lotNumber', label: 'Lot Number', sortable: false },
      //{ key: 'quantityDamaged', label: 'Qty Outstanding', sortable: false },
      //{ key: 'boxes', label: 'Boxes', sortable: false },
      //{ key: 'dimensions', label: 'Dimensions', sortable: false },
      { key: 'expiresDate', label: 'Expires', sortable: false },
    ],
    heading: 'Purchase Order',
  }),

  computed: {
    pickUsersList: {
      get() {
        let arrs = [];
        //arrs.push({ value: '-1', text: 'Any User' });
        this.vendorUsers.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.fullName + ' (' + itm.email + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
  },

  meta() {
    let titl = (this.itemForm && this.itemForm.orderNumber) ? ('PO: ' + this.itemForm.orderNumber) : 'LaCore';
      return {
          title: titl,
          // ...
      };
  },
  watch: {
    'id': function(val) {
      this.LoadItem(this.id);
    },
    '$route.query.id': function(val) {
      this.id = val;
      this.LoadItem(this.id);
    },
  },

  mounted() {
    if (this.id) {
      this.ResetForm();
      this.LoadItem(this.id)
        .then((resp) => {
          if(this.is('manufacturing-manager'))
          {
            this.loadVendorUsers(resp.data.vendorId);
          }
          // if(this.has('pm.communications.purchaseOrders.internalChannel'))
          //   this.StartInternalManufacturingDiscussion();
          if(this.has('pm.warehouses.purchaseOrders.workOrders.view'))
            this.loadWorkOrderItems();

            this.chatWidgetProps.relatedName = 'Purchase Order: ' + resp.data.orderNumber;
            this.chatWidgetProps.relatedId = this.id;
        })
      ;
    } else {
      this.ResetForm();
    }
  },

  methods: {
    ImportFileHandler() {
      let $this = this;
      let reader = new FileReader();
      $this.isFileUploading = true;
      let filePicked = this.$refs.fileImportPo.files[0];
      reader.onload = function(e) {
        let formData = new FormData();
        formData.append('purchaseOrderId', $this.itemForm.id);
        console.log('filename', filePicked.name)
        formData.append('formFile', filePicked, filePicked.name);
        //formData.append("projectId", $this.projectId);
        $this.$http
          .post('/attachToPO', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resp => {
            $this.isFileUploading = false;
            if (resp.data.url) {
              $this.itemForm.attachments = resp.data.attachments;
              $this.$swal('File Attached!');
            } else {
              $this.$swal('Something went wrong');
            }
          })
          .catch(err => {
            console.error(err);
            $this.isFileUploading = false;
            $this.$swal('Something went wrong');
          });
      };
      reader.readAsDataURL(filePicked);
    },
    loadVendorUsers(vendorId, hnl = () => {}) {
      this.$http
        .get('/api/usersForVendor/'+vendorId)
        .then(resp => {
          this.vendorUsers = resp.data;
          hnl();
        })
        .catch(err => {});
    },
    AssignTo() {
      if(this.assignToUserId) {
        let usrFound = this.vendorUsers.find(a => a.id == this.assignToUserId);
        if(usrFound != null) {
          if(!this.itemForm.assignedUserIds) {
            this.itemForm.assignedUserIds = [];
          }
          if(!this.itemForm.assignedUsers) {
            this.itemForm.assignedUsers = [];
          }
          this.itemForm.assignedUserIds.push(this.assignToUserId);
          this.itemForm.assignedUsers.push(JSON.parse(JSON.stringify(usrFound)));
          this.assignToUserId = ''
          if(this.itemForm.id) {
            this.$http
              .post('/api/common/purchaseOrders/updateAssigned/'+this.itemForm.id, this.itemForm.assignedUserIds)
              .then(resp => {
              })
              .catch(err => {});
          }
        }
      }
    },
    ChangeStatusWith(type, item) {
      item.type = type;
      this.$http
        .post('/api/manufacturing/workOrders/changeStatus', item)
        .then(resp => {
          if (resp.status) {
            this.$swal({ title: 'Item Was Saved.', icon: 'success' });
          } else {
            this.$swal({ title: resp.message, icon: 'error' });
          }
          this.loadItems();
        })
        .catch(err => {
          console.error('saved po err', err);
        });
    },
    GetForManufacturingItemName(poItemId) {
      let itemName = '-';
      let fndItm = this.itemForm.items.find(a => a.id == poItemId);
      if(fndItm != null) {
        itemName = fndItm.warehouseProductItem;
      }
      return itemName;
    },
    loadWorkOrderItems() {
      if(this.itemForm.id) {
      this.loadingWorkOrderItems = true;
        this.$http
          .post('/api/manufacturing/workOrders/listItems', {
            perPage: 100,
            purchaseOrderId: this.itemForm.id
          })
          .then(resp => {
            this.workOrderItems = resp.data.items;
            this.loadingWorkOrderItems = false;
          })
          .catch(err => {
            this.loadingWorkOrderItems = false;
          });
      }
    },
    MarkAs(what, item) {
      this.$swal({
        title: 'Are you sure you want to do this?',
        text: "You won't be able to revert this! ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!'
      }).then(result => {
        if (result.value) {
          if (what == 'completed') {
            item.orderStatus = 3;
          } else if (what == 'cancelled') {
            item.orderStatus = 4;
          } else if (what == 'active') {
            item.orderStatus = 5;
          }
          this.$http
            .post('/api/common/purchaseOrders/markAs/'+ item.id + '/' + item.orderStatus)
            .then(resp => {
                this.loadItems();
                this.$swal('Done!', 'PO change saved.', 'success');
            })
            .catch(err => {});
        }
      });
    },
    MarkOnHold(item, reason) {
      this.$swal({
        title: 'Are you sure?',
        text: "You will not be able to revert it, but you will be able to confirm it at a later date.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, put it on hold!'
      }).then(result => {
        if (result.value) {
          this.$http
            .post('/api/common/purchaseOrders/markOnHold/' + item.id + '/' + reason)
            .then(resp => {
              if(resp.data.success) {
                this.loadItems();
                this.$swal('Done!', 'The order was put on hold.', 'success');
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
            .catch(err => {});
        }
      });
    },
    MarkAsOpen(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "External Ref. Number (optional):",
        icon: 'warning',
        input: 'text',
        inputLabel: 'External Ref. Number',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mark it as open!'
      }).then(result => {
        if (result.value) {
          let externalRefNum = result.value;
          this.$http
            .post('/api/common/purchaseOrders/markAsOpen/' + item.id, {
              externalRefNum: externalRefNum
            })
            .then(resp => {
              if(resp.data.success) {
                this.loadItems();
                this.$swal('Done!', 'The order was set to open.', 'success');
              } else {
                this.$swal({ title: 'Error!', text: resp.data.errorMessage, icon: 'error' });
              }
            })
            .catch(err => {});
        }
      });
    },
    palletColorGen(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substring(-2);
      }
      return colour;
    },
    OrderedByLPN(shipmentItems) {
      let palletNum = 0;
      let oldLPN = ''
      let itms = shipmentItems;
      itms.sort((a,b) => (a.lpn > b.lpn) ? 1 : ((b.lpn > a.lpn) ? -1 : 0));
      itms.forEach((it) => {
        if(oldLPN != it.lpn) {
          oldLPN = it.lpn;
          palletNum++;
          it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
          it.palletNum = palletNum;
        } else {
          it.palletColor = this.palletColorGen(palletNum+'LCN-00000');
          it.palletNum = palletNum;
        }
      })
      return itms;
    },
    RefreshPoItem(code) {
      this.$http
        .post('/api/irmspos/refresh/' + code)
        .then(resp => {
          if (this.id) {
            this.LoadItem(this.id);
          }
        })
        .catch(err => {});
    },
    DownloadPackingListPdfItem(wareShipment) {
      // if(wareShipment.id && wareShipment.realStatus != 'In Transit' && wareShipment.realStatus != 'closed') {
      //   this.$swal('Error!', 'You can only download packing list for warehouse shipments that are at least in transit.', 'error');
      //   return;
      // }
      if(wareShipment.allocationRequest) {
        let form= document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/pdf/wareship-packing-list/' + wareShipment.id);
        document.body.appendChild(form); // Not sure if this step is necessary
        form.submit();
      } else {
        let form= document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.setAttribute('action', process.env.VUE_APP_API_URL + '/download/pdf/packing-list/' + wareShipment.packingListId);
        document.body.appendChild(form); // Not sure if this step is necessary
        form.submit();
      }
    },
    fullHumanDate(date, str) {
      return this.$moment
        .utc(date)
        .local()
        .format(str);
    }
  }
};
</script>
