<template>
  <section>
    <div class="popups">
        <input
          id="importMultipleFile"
          ref="importMultipleFile"
          type="file"
          class="d-none"
          accept=".xls,.xlsx"
          name="importMultipleFile"
          @change="ImportMultipleProducts(true)"
        />

      <b-modal
        id="form-setup"
        v-model="showUploadModal"
        :centered="true"
        size="lg"
        title="Upload a spreadsheet"
      >
        <div class="row no-gutters form-project">
          <b-col cols="12">
            <div v-if="!uploadRunning" class="text-center">
              <label for="importMultipleFile" class="btn btn-secondary ml-2"
                >Import Orders</label
              >

              <div class="mt-2">
                <a
                  class="btn btn-sm btn-info"
                  href="https://integration.lacoreconnect.com/orders_import_example.xlsx"
                  target="_blank"
                  >Download Sample Sheet</a
                >
              </div>
            </div>
            <div v-else>
              <div class="alert alert-warning text-center">
                Importing... Please Wait...
              </div>
            </div>
          </b-col>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
          </div>
        </template>
      </b-modal>

    </div>

    <div class="header">
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <h1 class="header-title">
              Import Orders
            </h1>
          </div>
          <div class="col-auto">

            <b-button v-if="hasOrdersToImport" variant="success" @click="ConfirmUpload()">
              <i class="fa fa-save mr-2"></i>
              Confirm Import
            </b-button>
            <b-button v-else variant="info" @click="showUploadModal = true">
              <i class="fa fa-upload mr-2"></i>
              Upload File
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <b-card class="card-table">
      <b-alert show v-if="!hasOrdersToImport">
        <p>
          Upload a spreadsheet to import orders. You can download an <a
                  class="btn btn-sm btn-info"
                  href="https://integration.lacoreconnect.com/orders_import_example.xlsx"
                  target="_blank"
                  >example sheet here</a>.
        </p>
        <p>
          We recommend uploading no more than 500 orders per batch. After uploading, you will be able to confirm the orders you are importing, and if there are issues with some like missing required fields, you will be told about it.
          Please make sure to delete the sample line items from the sheet before adding your own to it.
        </p>
      </b-alert>
      <b-alert show v-else-if="uploadRunning">
        <p>
          Uploading... Please wait...
        </p>
        <p>
          It might a bit for us to process your import, depending on the number of orders you are importing.
        </p>
      </b-alert>
      <b-table
        v-else
        head-variant="dark"
        responsive
        :items="itemsList"
        :fields="fields"
        hover
        striped
        fixed
        >
        <template v-slot:cell(id)="data">
          <template v-if="data.item.invalidReason">
            <label class="badge badge-danger"
              ><i class="fa fa-check" /> {{data.item.invalidReason}}</label
            >
          </template>
          <template v-else>
            <label class="badge badge-success badge-green"
              ><i class="fa fa-check" /> All Good</label
            >
          </template>
        </template>
      </b-table>
    </b-card>

    <div v-if="isFiltering">
      <div class="card text-center">
        <div class="card-body text-center">
          <i class="fa fa-spinner fa-spin" style="font-size: 40px;" />
        </div>
        <h3>Processing Data... Please wait..</h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Orders.Imports',

  data() {
    return {
      projectId: '',
      hasOrdersToImport: false,
      uploadRunning: false,
      isFiltering: false,
      showUploadModal: false,
      itemsList: [],
      fields: [
        { key: 'orderNumber', label: 'Order Number', sortable: true },
        { key: 'shipToName', label: 'Name', sortable: true },
        { key: 'shipToStreet1', label: 'Street 1', sortable: true },
        { key: 'customerEmail', label: 'Customer Email', sortable: true },
        { key: 'id', label: 'Status', sortable: true }
      ]
    };
  },
  computed: {},
  mounted() {
    this.projectId = this.$route.query.projectId;
  },
  methods: {
    ImportMultipleProducts(doCheck = true) {
      this.showUploadModal = false;
      let $this = this;
      $this.isFiltering = true;
      let reader = new FileReader();
      let filePicked = this.$refs.importMultipleFile.files[0];
      reader.onload = function(e) {
        $this.uploadRunning = true;
        let formData = new FormData();
        formData.append('file', filePicked);
        formData.append('projectId', $this.projectId);
        formData.append('storeId', $this.storeId);
        $this.$http
          .post('/importMultipleOrders' + (doCheck ? '/check' : ''), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(resp => {
            let myitemsList = []
            if(doCheck == false) {
              $this.itemsList = [];
              $this.$refs.importMultipleFile.value = null;
              $this.$swal('Orders queued! You should see them trickling in slowly.');
            } else {
              $this.isFiltering = false;
              $this.validatedResult = resp.data.validatedInfo;
              if($this.validatedResult.validOrders.length>0) {
                $this.hasOrdersToImport = true;
              } else {
                $this.itemsList = [];
                $this.hasOrdersToImport = false;
                $this.$swal('No valid orders to process!');
              }
              myitemsList = [...$this.validatedResult.validOrderImports, ...myitemsList]
              $this.itemsList = [...$this.validatedResult.invalidOrderImports,...myitemsList]
            }
            $this.uploadRunning = false;
          })
          .catch(err => {
            $this.$refs.importMultipleFile.value = null;
            $this.itemsList = [];
            $this.isFiltering = false;
            $this.uploadRunning = false;
            $this.$swal('Failed to parse imported sheet! Please make sure it is in the correct format.');
          });
      };
      reader.readAsDataURL(filePicked);
    },
    ConfirmUpload() {
      this.itemsList = [];
      this.ImportMultipleProducts(false);
    }
  }
};
</script>
