<template>
  <div
    v-on-clickaway="hideDropdown"
    class="v-select"
    :class="{ disabled: disabled }"
    @keyup.esc="onEscape"
    @keydown.up.prevent="typeAheadUp"
    @keydown.down.prevent="typeAheadDown"
    @keydown.enter.prevent="typeAheadSelect"
  >
    <template v-if="name">
      <b-form-select
        :id="id"
        v-model="value"
        class="form-control d-none"
        :name="name"
        :state="state"
        :options="options"
      ></b-form-select>
    </template>
    <button type="button" class="v-select-toggle" @click="toggle">
      <div v-b-tooltip.hover="title" class="v-select-toggle-title">{{ title }}</div>
      <div class="arrow-down" />
    </button>
    <div v-show="show" class="v-dropdown-container" :class="{ up: showup }">
      <div v-show="searchable" class="v-bs-searchbox">
        <input
          ref="searfhFld"
          v-model="searchValue"
          :placeholder="labelSearchPlaceholder"
          class="form-control"
          type="text"
          autofocus
        />
      </div>
      <ul class="v-dropdown-wrap">
        <li
          v-show="searchable && filteredOptions.length === 0"
          class="v-dropdown-item"
        >
          {{ labelNotFound }} "{{ searchValue }}"
        </li>
        <li
          v-if="showDefaultOption"
          class="v-dropdown-item disabled default-option"
        >
          {{ labelTitle }}
        </li>
        <li
          v-for="(option, index) in filteredOptions"
          :key="`v-select-${index}`"
          class="v-dropdown-item"
          :class="{
            selected: isSelectedOption(option, index),
            disabled: option[disabledProp]
          }"
          @click="onSelect(option, index)"
        >
          {{ getOptionLabel(option) }}
        </li>
      </ul>
    </div>
    <template v-if="name">
      <slot name="validationMsg" />
    </template>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
export default {
  name: 'VSelect',
  mixins: [clickaway],
  props: {
    allowNewValues: {
      type: Boolean,
      default: false,
      required: false
    },
    name: {
      required: false
    },
    state: {
      required: false
    },
    id: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledProp: {
      type: String,
      default: 'disabled'
    },
    labelTitle: {
      type: String,
      default: 'Nothing selected'
    },
    labelNotFound: {
      type: String,
      default: 'No results matched'
    },
    labelSearchPlaceholder: {
      type: String,
      default: 'Search'
    },
    options: {
      type: Array,
      default: () => []
    },
    showup: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    showDefaultOption: {
      type: Boolean,
      default: false
    },
    textProp: {
      type: String,
      default: 'text'
    },
    value: {
      type: [Object, String, Number, Boolean],
      default: null
    },
    valueProp: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      show: false,
      selectedValue: null,
      searchValue: '',
      tempValue: null,
      typeAheadPointer: -1
    };
  },
  computed: {
    title() {
      return this.selectedValue
        ? this.getOptionLabel(this.selectedValue)
        : this.labelTitle;
    },
    filteredOptions() {
      let filtrd = [];
      if (this.searchable && this.searchValue.length > 0) {
        filtrd = this.options.filter(item => {
          if (typeof item === 'object') {
            return (
              item[this.textProp]
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          } else {
            return (
              item.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      } else {
        filtrd = this.options;
      }
      if(filtrd.length == 0 && this.allowNewValues) {
        let itm = {};
        itm[this.textProp] ='+ New: ' +  this.searchValue.toUpperCase();
        itm[this.valueProp] = this.searchValue.toUpperCase();
        filtrd.push(itm);
      }
      return filtrd;
    },
    reversedOptions() {
      return [...this.filteredOptions].reverse();
    },
    lastOptionIndex() {
      return this.filteredOptions.length - 1;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.tempValue = newVal;
        const index = this.options.findIndex(op => op.value == newVal);
        let val = this.options.find(op => op.value == newVal);
        if (val) {
          this.onSelect(val, index);
        } else {
          this.onSelect(null, -1);
        }
      }
    },
    options: {
      handler(vals) {
        let newVal = this.value;
        if (!newVal) {
          newVal = this.tempValue;
        }
        this.tempValue = newVal;
        const index = vals.findIndex(op => op.value == newVal);
        let val = vals.find(op => op.value == newVal);
        if (index != -1) {
          this.onSelect(val, index);
        }
      }
    }
  },
  methods: {
    onSelect(option, index) {
      if (option && !option[this.disabledProp]) {
        if(this.selectedValue != option) {
          if(this.options.find(a => a.value == option.value) == undefined) {
            this.options.push(option);
          }
          this.selectedValue = option;
          this.typeAheadPointer = index;
          this.hideDropdown();
          this.$emit('input', option[this.valueProp], option, index);
        }
      } else if (option === null) {
        this.selectedValue = null;
      }
    },
    onEscape() {
      this.hideDropdown();
    },
    typeAheadUp() {
      if (!this.show) {
        this.show = true;
      }
      if (this.typeAheadPointer > 0) {
        const nextPointer = this.typeAheadPointer - 1;
        const option = this.filteredOptions[nextPointer];
        const isDisabled = option ? option[this.disabledProp] || false : false;
        if (!isDisabled) {
          this.typeAheadPointer--;
        } else {
          this.typeAheadPointer--;
          this.typeAheadUp();
        }
      } else {
        const nextEnabledOption = this.reversedOptions.findIndex(
          o => o[this.disabledProp] !== true
        );
        this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
      }
    },
    typeAheadDown() {
      if (!this.show) {
        this.show = true;
      }
      if (this.typeAheadPointer < this.lastOptionIndex) {
        const nextPointer = this.typeAheadPointer + 1;
        const option = this.filteredOptions[nextPointer];
        const isDisabled = option ? option[this.disabledProp] || false : false;
        if (!isDisabled) {
          this.typeAheadPointer++;
        } else {
          this.typeAheadPointer++;
          this.typeAheadDown();
        }
      } else {
        const nextEnabledOption = this.filteredOptions.findIndex(
          o => o[this.disabledProp] !== true
        );
        this.typeAheadPointer = nextEnabledOption;
      }
    },
    typeAheadSelect() {
      if (this.filteredOptions[this.typeAheadPointer]) {
        this.onSelect(
          this.filteredOptions[this.typeAheadPointer],
          this.typeAheadPointer
        );
      }
    },
    hideDropdown() {
      this.show = false;
      this.searchValue = '';
    },
    getOptionLabel(option) {
      if (typeof option === 'object') {
        return option[this.textProp];
      }
      return option;
    },
    isSelectedOption(option, index) {
      if (this.typeAheadPointer === -1 && this.selectedValue) {
        return this.isEqualOption(option, this.selectedValue);
      }
      return this.typeAheadPointer === index;
    },
    isEqualOption(a, b) {
      if (a && b && typeof a === 'object' && typeof b === 'object') {
        return (
          a[this.textProp] === b[this.textProp] &&
          a[this.valueProp] === b[this.valueProp]
        );
      }
      return a === b;
    },
    toggle() {
      if (!this.disabled) {
        this.show = !this.show;
        if (this.searchable) {
          setTimeout(() => {
            this.$refs.searfhFld.focus();
          }, 80);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
input {
  width: 100%;
}
ul {
  font-size: 12px;
  color: #424242;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  padding: 0px;
  margin: 2px 0px 0px 0px;
}
.v-dropdown-wrap {
  display: block;
  max-height: 250px;
  overflow-y: auto;
}
.v-select {
  position: relative;
  width: 100%;
  height: 30px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    .v-select-toggle {
      & > div {
        overflow-x: hidden;
      }
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      opacity: 0.65;
      cursor: not-allowed;
      &:focus {
        outline: 0 !important;
      }
    }
  }
}
.v-select-toggle {
  display: flex;
  justify-content: space-between;
  user-select: none;
  color: #212529;
  background-color: #f8f9fa;
  border-color: #d3d9df;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  border: 1px solid transparent;
  font-size: 12px;
  font-family: inherit, sans-serif;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  padding-right: 12px;
  font-size: 0.875rem;
  background: #fff;
  border: 1px solid #e9ecef;
  &:focus {
    border-color: #7489ac;
    outline: 0;
    box-shadow: 0 0 0 1px rgb(159, 172, 196);
  }
}

.vertical-layout .v-select {
  height: 37px !important;
}
.vertical-layout .v-dropdown-wrap {
  margin-bottom:0;
}
.vertical-layout .v-select .v-select-toggle {
  border: 1px solid #d8d6de !important;
  font-size: 14px !important;
  padding: 0.538rem 1rem !important;
  height: 37px !important;
}
.dark-layout .v-select,
.dark-layout .v-select .v-select-toggle,
.dark-layout .v-dropdown-wrap, .dark-layout .v-dropdown-container {
  background: #283046;
  color: #b4b7bd;
  border: 1px solid #404656 !important;
}
.dark-layout .v-dropdown-item {
    border-bottom: 1px solid #404656;
}
.dark-layout .v-bs-searchbox .form-control {
  color: #949699;
}
.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  margin-top: 7px;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.v-dropdown-container {
  position: absolute;
  width: 100%;
  background: red;
  padding: 0;
  margin: 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
  box-shadow: 0 0 0 0.1rem rgba(60, 75, 100, 0.25);
  &.up {
    bottom: 100%;
  }
}
.v-dropdown-item {
  text-decoration: none;
  line-height: 25px;
  padding: 4px 12px;
  user-select: none;
  border-bottom: 1px solid #efefef;

  &:hover:not(.default-option) {
    background-color: #f8f9fa;
  }
  &.disabled {
    color: #9a9b9b;
  }
  &.selected {
    background-color: #007bff;
    color: #fff;
    &:hover {
      background-color: #007bff;
      color: #fff;
    }
  }
  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #fff;
    }
  }
}
.v-bs-searchbox {
  padding: 2px 3px;
  .form-control {
    display: block;
    width: 100%;
    padding: 2px 8px;
    font-size: 13px;
    line-height: 26px;
    height: 30px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.is-invalid ~ button.v-select-toggle {
  border-color: #dc3545;
  color: #dc3545;
  .arrow-down {
    color: #dc3545;
  }
}
.is-valid ~ button.v-select-toggle {
  border-color: #31538d;
  .arrow-down {
    color: #31538d;
  }
}
.v-select-toggle-title {
  overflow: hidden;
}
</style>
