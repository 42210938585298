<template>
  <section class="filters-badge-list">
    <div class="d-inline-block filter-badge mr-1" v-for="(filterVal, key) in removeUnwantedFilters(itemsFilters)" :key="key">
    <slot :name="'filterWrap('+key+')'" :item="filterVal">
      <span class="badge badge-primary mr-2">
        <slot :name="'filter('+key+')'" :item="filterVal">
          <b>{{prettifyCamelCase(key)}}: </b>
          <slot :name="'filterVal('+key+')'" :item="filterVal">
            <span>{{prettyFilterValue(key, filterVal)}}</span>
          </slot>
        </slot>
          <span
            class="fa fa-times fa-1x ml-2"
            @click="
              resetFilterWithKey(key);
              loadItems();
            "
        /></span>
      </slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    itemsFilters: {
      type: Object,
      required: true
    },
    niceNamedFilters: {
      type: Array,
      required: false,
      default: null
    },
    loadItems: {
      type: Function,
      required: true
    },
    resetFilterWithKey: {
      type: Function,
      required: true
    }
  },
  methods: {
    removeUnwantedFilters(fltrs) {
      let filters = JSON.parse(JSON.stringify(fltrs));
      delete filters.fields;
      Object.keys(filters).forEach((dkey) => {
        let val = filters[dkey];
        if(!val) {
          delete filters[dkey];
        }
      })
      delete filters.storefrontIds;
      delete filters.includeTypes;
      delete filters.excludeTypes;
      delete filters.sortDesc;
      delete filters.sortBy;
      delete filters.sortDir;
      delete filters.pagination;
      delete filters.fields;
      delete filters.perPage;
      delete filters.currentPage;
      delete filters.doNotShowOrderStatus;
      return filters;
    },
    prettyFilterValue(key, val) {
      return val;
    },
    prettifyCamelCase(str) {
        let niceNameTry = this.niceNamedFilters!=null ? this.niceNamedFilters.find((val, index) => index == str) : false;
        if(niceNameTry) {
          return niceNameTry;
        }
        var output = '';
        var len = str.length;
        var char;

        for (var i=0 ; i<len ; i++) {
            char = str.charAt(i);

            if (i==0) {
                output += char.toUpperCase();
            }
            else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
                output += ' ' + char;
            }
            else if (char == '-' || char == ' ') {
                output += ' ';
            }
            else {
                output += char;
            }
        }

        return output;
    }
  }
}
</script>

<style>

</style>
