<template>
  <b-row>
    <div class="col-12 col-sm-9">
      <b-card class="mb-3" :header="heading" no-body>
        <div class="card-body">
          <b-row>

            <b-col cols="12">
              <b-form-group label="Name:">
                <b-form-input
                  id="form-input-name"
                  name="form-input-name"
                  v-model="itemForm.name"
                  v-validate="{ required: true, min: 2 }"
                  :state="validateState('form-input-name')"
                  data-vv-as="Name"
                  placeholder="Name must be unique"
                  />
                <b-form-invalid-feedback
                  id="form-input-name-feedback"
                >
                  {{ veeErrors.first('form-input-name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <div class="col-12 col-md-4">
              <b-form-group label="Storefront:">
                <b-v-select
                  id="form-input-projectId"
                  name="form-input-projectId"
                  v-validate="{ required: true }"
                  :state="validateState('form-input-projectId')"
                  v-model="itemForm.projectId"
                  :searchable="true"
                  :options="projectsList"
                />
                <b-form-invalid-feedback id="form-input-projectId-feedback">
                  {{ veeErrors.first('form-input-projectId') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-12 col-md-4">
              <b-form-group label="Type:">
                <b-v-select
                  id="form-input-type"
                  name="form-input-type"
                  v-validate="{ required: true }"
                  v-model="itemForm.type"
                  :state="validateState('form-input-type')"
                  :searchable="true"
                  :options="typesList"
                />
                <b-form-invalid-feedback
                  id="form-input-type-feedback"
                >
                  {{ veeErrors.first('form-input-type') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-col cols="12" md="4" v-if="!is('brand-user')">
              <b-form-group label="Visibility:">
                <b-v-select
                  id="form-input-visibleToEndUsers"
                  name="form-input-visibleToEndUsers"
                  v-model="itemForm.visibleToEndUsers"
                  v-validate="{ required: false }"
                  :state="validateState('form-input-visibleToEndUsers')"
                  :searchable="true"
                  :options="visibleList"
                />
                <b-form-invalid-feedback id="form-input-visibleToEndUsers-feedback">
                  {{ veeErrors.first('form-input-visibleToEndUsers') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description:">
                <b-form-textarea
                  id="form-input-description"
                  name="form-input-description"
                  v-model="itemForm.description"
                  v-validate="{ required: false, min: 1, max: 100 }"
                  :state="validateState('form-input-description')"
                  data-vv-as="Description"
                  rows="3"
                  placeholder="Max 100 characters!"
                  ></b-form-textarea>
                <b-form-invalid-feedback
                  id="form-input-description-feedback"
                >
                  {{ veeErrors.first('form-input-description') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Email Subject:" v-if="itemForm.type == 'Email'">
                <b-form-input
                  id="form-input-subject"
                  name="form-input-subject"
                  v-model="itemForm.subject"
                  v-validate="{ required: false, min: 0 }"
                  :state="validateState('form-input-subject')"
                  data-vv-as="Email Subject"
                  />
                <b-form-invalid-feedback
                  id="form-input-subject-feedback"
                >
                  {{ veeErrors.first('form-input-subject') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="itemForm.type == 'SMS'">
              <b-form-group label="SMS Message Template:">
                <b-form-textarea
                  rows="7"
                  id="form-input-contentSms"
                  name="form-input-contentSms"
                  v-model="itemForm.content"
                  v-validate="{ required: true, min: 1, max: 160 }"
                  :state="validateState('form-input-contentSms')"
                  data-vv-as="SMS Message Template"
                  placeholder="Max 160 characters!"
                  ></b-form-textarea>
                <b-form-invalid-feedback
                  id="form-input-contentSms-feedback"
                >
                  {{ veeErrors.first('form-input-contentSms') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Email Template:"  v-if="itemForm.type == 'Email'">
                <b-form-textarea v-model="itemForm.content" cols="10"></b-form-textarea>
                <b-form-invalid-feedback
                  id="form-input-content-feedback"
                >
                  {{ veeErrors.first('form-input-content') }}
                </b-form-invalid-feedback>
              </b-form-group>

                <div class="text-right mt-2">
                  <b-button
                      :disabled="savingItem"
                      variant="info"
                      type="submit"
                      @click="SendTestEmail"
                    >
                      <span v-if="savingItem">Wait...</span>
                      <span v-else>Send Test Email</span>
                    </b-button>
                </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <b-button
          :disabled="savingItem"
          variant="primary"
          block
          type="submit"
          @click="SaveForm"
        >
          <span v-if="savingItem">Saving...</span>
          <span v-else>Save Changes</span>
        </b-button>
        <router-link
          :disabled="savingItem"
          class="mt-2 btn btn-block btn-outline-danger"
          :to="{ name: 'Other.AutomationsContent.Index' }"
        >
          <span>Cancel</span>
        </router-link>
      </b-card>
    </aside>
  </b-row>
</template>

<script>
import { crudMixinGen, myCommonDataMixin } from '@/helpers/utils/vueMixins.js';

// Create a configuration object
export default {
  mixins: [crudMixinGen('contentTemplates'), myCommonDataMixin],
  props: {
    id: {
      type: String,
      default: '',
      required: false
    }
  },

  data: () => ({
    heading: 'Content Template Form',
    visibleList: [
      {
        value: true,
        text: 'Visible to End Users'
      },
      {
        value: false,
        text: 'Hidden From End Users'
      }
    ],
    typesList: [
      {
        value: 'Email',
        text: 'Email'
      }
    ]
  }),

  computed: {
  },

  mounted() {
    this.$store.dispatch('fetchMyTPLData');
    if (this.id) {
      this.ResetForm();
      this.LoadItem(this.id);
    } else {
      this.ResetForm();
    }
  },

  methods: {
    SendTestEmail() {
      this.$http.post('/api/automations/contentTemplates/testEmail', this.itemForm)
        .then(resp => {
          this.$swal({ title: 'Email was Sent!', icon: 'success' });
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errorMessage) {
            this.$swal({ title: err.response.data.errorMessage, icon: 'error' });
          } else {
            this.$swal({ title: 'Email Was NOT Sent!', icon: 'error' });
          }
        });
    },
    SaveForm() {
      this.savingItem = true;
      this.SaveItem()
        .then(resp => {
          this.ResetForm();
          this.$swal({ title: 'Item Was Saved!', icon: 'success' });
          this.$router.push({
            name: 'Other.ContentTemplates.Index'
          });
        })
        .catch(err => {
          this.savingItem = false;
          if (err.response && err.response.data && err.response.data.errorMessage) {
            this.$swal({ title: err.response.data.errorMessage, icon: 'error' });
          } else {
            this.$swal({ title: 'Item Was NOT Saved!', icon: 'error' });
          }
        });
    }
  }
};
</script>
<style>
.ck.ck-dropdown__panel {
	max-height: 250px;
	overflow-y: auto;
}
</style>
