//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  brandId: '',
  warehouseId: '',
  singleUnitCharge: 0.0,
  numberOfUnits: 0.0,
  chargeLabel: '',
  chargeType: 'ConnectAdministrative',
  type: 'Other',
  invoiceId: '',
  refNum: ''
};

let defaultFilters = {
  name: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions
  },
};
