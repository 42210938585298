export let pagination = {
  perPage: 10,
  page: 1
};

export let tableOptions = {
  totalRows: 0,
  loadedAll: false
};

export default {
  pagination: {
    perPage: 10,
    page: 1,
    sortBy: '',
    sortDesc: true
  },
  tableOptions: {
    totalRows: 0
  }
};
