<template>
  <b-row>
    <div class="col-9" v-if="reshipOrderId && !shipPulledInfo">
      <div class="alert alert-warning p-3">Please wait... loading...</div>
    </div>
    <div class="col-9" v-else>
      <form @submit.prevent data-vv-scope="itemForm">
        <b-card class="mb-3" :header="heading" no-body>
          <div class="card-body">
            <b-row>
              <div class="col-7">
              </div>
              <div class="col-5" style="text-align: right;">
                  <b-form-checkbox v-model="showAdvancedFields" name="check-button" switch>
                    Show Advanced Fields?
                  </b-form-checkbox>
              </div>
              <b-col cols="12" md="6">
                <b-form-group label="Storefront *">
                  <b-v-select
                    v-validate="{ required: true }"
                    name="form-input-projectId"
                    :state="
                      validateStateScoped('itemForm', 'form-input-projectId')
                    "
                    v-model="itemForm.projectId"
                    :searchable="true"
                    :options="projectsListAllowed"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Order Group">
                  <b-v-select
                    v-validate="{ required: true }"
                    name="form-input-storeId"
                    :state="
                      validateStateScoped('itemForm', 'form-input-storeId')
                    "
                    v-model="itemForm.storeId"
                    :searchable="true"
                    :options="storesList"
                  />
                </b-form-group>
              </b-col>
              <div class="col-12 col-md-4" v-if="showAdvancedFields && !has('pm.is_end_partner')">
                <b-form-group
                  label="Order Key"
                  description="Autogenerated, change only if required."
                >
                  <b-form-input
                    v-validate="{ required: true, min: 1, max: 15 }"
                    id="form-input-orderKey"
                    name="form-input-orderKey"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'itemForm.form-input-orderKey'
                      )
                    "
                    v-model="itemForm.orderKey"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-md-4">
                <b-form-group label="Order Number *">
                  <b-form-input
                    v-validate="{ required: true, min: 1, max: 25 }"
                    name="form-input-orderNumber"
                    :state="
                      validateStateScoped('itemForm', 'form-input-orderNumber')
                    "
                    v-model="itemForm.orderNumber"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-md-4">
                <b-form-group label="Order Status *">
                  <b-v-select
                    v-validate="{ required: true, min: 1 }"
                    name="form-input-orderStatus"
                    :state="
                      validateStateScoped('itemForm', 'form-input-orderStatus')
                    "
                    v-model="itemForm.orderStatus"
                    :searchable="true"
                    :options="posOrderStatus"
                  />
                </b-form-group>
              </div>

              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group label="Order Total">
                  <money
                    class="form-control"
                    v-model="itemForm.orderTotal"
                    placeholder="0.00"
                    min="0"
                    v-bind="{ prefix: '$ ' }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group label="Amount Paid">
                  <money
                    class="form-control"
                    v-model="itemForm.amountPaid"
                    placeholder="0.00"
                    min="0"
                    v-bind="{ prefix: '$ ' }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group label="Shipping Amount">
                  <money
                    class="form-control"
                    v-model="itemForm.shippingAmount"
                    placeholder="0.00"
                    min="0"
                    v-bind="{ prefix: '$ ' }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group label="Tax Amount">
                  <money
                    class="form-control"
                    v-model="itemForm.taxAmount"
                    placeholder="0.00"
                    min="0"
                    v-bind="{ prefix: '$ ' }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group label="Requested Shipping Method">
                  <b-form-input
                    name="form-input-requestedShippingService"
                    v-model="itemForm.requestedShippingService"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Customer Email">
                  <b-form-input
                    v-validate="{
                      required: false,
                      min: 1,
                      max: 45,
                      email: true
                    }"
                    name="form-input-customerEmail"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customerEmail'
                      )
                    "
                    v-model="itemForm.customerEmail"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group
                  label="Customer Username/ID"
                  description="If you have no user ID, you can reuse the email."
                >
                  <b-form-input
                    v-validate="{ required: false, min: 1, max: 45 }"
                    name="form-input-customerUsername"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customerUsername'
                      )
                    "
                    v-model="itemForm.customerUsername"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="showAdvancedFields">
                <b-form-group
                  label="Note From Buyer"
                >
                  <b-form-input
                    v-validate="{ required: false }"
                    name="form-input-customerNotes"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customerNotes'
                      )
                    "
                    v-model="itemForm.customerNotes"
                  />

                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" md="6">
                <b-form-group
                  label="Note to Buyer"
                >
                  <b-form-input
                    v-validate="{ required: false }"
                    name="form-input-privateNotes"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-privateNotes'
                      )
                    "
                    v-model="itemForm.privateNotes"
                  />

                </b-form-group>
              </div> -->
              <div class="col-12 col-md-12" v-if="showAdvancedFields">
                <b-form-group
                  label="Custom Field 1"
                >
                  <b-form-input
                    v-validate="{ required: false, min: 1, max: 45 }"
                    name="form-input-customField1"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customField1'
                      )
                    "
                    v-model="itemForm.advancedOptions.customField1"
                  />

                </b-form-group>
              </div>
              <div class="col-12 col-md-12" v-if="showAdvancedFields">
                <b-form-group
                  label="Custom Field 2"
                >
                  <b-form-input
                    v-validate="{ required: false, min: 1, max: 45 }"
                    name="form-input-customerUsername"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customerUsername'
                      )
                    "
                    v-model="itemForm.advancedOptions.customField2"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-md-12" v-if="showAdvancedFields">
                <b-form-group label="Custom Field 3">
                  <b-form-input
                    v-validate="{ required: false, min: 1, max: 45 }"
                    name="form-input-customerUsername"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-customerUsername'
                      )
                    "
                    v-model="itemForm.advancedOptions.customField3"
                  />
                </b-form-group>
              </div>
            </b-row>
          </div>
        </b-card>
        <b-card class="mb-3" no-body>
          <template v-slot:header>
            <div>
              <div class="card-header-small">Ship To Address</div>
            </div>
          </template>
          <div class="card-body">
            <div class="form-row">
              <div class="col-6">
                <b-form-group label="Ship To Name *">
                  <vue-bootstrap-typeahead
                    v-validate="{ required: true }"
                    v-model="itemForm.shipmentAddress.name"
                    name="form-input-shipmentAddress-name"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-name'
                      )
                    "
                    :serializer="s => s.name"
                    :data="customersListData"
                    :placeholder="itemForm.shipmentAddress.name ? itemForm.shipmentAddress.name : 'Enter to search customers...'"
                    @hit="selectedCustomerAutocomplete = $event"
                  />
                </b-form-group>
              </div>
              <b-col cols="12" md="6">
                <b-form-group label="Country *">
                  <b-v-select
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-countryCode"
                    :state="
                      validateStateScoped('itemForm', 'form-input-shipmentAddress-countryCode')
                    "
                    v-model="itemForm.shipmentAddress.countryCode"
                    :searchable="true"
                    :options="countryCodeList"
                  />
                  </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 1 *">
                  <b-form-input
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-addressLine1"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-addressLine1'
                      )
                    "
                    v-model="itemForm.shipmentAddress.addressLine1"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="City *">
                  <b-form-input
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-city"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-city'
                      )
                    "
                    v-model="itemForm.shipmentAddress.city"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 2">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.addressLine2"
                  />
                </b-form-group>
              </b-col>
              <div class="col-6 col-md-3">
                <b-form-group label="State *">
                  <b-v-select
                    v-if="itemForm.shipmentAddress.countryCode == 'US'"
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-state"
                    :state="
                      validateStateScoped('itemForm', 'form-input-shipmentAddress-state')
                    "
                    v-model="itemForm.shipmentAddress.state"
                    :searchable="true"
                    :options="USAStateCodeList"
                  />
                  <b-form-input
                    v-else
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-state"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-state'
                      )
                    "
                    v-model="itemForm.shipmentAddress.state"
                  />
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group label="Postcode *">
                  <b-form-input
                    v-validate="{ required: true }"
                    name="form-input-shipmentAddress-postcode"
                    :state="
                      validateStateScoped(
                        'itemForm',
                        'form-input-shipmentAddress-postcode'
                      )
                    "
                    v-model="itemForm.shipmentAddress.postcode"
                  />
                </b-form-group>
              </div>
              <b-col cols="12" md="6">
                <b-form-group label="Address Line 3">
                  <b-form-input
                    v-model="itemForm.shipmentAddress.addressLine3"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Phone Number">
                  <b-form-input
                    type="text"
                    v-model="itemForm.shipmentAddress.phone"
                  />
                </b-form-group>
              </b-col>
            </div>
          </div>
        </b-card>
      </form>

      <b-card class="mb-3" header="Order Items" no-body>
        <div class="card-body">
          <div class="items">
            <b-table
              head-variant="light"
              :fields="orderItemFields"
              :items="itemForm.items"
              :bordered="true"
            >
              <template v-slot:cell(quantity)="data">
                <span>
                  {{ data.item.quantity }}
                </span>
                <span
                  v-if="availableInventoryForSku(data.item.sku) != -1 && availableInventoryForSku(data.item.sku)<data.item.quantity"
                  v-b-tooltip.hover :title="'Not Enough Inventory, currently ' + availableInventoryForSku(data.item.sku) + ' are available.'"><i class="fa fa-balance-scale bg-danger  small rounded p-1" ></i></span>
                <span
                 v-else-if="availableInventoryForSku(data.item.sku) != -1"
                v-b-tooltip.hover :title="'Enough inventory, currently ' + availableInventoryForSku(data.item.sku) + ' are available.'"><i class="fa fa-balance-scale bg-success  small rounded p-1"></i></span>
              </template>
              <template v-slot:cell(actions)="data">
                <div class="d-flex">
                  <button
                    class="btn btn-danger"
                    @click="data.item.id ? RemoveItem(data.item) : RemoveItem2(data.item)"
                  >
                    Remove
                  </button>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div v-if="editThisSku == data.item.sku" class="form-row">
                  <b-form-input v-model="data.item.name" placeholder="Name" required />
                  <b-button variant="secondary" size="sm" @click="editThisSku = ''"><i class="fa fa-save"></i></b-button>
                </div>
                <div v-else>
                  <span>{{ data.item.name }}</span>
                  <b-button variant="secondary" size="sm" @click="editThisSku = data.item.sku"><i class="fa fa-edit"></i></b-button>
                </div>
              </template>
              <template v-slot:cell(unitPrice)="data">
                <span>
                  $ {{ parseFloat( parseFloat(data.item.unitPrice * data.item.quantity) ).toFixed(2) }}
                </span>
              </template>
              <template v-slot:cell(unitWeightOz)="data">
                <span>
                  {{ parseFloat( parseFloat(data.item.quantity * data.item.unitWeightOz) ).toFixed(2) }} oz
                </span>
                <span  v-b-tooltip.hover title="Reported storefront weight, please note that warehouse weight is used at fulfillment." v-if="activeProject && !activeProject.sendWeightsFromStorefront"><i class="fa fa-bell bg-danger" /></span>
              </template>
            </b-table>
          </div>
        </div>
        <template v-slot:footer>
          <form
            @submit.prevent
            name="orderItemForm"
            id="orderItemForm"
            data-vv-scope="orderItemForm"
          >
            <div class="alert alert-info p-2" v-if="!itemForm.projectId">
              <b>Please pick the storefront first in the above form to be able to add products to this order.</b>
            </div>
            <div class="alert alert-warning p-2" v-else-if="loadingProductItems">
              <b>Loading Products, please wait...</b>
            </div>
            <div class="form-row" v-else>
              <div class="col-6 col-md-4">
                <b-form-group label="Pick Product">
                  <b-v-select
                    key="form-iteminput-productId"
                    v-validate="{ required: true }"
                    name="form-iteminput-productId"
                    :state="
                      validateStateScoped(
                        'orderItemForm',
                        'form-iteminput-productId'
                      )
                    "
                    :showup="true"
                    v-model="orderItemForm.pickedProduct"
                    :searchable="true"
                    :allowNewValues="true"
                    :options="productsList"
                  />
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label="Quantity">
                  <b-input-group>
                    <b-form-input
                      type="number"
                      step="1"
                      key="form-iteminput-quantity"
                      v-validate="{
                        required: true,
                        numeric: true,
                        min_value: 1
                      }"
                      name="form-iteminput-quantity"
                      :state="
                        validateStateScoped(
                          'orderItemForm',
                          'form-iteminput-quantity'
                        )
                      "
                      class="form-control"
                      v-model="orderItemForm.quantity"
                      min="1"
                          />
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label="Unit Price">
                  <b-input-group>
                    <b-form-input
                      type="number"
                      step="0.01"
                      key="form-iteminput-unitPrice"
                      name="form-iteminput-unitPrice"
                      class="form-control"
                      v-model="orderItemForm.unitPrice"
                      min="0"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label="Unit Weight">
                  <b-input-group>
                    <b-form-input
                      type="number"
                      step="0.01"
                      key="form-iteminput-unitWeightOz"
                      name="form-iteminput-unitWeightOz"
                      class="form-control"
                      v-model="orderItemForm.unitWeightOz"
                      min="0"
                      :disabled="activeProject && !activeProject.sendWeightsFromStorefront"
                    />
                    <template #append>
                      <div class="btn btn-secondary active">oz</div>
                    </template>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6 col-md-2">
                <b-form-group label=".">
                  <b-button
                    type="button"
                    block
                    variant="primary"
                    @click="AddOrderItem"
                  >
                    Add
                  </b-button>
                </b-form-group>
              </div>
            </div>
          </form>
        </template>
      </b-card>
    </div>
    <aside class="col-12 col-sm-3">
      <b-card header="Actions">
        <div v-if="has('pm.orders.checkout.create')">
          <div class="p-2">
              <b-form-checkbox v-model="itemForm.createOrderCheckout" name="check-button" switch>
                Generate Order Checkout
              </b-form-checkbox>
          </div>
        </div>
        <b-button
          :disabled="savingItem"
          variant="primary"
          block
          type="submit"
          @click="SaveForm"
        >
          <span v-if="savingItem">Saving...</span>
          <span v-else>Save Changes</span>
        </b-button>
        <button
          v-if="(activeProject && activeProject.name == 'Sunbasket')"
          @click="SimulateSunbasketOrder(false)"
          class="btn btn-info btn-block"
          :disabled="loadingOrderChange"
        >
          Validate Order in Sunbasket
        </button>
      </b-card>
      <b-card header="Carrier Config" v-if="(activeProject && !showAdvancedFields) && itemForm.projectId">
        <b-form-group label="Requested Shipping Method">
          <b-form-select
            v-model="itemForm.requestedShippingService"
            :options="reqServicesList"
          />
        </b-form-group>
      </b-card>
    </aside>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStoreGetSet } from '@/helpers/utils/storeHelpers.js';
import { crudMixinGen, myCommonDataMixin } from '../../../../helpers/utils/vueMixins.js';
export default {
  mixins: [crudMixinGen('orders'), myCommonDataMixin],

  data() {
    return {
      editThisSku: '',
      reshipOrderId: '',
      reshipReason: '',
      cancelSearchSources:[],
      shipPulledInfo: false,
      reshipOrder: null,
      selectedReqShippingMethod: null,

      posOrderStatus: [
        {
          value: 'awaiting_payment',
          text: 'Awaiting Payment'
        },
        {
          value: 'awaiting_shipment',
          text: 'Awaiting Shipment'
        },
        // {
        //   value: 'pending_fulfillment',
        //   text: 'Pending Fulfillment'
        // },
        {
          value: 'on_hold',
          text: 'On Hold'
        },
        {
          value: 'shipped',
          text: 'Shipped'
        }
      ],

      loadingProductItems: false,

      customersListData: [],
      selectedCustomerAutocomplete: null,

      heading: 'Storefront Order Form',
      showAdvancedFields: false,
      readyToLoad: false,
      storesListAll: [],
      storeProdsList: [],
      pickedProductId: '',
      orderItemForm: {
        unitPrice: 0,
        unitWeightOz: 0,
        sku: '',
        name: '',
        productId: null,
        quantity: 1
      },
      orderItemFields: [
        { key: 'sku', label: 'SKU', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'quantity', label: 'Quantity', sortable: false },
        { key: 'unitPrice', label: 'Price', sortable: false },
        { key: 'unitWeightOz', label: 'Weight', sortable: false },
        { key: 'actions' }
      ],
      storefrontReqShipCodesAll: [],
      //storefrontCarriersAll: [],
      //storefrontCarrierServicesAll: []
    }
  },

  computed: {
    ...mapGetters({
      projectsList: 'getProjectsList',
      myProjs: 'getMyProjects',
      countryCodeList: 'getCountryCodeList',
      USAStateCodeList: 'getUSAStateCodeList'
    }),
    ...mapStoreGetSet('orders', {
      itemForm: ['getItemForm', 'setItemForm']
    }),
    projectsListAllowed: {
      get() {
        let myItems = this.myProjects;
        let itmsList = [];
        myItems.forEach(itm => {
          itmsList.push({ value: itm.id, text: itm.name + ' (' + itm.clientCode + ')' });
        });
        return itmsList;
      },
      set(old, val) {}
    },
    reqServicesList: {
      get() {
        let arrs = [{ value: null, text: 'Not Set' }];
        this.storefrontReqShipCodesAll
          .forEach(itm => {
            arrs.push({ value: itm.code, text: itm.name });
          });
        return arrs;
      },
      set(old, val) {}
    },
    activeProject: {
      get() {
        let projId = this.itemForm.projectId;
        return this.myProjs.find(proj => proj.id === projId);
      },
      set(val) {
        //this.itemForm.projectId = val.id;
      }
    },
    storesList: {
      get() {
        let arrs = [];
        // arrs.push({
        //   value: '',
        //   text: 'Not Specified (default)'
        // });
        this.storesListAll.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.storeName
          });
        });
        return arrs;
      },
      set(old, val) {}
    },
    productsList: {
      get() {
        let arrs = [];
        this.storeProdsList.forEach(itm => {
          arrs.push({
            value: itm.id,
            text: itm.sku + ' (' + itm.name + ')'+ ' [Qty: ' + itm.quantityAvail + ')'
          });
        });
        return arrs;
      },
      set(old, val) {}
    }
  },

  watch: {
    selectedCustomerAutocomplete: {
      handler: function(val, oldval) {
        if (val && val.id) {
          this.itemForm.shipmentAddress.addressLine1 = val.street1;
          this.itemForm.shipmentAddress.addressLine2 = val.street2;
          this.itemForm.shipmentAddress.postcode = val.postalCode;
          this.itemForm.shipmentAddress.city = val.city;
          this.itemForm.shipmentAddress.state = val.state;
          this.itemForm.shipmentAddress.phone = val.phone;
          this.itemForm.shipmentAddress.countryCode = val.countryCode;
        }
      },
      deep: true
    },
    'itemForm.shipmentAddress.name': {
      handler: function(val, oldval) {
        let $this = this;
        if (this.cancelSearchSources.length > 0) {
          this.cancelSearchSources.forEach((cnc) => {
            if (cnc) {
              cnc.cancel('Loading different items list now!');
              cnc = false;
            }
          })
        }
        if(this.cancelSearchSource) {
          this.cancelSearchSource.cancel('Loading different items list now!');
        }
        let newtoken = window.$http.CancelToken.source();
        this.cancelSearchSources.push(newtoken);
        setTimeout(() => {
          $this.searchCustomersLists(val, newtoken);
        },600)
      }
    },
    selectedReqShippingMethod: {
      handler: function(val, oldval) {
        // if (val && val.id) {
        //   this.activePO.userPackingListId = val.id;
        // }
      }
    },
    'itemForm.carrierCode': function(newVal) {
      //this.LoadCarrierServicesFor(this.itemForm.projectId);
    },
    'itemForm.projectId': function(newVal) {
      this.LoadProductsFor(newVal);
      this.LoadReqShipCodesFor(newVal);
      //this.LoadCarrierServicesFor(newVal);
    },
    'orderItemForm.pickedProduct': function(newVal) {
      var prod = this.storeProdsList.find(a => a.id == newVal);
      if (prod) {
        this.pickedProductId = prod.id;
        this.orderItemForm.productId = prod.productId;
        this.orderItemForm.unitPrice = prod.price;
        this.orderItemForm.unitWeightOz = prod.weightOz;
        this.orderItemForm.addProductSku = '';
      }else {
        this.orderItemForm.addProductSku = newVal;
        this.orderItemForm.productId = '';
        this.orderItemForm.unitPrice = 0;
        this.orderItemForm.unitWeightOz = 0;
      }
    },
    'orderItemForm.productId': function(newVal) {
      var prod = this.storeProdsList.find(a => a.productId == newVal);
      if (prod) {
        this.orderItemForm.unitPrice = prod.price;
        this.orderItemForm.unitWeightOz = prod.weightOz
      }
    }
  },

  mounted() {
    this.ResetForm();
    this.itemForm.orderKey = '';
    this.itemForm.items = [];
    this.itemForm.shipmentAddress = {
      name: ''
    };
    this.readyToLoad = true;
    this.reshipReason = this.$route.query.reshippedReason;
    if(this.$route.query.reshipOrderId) {
      this.reshipOrderId = this.$route.query.reshipOrderId;
      this.LoadOrder(this.$route.query.reshipOrderId);
    } else {
      this.reshipOrderId = '';
    }
  },

  methods: {
    availableInventoryForSku(sku) {
      let availQty = -1;
      let fndProd = this.storeProdsList.find(a => a.projectId == this.itemForm.projectId && a.sku == sku );
      if(fndProd) {
        availQty = fndProd.quantityAvail;
      }
      return availQty;
    },
    LoadOrder(orderId) {
      this.$http
        .get('/api/common/orders/' + orderId)
        .then(resp => {
          this.reshipOrder = resp.data;
          if(!this.reshipOrder.advancedOptions) {
            this.reshipOrder.advancedOptions = {
              customField1: ''
            }
          }
          let reshipItemForm = JSON.parse(JSON.stringify(this.reshipOrder));
          reshipItemForm.shipmentAddress = {
            name: ''
          };
          reshipItemForm.items.forEach((itm) => {
            if(itm.weight) {
              if(itm.weight.units == 'ounces') {
                  itm.unitWeightOz = itm.weight.value;
              } else if(itm.weight.units == 'grams') {
                  itm.unitWeightOz = parseFloat( parseFloat(parseFloat(itm.weight.value) / 28.34952).toFixed(2) );
              }
            } else {
                itm.unitWeightOz = 0;
            }
          })
          reshipItemForm.orderId = '';
          reshipItemForm.id = '';
          let reshipReason = this.reshipReason;
          if(reshipReason == 'Wrong Product Received') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RL';
          }
          else if(reshipReason == 'Damaged Product by Carrier') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RC';
          }
          else if(reshipReason == 'Missing Packets in Box') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RN';
          }
          else if(reshipReason == 'Missing Item from Order') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RL';
          }
          else if(reshipReason == 'Damaged Product in Box') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RN';
          }
          else if(reshipReason == 'Returned To Sender') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RC';
          }
          else if(reshipReason == 'Empty Packets in Box') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RN';
          }
          else if(reshipReason == 'Package Lost in Transit') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RC';
          }
          else if(reshipReason == 'Other') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-RE';
          }
          else if(reshipReason == 'Exchange') {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-EX';
          } else {
            reshipItemForm.orderNumber = reshipItemForm.orderNumber + '-R';
          }
          reshipItemForm.orderStatus = 'awaiting_shipment';
          reshipItemForm.shipmentAddress.name = this.reshipOrder.shipTo.name;
          reshipItemForm.shipmentAddress.company = this.reshipOrder.shipTo.company;
          reshipItemForm.shipmentAddress.addressLine1 = this.reshipOrder.shipTo.street1;
          reshipItemForm.shipmentAddress.addressLine2 = this.reshipOrder.shipTo.street2;
          reshipItemForm.shipmentAddress.countryCode = this.reshipOrder.shipTo.country;
          reshipItemForm.shipmentAddress.state = this.reshipOrder.shipTo.state;
          reshipItemForm.shipmentAddress.city = this.reshipOrder.shipTo.city;
          reshipItemForm.shipmentAddress.phone = this.reshipOrder.shipTo.phone;
          reshipItemForm.shipmentAddress.postcode = this.reshipOrder.shipTo.postalCode;
          this.itemForm = reshipItemForm;
          this.shipPulledInfo = true;
        })
        .catch(err => {
          console.error(err)
        });
    },
    searchCustomersLists(val, tokn) {
      if(val) {
      this.$http
        .post('/api/common/storefrontCustomers/search', {
          name: val
        }, {cancelToken: tokn.token})
        .then(resp => {
          this.customersListData = resp.data;
        })
        .catch(err => {});
      } else {
          this.customersListData = []
      }
    },

    SimulateSunbasketOrder(hdnl = false) {
      if(this.itemForm.projectId) {
        let order = this.order;
        this.loadingOrderChange = true;
        let swl = this.$swal({
          title: 'Validating Order!',
          text: 'Please wait...',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
         this.itemForm.id = 'test-';
         this.itemForm.items.forEach((itm) => {
           itm.id = '-';
           itm.orderId = 'test-';
         })
        this.$http
          .post('/api/common/orders/'+this.itemForm.projectId+'/sunbasket-simulate-form', this.itemForm)
          .then(resp => {
            this.loadingOrderChange = false;
            swl.close();
            if(resp.data.success) {
              if(hdnl != false) {
                hdnl();
              } else {
                this.$swal('Success!', 'Order was validated against Sunbasket. No issues detected.', 'success');
              }
            } else {
              this.savingItem = false;
              this.$swal('Success!', 'Something went wrong', 'error');
            }
          })
          .catch(err => {
            this.savingItem = false;
            this.loadingOrderChange = false;
              swl.close();
              console.error(err);
              if (err.response && err.response.data.errorMessage) {
                this.$swal({ title: err.response.data.errorMessage, icon: 'error' });
              } else {
                this.$swal({ title: 'Something went wrong!', icon: 'error' });
              }
          });
      }
    },

    RemoveItem(item) {
      this.itemForm.items = this.itemForm.items.filter(
        a => a.id != item.id
      );
    },

    RemoveItem2(item) {
      this.itemForm.items = this.itemForm.items.filter(
        a => a.sku != item.sku
      );
    },

    AddOrderItem() {
      this.$validator.validateAll('orderItemForm').then(result => {
          console.log('this.pickedProductId', this.pickedProductId)
          console.log('orderItemForm called', this.orderItemForm)
        if (!result) {
          console.log('orderItemForm error', result)
          this.savingItem = false;
          return;
        }
        if (
          (this.orderItemForm.productId || this.pickedProductId || this.orderItemForm.addProductSku) &&
          parseFloat(this.orderItemForm.quantity) > 0
        ) {
            let prod = this.storeProdsList.find(
              a => a.productId == this.orderItemForm.productId && a.productId != ''
            );
            let prod2 = this.storeProdsList.find(
              a => a.id == this.pickedProductId && a.id != ''
            );
          if(this.orderItemForm.productId && prod) {
            this.itemForm.items.push({
              sku: prod.sku,
              name: prod.name,
              quantity: parseInt(this.orderItemForm.quantity),
              unitPrice: parseFloat(this.orderItemForm.unitPrice),
              unitWeightOz: parseFloat(this.orderItemForm.unitWeightOz),
              productId: this.orderItemForm.productId
            });
          } else if(this.pickedProductId && prod2){
            this.itemForm.items.push({
              sku: prod2.sku,
              name: prod2.name,
              quantity: parseInt(this.orderItemForm.quantity),
              unitPrice: parseFloat(this.orderItemForm.unitPrice),
              unitWeightOz: parseFloat(this.orderItemForm.unitWeightOz),
              productId: prod2.productId
            });
          } else if(this.orderItemForm.addProductSku){
            this.itemForm.items.push({
              sku: this.orderItemForm.addProductSku,
              name: this.orderItemForm.addProductSku,
              quantity: parseInt(this.orderItemForm.quantity),
              unitPrice: parseFloat(this.orderItemForm.unitPrice),
              unitWeightOz: parseFloat(this.orderItemForm.unitWeightOz),
              productId: ''
            });
          } else {
            alert('No Item Picked!');
          }
          this.pickedProductId = '';
          this.orderItemForm = {
            unitPrice: 0,
            unitWeightOz: 0,
            addProductSku: '',
            sku: '',
            name: '',
            productId: null,
            quantity: 1
          };
        }
      });
    },

    LoadProductsFor(projectId) {
      if (!this.readyToLoad) {
        return;
      }
      if (this.loadingProductItems) {
        return;
      }
      this.storeProdsList = [];
      if (projectId) {
        this.loadingProductItems = true;
        this.storeProdsList = [];
        this.$http
          .post('/api/common/stores/byProject/' + projectId)
          .then(resp => {
            this.storesListAll = resp.data;
            this.$http
              .post('/api/common/products/byProject/' + projectId)
              .then(resp => {
                this.storeProdsList = resp.data;
                this.loadingProductItems = false;
              })
              .catch(err => {
                this.storeProdsList = [];
                this.loadingProductItems = false;
              });
          })
          .catch(err => {
            this.storesListAll = [];
          });
      }
    },
    LoadReqShipCodesFor(projectId) {
      this.storefrontReqShipCodesAll = [];
      if(projectId) {
      this.$http
        .get('/api/storefrontReqShipCodes/'+projectId)
        .then(resp => {
          this.storefrontReqShipCodesAll = resp.data;
        })
        .catch(err => {
          this.storefrontReqShipCodesAll = [];
        });
      }
    },
    // LoadCarriersFor(projectId) {
    //   this.storefrontCarriersAll = [];
    //   if(projectId) {
    //   this.$http
    //     .get('/api/common/storefrontCarriers/byProject/'+projectId)
    //     .then(resp => {
    //       this.storefrontCarriersAll = resp.data;
    //     })
    //     .catch(err => {
    //       this.storefrontCarriersAll = [];
    //     });
    //   }
    // },
    // LoadCarrierServicesFor(projectId) {
    //   this.storefrontCarrierServicesAll = [];
    //   if(projectId && this.itemForm.carrierCode) {
    //   this.$http
    //     .get('/api/common/storefrontCarrierServices/by/'+projectId+'/'+this.itemForm.carrierCode)
    //     .then(resp => {
    //       this.storefrontCarrierServicesAll = resp.data;
    //     })
    //     .catch(err => {
    //       this.storefrontCarrierServicesAll = [];
    //     });
    //   }
    // },

    SaveFormRequest() {
      if(!this.itemForm.orderKey) {
        this.itemForm.orderKey = this.itemForm.orderNumber;
      }
      if(this.reshipOrderId) {
        this.itemForm.orderKey = this.itemForm.orderNumber;
        this.itemForm.parentReshipOrderId = this.reshipOrderId;
        this.itemForm.isConnectReship = true;
      }
      this.$http
        .post('/api/common/orders/create', this.itemForm)
        .then(resp => {
          if (resp.data.success) {
            this.$router.push({
              name: 'Orders.Index'
            });
          } else if (resp.data.errorMessage) {
            this.$swal({ title: resp.data.errorMessage, icon: 'error' });
          } else {
            this.$swal({ title: 'Order Was NOT Saved!', icon: 'error' });
          }
          this.ResetForm();
          this.savingItem = false;
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errorMessage) {
            this.$swal({
              title: err.response.data.errorMessage,
              icon: 'error'
            });
          } else {
            this.$swal({ title: 'Order Was NOT Saved!', icon: 'error' });
          }
          console.log('CreatePrder:SaveFormRequest error', err.response.data);
          console.error('CreatePrder:SaveFormRequest err', err);
          this.savingItem = false;
        });
    },

    SaveForm() {
      this.$validator.validateAll('itemForm').then(result => {
        if (!result) {
          this.savingItem = false;
          return;
        }
        if (
          this.itemForm.items.length > 0 &&
          this.itemForm.shipmentAddress.name &&
          this.itemForm.shipmentAddress.addressLine1 &&
          this.itemForm.projectId &&
          this.itemForm.orderNumber
        ) {
          this.savingItem = true;
          if((this.activeProject && this.activeProject.name == 'Sunbasket')) {
            this.SimulateSunbasketOrder((resp) => {
              this.SaveFormRequest();
            });
          } else {
            this.SaveFormRequest();
          }
       } else {
          this.$swal({ title: 'Fill out required fields!', icon: 'error' });
          this.savingItem = false;
        }
      });
    }
  }
};
</script>
