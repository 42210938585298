<template>
  <div>
      <b-modal
        id="split-items"
        v-model="showSplitFormLive"
        :centered="true"
        size="lg"

        :title="customTitle ? customTitle : defTitle"
        :no-close-on-backdrop="true"
      >
      <section
         v-if="previewEmail">
         <h3>Preview Email:</h3>
        <div class="border p-3" v-html="previewEmail"></div>
      </section>
      <section v-else-if="previewSMS">
         <h3>Preview SMS:</h3>
         <div class="border p-3">
          <b-form-textarea rows="5" :disabled="true">{{previewSMS}}</b-form-textarea>
        </div>
      </section>
      <section v-else>
        <b-card>
          <div>
            <!--pick the storeId from storesList-->
            <b-form-group label="Order Group:">
              <b-form-select
                v-model="orderGroupId"
                :options="storesListPos"
              />
            </b-form-group>
          </div>
          <ul v-if="orderItemsPicked.length" class="list-unstyled">
            <h5 class="pb-2 border-bottom">Order Items to Split Ship Later:</h5>
            <b-media
              v-for="item in orderItemsPicked"
              :key="item.itemId"
              tag="li"
            >
              <b-row class="mb-0">
                <b-col col md="6" class="border-bottom pt-2">
                  <h5 class="mt-0 mb-1">
                    {{ item.name }}
                  </h5>
                  <p class="mb-0">SKU: {{ item.sku }}</p>
                </b-col>
                <b-col col md="5" class="border-bottom pt-2 border-right border-left">
                  <b-form-group
                      :invalid-feedback="'Must be above 0 and below ' + (item.originalQty+1)"
                      :state="(item.quantity>0 && item.quantity<=item.originalQty)"
                      :label="'Quantity to Split (out of '+item.originalQty+')'"
                      :valid-feedback="'Valid Qty! Left on Order: ' + (item.originalQty - item.quantity)">
                    <b-form-input v-model="item.quantity" :disabled="loadingSplitResult" type="number" step="1" min="1" :max="item.originalQty" />
                  </b-form-group>
                </b-col>
                <b-col col md="1" class="border-bottom pt-2 border-sright">
                  <button
                    class="btn btn-danger mt-4"
                    type="button"
                    @click="RemoveSplitOrderItem(item.id)"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </b-col>
              </b-row>
            </b-media>
          </ul>
          <div v-else class="alert alert-warning p-2">
            You must pick at least one item to split of into a new order.
          </div>

          <b-form-group label="" v-if="canOptIn1">
            <b-form-checkbox
              v-model="shouldOptIn1"
              name="check-button"
              switch
            >
              Send an email to the customer with the new order details.
            </b-form-checkbox>
             <a href="#" @click.prevent="PreviewOpt1" class="small d-inline-block mr-2">[Preview]</a>
          </b-form-group>

          <b-form-group label="" v-if="canOptIn2">
            <b-form-checkbox
              v-model="shouldOptIn2"
              name="check-button"
              switch
            >
              Send an SMS to the customer with the new order details.
            </b-form-checkbox>
             <a href="#" @click.prevent="PreviewOpt2" class="small d-inline-block mr-2">[Preview]</a>
          </b-form-group>
          <template v-slot:footer>
            <div class="form-row" v-if="toPickOrderItemsList.length">
              <b-form-group label="Product" class="col-7">
                <v-select
                  v-model="splitItemForm.productItm"
                  label="text"
                  :reduce="item => item.value"
                  :options="toPickOrderItemsList"
                />
              </b-form-group>
              <div v-if="pickedProductItm && pickedProductItm.originalQty" class="col-4">
                <b-form-group
                    :invalid-feedback="'Must be above 0 and below '+ (pickedProductItm.originalQty+1)"
                    :state="(splitItemForm.quantity>0 && splitItemForm.quantity<=pickedProductItm.originalQty)"
                    :label="'Quantity (out of '+pickedProductItm.originalQty+')'"
                  >
                  <b-form-input
                  v-model="splitItemForm.quantity" min="1" :max="pickedProductItm.originalQty" />
                </b-form-group>
              </div>
              <b-form-group label="Quantity" class="col-4" v-else description="First pick a product!">
                <b-form-input
                :disabled="true"
                 v-model="splitItemForm.quantity" min="1" />
              </b-form-group>
              <b-form-group label="Action" class="col-1">
                <button class="btn btn-primary" @click="AddSplitOrderItem()">
                  Add
                </button>
              </b-form-group>
            </div>
            <div v-else class="alert alert-warning p-2">
              No more order items to split off.
            </div>
          </template>
        </b-card>

        <div class="alert alert-info p-2" v-if="notPickedOrderItemsList.length">
          <h5>Order Items that will ship now:</h5>
          <div class="border-bottom py-1" :key="key" v-for="(notPickedOrder, key) in notPickedOrderItemsList">
            <span :class="{ 'text-danger': true }"
              v-if="notPickedOrder.notEnoughInventory"
                ><i
                  v-b-tooltip.hover
                  class="fa fa-info-circle"
                  title="This order item did not have enough inventory to be fulfilled."
                />
            </span>
            <b>{{notPickedOrder.name}} - {{notPickedOrder.sku}}</b> - Qty: {{notPickedOrder.quantity}}
          </div>
        </div>
        <div class="alert alert-danger p-2" v-else>
          <b>No items left on original order! This is not allowed!</b>
        </div>

      </section>

        <template v-slot:modal-footer>
          <div class="w-100" v-if="!previewEmail && !previewSMS">
            <b-button
              type="submit"
              variant="primary"
              class="ml-2 float-right"
              :disabled="loadingSplitResult"
              @click.prevent="SplitShipmentOrderConfirm"
            >
              <span v-if="splitMultiple">
                Confirm &amp; Open Next
              </span>
              <span v-else>
                Confirm
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="warning"
              class="ml-2 float-right"
              :disabled="loadingSplitResult"
              v-if="splitMultiple"
              @click="NextOrderInList"
            >
              Skip
            </b-button>
            <b-button
              type="submit"
              variant="secondary"
              class="ml-2 float-right"
              :disabled="loadingSplitResult"
              @click="showSplitFormLive = false"
            >
              Cancel
            </b-button>
          </div>

          <div class="w-100" v-else>
            <b-button
              type="submit"
              variant="secondary"
              class="ml-2"
              @click="previewEmail = ''; previewSMS = ''"
            >
              Back to form
            </b-button>
          </div>
        </template>
      </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';
export default {
  components: {
    'v-select': vSelect
  },
  props: {
    customTitle: {
      type: String,
      default: ''
    },
    splitMultiple: {
      type: Boolean,
      default: false,
    },
    splitMultipleList: {
      type: Array,
      default() {
          return []
      }
    },
    order: {
      type: Object,
      required: true
    },
    showSplitForm: {
      type: Boolean,
      required: true
    },
    prefillOrder: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    storesListPos: {
      get() {
        let arrs = [];
        //arrs.push({ value: '', text: 'All' });
        this.storesList.forEach(itm => {
          arrs.push({ value: itm.id, text: itm.storeName });
        });
        return arrs;
      },
      set(val, old) {}
    },
    pickedProductItm: {
      get() {
        let itm = null;
        let itmSku = this.splitItemForm.productItm;
        itm = this.toPickOrderItemsList.find(a => a.value == itmSku);
        return itm;
      },
      set(value) {
        //this.splitItemForm.productItm = value
      }
    },
    ...mapGetters({
      myContentTemplates: 'getMyContentTemplates',
    }),
    canOptIn1: {
      get() {
        let canOptIn = false;
        if(this.order && this.order.projectId) {
          let foundAutomation = this.myContentTemplates.find(a => a.projectId == this.order.projectId && a.eventId == 'ORDER_SPLIT_USEROPT1');
          if(foundAutomation && foundAutomation.type == 'Email') {
            canOptIn = true;
          }
        }
        return canOptIn;
      },
      set(value) {
        //this.$emit('update:showSplitForm', value);
      }
    },
    canOptIn2: {
      get() {
        let canOptIn = false;
        if(this.order && this.order.projectId) {
          let foundAutomation = this.myContentTemplates.find(a => a.projectId == this.order.projectId && a.eventId == 'ORDER_SPLIT_USEROPT2');
          if(foundAutomation && foundAutomation.type == 'SMS') {
            canOptIn = true;
          }
        }
        return canOptIn;
      },
      set(value) {
        //this.$emit('update:showSplitForm', value);
      }
    },

    notPickedOrderItemsList: {
      get() {
        let arrs = [];
        if(this.order.items) {
          let list = JSON.parse(JSON.stringify(this.order.items));
          if(list && list.length) {
            list.forEach(itm => {
              if(this.orderItemsPicked.find(a => a.sku == itm.sku) == null) {
                arrs.push(itm);
              } else {
                let pickdItm = this.orderItemsPicked.find(a => a.sku == itm.sku);
                itm.quantity = itm.quantity - pickdItm.quantity;
                if(itm.quantity>0) {
                  arrs.push(itm);
                }
              }
            });
          }
        }
        return arrs;
      },
      set(val) {

      }
    },
    showSplitFormLive: {
      get() {
        return this.showSplitForm;
      },
      set(value) {
        this.$emit('update:showSplitForm', value);
      }
    },
    toPickOrderItemsList: {
      get() {
        let arrs = [];
        if(this.order.items) {
          let list = [];
          if(this.splitMultiple) {
            list = JSON.parse(JSON.stringify(this.order.items.filter(a => a.notEnoughInventory)));
          } else {
            list = JSON.parse(JSON.stringify(this.order.items));
          }
          if(list && list.length) {
            list.forEach(itm => {
              if(this.orderItemsPicked.find(a => a.sku == itm.sku) == null) {
                arrs.push({
                  text: itm.name + ' (' + itm.sku + ')',
                  label: itm.name + ' (' + itm.sku + ')',
                  value: itm.sku,
                  name: itm.name,
                  sku: itm.sku,
                  originalQty: itm.quantity
                });
              }
            });
          }
        }
        return arrs;
      },
      set(val, old) {
      }
    },
  },
  watch: {
    showSplitFormLive: function(val, old) {
      if(val && this.order) {
        this.SplitShipmentOrder(this.order);
      }
    }
  },
  mounted() {
    if(this.prefillOrder) {
      let orderItemsToPrefill = this.order.items.filter(a => a.notEnoughInventory);
      this.orderGroupId = this.order.storeId;
      this.orderItemsPicked = [];
      orderItemsToPrefill.forEach((itm) => {
        this.orderItemsPicked.push({
          id: (itm.lineItemKey ? 'itm' + new Date().getUTCMilliseconds() + '-' + itm.lineItemKey : 'itm' + new Date().getUTCMilliseconds()),
          unitPrice: itm.unitPrice,
          originalQty: itm.quantity,
          quantity: itm.quantity,
          lineItemKey: (itm.lineItemKey ? 'itm' + new Date().getUTCMilliseconds() + '-' + itm.lineItemKey : 'itm' + new Date().getUTCMilliseconds()),
          orderId: 'tmpId',
          sku: itm.sku,
          productId: itm.productId,
          name: itm.name
        });
      });
    } else {
      this.orderItemsPicked = [];
      this.orderGroupId = this.order.storeId;
    }
    if(this.splitMultiple) {
      this.defTitle = 'Order ' + this.orderInList + ' / ' + this.splitMultipleList.length + ' to split - Order #' + this.order.orderNumber;
    } else {
      this.defTitle = 'Split Order - Order #' + this.order.orderNumber;
    }
    this.loadStoresForOrder();
  },
  methods: {
    PreviewOpt1() {
      let foundAutomationContent = ''
      if(this.order && this.order.projectId) {
        let foundAutomation = this.myContentTemplates.find(a => a.projectId == this.order.projectId && a.eventId == 'ORDER_SPLIT_USEROPT1');
        if(foundAutomation && foundAutomation.type == 'Email') {
          foundAutomationContent = foundAutomation.content;
        }
      }
      this.previewEmail = foundAutomationContent;
    },
    PreviewOpt2() {
      let foundAutomationContent = ''
      if(this.order && this.order.projectId) {
        let foundAutomation = this.myContentTemplates.find(a => a.projectId == this.order.projectId && a.eventId == 'ORDER_SPLIT_USEROPT1');
        if(foundAutomation && foundAutomation.type == 'SMS') {
          foundAutomationContent = foundAutomation.content;
        }
      }
      this.previewSms = foundAutomationContent;
    },
    NextOrderInList() {
      this.orderInList++;
      if(this.orderInList>this.splitMultipleList.length) {
        this.showSplitFormLive = false;
        this.$swal('Finished!', 'All Orders in the list have been split!', 'success');
      } else {
        this.order = this.splitMultipleList[this.orderInList-1];
        this.$emit('update:order', this.order);
        this.defTitle = 'Order ' + this.orderInList + ' / ' + this.splitMultipleList.length + ' to split - Order #' + this.order.orderNumber;

        if(this.prefillOrder) {
          let orderItemsToPrefill = this.order.items.filter(a => a.notEnoughInventory);
          this.orderItemsPicked = [];
          orderItemsToPrefill.forEach((itm) => {
            this.orderItemsPicked.push({
              id: 'itm' + new Date().getUTCMilliseconds(),
              unitPrice: itm.unitPrice,
              originalQty: itm.quantity,
              quantity: itm.quantity,
              lineItemKey: 'itm' + new Date().getUTCMilliseconds(),
              orderId: 'tmpId',
              sku: itm.sku,
              productId: itm.productId,
              name: itm.name
            });
          });
        }
        this.SplitShipmentOrder(this.order);
      }
    },
    RemoveSplitOrderItem(id) {
      this.orderItemsPicked = this.orderItemsPicked.filter(
        s => s.id != id
      );
    },

    AddSplitOrderItem() {
      let prodFound = this.toPickProducts.find(
        s => s.sku == this.splitItemForm.productItm
      );
      let prodOriginalFound = this.toPickOrderItemsList.find(
        s => s.sku == this.splitItemForm.productItm
      );
      let qty = parseInt(this.splitItemForm.quantity);
      let price = parseFloat(this.splitItemForm.unitPrice);
      if (prodOriginalFound && qty > 0 && qty <= prodOriginalFound.originalQty) {
        this.orderItemsPicked.push({
          id: 'itm' + new Date().getUTCMilliseconds(),
          unitPrice: price,
          originalQty: prodOriginalFound.originalQty,
          quantity: qty,
          lineItemKey: 'itm' + new Date().getUTCMilliseconds(),
          orderId: 'tmpId',
          sku: prodOriginalFound.sku,
          productId: prodFound ? prodFound.productId : '',
          name: prodOriginalFound.name
        });
        this.splitItemForm.productItm = null;
        this.splitItemForm.unitPrice = 0;
        this.splitItemForm.quantity = 1;
      }
      else  if (prodFound && prodOriginalFound)
      {
        this.$swal('Invalid Quantity Entered!');
      }
      else {
        this.$swal('You must fill out the fields!');
      }
    },
    loadStoresForOrder() {
      this.$http
          .post('/api/common/stores/byProject/' + this.order.projectId)
          .then(resp => {
            this.storesList = resp.data;
          })
          .catch(err => {
            this.storesList = [];
          });
    },

    SplitShipmentOrder(order) {
      this.splitItemForm.productId = '';
      this.splitItemForm.unitPrice = 0;
      this.splitItemForm.quantity = 1;
      this.toPickProducts = [];

      this.$http
        .post('/api/common/products/byProject/' + order.projectId)
        .then(resp => {
          this.toPickProducts = resp.data;
          order.items.forEach((it) => {
            it.originalQty = it.quantity;
            if(it.productId) {
              it.itemProduct = this.toPickProducts.find(a => a.id == it.productId);
            } else {
              it.itemProduct = this.toPickProducts.find(a => a.sku == it.sku);
            }
          })
          //this.orderItemsPicked = order.items;
        })
        .catch(err => {});
      this.loadStoresForOrder();
    },

    SplitShipmentOrderConfirm() {
      let itemsList = this.orderItemsPicked;
      let allGood = true;
      let noItemsPicked = true;
      let noItemsLeftPicked = true;
      itemsList.forEach((it) => {
        it.quantity = parseInt(it.quantity);
        if(it.quantity>=0 && it.quantity<=it.originalQty) {
          //allGood = true;
        } else {
          allGood = false;
        }
        if(it.quantity>0) {
          noItemsPicked = false;
        }
        // if(it.quantity != it.originalQty) {
        //   noItemsLeftPicked = false;
        // }
      });
      this.order.items.forEach((itm) => {
        let fndItm = itemsList.find(a => a.id == itm.id);
        if(fndItm) {
          if(fndItm.quantity > itm.quantity) {
            noItemsLeftPicked = false;
          }
        } else {
          noItemsLeftPicked = false;
        }
      })
      if(!allGood) {
        this.$swal({
          title: 'Invalid Quantity Selected!',
          text: 'You cannot split an order if you set the quantity less then 0, or if quantity is more then the original quantity.',
          icon: 'error',
        })
        return false;
      }
      if(noItemsPicked) {
        this.$swal({
          title: 'Invalid Quantity Selected!',
          text: 'You are trying to split of an order, but with 0 order items in it, please check your order items quantity.',
          icon: 'error',
        });
        return false;
      }
      if(noItemsLeftPicked) {
        this.$swal({
          title: 'Invalid Quantity Selected!',
          text: 'You are trying to split of an order, but the new order contains all the items from the original, nothing is left in the initial order!',
          icon: 'error',
        });
        return false;
      }
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, split the order!'
      }).then(result => {
        if (result.value) {
          this.loadingSplitResult = true;
          this.$http
            .post('/api/common/orders/split/' + this.order.id, {
              items: itemsList,
              orderGroupId: this.orderGroupId,
              optIn1: this.shouldOptIn1,
              optIn2: this.shouldOptIn2
            })
            .then(resp => {
              this.loadingSplitResult = false;

              if (resp.data.success) {
                this.$swal('Order Split!', 'Order was split.', 'success');
                if(!this.splitMultiple) {
                  this.showSplitFormLive = false;
                } else {
                  this.NextOrderInList();
                }
                //this.loadItem();
              } else {
                if(!this.splitMultiple) {
                  this.showSplitFormLive = false;
                }
                this.$swal(
                  'Order was NOT Split!',
                  'Something went wrong',
                  'error'
                );
              }
            })
            .catch(err => {
              this.loadingSplitResult = false;
              if(!this.splitMultiple)
                this.showSplitFormLive = false;
              if (err.response && err.response.data.errorMessage) {
                this.$swal({ title: 'Order was NOT Split!', text: err.response.data.errorMessage, icon: 'error' });
              } else {
                this.$swal(
                  'Order was NOT Split!',
                  'Something went wrong',
                  'error'
                );
              }
            });
        }
      });
    },
  },
  data() {
    return {
      storesList: [],
      previewEmail: '',
      previewSMS: '',
      shouldOptIn1: false,
      shouldOptIn2: false,
      orderInList: 1,
      defTitle: 'Split Order',
      loadingSplitResult: false,
      splitItemForm: {
        productItm: null,
        quantity: 1,
        unitPrice: 0
      },
      orderGroupId: '',
      toPickProducts: [],
      orderItemsPicked: []
    }
  }
}
</script>
