<template>
    <section>
        <div class="card-body"
            v-if="!internalChannel">
            <button
            class="mt-2 btn btn-block btn-outline-primary"
            @click="StartDiscussion()"
            >
            <span><i class="fa fa-comments-o"></i> Open Discussion</span>
            </button>
        </div>
        <div v-else>
            <ChannelWindow :sidebarView="true" :channel="internalChannel"></ChannelWindow>
        </div>
    </section>
</template>
<script>
import ChannelWindow from '../pages/communications/ChannelWindow.vue';
export default {
  name: 'ChatWidgetInSidebar',
  components: {
    ChannelWindow
  },
  props: {
    startOpen: {
        type: Boolean,
        required: false,
        default: false
    },
    channelType: {
        type: String,
        required: true
    },
    relatedDesc: {
        type: String,
        required: true
    },
    relatedName: {
        type: String,
        required: true
    },
    relatedId: {
        type: String,
        required: true
    },
    relatedType: {
        type: String,
        required: true
    }
  },
  data() {
    return {
        internalChannel: null
    };
  },
  mounted() {
    //if(this.has('pm.communications.purchaseOrders.internalChannel'))
    if(this.startOpen)
        this.StartDiscussion();
  },
  methods: {
    StartDiscussion() {
      if(this.relatedId) {
        this.$http
          .post('/api/communications/channels/startDiscussionFor/' + this.channelType, {
            relatedId: this.relatedId, //this.itemForm.id,
            relatedType: this.relatedType,//'BoM',
            name: this.relatedName, //'Bill of Materials: ' + this.itemForm.name,
            description: this.relatedDesc //'Internal discussion for BoM.'
          })
          .then(resp => {
            if (resp.data.id) {
              //this.$router.push({name: 'Communications.Index', query: {channelId: resp.data.id}});
              this.internalChannel = resp.data;
            }
          })
          .catch(err => {});
      }
    },
  }
};
</script>