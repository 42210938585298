<template>
  <div>
    <div class="mb-2 d-flex">
      <div class="col-auto pr-1">
        <label>Sort By: </label>
      </div>
      <div class="col">
        <b-form-select
          v-model="orderByInternal"
          size="sm"
          :options="posSortBySummary"
        />
      </div>
    </div>

    <b-list-group>
      <b-list-group-item v-for="item in listItems" :key="item.id" class="p-1">
        <div class="item-summary-item">
          <div class="item-summary-label pl-2">
            <span
              :id="'dht' + item.id"
              class="d-inline-block badge badge-primary mr-1"
              ><i class="fa fa-info-circle"
            /></span>
            <span class="badge badge-secondary">{{
              item.warehouseProductItem
            }}</span>
            -
            <span>{{ item.warehouseProductDescription }}</span>
          </div>
          <div
            class="item-summary-numbers mb-1 pb-1 d-flex"
            style="white-space: nowrap"
          >
            <div class="border-right col">
              <span>Requested:</span>
              {{ item.quantity }}
            </div>
            <div class="border-right col">
              <span>Sent:</span>
              {{ item.quantityTotalShipped }}
            </div>
            <div class="col">
              <span>Received:</span>
              {{ item.quantityTotalReceived }}
            </div>
          </div>
        </div>

        <b-popover
          v-if="order.shippings.length > 0"
          class="popovermappings"
          boundary="window"
          :target="'dht' + item.id"
          triggers="hover"
          placement="top"
        >
          <template v-slot:title>
            By Shipment
          </template>
          <b-list-group>
            <b-list-group-item
              v-for="shipPO in order.shippings"
              :key="shipPO.id"
              class="p-1"
            >
              <div class="item-summary-item">
                <div
                  class="item-summary-numbers mb-1 pb-1 d-flex"
                  style="white-space: nowrap"
                >
                  <div class="border-right col item-summary-label">
                    <span class="badge badge-secondary">{{ shipPO.customReferenceNumber }}</span>
                  </div>
                  <div
                    class="border-right col"
                  >
                    <span>Sent:</span>
                    {{
                      shipPO.items.find(a => a.purchaseOrderItemId == item.id)
                        ? shipPO.items.find(
                            a => a.purchaseOrderItemId == item.id
                          ).quantityShipped
                        : 'N/A'
                    }}
                  </div>
                  <div
                    class="col"
                  >
                    <span>Received:</span>
                    {{
                      shipPO.items.find(a => a.purchaseOrderItemId == item.id)
                        ? shipPO.items.find(
                            a => a.purchaseOrderItemId == item.id
                          ).quantityReceived
                        : 'N/A'
                    }}
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-popover>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: 'POItemsSummary',
  props: {
    order: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      orderByInternal: '',
      posSortBySummary: [
        {
          value: '',
          text: 'Order Added'
        },
        {
          value: 'requested',
          text: 'Requested'
        },
        {
          value: 'sent',
          text: 'Sent'
        },
        {
          value: 'received',
          text: 'Received'
        }
      ]
    };
  },
  computed: {
    listItems: {
      get() {
        let itms = this.order.items;
        let sort = this.orderByInternal;
        if (sort == 'received') {
          itms = itms.sort((firstEl, secondEl) => {
            if (
              firstEl.quantityTotalReceived > secondEl.quantityTotalReceived
            ) {
              return 1;
            }
            if (
              firstEl.quantityTotalReceived < secondEl.quantityTotalReceived
            ) {
              return -1;
            }
            return 0;
          });
        } else if (sort == 'sent') {
          itms = itms.sort((firstEl, secondEl) => {
            if (firstEl.quantityTotalShipped > secondEl.quantityTotalShipped) {
              return 1;
            }
            if (firstEl.quantityTotalShipped < secondEl.quantityTotalShipped) {
              return -1;
            }
            return 0;
          });
        } else if (sort == 'requested') {
          itms = itms.sort((firstEl, secondEl) => {
            if (firstEl.quantity > secondEl.quantity) {
              return 1;
            }
            if (firstEl.quantity < secondEl.quantity) {
              return -1;
            }
            return 0;
          });
        } else {
          itms = itms.sort((firstEl, secondEl) => {
            if (firstEl.id > secondEl.id) {
              return 1;
            }
            if (firstEl.id < secondEl.id) {
              return -1;
            }
            return 0;
          });
        }

        return itms;
      },
      set(val) {}
    }
  },
  methods: {}
};
</script>
