//import { pagination, tableOptions } from '../../..//utils/filters';
import apiHelpers from './apiHelpers.js';
import { mapCrudStoreGen } from '@/helpers/utils/storeHelpers.js';
let defaultForm = {
  code: '',
  name: '',
  onlySyncStoreId: ''
};

let defaultFilters = {
  code: '',
  name: ''
};

let genedStore = mapCrudStoreGen(defaultForm, defaultFilters, apiHelpers);

export default {
  namespaced: true,

  state: () => ({
    ...genedStore.state,
    tableOptions: {
      ...genedStore.state.tableOptions,
      loadedAll: true
    }
  }),

  mutations: {
    ...genedStore.mutations
  },

  getters: {
    ...genedStore.getters
  },

  actions: {
    ...genedStore.actions,

    fetchItems(
      { state, commit, rootState },
      { saveToStore, cancelToken } = {
        saveToStore: true,
        cancelToken: false
      }
    ) {
      return new Promise((resolve, reject) => {
        state.loadingItems = true;
        apiHelpers
          .fetchItems(state.filters, state.pagination, cancelToken)
          .then(resp => {
            if(resp && resp.data)  {
              if (saveToStore) {
                state.tableOptions.totalRows = resp.data.length;
                commit('setItems', resp.data);
              }
              if(state.failedLoadItems)
                state.failedLoadItems = false;
              state.loadingItems = false;
              resolve(resp);
            } else {
              state.failedLoadItems = true;
              state.loadingItems = false;
              state.tableOptions.totalRows = 0;
              commit('setItems', []);
            }
          })
          .catch(err => {
            state.failedLoadItems = true;
            state.loadingItems = false;
            reject(err);
          });
      });
    }
  }
};
