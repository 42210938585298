import GlobalModule from './modules/global';
import AccountModule from './modules/account/index';
import UserProfilesModule from './modules/userProfiles/index';

import PurchaseOrdersModule from './modules/purchaseOrders/index';

import PurchaseOrders2Module from './modules/purchaseOrders2/index';
import WarehouseShipmentsModule from './modules/warehouseShipments/index';

import ItemsReportModule from './modules/itemsReport/index';
import BrandsModule from './modules/brands/index';
import ProjectsModule from './modules/projects/index';
import WarehousesModule from './modules/warehouses/index';
import WarehouseTransfersModule from './modules/warehouseTransfers/index';
import ManualShipmentsModule from './modules/manualShipments/index';

import GeneratedReportsModule from './modules/generatedReports/index';
import WarehouseProductLocationsModule from './modules/warehouseProductLocations/index';

import RateCardsModule from './modules/rateCards/index';

import BulkShipmentsModule from './modules/bulkShipments/index';

import LogActionsModule from './modules/logActions/index';
import ShipCodeMappings from './modules/shipCodeMappings/index';
import IRMSPurchaseOrdersModule from './modules/irmsPurchaseOrders/index';
import IRMSCustomerOrdersModule from './modules/irmsCustomerOrders/index';
import OrdersModule from './modules/orders/index';
import OrderTagsModule from './modules/orderTags/index';
import ReturnsModule from './modules/returns/index';

import TPLFacilitiesModule from './modules/tpl/facilities/index';
import TPLCustomersModule from './modules/tpl/customers/index';
import TPLShipmentServicesModule from './modules/tpl/shipmentServices/index';
import TPLBillingCodesModule from './modules/tpl/billingCodes/index';
import TPLUOMModule from './modules/tpl/uom/index';
import TPLCarriers from './modules/tpl/carriers/index';
import TPLReceivers from './modules/tpl/receivers/index';
import TPLAdjustmentsModule from './modules/tpl/adjustments/index';

import BillingCustomerInvoicesModule from './modules/billing/customerInvoices/index';
import BillingCustomerBillingLedgerModule from './modules/billing/customerBillingLedger/index';
import BillingPtcFilesModule from './modules/billing/ptcFiles/index';
import BillingCarrierInvoicesModule from './modules/billing/carrierInvoices/index';

import Automation from './modules/automation/automation/index';
import ContentTemplates from './modules/automation/contentTemplates/index';
import AutomationsContent from './modules/automation/automationsContent/index';

import ManageUsersModule from './modules/manageUsers/index';

import StorefrontShipmentsModule from './modules/storefrontShipments';
import StorefrontProductsModule from './modules/storefrontProducts';

import WorkOrdersModule from './modules/manufacturing/workOrders/index';
import RnDProjectsModule from './modules/manufacturing/rndProjects/index';
import BillOfMaterialsModule from './modules/manufacturing/billOfMaterials/index';

import VendorsModule from './modules/vendors';
import DiuModule from './modules/diu';
import OrderGroupsModule from './modules/orderGroups';

import WarehouseProductsModule from './modules/warehouseProducts/index';
import StorefrontProductGroupsModule from './modules/storefrontProductGroups/index';
import WarehouseProductGroupsModule from './modules/warehouseProductGroups/index';

import LedgerEntriesModule from './modules/ledgerEntries/index';
import SupportTicketsModule from './modules/supportTickets/index';

import DocsKnowledgebasePages from './modules/docs/knowledgebasePages/index';
import DocsKnowledgebaseGroups from './modules/docs/knowledgebaseGroups/index';
import DocsChangelog from './modules/docs/changelog/index';

export default {
  g: GlobalModule,
  account: AccountModule,
  userProfiles: UserProfilesModule,

  manageUsers: ManageUsersModule,

  ledgerEntries: LedgerEntriesModule,
  storefrontProducts: StorefrontProductsModule,
  irmsPurchaseOrders: IRMSPurchaseOrdersModule,
  irmsCustomerOrders: IRMSCustomerOrdersModule,

  vendors: VendorsModule,
  orderTags: OrderTagsModule,
  orders: OrdersModule,
  shipCodeMappings: ShipCodeMappings,
  logActions: LogActionsModule,
  projects: ProjectsModule,
  brands: BrandsModule,
  warehouses: WarehousesModule,

  warehouseShipments: WarehouseShipmentsModule,
  purchaseOrders2: PurchaseOrders2Module,

  purchaseOrders: PurchaseOrdersModule,
  warehouseTransfers: WarehouseTransfersModule,
  manualShipments: ManualShipmentsModule,
  warehouseProducts: WarehouseProductsModule,
  generatedReports: GeneratedReportsModule,

  rateCards: RateCardsModule,

  warehouseProductLocations: WarehouseProductLocationsModule,

  bulkShipments: BulkShipmentsModule,

  itemsReport: ItemsReportModule,
  returns: ReturnsModule,

  automation: Automation,
  contentTemplates: ContentTemplates,
  automationsContent: AutomationsContent,

  orderGroups: OrderGroupsModule,

  diu: DiuModule,

  storefrontProductGroups: StorefrontProductGroupsModule,
  warehouseProductGroups: WarehouseProductGroupsModule,
  storefrontShipments: StorefrontShipmentsModule,

  'support-tickets': SupportTicketsModule,

  'billing/customerInvoices': BillingCustomerInvoicesModule,
  'billing/customerBillingLedger': BillingCustomerBillingLedgerModule,
  'billing/ptcFiles': BillingPtcFilesModule,
  'billing/carrierInvoices': BillingCarrierInvoicesModule,

  'manufacturing/workOrders': WorkOrdersModule,
  'manufacturing/billOfMaterials': BillOfMaterialsModule,
  'manufacturing/rndProjects': RnDProjectsModule,

  'docs/knowledgebasePages': DocsKnowledgebasePages,
  'docs/knowledgebaseGroups': DocsKnowledgebaseGroups,
  'docs/changelog': DocsChangelog,

  'tpl-adjustments': TPLAdjustmentsModule,
  'tpl-facilities': TPLFacilitiesModule,
  'tpl-customers': TPLCustomersModule,
  'tpl-shipmentServices': TPLShipmentServicesModule,
  'tpl-billingCodes': TPLBillingCodesModule,
  'tpl-uom': TPLUOMModule,
  'tpl-carriers': TPLCarriers,
  'tpl-receivers': TPLReceivers
};
