/* eslint-disable no-console */

import { register } from 'register-service-worker';

const notifyUserAboutUpdate = worker => {
  //let result = window.confirm("New version available. Load new version?");
  setTimeout(() => {
    if (window.$userHub) {
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: worker })
      )
    } else {
      notifyUserAboutUpdate(worker)
    }
  }, 3000);
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n');
    },
    registered(registration) {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound(registration) {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content just updated.');
      notifyUserAboutUpdate(registration);
    },
    activated() {
      console.log('New content just got activated; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });

}
