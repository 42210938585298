<template>
  <section class="table-listing">
    <div class="m-2" v-if="!hidePaginationTopFields">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="6"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPageLive"
            label="text"
            :reduce="item => item.value"
            :options="posPerPage"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="6"
          md="8"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <slot name="topRight"></slot>
        </b-col>
      </b-row>
    </div>
    <div v-if="loadingPage" class="m-1">
      <div class="alert alert-warning px-2 py-1">
        <h5>Loading Items...</h5>
      </div>
    </div>
    <div  v-else-if="failedLoading">
      <b-alert show variant="danger" class="text-center">
          <div classO="p-5">
            <div><i class="fa fa-5x fa-exclamation-triangle" /></div>
            <h4 class="mt-2">Failed to load items!</h4>
            <div class="pt-2 text-center">
                <b-button
                  variant="warning"
                  @click="ReloadItems"
                >
                  Try Again
                </b-button>
            </div>
          </div>
        </b-alert>
    </div>
    <div class="card card-warning" v-else-if="itemsList && itemsList.length < 1">
      <div class="card bg-light-primary">
        <b-row class="justify-content-center">
          <div class="col-12 col-xl-10">
            <h4 class="mb-0 text-center py-2">
              There are no items to list.
            </h4>
          </div>
        </b-row>
      </div>
    </div>
    <div v-else :class="moreCustom5Class">
      <div :class="{ card: insideCard, 'mb-0': !showPaginationRow}">
        <b-table
          class="m-0"
          :sticky-header="stickyHeader"
          :striped="striped"
          :head-variant="headVariant"
          :responsive="responsive"
          :bordered="bordered"
          :outlined="outlined"
          :small="small"
          :hover="hover"
          :fixed="fixed"
          :foot-clone="footClone"
          :items="itemsList"
          :sort-desc.sync="sortDescLive"
          :sort-by.sync="sortByLive"
          :fields="fields"
          custom-prop="any"
        >
          <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot :name="name" v-bind="slotData" />
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="showPaginationRow">
      <div class="mx-2 mb-2" v-if="!hidePaginationFields">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMetaFrom }} to {{ dataMetaTo }} of
              {{ options.totalRows }} entries</span
            >
          </b-col>
          <b-col cols="12" sm="3">
            <div class="form-row d-none">
              <div class="col">
                <label class="py-2 m-0">Go To: </label>
              </div>
              <div class="col">
                <b-form-input
                  v-model="jumpToPage"
                  type="number"
                  min="1"
                  placeholder=""
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-secondary"
                  @click="
                    pageLive = parseInt(jumpToPage);
                    jumpToPage = '';
                  "
                >
                  Go
                </button>
              </div>
            </div>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pageLive"
              :total-rows="options.totalRows"
              :per-page="perPageLive"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import vSelect from 'vue-select';
/*eslint no-self-assign: "off" */

export default {
  name: 'TableListing',
  components: {
    vSelect
  },
  methods: {
    ReloadItems() {
      this.$emit('update:pagination', this.paginationComputed);
        setTimeout(() => {
          this.$emit('pagination-changed', this.paginationComputed);
        }, 10);
    }
  },
  props: {
    failedLoading: {
      type: [Boolean, Function],
      default: false
    },
    moreCustom5Class: {
      default: 'mb-4 nopadinr'
    },
    showPaginationRow: {
      type: Boolean,
      default: true
    },
    pagination: {
      required: false,
      default: () => {
        return {
        perPage: 10,
        page: 1,
        sortBy: '',
        sortDesc: ''
        }
      }
    },
    options: {
      required: false,
      default: () => {
        return {
          totalRows: 0,
          loadedAll: false
        }
      }
    },
    hidePaginationTopFields: {
      type: [Boolean, Function],
      default: false
    },
    hidePaginationFields: {
      type: [Boolean, Function],
      default: false
    },
    insideCard: {
      type: Boolean,
      default: true
    },
    loadingPage: {
      type: [Boolean, Function],
      default: false
    },
    headVariant: {
      type: String,
      default: 'dark'
    },
    stickyHeader: {
      type: [Boolean, Function],
      default: false
    },
    responsive: {
      type: [Boolean, Function],
      default: true
    },
    striped: {
      type: [Boolean, Function],
      default: true
    },
    small: {
      type: [Boolean, Function],
      default: false
    },
    fixed: {
      type: [Boolean, Function],
      default: true
    },
    hover: {
      type: [Boolean, Function],
      default: false
    },
    footClone: {
      type: [Boolean, Function],
      default: false
    },
    outlined: {
      type: [Boolean, Function],
      default: true
    },
    bordered: {
      type: [Boolean, Function],
      default: true
    },
    items: {
      required: true,
      type: [Array, Function]
    },
    fields: {
      required: true,
      type: [Array, Function]
    }
    // sortBy: {
    //   required: false,
    //   type: String,
    //   default: ''
    // },
    // sortDesc: {
    //   required: false,
    //   type: String,
    //   default: ''
    // }
  },
  data() {
    return {
      perPageInputVal: 10,
      jumpToPage: '',
      posPerPage: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        },
        {
          value: 25,
          text: '25'
        },
        {
          value: 50,
          text: '50'
        },
        {
          value: 100,
          text: '100'
        },
        {
          value: 150,
          text: '150'
        },
        {
          value: 200,
          text: '200'
        },
        {
          value: 500,
          text: '500'
        },
        {
          value: 1000,
          text: '1000'
        }
      ]
    };
  },

  mounted() {
    if(this.hidePaginationFields) {
      this.pagination.perPage = 50000;
      this.options.loadedAll = true;
    } else {
      this.pagination.perPage = 10;
      this.options.loadedAll = false;
    }
  },
  computed: {
    dataMetaFrom: function() {
      return (
        (this.pagination.page - 1) * this.pagination.perPage + 1
      );
    },
    dataMetaTo: function() {
      return Math.min(
        this.pagination.page * this.pagination.perPage,
        this.options.totalRows
      );
    },
    perPageInput: {
      get() {
        return this.perPageInputVal;
      },
      set(val) {
        this.perPageInputVal = val;
      }
    },
    itemsList: {
      get() {
        let itemsShown = this.items;
        if (this.optionsComputed.loadedAll) {
          return itemsShown.slice(
            (this.pagination.page - 1) * this.pagination.perPage,
            this.pagination.page * this.pagination.perPage
          );
        }

        return itemsShown;
      },
      set(val) {}
    },
    optionsComputed: {
      get() {
        let defaultVals = {
          totalRows: 0,
          loadedAll: false
        };
        let mainOpts = this.options;
        return { ...defaultVals, ...mainOpts };
      },
      set(val) {
        this.$emit('options-changed', val);
        this.$emit('update:options', val);
      }
    },
    paginationComputed: {
      get() {
        let defaultVals = {
          perPage: 10,
          page: 1,
          sortBy: '',
          sortDesc: false
        };
        let mainPags = this.pagination;
        let val = { ...defaultVals, ...mainPags };
        this.perPageInputVal = val.perPage;
        return val;
      },
      set(val) {
        this.pagination = val;
        this.$emit('update:pagination', val);
        setTimeout(() => {
          this.$emit('pagination-changed', val);
        }, 10);
      }
    },
    perPageLive: {
      get() {
        let defVal = 10;
        let mainVal = this.pagination.perPage;
        if (!mainVal) {
          mainVal = defVal;
        }
        return mainVal;
      },
      set(val) {
        this.$set(this.paginationComputed, 'perPage', val);
        this.paginationComputed = this.paginationComputed;
      }
    },
    pageLive: {
      get() {
        let defVal = 1;
        let mainVal = this.pagination.page;
        if (!mainVal) {
          mainVal = defVal;
        }
        return mainVal;
      },
      set(val) {
        this.$set(this.paginationComputed, 'page', val);
        this.paginationComputed = this.paginationComputed;
      }
    },
    sortByLive: {
      get() {
        let defaultSort = '';
        let shouldSort = this.paginationComputed.sortBy;
        return shouldSort;
      },
      set(val) {
        this.$emit('update:sortBy', val);
        this.$set(this.paginationComputed, 'sortBy', val);
        this.paginationComputed = this.paginationComputed;
      }
    },
    sortDescLive: {
      get() {
        let defaultSort = '';
        let shouldSort = this.paginationComputed.sortDesc;
        return shouldSort;
      },
      set(val) {
        this.$emit('update:sortDesc', val);
        this.$set(this.paginationComputed, 'sortDesc', val);
        this.paginationComputed = this.paginationComputed;
      }
    }
  }
};
</script>

