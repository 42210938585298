import modules from '../../../../ManagementApp/wwwroot/client/src/helpers/store/modules';
import apiGens from '../../../../ManagementApp/wwwroot/client/src/helpers/utils/apiHelperGenerators.js';
import defFilters from '../../../../ManagementApp/wwwroot/client/src/helpers/utils/filters.js';
import mixins from '../../../../ManagementApp/wwwroot/client/src/helpers/utils/vueMixins.js';

import notificationsHubModule from '../../../../ManagementApp/wwwroot/client/src/helpers/notificationsHub.js';

import HeaderQuickSearchComp from '../../../../ManagementApp/wwwroot/client/src/containers/HeaderQuickSearch.vue';
import OrderSplitPopupComp from '../../../../ManagementApp/wwwroot/client/src/views/components/OrderSplitPopup.vue';
import BsSelectComp from '../../../../ManagementApp/wwwroot/client/src/views/components/BsSelect.vue';
import moneyComp from '../../../../ManagementApp/wwwroot/client/src/views/components/vDecimalInput/index.js';
import OrderCreate from '../../../../ManagementApp/wwwroot/client/src/views/pages/storefronts/orders/Create.vue';
import OrdersImport from '../../../../ManagementApp/wwwroot/client/src/views/pages/storefronts/orders/Import.vue';

import OrderDetails from '../../../../ManagementApp/wwwroot/client/src/views/pages/storefronts/orders/View.vue';

import AutomationFormPageComp from '../../../../ManagementApp/wwwroot/client/src/views/pages/other/automation/Form.vue';
import AutomationContentFormPageComp from '../../../../ManagementApp/wwwroot/client/src/views/pages/other/automationsContent/Form.vue';

import BulkShipmentDetailsComp from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/bulkShipments/Form.vue';

//import ImportASN from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders/ImportASN.vue';
//import CreateASN from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders/CreateASN.vue';

import WarehouseItemsByLocationPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/warehouses/warehouseProductLocations/Index.vue';

import WarehouseItemFormPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/warehouses/warehouseItems/WarehouseItemForm.vue';

import POForm from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders2/Form.vue';
import ViewShipment from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders2/ViewShipment.vue';
import CreateShipmentForm from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders2/CreateShipment.vue';
import CreateBulkShipmentPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/bulkShipments/Form.vue';
import CreateManualShipmentPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/manualShipments/Form.vue';

import DocsHomePag from '../../../../ManagementApp/wwwroot/client/src/views/pages/other/DocsHome.vue';
import ViewKnowledgeArticlePag from '../../../../ManagementApp/wwwroot/client/src/views/pages/other/ViewKnowledgeArticle.vue';
import ViewKnowledgeGroupPag from '../../../../ManagementApp/wwwroot/client/src/views/pages/other/ViewKnowledgeGroup.vue';

import POView from '../../../../ManagementApp/wwwroot/client/src/views/pages/transfers/purchaseOrders2/View.vue';

import POItemsSummaryComp from '../../../../ManagementApp/wwwroot/client/src/views/components/POItemsSummary.vue';

import OrderReturnsPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/storefronts/returns/Index.vue';
import WarehouseItemManageWidgetsComp from '../../../../ManagementApp/wwwroot/client/src/views/components/partials/WarehouseItemManageWidgets.vue';
import InventoryStatusBadgeComp from '../../../../ManagementApp/wwwroot/client/src/views/components/partials/InventoryStatusBadge.vue';

import OrderReturnsFormPage from '../../../../ManagementApp/wwwroot/client/src/views/pages/storefronts/returns/Form.vue';
import CommunicationsIndexComp from '../../../../ManagementApp/wwwroot/client/src/views/pages/communications/Index.vue';

import DiscussionsWidgetComponent from '../../../../ManagementApp/wwwroot/client/src/views/components/DiscussionsWidget.vue';

export const WarehouseProductLocationsModule = modules.warehouseProductLocations;
export const WarehouseProductsModule = modules.warehouseProducts;
export const ReturnsModule = modules.returns;
export const OrdersModule = modules.orders;
export const AccountModule = modules.account;
export const GlobalModule = modules.g;
export const ContentTemplatesModule = modules.contentTemplates;
export const AutomationModule = modules.automation;

export const ManualShipmentsModule = modules.manualShipments;
export const BulkShipmentsModule = modules.bulkShipments;
export const CustomerInvoicesModule = modules['billing/customerInvoices'];

export const PurchaseOrdersStoreModule = modules.purchaseOrders2;
export const WarehouseTransfersStoreModule = modules.warehouseTransfers;
export const AdvancedShipNoticeStoreModule = modules.warehouseShipments;

export const crudMixinGen = mixins.crudMixinGen;
export const apiHelperGenerators = apiGens;
export const filters = defFilters;

export const DocsHome = DocsHomePag;
export const ViewKnowledgeArticle = ViewKnowledgeArticlePag;
export const ViewKnowledgeGroup = ViewKnowledgeGroupPag;

export const POItemsSummary = POItemsSummaryComp;
export const OrderDetailsPage = OrderDetails;
export const BulkShipmentDetailsPage = BulkShipmentDetailsComp;

export const AutomationFormPage = AutomationFormPageComp;
export const AutomationContentFormPage = AutomationContentFormPageComp;

//export const CreateASNPage = CreateASN;
export const CreateBulkShipmentForm = CreateBulkShipmentPage;
export const CreateManualShipmentForm = CreateManualShipmentPage;

export const WarehouseItemForm = WarehouseItemFormPage;
//export const ImportASNPage = ImportASN;
export const POFormPage = POForm;
export const ViewShipmentPage = ViewShipment;
export const CreateShipmentFormPage = CreateShipmentForm;

export const POViewPage = POView;

export const OrderReturns = OrderReturnsPage;
export const OrderReturnsForm = OrderReturnsFormPage;

export const myCommonDataMixin = mixins.myCommonDataMixin;
export const OrderCreatePage = OrderCreate;
export const OrdersImportPage = OrdersImport;

export const bsSelect = BsSelectComp;
export const money = moneyComp;
export const OrderSplitPopup = OrderSplitPopupComp;
export const NotificationsHub = notificationsHubModule;
export const HeaderQuickSearch = HeaderQuickSearchComp;

export const WarehouseItemManageWidgets = WarehouseItemManageWidgetsComp;
export const InventoryStatusBadge = InventoryStatusBadgeComp;

export const WarehouseItemsByLocation = WarehouseItemsByLocationPage;
export const DiscussionsWidgetComp = DiscussionsWidgetComponent;
export const CommunicationsIndex = CommunicationsIndexComp;

export const DocsKnowledgebasePagesModule = modules['docs/knowledgebasePages'];
export const DocsKnowledgebaseGroupsModule = modules['docs/knowledgebaseGroups'];
export const DocsChangelogModule = modules['docs/changelog'];

export default {
  CommunicationsIndex: CommunicationsIndexComp,
  DiscussionsWidgetComp: DiscussionsWidgetComponent,

  DocsKnowledgebasePagesModule: modules['docs/knowledgebasePages'],
  DocsKnowledgebaseGroupsModule: modules['docs/knowledgebaseGroups'],
  DocsChangelogModule: modules['docs/changelog'],

  WarehouseItemsByLocation: WarehouseItemsByLocationPage,

  OrderReturns: OrderReturnsPage,
  OrderReturnsForm: OrderReturnsFormPage,

  PurchaseOrdersStoreModule: modules.purchaseOrders2,
  WarehouseTransfersStoreModule: modules.warehouseTransfers,
  AdvancedShipNoticeStoreModule: modules.warehouseShipments,

  InventoryStatusBadge: InventoryStatusBadgeComp,
  WarehouseItemManageWidgets: WarehouseItemManageWidgetsComp,

  NotificationsHub: notificationsHubModule,
  HeaderQuickSearch: HeaderQuickSearchComp,
  OrderSplitPopup: OrderSplitPopupComp,
  money: moneyComp,
  //CreateASNPage: CreateASN,
  CreateBulkShipmentForm: CreateBulkShipmentPage,
  CreateManualShipmentForm: CreateManualShipmentPage,
  //ImportASNPage: ImportASN,

  CreateShipmentFormPage: CreateShipmentForm,
  POFormPage: POForm,
  ViewShipmentPage: ViewShipment,
  POViewPage: POView,
  BulkShipmentDetailsPage: BulkShipmentDetailsComp,

  AutomationFormPage: AutomationFormPageComp,
  AutomationContentFormPage: AutomationContentFormPageComp,

  DocsHome: DocsHomePag,
  ViewKnowledgeArticle: ViewKnowledgeArticlePag,
  ViewKnowledgeGroup: ViewKnowledgeGroupPag,

  bsSelect: BsSelectComp,
  ReturnsModule: modules.returns,
  OrdersModule: modules.orders,
  OrderCreatePage: OrderCreate,
  OrdersImportPage: OrdersImport,
  OrderDetailsPage: OrderDetails,
  POItemsSummary: POItemsSummaryComp,
  filters: defFilters,
  AccountModule: modules.account,
  WarehouseProductsModule: modules.warehouseProducts,
  WarehouseProductLocationsModule: modules.warehouseProductLocations,
  GlobalModule: modules.g,
  contentTemplates: modules.contentTemplates,
  automation: modules.automation,

  ManualShipmentsModule: ManualShipmentsModule,
  BulkShipmentsModule: BulkShipmentsModule,
  CustomerInvoicesModule: CustomerInvoicesModule,
  crudMixinGen: mixins.crudMixinGen,
  myCommonDataMixin: mixins.myCommonDataMixin,
  mixins: mixins,
  apiHelperGenerators: apiGens
};
