export default {
  namespaced: true,

  state: () => ({
    filters: {}
  }),

  mutations: {
    setFilters(state, payload) {
      state.filters = payload;
    }
  },

  getters: {
    getFilters: state => {
      return state.filters;
    }
  }
};
