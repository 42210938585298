<template>
  <section class="communications overflow-hidden shadow">
    <!-- Channels List --->
    <aside class="communications-channelslist-sidebar" :class="{'no-channel-open': activeChannel == null, 'has-channel-open': activeChannel != null}">
        <div class="bg-info commcenter-btnbanner">
          <div class="h5 mb-0 py-1">
            <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-link d-inline-block m-0 text-white">
                <i class="fa fa-arrow-left"></i>
            </button>
            <span>Communications Center</span>
            <b-button v-if="!is('brand-user')" :to="{name: 'Common.UserProfiles.Index'}" variant="link" size="sm" class="float-right d-inline-block m-0 text-white">
                <i class="fa fa-users mr-1"></i>
                <i class="fa fa-arrow-right"></i>
            </b-button>
          </div>
        </div>

        <div class="messages-box">
          <div class="container">
            <div class="form-row py-1">
              <b-form-input class="col" @change="event => {event.target.value == '' ? loadChannels() : null}" v-on:keyup.enter="loadChannels" v-model="searchChannelsTxt" placeholder="Search..." />
              <b-button class="col-auto px-2" variant="light" type="button" @click="loadChannels"><i class="fa fa-search"></i></b-button>
            </div>
          </div>
          <div class="list-group rounded-0">
            <!-- <b-button @click="CreateTestChannel()">create test</b-button> -->
            <div @click="OpenChannel(channel)" class="list-group-item list-group-item-action list-group-item-light rounded-0"
            :class="{'active  text-white': (activeChannelId == channel.id)}"
              v-for="channel in channels" :key="channel.id">
              <div class="media">
                <div v-if="(channel.type == 'DirectMessages')" class="mr-1">
                  <b-avatar v-if="getChannelAvatarUrl(channel)"  class="rounded-circle" :src="getChannelAvatarUrl(channel)"></b-avatar>
                  <b-avatar v-else  class="rounded-circle" :src="'https://ui-avatars.com/api/?name='+getChannelName(channel)"></b-avatar>
                </div>
                <div class="media-body ml-1">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <h6 class="mb-0">{{ getChannelName(channel) }}</h6>
                    <small class="badge badge-danger" v-if="getUnreadMessages(channel)">{{ getUnreadMessages(channel) }}</small>
                  </div>
                  <p class="font-italic text-muted mb-0 text-small" v-if="channel.type != 'DirectMessages'">{{ channel.description }}</p>
                  <div class="clearfix">
                    <div class="text-left d-inline-block">
                      <i class="small font-weight-bold">
                        <template v-if="channel.type == 'ManufacturingInternalSupport'">Manufacturing (internal)</template>
                        <template v-else-if="channel.type == 'DirectMessages'">Direct Messages</template>
                        <template v-else-if="channel.type == 'PrivateGroupChat'">Group Chat</template>
                        <template v-else-if="channel.type == 'ClientSupport'">Private Client Support</template>
                        <template v-else-if="channel.type == 'ClientSupportPublic'">Public Client Support</template>
                        <template v-else-if="channel.type == 'VendorSupportPublic'">Private Vendor Support</template>
                        <template v-else-if="channel.type == 'ManufacturingClientSupportPublic'">Manufacturing</template>
                        <template v-else-if="channel.type == 'ManufacturingClientSupport'">Manufacturing Client Support</template>
                      </i>
                    </div>
                    <div class="pull-right d-inline-block">
                      <small class="small font-weight-bold" v-if="!channel.lastMessageDateTimeUtc.endsWith('Z')">{{ (channel.lastMessageDateTimeUtc + 'Z') | moment('h:mm a | MMM Do') }}</small>
                      <small class="small font-weight-bold" v-else>{{ (channel.lastMessageDateTimeUtc) | moment('h:mm a') }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-alert v-if="channels.length == 0 && !loadingPage" show variant="warning" class="p-3"><b>No channels.</b></b-alert>
            <b-alert v-else-if="channels.length == 0 && loadingPage" show variant="warning" class="p-1"><b>Loading...</b></b-alert>
          </div>
          <div class="p-2 text-center" v-if="(pagination.page < pagination.totalPages)">
            <b-button variant="secondary" :disabled="loadingPage" @click="loadMore()">Load More</b-button>
          </div>
        </div>
    </aside>
    <!-- Chat Box-->
    <section class="px-0 communications-main" :class="{'no-channel-open': activeChannel == null, 'has-channel-open': activeChannel != null}">
      <ChannelWindow v-if="activeChannel" :channel.sync="activeChannel"></ChannelWindow>
      <b-alert v-else show variant="info" class="p-3"><b>No channel is opened.</b></b-alert>
    </section>
  </section>
</template>

<script>
import ChannelWindow from './ChannelWindow.vue';
import { myCommonDataMixin } from '@/helpers/utils/vueMixins.js';
export default {
  mixins: [myCommonDataMixin],
  name: 'WarehousesIndex',
  components: {
    ChannelWindow
  },
  props: {
    openChannelId: {
      type: String,
      required: false
    },
  },
  beforeDestroy() {
    this.$userHub.$off('communications-channel-member-update');
  },
  data: () => ({
    activeChannel: null,
    activeChannelId: null,
    loadingPage: false,
    pagination: {
      perPage: 25,
      page: 1
    },
    paginationChannel: {
      perPage: 25,
      page: 1
    },
    channels: [],
    searchChannelsTxt: '',
    channelsFilter: {}
  }),
  computed: {
    currentUserId: {
      get() {
        return this.$store.getters['account/getUserData'].id;
      },
      set(val) {

      }
    }
  },
  watch: {
    activeChannel: {
      handler: function(val, oldVal) {
        if(val == null || !val) {
          this.channels = [];
          this.activeChannel = null;
          this.loadChannels();
        }
      },
      deep: true
    },
    openChannelId: {
      handler: function(val, oldVal) {
        let fndChnl = this.channels.find(a => a.id == val);
        if(fndChnl)
          this.OpenChannel(fndChnl);
        else
          this.loadChannelById(val);
      },
      deep: true
    },
    pagination: {
      handler: function(val, oldVal) {
        this.loadchannels();
      },
      deep: true
    },
    paginationChannel: {
      handler: function(val, oldVal) {
        this.loadchannels();
      },
      deep: true
    }
  },
  mounted() {
    if(this.openChannelId)
    {
      this.loadChannelById(this.openChannelId);
    }
    let queryHas = this.$route.query;
    if (queryHas && queryHas.channelId) {
        this.$router
        .replace({name: 'Communications.Index', params: {openChannelId: queryHas.channelId}})
        .catch(err => {});
      this.prepInit();
      this.loadChannels();
    } else {
      this.prepInit();
      this.loadChannels();
    }
  },

  methods: {
    prepInit() {
      this.$userHub.$off('communications-channel-member-update');
      this.$userHub.$on('communications-channel-member-update', (memberInfo) => {
        let fndChannelInList = this.channels.find(a => a.id == memberInfo.channelId);
        if(fndChannelInList) {
          let memberInfoOld = fndChannelInList.members.find(a => a.id == memberInfo.id);
          if(memberInfoOld) {
            let newLastMessagedate = new Date(memberInfo.lastRead);
            let oldLastMessagedate = new Date(memberInfoOld.lastRead);
            console.log('communications-channel-member-update', newLastMessagedate, 'oldLastMessagedate', oldLastMessagedate)
            if(newLastMessagedate >= oldLastMessagedate) {
              memberInfoOld.unreadMessages = memberInfo.unreadMessages;
              memberInfoOld.lastRead = memberInfo.lastRead;
              memberInfoOld.lastWrite = memberInfo.lastWrite;
              fndChannelInList.lastMessageDateTimeUtc = memberInfo.lastWrite;
            } else {
              let unreadMsgsToUndo =  memberInfo.unreadMessages;
              if(unreadMsgsToUndo<1)
                unreadMsgsToUndo = 1;
              this.$userHub.$emit('CommunicationsRemoveUnreadNum', unreadMsgsToUndo)
            }
          }
        }
      });
    },
    getChannelAvatarUrl(channel) {
      let avatarUrl = '';
      if(channel.type != 'DirectMessages') {
        channelName = channel.name;
      } else {
        if(channel.members != null) {
          let fndMemberInfo = channel.members.find(x => x.appUserId != this.currentUserId);
          if(fndMemberInfo != null) {
            avatarUrl = fndMemberInfo.appUser.avatarUrl;
          } else {
            fndMemberInfo = channel.members.find(x => x.appUserId == this.currentUserId);
            avatarUrl = fndMemberInfo.appUser.avatarUrl;
          }
        }
      }
      return avatarUrl;
    },
    getChannelName(channel) {
      let channelName = '';
      if(channel.type != 'DirectMessages') {
        channelName = channel.name;
      } else {
        let otherUserFullName = '';
        if(channel.members != null) {
          let fndMemberInfo = channel.members.find(x => x.appUserId != this.currentUserId);
          if(fndMemberInfo != null) {
            otherUserFullName = fndMemberInfo.appUser.fullName;
          } else {
            let fndMemberInfo = channel.members.find(x => x.appUserId == this.currentUserId);
            otherUserFullName = fndMemberInfo.appUser.fullName + ' (My Notes)';
          }
          if(otherUserFullName) {
            channelName = otherUserFullName;
          }
        } else {
          channelName = 'Other';
        }
      }
      return channelName;
    },
    getUnreadMessages(channel) {
      let unreadNum = 0;
      if(channel.members != null) {
        let fndMemberInfo = channel.members.find(x => x.appUserId == this.currentUserId);
        if(fndMemberInfo != null) {
          unreadNum = fndMemberInfo.unreadMessages;
        }
      }
      return unreadNum;
    },
    OpenChannel(channel) {
      if(channel) {
        //this.loadMessages(channel.id);
        this.activeChannel = channel;
        this.activeChannelId = channel.id;
        this.$router
        .replace({name: 'Communications.Index', params: {openChannelId: this.activeChannelId}})
        .catch(err => {})
        //this.activeChannelId);
      }
    },
    loadChannelById(chanId) {
      if(chanId) {
        if(this.channels.find(a => a.id == channelLoaded.id) == null) {
          this.$http
            .get('/api/communications/channels/' + chanId)
            .then(resp => {
              let channelLoaded = resp.data;
              this.OpenChannel(channelLoaded);
              if(this.channels.find(a => a.id == channelLoaded.id) == null)
                this.channels.push(channelLoaded);

            })
            .catch(err => {
              console.error('channels err', err);
              this.loadingPage = false;
            });
        } else {
          this.OpenChannel(channelLoaded);
        }
      }
    },
    loadMore() {
      if(this.pagination.page < this.pagination.totalPages) {
        this.pagination.page++;
        this.loadChannels();
      }
    },
    loadChannels() {
      if (this.loadingPage) {
        return;
      }
      this.loadingPage = true;
      this.channelsFilter.page = parseInt(this.pagination.page);
      this.channelsFilter.perPage = parseInt(this.pagination.perPage);
      this.channelsFilter.search = this.searchChannelsTxt;
      if(this.searchChannelsTxt) {
        this.channels = [];
        this.OpenChannel(null);
      }
      this.$http
        .post('/api/communications/channels/listItems', this.channelsFilter)
        .then(resp => {
          this.loadingPage = false;
          // this.pagination.page = resp.data.page
          this.pagination.totalPages = resp.data.totalPages
          resp.data.items.forEach(chnl => {
            if(this.channels.find(a => a.id == chnl.id) == null)
              this.channels.push(chnl)
          });
          if(this.channels.length && !this.activeChannelId) {
            let firstChannel = this.channels[0];
            //this.OpenChannel(firstChannel);
          }
        })
        .catch(err => {
          console.error('channels err', err);
          this.loadingPage = false;
        });
    }
  }
};
</script>
